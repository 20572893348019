export function initialMatchGroupState(matchGroups) {
  // return { [matchGroupKey]: [matchFieldKey] }
  return matchGroups.reduce((acc, g) => {
    return {
      ...acc,
      [g.key]: g.match_fields.flatMap((f) => (f.is_default ? [f.key] : [])),
    };
  }, {});
}

export function initialMatchGroupErrorState(matchGroups) {
  // return { [matchGroupKey]: null }
  return matchGroups.reduce((acc, g) => {
    return { ...acc, [g.key]: null };
  }, {});
}

// validator for the form
export function matchConditionsArePresent(label = "cannot be empty") {
  return (input) => {
    let error = Object.entries(input.values.matchConditions).reduce(
      (acc, [key, fields]) => {
        acc = {
          ...acc,
          [key]: fields.length === 0 ? label : null,
        };
        return acc;
      },
      {}
    );
    const ok = Object.values(error).every((v) => v === null);
    return ok ? { ok: input } : { error };
  };
}
