import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Auth0Provider } from "@auth0/auth0-react";
import "./main.css";
import { persistor, store } from "./store";
import { App } from "./App";
import { Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { init as amplitudeInit } from "@amplitude/analytics-browser";

if (process.env.REACT_APP_SENTRY_DSN) {
  console.log("Sentry DSN found, initializing Sentry");
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,
  });
}

class ErrorBoundary extends React.Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }
  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="splash">
          <Typography variant="h4" component="h1">
            Something went wrong.
          </Typography>
          <Typography marginTop={"32px"}>{this.state.error.message}</Typography>
        </div>
      );
    }
    return this.props.children;
  }
}

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
// const scope = process.env.REACT_APP_AUTH0_SCOPES;
const redirectUri = window.location.origin;

if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
  console.log("Amplitude API key found, initializing Amplitude");
  amplitudeInit(process.env.REACT_APP_AMPLITUDE_API_KEY, {
    defaultTracking: true,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <Auth0Provider
            redirectUri={redirectUri}
            domain={domain}
            clientId={clientId}
            audience={audience}
            cacheLocation="localstorage"
          >
            <App />
          </Auth0Provider>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
