export function toGridRowsRepresentation({
  standard,
  specialties = [],
  designations = [],
  requirements = [],
  metricsLookup = {},
}) {
  const rows = [];
  const has_access_metrics_alternative =
    standard?.access_metrics_logical_link.toLowerCase() === "or" &&
    Object.values(metricsLookup).filter((m) => m.type === "access").length > 1;
  for (const specialty of specialties) {
    for (const designation of designations) {
      const requirementMetrics =
        requirements[specialty.id]?.[designation.id] ?? {};
      let rowMetrics = {};
      for (const [metric_id, adequacyMetric] of Object.entries(metricsLookup)) {
        const requirement = requirementMetrics[metric_id];
        const isAccess = adequacyMetric.type === "access";
        rowMetrics[`${adequacyMetric.code}_id`] = requirement?.id ?? null;
        rowMetrics[adequacyMetric.code] = requirement?.value ?? null;
        if (isAccess) {
          if (!rowMetrics.access_threshold)
            rowMetrics.access_threshold =
              requirement?.passing_percentage ?? null;
          if (!rowMetrics.access_count)
            rowMetrics.access_count = requirement?.passing_count ?? null;
        }
      }
      rows.push({
        id: `${specialty.id}-${designation.id}`,
        specialty_id: specialty.id,
        specialty_name: specialty.name,
        specialty_type: specialty.facility_only ? "Facility" : "Provider",
        designation_id: designation.id,
        designation_name: designation.name,
        has_access_metrics_alternative,
        ...rowMetrics,
      });
    }
  }
  return rows;
}
