import { useFlags } from "launchdarkly-react-client-sdk";

export const OIG_SANCTIONED_FLAG_KEY = "oig-sanctioned";

export function useOigSanctionedColumnEnabled(columns) {
  const isOigColumnEnabled = useFlags()[OIG_SANCTIONED_FLAG_KEY];
  if (!isOigColumnEnabled) return columns.slice(0, -1);

  return columns;
}
