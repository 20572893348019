import { Stack } from "@mui/material";
import { blue, red } from "@mui/material/colors";

function getGapCellContent(value) {
  return value === 1 ? "GAP" : "NO GAP";
}

export function getGapSummaryColumns(data = []) {
  const countiesColumns = {};
  for (const { counties } of data) {
    for (const { county_id, county_name } of counties) {
      countiesColumns[county_id] = {
        field: county_id,
        headerName: county_name,
        type: "number",
        valueFormatter: (params) => {
          if (typeof params.id === "string") return params.value; // summary row
          return getGapCellContent(params.value);
        },
        renderCell: (params) => {
          const { value, aggregation } = params;
          return (
            <Stack
              direction={"row"}
              width={"100%"}
              height={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                padding: "0 10px",
                lineHeight: "1.43",
                color: aggregation
                  ? blue[800]
                  : value === 1
                  ? red[900]
                  : "inherit",
                backgroundColor: value === 1 ? red[50] : "inherit",
              }}
            >
              {aggregation ? value : getGapCellContent(value)}
            </Stack>
          );
        },
      };
    }
  }

  return [
    { field: "specialty_name", headerName: "Specialty", width: 350 },

    {
      field: "gap_count",
      headerName: "Gaps",
      type: "number",
    },

    ...Object.values(countiesColumns),
  ];
}

export function getGapSummaryRows(data = []) {
  return data.map(({ counties, ...rest }) => ({
    ...rest,
    ...counties.reduce(
      (agg, { county_id, county_name, ...countyGap }) => ({
        ...agg,
        ...countyGap,
      }),
      {}
    ),
  }));
}

export function getGapSummaryAggregationModel(columns) {
  const model = {};
  for (const { field } of columns.slice(1)) model[field] = "sum";
  return { model };
}
