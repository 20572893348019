import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setPathname } from "../../features/navigation";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

export function AppDrawerNavItem({
  label,
  icon,
  to,
  onClick,
  isSelected,
  ...otherProps
}) {
  const { pathname } = useLocation();
  const linkTo = to || ".";
  const dispatch = useDispatch();
  const onClickHandler = (e) => {
    dispatch(setPathname(linkTo));
    if (onClick) onClick(e);
  };
  return (
    <Link to={linkTo} onClick={onClickHandler} {...otherProps}>
      <ListItemButton
        selected={isSelected ? isSelected(pathname) : to === pathname}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </Link>
  );
}
