import { withPrefixSuffix } from "../../utils";
import { APP_ENV_KEY } from "../../utils/appEnv";
import { PersistLocalStorage } from "../persistInput/storageApi";

const LS_FILTERS_KEY = `j2StoredFilters${withPrefixSuffix(APP_ENV_KEY, "_")}`;
const LEGACY_KEY = "j2MapGridFilters";

class FiltersPersistLocalStorage extends PersistLocalStorage {
  getItems() {
    return JSON.parse(
      localStorage.getItem(this.storageKey) ||
        localStorage.getItem(LEGACY_KEY) ||
        null
    );
  }
}

export const filtersStorage = new FiltersPersistLocalStorage(LS_FILTERS_KEY);
