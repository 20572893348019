import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

// import DashboardOutlined from "@mui/icons-material/DashboardOutlined";
import ScoreOutlined from "@mui/icons-material/ScoreOutlined";
// import PieChartOutlined from "@mui/icons-material/PieChartOutlined";
import MapOutlined from "@mui/icons-material/MapOutlined";
import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import InboxOutlined from "@mui/icons-material/InboxOutlined";
import AppRegistrationOutlined from "@mui/icons-material/AppRegistrationOutlined";

import logo from "../assets/j2logo.png";
import "./AppDrawer/AppDrawer.css";
import Account from "./Account";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPathname } from "../features/navigation";
import { HelpOutline, HubOutlined, TuneOutlined } from "@mui/icons-material";
import { useSelfServeAllowed } from "../hooks/useSelfServeAllowed";
import { ArticleOutlined } from "@mui/icons-material";
import { useCustomStandardsEnabled } from "../hooks/useCustomStandardsEnabled";
import { useNetworkContextAmplitudeEvent } from "../hooks/useNetworkContextAmplitudeEvent";

function AppDrawerHeader({ label, children }) {
  return (
    <ListItem sx={{ padding: "0px 16px" }}>
      <Typography variant="overline" component="h3" className="sectionHeader">
        {label}
      </Typography>
      {children}
    </ListItem>
  );
}

function AppDrawerNavItem({
  label,
  icon,
  to,
  onClick,
  isSelected,
  ...otherPorps
}) {
  // const navigate = useNavigate();
  const { pathname } = useLocation();
  const linkTo = to || "/map";
  const dispatch = useDispatch();
  const onClickHandler = (e) => {
    dispatch(setPathname(linkTo));
    if (onClick) onClick(e);
  };
  return (
    <Link to={linkTo} onClick={onClickHandler} {...otherPorps}>
      <ListItemButton
        selected={isSelected ? isSelected(pathname) : to === pathname}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </Link>
  );
}

export function AppDrawer() {
  const isSelfServeAllowed = useSelfServeAllowed();
  const isCustomStandardsEnabled = useCustomStandardsEnabled();
  const selfServeMenuItem = isCustomStandardsEnabled
    ? { label: "Networks", icon: <HubOutlined /> }
    : { label: "Self serve", icon: <TuneOutlined /> };
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  return (
    <Drawer variant="permanent" className="app-drawer">
      <img className="logo" src={logo} alt="Logo" width={80} height={80} />
      <Box className="app-drawer-main">
        <List>
          <AppDrawerHeader label="Analyze" />
          <AppDrawerNavItem
            to="/scorecard"
            label="Scorecard"
            icon={<ScoreOutlined />}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "Scorecard",
              })
            }
          />
          <AppDrawerNavItem
            to="/map"
            label="Map"
            icon={<MapOutlined />}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "Map",
              })
            }
          />
          <AppDrawerNavItem
            to="/reports"
            label="Reports"
            icon={<ArticleOutlined />}
            isSelected={(pathname) => pathname.startsWith("/reports")}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "Reports",
              })
            }
          />
        </List>
        <Divider />
        <List>
          <AppDrawerHeader label="Providers" />
          <AppDrawerNavItem
            to="/current-providers"
            label="Current Network"
            icon={<InboxOutlined />}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "In Network",
              })
            }
          />
          <AppDrawerNavItem
            to="/oon-providers"
            label="Out Of Network"
            icon={<AddBoxOutlined />}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "Out of Network",
              })
            }
            handle={{ title: "Out of Network" }}
          />
          <AppDrawerNavItem
            to="/edits"
            label="Edits"
            icon={<AppRegistrationOutlined />}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "Edits",
              })
            }
          />
        </List>
        {isSelfServeAllowed && (
          <>
            <Divider />
            <List>
              <AppDrawerHeader label="Manage" />
              <AppDrawerNavItem
                to="/self-serve/networks"
                {...selfServeMenuItem}
                isSelected={(pathname) =>
                  pathname.startsWith("/self-serve/networks")
                }
                onClick={() =>
                  trackNetworkContextAmplitudeEvent("View portal page", {
                    page: "Networks",
                  })
                }
              />
              {isCustomStandardsEnabled && (
                <AppDrawerNavItem
                  to="/self-serve/standards"
                  label="Standards"
                  icon={<TuneOutlined />}
                  isSelected={(pathname) =>
                    pathname.startsWith("/self-serve/standards")
                  }
                  onClick={() =>
                    trackNetworkContextAmplitudeEvent("View portal page", {
                      page: "Standards",
                    })
                  }
                />
              )}
            </List>
          </>
        )}
        <Divider />
        <List>
          <AppDrawerHeader label="Account" />
          <AppDrawerNavItem
            to="/user-guide"
            target="_blank"
            rel="noopener noreferrer"
            label="User Guide"
            icon={<HelpOutline />}
            isSelected={(pathname) => pathname.startsWith("/user-guide")}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "User Guide",
              })
            }
          />
        </List>
      </Box>
      <Account />
    </Drawer>
  );
}
