import { Dialog, DialogContent, Typography } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { useSelector } from "react-redux";
import { useGetStandardsQuery } from "../../api/networksApi";
import { selectSelectedNetworkId } from "../../features/selectedNetwork";
import { selectSpecialtiesMap } from "../../features/specialties";
import { Table } from "../Table";
import { getRowsSpecialtyName, getStandardsColumns } from "./standardsUtils";

export function Standards({ show, onClose, specialtyId }) {
  const networkId = useSelector(selectSelectedNetworkId);
  const specialtiesMap = useSelector(selectSpecialtiesMap);
  const specialtyName = specialtiesMap[specialtyId] ?? "All specialties";
  const { data = [], isFetching } = useGetStandardsQuery(
    {
      networkId,
      specialtyId,
    },
    { skip: show === undefined }
  );
  const rows = specialtyId ? data : getRowsSpecialtyName(data, specialtiesMap);
  const columns = getStandardsColumns(rows);

  if (show === undefined) return null;

  return (
    <Dialog
      open={show}
      onClose={onClose}
      container={() => document.querySelector("main")}
      sx={{ "& .MuiPaper-root": { width: "80%", maxWidth: "initial" } }}
    >
      <DialogContent>
        <Typography variant="h5" mb={"16px"}>
          {specialtyName}
        </Typography>

        <Table
          columns={columns}
          rows={rows}
          loading={isFetching}
          disableRowSelectionOnClick
          components={{
            Toolbar: () => (
              <GridToolbarContainer style={{ justifyContent: "flex-end" }}>
                <GridToolbarExport />
              </GridToolbarContainer>
            ),
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
