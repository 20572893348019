import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetCountyScoresQuery,
  useGetNetworksQuery,
} from "../api/networksApi";
import { selectSelectedNetwork } from "../features/networks";
import {
  selectSelectedNetworkId,
  setSelectedNetworkId,
} from "../features/selectedNetwork";
import { Splash } from "./Splash";

export function RequireMasterData({ children }) {
  const dispatch = useDispatch();
  const selectedNetwork = useSelector(selectSelectedNetwork);
  const selectedNetworkId = useSelector(selectSelectedNetworkId);
  const networksRequest = useGetNetworksQuery();

  const { data: networks = [] } = networksRequest;
  const networkId = selectedNetwork?.id;

  // county scores are necessary for gaps calc in the top bar
  useGetCountyScoresQuery({ networkId }, { skip: !selectedNetwork });

  // county baseline scores are necessary for gaps changes calc in the top bar
  useGetCountyScoresQuery(
    { networkId, baseline: true },
    { skip: !selectedNetwork }
  );

  const hasProperNetworkId = networks.some((n) => selectedNetworkId === n.id);

  const done =
    !networksRequest.isFetching &&
    (networks.length === 0 || hasProperNetworkId);

  if (networksRequest.error) throw new Error("Cannot load networks");

  useEffect(() => {
    if (!hasProperNetworkId && networks.length > 0) {
      const network = networks[0];
      if (network?.id) dispatch(setSelectedNetworkId(network.id));
    }
  }, [networks, dispatch, hasProperNetworkId]);

  if (done) return children;

  return (
    <Splash>
      <p>Initializing data ...</p>
    </Splash>
  );
}
