import { Autocomplete, Chip, Stack, TextField } from "@mui/material";
import { renderJ2SpecialtiesOptions } from "../NewSpecialtyForm";

export function MappingEditCell(props) {
  const { id, value: currentIds, field, api } = props;
  const { j2Specialties = [] } = api.state;
  return (
    <Autocomplete
      id={`mapping-select-${id}`}
      size="small"
      multiple
      disableCloseOnSelect
      openOnFocus
      fullWidth
      options={Object.entries(j2Specialties).sort((a, b) =>
        a[1] > b[1] ? 1 : -1
      )}
      isOptionEqualToValue={(option, value) => option[1] === value[1]}
      value={currentIds.map((id) => [id, j2Specialties[id] ?? "missing name"])}
      getOptionLabel={(option) => option[1]}
      renderOption={renderJ2SpecialtiesOptions}
      onChange={(_, value) => {
        api.setEditCellValue({ id, field, value: value.map(([id]) => id) });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          margin="none"
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // disable browser autocomplete and autofill
          }}
        />
      )}
    />
  );
}

export function MappingCell({ row, api }) {
  const { j2Specialties } = api.state;
  if (!j2Specialties) return null;
  const names = row.j2_specialties.map((id) => j2Specialties[id]).sort();

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      height="100%"
      sx={{
        flexWrap: "wrap",
        gap: "4px",
        overflowX: "auto",
      }}
    >
      {names.map((name) => (
        <Chip
          key={name}
          size="small"
          label={name}
          sx={{ lineHeight: "1.1", marginLeft: "0 !important" }}
        />
      ))}
    </Stack>
  );
}
