import { useCallback, useState } from "react";
import {
  usePostCustomStandardDesignationTypesMutation,
  usePostStandardMemberUniversesMutation,
  usePutStandardMemberUniversesMutation,
  usePutCustomStandardDesignationTypeMutation,
  usePutCustomStandardsMutation,
  usePostCustomStandardsMutation,
} from "../api/j2/j2Api";

import { AlertServerError } from "../components/SelfServeCustomStandards/AlertServerError";
import { DialogServerError } from "../components/SelfServeCustomStandards/DialogServerError";

export function useHandleStandardWithNestedObjects({ standard, onSuccess }) {
  const [postCreateStandard] = usePostCustomStandardsMutation();

  const [putUpdateStandard] = usePutCustomStandardsMutation();

  const [postCreateDesignationType] =
    usePostCustomStandardDesignationTypesMutation();

  const [putUpdateDesignationType] =
    usePutCustomStandardDesignationTypeMutation();

  const [postCreateMemberUniverses] = usePostStandardMemberUniversesMutation();
  const [putUpdateMemberUniverses] = usePutStandardMemberUniversesMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const [serverError, setServerError] = useState({});

  const showServerErrors = useCallback((errors) => {
    setOpenDialog(true);
    setServerError(errors);
  }, []);

  const clearServerErrors = useCallback(() => {
    setServerError({});
  }, []);

  const setCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const submitChanges = useCallback(
    async (formData) => {
      setServerError({});

      const { designationFormData, memberFormData, ...data } = formData;
      const hasMemberData = !!memberFormData;
      const hasDesignationData = !!designationFormData;
      const hasNestedData = hasDesignationData || hasMemberData;

      let errorSource;
      let errorData;
      let _standard;

      try {
        const updateStandardAction = standard
          ? putUpdateStandard
          : postCreateStandard;
        errorSource = "standard";

        _standard = await updateStandardAction({
          standard,
          data,
          skipInvalidateAll: hasNestedData,
        }).unwrap();

        if (!hasNestedData) return;

        if (hasDesignationData) {
          errorSource = "designation";

          const updatedDesignationAction = data.designation_type
            ? putUpdateDesignationType
            : postCreateDesignationType;

          await updatedDesignationAction({
            standard_id: _standard.id,
            data: designationFormData,
            skipInvalidateAll: hasMemberData,
          }).unwrap();
        }

        if (hasMemberData) {
          errorSource = "file";

          const updatedMemberAction = data.member_universe
            ? putUpdateMemberUniverses
            : postCreateMemberUniverses;

          await updatedMemberAction({
            standard_id: _standard.id,
            data: memberFormData,
          }).unwrap();
        }
      } catch (error) {
        errorData =
          typeof error.data === "string"
            ? {
                "Something went wrong": [
                  "There was an error when processing you request. Please try again.",
                ],
              }
            : error.data;
        showServerErrors({ source: errorSource, data: errorData });
      } finally {
        if (_standard && !errorData && onSuccess) onSuccess(_standard);
      }
    },

    [
      standard,
      putUpdateStandard,
      postCreateStandard,
      putUpdateDesignationType,
      postCreateDesignationType,
      putUpdateMemberUniverses,
      postCreateMemberUniverses,
      showServerErrors,
      onSuccess,
    ]
  );

  const errorDisplay =
    serverError.source && (serverError.source === "file" ? "dialog" : "alert");

  const errorInfos = {
    alert: <AlertServerError serverError={serverError} />,

    dialog: (
      <DialogServerError
        open={openDialog}
        serverError={serverError}
        onDialogClose={clearServerErrors}
        onCloseClick={setCloseDialog}
      />
    ),
  };

  return {
    submitChanges,
    showServerErrors,
    serverErrorInfo: errorInfos[errorDisplay] ?? null,
    errorDisplay,
    serverError,
  };
}
