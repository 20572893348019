import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Typography, Stack } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useSelfServeAllowed } from "../../hooks/useSelfServeAllowed";
import { selectNetworks } from "../../features/networks";
import { useCanEditSelfServeNetwork } from "../../hooks/useCanEditSelfServeNetwork";
import { networksGridColumns } from "../../components/SelfServe/SelfServeNetworks/networksGridColumns";
import { useWithFlagGetFormStandards } from "../../hooks/useWithFlagGetFormStandards";
import { useGetNetworkUpdateTasks } from "../../hooks/useGetUpdateNetworkTasks";
import { useAmplitudeEvent } from "../../hooks/useAmplitudeEvent";

export function NetworksIndexRoute() {
  const isSelfServeAllowed = useSelfServeAllowed();
  const canEdit = useCanEditSelfServeNetwork();
  const { isFetching, isNetworkUpdateInProgress } = useGetNetworkUpdateTasks();
  const trackAmplitudeEvent = useAmplitudeEvent();

  const networks = useSelector(selectNetworks).map((n) => {
    return {
      ...n,
      createdBy: n.params?.user_email,
      canBeEdited: Boolean(n?.params), // only self-serve network can be updated
      userCanEdit: canEdit(n.params?.user_email),
      hasUpdateInProgress: isNetworkUpdateInProgress(n),
      handleClick: () =>
        trackAmplitudeEvent("Start network update", {
          networkId: n.id,
          networkName: n.name,
        }),
    };
  });
  useWithFlagGetFormStandards(undefined, { skip: !isSelfServeAllowed }); // fetch taxonomies for self serve LOBs

  if (!isSelfServeAllowed) return <Navigate to="/" />;

  return (
    <Stack mt={1} width={"100%"} maxWidth={"1000px"}>
      <Typography variant="h4">Networks</Typography>
      <Stack mt={2}>
        <DataGridPremium
          rows={networks}
          autoHeight
          hideFooter
          disableRowSelectionOnClick
          columns={networksGridColumns}
          loading={isFetching}
        />
      </Stack>
    </Stack>
  );
}
