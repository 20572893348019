import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  FormHelperText,
  Input,
} from "@mui/material";
import { useCallback, useState } from "react";
import {
  fieldValidator,
  isPresent,
  validate,
} from "../../../utils/formValidate";
import { Link } from "react-router-dom";
import networkTemplateFile from "../../../assets/Disruption Network Loading Template.csv";

const schema = {
  name: validate([
    isPresent(
      "cannot be empty",
      ({ field, values }) => values[field].trim().length > 0
    ),
  ]),
  networkType: validate([isPresent("cannot be empty")]),
  file: validate([isPresent("cannot be empty")]),
};

const networkTypes = { source: "Incumbent", target: "Replacement" };

export function NewNetworkForm({ readOnly, initValues, onSubmit }) {
  const [name, setName] = useState(initValues?.name ?? "");
  const [nameError, setNameError] = useState();

  const [networkType, setNetworkType] = useState(
    initValues?.network_type ?? ""
  );
  const [networkTypeError, setNetworkTypeError] = useState();

  const [file, setFile] = useState();
  const [fileError, setFileError] = useState();

  const hasSomeValues = name && networkType && file;
  const hasErrors = nameError || networkTypeError || fileError;
  const isFormValid = hasSomeValues && !hasErrors;

  const validateField = fieldValidator(schema, {
    name,
    networkType,
    file: Boolean(file),
  });

  const validateForm = useCallback(() => {
    const nameError = validateField("name").error;
    const networkTypeError = validateField("networkType").error;
    const fileError = validateField("file").error;

    if (nameError) setNameError(nameError);
    if (networkTypeError) setNetworkTypeError(networkTypeError);
    if (fileError) setFileError(fileError);
    if (nameError || networkTypeError || fileError) return false;
    return true;
  }, [validateField]);

  const onSave = () => {
    if (!validateForm()) return;
    const payload = { name, networkType, file };
    onSubmit(payload);
  };

  return (
    <Stack sx={{ textAlign: "left" }} gap={3} maxWidth={600}>
      <FormControl>
        <TextField
          id="network-name"
          variant="outlined"
          size="small"
          margin="dense"
          label="Network display name"
          required
          value={name}
          disabled={readOnly}
          onChange={(e) => setName(e.target.value)}
          onFocus={() => setNameError()}
          onBlur={() => {
            setNameError(validateField("name").error);
          }}
          error={!!nameError}
          helperText={nameError}
        />
      </FormControl>
      <Stack>
        <FormControl size="small" error={!!networkTypeError} required>
          <InputLabel
            size="small"
            id="network-type-label"
            htmlFor="network-type"
          >
            Network type
          </InputLabel>
          <Select
            id="network-type"
            size="small"
            labelId="network-type-label"
            label="Network type"
            value={networkType}
            onChange={(e) => {
              setNetworkType(e.target.value);
            }}
            onFocus={() => setNetworkTypeError()}
            onBlur={() =>
              setNetworkTypeError(validateField("networkType").error)
            }
            disabled={readOnly}
          >
            {Object.entries(networkTypes).map(([id, name]) => {
              return (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
          {networkTypeError && (
            <FormHelperText>{networkTypeError}</FormHelperText>
          )}
        </FormControl>
      </Stack>
      <Stack>
        <FormControl margin="none">
          <Button
            size="small"
            variant="contained"
            href={networkTemplateFile}
            download
            sx={{ width: "fit-content", textTransform: "none" }}
          >
            Download network template
          </Button>
        </FormControl>

        <FormControl margin="dense">
          <>
            <label htmlFor="select-file">
              <Input
                sx={{ display: "none" }}
                id="select-file"
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  e.target.value = null;
                }}
                disabled={readOnly}
                inputProps={{ accept: ".csv" }}
              />
              {!readOnly && (
                <Button variant="outlined" component="span">
                  Select CSV file
                </Button>
              )}
            </label>
            {!readOnly && (
              <FormHelperText sx={{ marginLeft: "14px" }}>
                {file ? `Selected file: ${file.name}` : "no file selected"}
              </FormHelperText>
            )}
          </>
          {fileError && <FormHelperText error>{fileError}</FormHelperText>}
        </FormControl>
      </Stack>

      {!readOnly && (
        <Stack direction="row" gap={2}>
          <Button variant="contained" disabled={!isFormValid} onClick={onSave}>
            Save
          </Button>
          <Button component={Link} to="..">
            Cancel
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
