import { Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useGetDisruptionReportsResultsQuery } from "../../api/j2/j2Api";
import {reportResultsGridColumnsBase} from "../../components/workspace-disruption/reports/reportResultsGridColumnsBase";
import { GridToolbarWithExport } from "../../components/GridToolbarWithExport/GridToolbarWithExport";
import { useAmplitudeEvent } from "../../hooks/useAmplitudeEvent";

const EXPECTED_ORIGINAL_DATA_KEYS = [
  "Address",
  "Address_2",
  "City",
  "State",
  "Zip_Code",
  "NPI",
  "TIN",
  "Provider_Name",
];

export function ReportsResultsRoute() {
    const { reportId } = useParams();
    const {
        data: disruptionReportResults = [],
        isFetching,
    } = useGetDisruptionReportsResultsQuery(reportId);
    const trackAmplitudeEvent = useAmplitudeEvent();

    const reportResultsGridColumns = [...reportResultsGridColumnsBase];
    const reportResultsRows = disruptionReportResults.matches?.map((result) => {
        let row = {
            id: result.id,
            match_type: result.match_type.toUpperCase(),
            ...result.source_network_provider,
            matched_provider_name: result.target_network_provider_name,
        };

        Object.keys(result.source_network_provider?.additional_fields || {}).forEach((key) => {
          if (!EXPECTED_ORIGINAL_DATA_KEYS.includes(key)) {
            if (!reportResultsGridColumns.some(column => column.field === key.toLowerCase())) {
              reportResultsGridColumns.push({
                field: key.toLowerCase(),
                headerName: key,
                width: 150,
              });
            }

            row[key.toLowerCase()] = result.source_network_provider.additional_fields[key];
          }
        });

        return row;
    }) ?? [];

    const reportName = disruptionReportResults.summary?.name;
  
    return (
        <Stack mt={1} width={"100%"}>
          <Typography variant="h4">Report results for {reportName}</Typography>
          <Typography variant="h5" my={2}>Summary</Typography>
          <Typography variant="body1">Replacement Network: {disruptionReportResults.summary?.target_network_name}</Typography>
          <Typography variant="body1">Incumbent Network: {disruptionReportResults.summary?.source_network_name}</Typography>
          <Typography variant="body1">Match Criteria:</Typography>
          {Object.entries(disruptionReportResults.summary?.match_conditions || {}).map(([key, value]) => (
            <Typography key={key} variant="body1" sx={{ ml: 2 }}>
              • {key.charAt(0).toUpperCase() + key.slice(1)}: ({value.join(', ')})
            </Typography>
          ))}
          <Typography variant="h5" mt={2}>Incumbent Network Providers</Typography>
          <Stack mt={2}>
            <DataGridPremium
              rows={reportResultsRows}
              autoHeight
              hideFooter
              disableRowSelectionOnClick
              columns={reportResultsGridColumns}
              loading={isFetching}
              slots={{ toolbar: GridToolbarWithExport }}
              slotProps={{ toolbar: { onClickEvent: () => { trackAmplitudeEvent("Export Data", {page: "Disruption Report Matches", reportId: reportId, reportName: reportName}) } }}}
            />
          </Stack>
        </Stack>
    );
  }