import { useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import { Alert, Stack } from "@mui/material";
import { useGetJ2SpecialtiesQuery } from "../../api/networksApi";
import { SpecialtiesGrid } from "../../components/SelfServeCustomStandards/SpecialtiesGrid";
import {
  useGetCustomSpecialtiesQuery,
  usePostCustomSpecialtiesMutation,
} from "../../api/j2/j2Api";
// import specialtiesTemplate from "../../assets/custom_standard_specialties_template.xlsx";

export function StandardSpecialtiesEditRoute() {
  const { standard } = useOutletContext();
  // const location = useLocation();
  // const navigate = useNavigate();

  // const standardJustCreated = location.state?.justCreated;

  const j2Specialties = useGetJ2SpecialtiesQuery();
  const specialties = useGetCustomSpecialtiesQuery(standard.id);

  const [postUpdateSpecialties, updateSpecialtiesResult] =
    usePostCustomSpecialtiesMutation();

  const submitUpdateSpecialties = useCallback(
    async (data, onSuccess) => {
      try {
        await postUpdateSpecialties({
          standard_id: standard.id,
          data,
        }).unwrap();
        // if (standardJustCreated && formData.file) {
        //   return navigate(`../../requirements/edit`, {
        //     state: { justCreated: true },
        //   });
        // }
        if (onSuccess) onSuccess();
      } catch (error) {
        console.error(error?.data?.message ?? error);
      }
    },
    [postUpdateSpecialties, standard.id]
  );

  return (
    <Stack gap={2} alignItems={"flex-start"} height={"100%"}>
      {/* <FormHelperText>
        Upload specialties that you would like to measure your network against.
      </FormHelperText> */}
      {/* <Button
        size="small"
        variant="contained"
        href={specialtiesTemplate}
        download
        sx={{ width: "fit-content", textTransform: "none" }}
      >
        Download data template
      </Button> */}
      {/* <SubmitFileForm
        label="Upload specialties file"
        onSubmit={submitCreateSpecialties}
      /> */}
      {/* <Divider width={"100%"} /> */}
      {/* {(specialties.data?.length > 0 || createSpecialtiesResult.isSuccess) && */}
      {/* Object.keys(j2Specialties.data ?? {}).length > 0 && ( */}
      <Stack
        gap={2}
        alignItems={"flex-start"}
        overflow={"auto"}
        height={"100%"}
      >
        {/* <FormHelperText sx={{ maxWidth: 600 }}>
              Complete mapping of your specialties to J2 specialties if
              applicable. This will enable J2 to surface leads from the J2's
              provider universe. Please note that not all of your specialties
              need to map to a J2 specialty.
            </FormHelperText> */}
        {updateSpecialtiesResult.isError && (
          <Alert severity="error">
            {Object.values(
              updateSpecialtiesResult.error.data ?? {
                error: "Something went wrong. Cannot update mappings.",
              }
            ).map((errorMsg) => (
              <>
                {errorMsg}
                <br />
              </>
            ))}
          </Alert>
        )}
        <SpecialtiesGrid
          rows={specialties.data ?? []}
          j2Specialties={j2Specialties.data}
          loading={specialties.isFetching || j2Specialties.isFetching}
          onSubmit={submitUpdateSpecialties}
        />
      </Stack>
      {/* )} */}
    </Stack>
  );
}
