export class PersistLocalStorage {
  constructor(storageKey) {
    this.storageKey = storageKey;
  }

  getItems() {
    let current = JSON.parse(localStorage.getItem(this.storageKey) || null);
    return current;
  }

  setItem(key, value) {
    const next = this.getItems() || {};
    next[key] = value;
    localStorage.setItem(this.storageKey, JSON.stringify(next));
    return next;
  }

  deleteItem(key) {
    let next = this.getItems();
    if (!next) return null;
    delete next[key];
    if (Object.keys(next).length === 0) next = null;
    if (next) localStorage.setItem(this.storageKey, JSON.stringify(next));
    else localStorage.removeItem(this.storageKey);
    return next;
  }
}
