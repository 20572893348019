import { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import {
  fieldValidator,
  isPresent,
  validate,
} from "../../../utils/formValidate";
import { usePostCloneCustomStandardsMutation } from "../../../api/j2/j2Api";
import { actionsConfig } from "../../../hooks/useHandleStandardActions";

const schema = {
  name: validate([
    isPresent(
      "cannot be empty",
      ({ field, values }) => values[field].trim().length > 0
    ),
  ]),
};

export function CustomStandardCopyButton({ standard }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState();
  const [nameError, setNameError] = useState();

  const [postCloneCustomStandards] = usePostCloneCustomStandardsMutation({
    fixedCacheKey: actionsConfig.postCloneCustomStandards.cacheKey,
  });

  const validateField = fieldValidator(schema, { name });
  const validateForm = useCallback(() => {
    const nameError = validateField("name").error;
    if (nameError) setNameError(nameError);
    if (nameError) return false;
    return true;
  }, [validateField]);

  return (
    <>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
          setName((standard?.name ?? "Unknown name") + " - Copy");
          setNameError();
        }}
      >
        Copy
      </Button>
      <Dialog open={open}>
        <DialogTitle>Copy Standard</DialogTitle>
        <DialogContent>
          <TextField
            id="standard-name"
            variant="outlined"
            size="small"
            margin="dense"
            label="Display name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            onFocus={() => setNameError()}
            onBlur={() => {
              setNameError(validateField("name").error);
            }}
            error={!!nameError}
            helperText={nameError}
            sx={{ width: "100%", marginBottom: 2 }}
          />
          <DialogContentText>
            Confirm to create new custom standard draft with the same attributes
            as the original
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              if (!validateForm()) return;
              setOpen(false);
              postCloneCustomStandards({ standard, data: { name } });
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
