import { createSelector, createSlice } from "@reduxjs/toolkit";

const defaultThemeStyle = { defaultColor: "gray", steps: 5 };
const initialTheme = { metric_id: "adequacy", ...defaultThemeStyle };

export const mapSlice = createSlice({
  name: "map",
  initialState: {
    hover: null,
    viewport: null,
    theme: initialTheme,
    pointToMark: null,
    highlight: null,
  },
  reducers: {
    setMapHover(state, { payload }) {
      state.hover = payload;
    },
    setMapViewport(state, { payload }) {
      state.viewport = payload;
    },
    setMapTheme(state, { payload }) {
      state.theme = payload ?? initialTheme;
    },
    setPointToMark(state, { payload }) {
      state.pointToMark = payload;
    },
    setHighlitedCounty(state, { payload }) {
      state.highlight = payload;
    },
  },
});

export const {
  setMapHover,
  setMapViewport,
  setMapTheme,
  setPointToMark,
  setHighlitedCounty,
} = mapSlice.actions;

export const selectMap = (state) => state.map;

export const selectMapHover = createSelector(
  selectMap,
  ({ hover }) => hover ?? {}
);

export const selectMapTheme = createSelector(selectMap, ({ theme }) => theme);

export const selectHiglightedCounty = createSelector(
  selectMap,
  ({ highlight }) => highlight
);
