export const providerRatioReport = {
  id: "provider-ratio",
  name: "Provider Ratio Report",
  isAvailableFn: (ctx) => {
    const { standard } = ctx;
    return Boolean(standard?.member_universe?.member_file_name);
  },
  columns: [
    { field: "specialty_name", headerName: "Specialty", width: 250 },

    {
      field: "member_count",
      headerName: "Member Count",
      type: "number",
      width: 150,
    },

    {
      field: "provider_count",
      headerName: "Provider Count",
      type: "number",
      width: 150,
    },

    {
      field: "bed_count",
      headerName: "Bed Count",
      type: "number",
      width: 150,
    },

    {
      field: "provider_ratio",
      headerName: "Provider Ratio (Members per Provider)",
      type: "number",
      width: 150,
    },

    {
      field: "bed_ratio",
      headerName: "Bed Ratio (Members per Bed)",
      type: "number",
      width: 150,
    },
  ],
};
