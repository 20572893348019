import { useSelector } from "react-redux";
import { FileDownloadOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  gridFilteredSortedRowEntriesSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { selectServiceArea } from "../../features/networks";
import { useNetworkContextAmplitudeEvent } from "../../hooks/useNetworkContextAmplitudeEvent";
import { SCORES, ADEQUACIES, SCORE_TYPE_LABELS } from "@j2nm/score-metric-keys";

const RAW_HEADERS_CONFIG = [
  ["countyName", "County"],
  ["specialty", "Specialty"],
  [SCORES.DRIVE_DISTANCE, `${SCORE_TYPE_LABELS[SCORES.DRIVE_DISTANCE]} Score`],
  [SCORES.DRIVE_TIME, `${SCORE_TYPE_LABELS[SCORES.DRIVE_TIME]} Score`],
  [SCORES.MINIMUM_COUNT, `${SCORE_TYPE_LABELS[SCORES.MINIMUM_COUNT]} Score`],
  [
    SCORES.MINIMUM_COUNT_IN_COUNTY,
    `${SCORE_TYPE_LABELS[SCORES.MINIMUM_COUNT_IN_COUNTY]} Score`,
  ],
  ["bedCount", "Bed Count Score"],
  ["isGap", "Is Gap"],
  ["countyGapCount", "County Gap Count"],
];

export function ExportScorecardAsExcelButton({
  getScorecardDataAsCsv,
  ...options
}) {
  const { counties: countiesLookup } = useSelector(selectServiceArea);
  const apiRef = useGridApiContext();
  const visibleRows = gridFilteredSortedRowEntriesSelector(apiRef);
  const visibleColumns = gridVisibleColumnFieldsSelector(apiRef);
  const { countyGaps = {} } = apiRef.current?.state;
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  function makeSheets(params) {
    // scorecard sheet
    const scorecardSheet = params.workbook.addWorksheet("Scorecard");
    const [scorecardHeaders, scorecardRows] = makeExcelScorecardRows(
      getScorecardDataAsCsv()
    );

    scorecardSheet.addRow(scorecardHeaders);
    scorecardSheet.addRows(scorecardRows);

    // raw data sheet
    const rawDataSheet = params.workbook.addWorksheet("Raw data");
    const [rawDataHeaders, rawDataRows] = makeExcelRawDataRows(
      makeRawDataRows(visibleRows, visibleColumns, countiesLookup, countyGaps)
    );

    rawDataSheet.addRow(rawDataHeaders);
    rawDataSheet.addRows(rawDataRows);
  }

  const exportOptions = {
    ...options,
    exceljsPreProcess: makeSheets,
    exceljsPostProcess: ({ workbook }) => {
      // remove the default export
      workbook.removeWorksheet(workbook.worksheets[0].id);
    },
  };

  return (
    <Button
      color="primary"
      size="small"
      startIcon={<FileDownloadOutlined />}
      onClick={() => {
        apiRef.current.exportDataAsExcel(exportOptions);
        trackNetworkContextAmplitudeEvent("Export data");
      }}
    >
      Export as Excel
    </Button>
  );
}

function makeExcelScorecardRows(csvData) {
  const [headers, , ...rows] = csvData // skip totals row
    .split("\r\n")
    .map((txtRow) => txtRow.split(";"));
  const gapsIdx = 1;
  // we turn strings into numbers/booleans to be used as proper cell types
  for (let row of rows) {
    for (let [i, cell] of row.entries()) {
      if (i === gapsIdx) row[i] = parseInt(cell);
      if (cell.includes("%")) row[i] = parseFloat(cell) / 100;
      if (cell === "TRUE") row[i] = true;
      if (cell === "FALSE") row[i] = false;
    }
  }
  return [headers, rows];
}

function makeExcelRawDataRows(rows) {
  // skip header keys that has no value
  let headers = new Set();
  for (const row of rows) {
    for (const [header] of RAW_HEADERS_CONFIG) {
      if (row[header] !== undefined) headers.add(header);
    }
  }
  // make sure we have proper keys order
  let excelHeaders = RAW_HEADERS_CONFIG.filter(([key]) => headers.has(key));

  // make labels
  let excelHeadersLabels = excelHeaders.map(([, label]) => label);

  // map array of objects to array of tuples (arrays)
  let excelRows = rows.map((row) => excelHeaders.map(([key]) => row[key]));

  // sort as required
  excelRows.sort(sortByCountyAndSpecialty);

  return [excelHeadersLabels, excelRows];
}

function makeRawDataRows(rows, visibleColumns, countiesLookup, countyGaps) {
  return rows.flatMap(({ model }) => {
    const { name: specialty } = model;
    return Object.entries(model).flatMap(([key, value]) => {
      const countyName = countiesLookup[key];
      const hasCounty = countyName && visibleColumns.includes(key);
      return hasCounty
        ? [
            {
              ...value,
              isGap: !Boolean(value[ADEQUACIES.OVERALL]),
              specialty,
              countyName,
              countyGapCount: countyGaps[key] ?? 0,
            },
          ]
        : [];
    });
  });
}

function sortByCountyAndSpecialty(
  [county1, specialty1],
  [county2, specialty2]
) {
  if (county1 > county2) return 1;
  if (county1 < county2) return -1;
  if (specialty1 > specialty2) return 1;
  if (specialty1 < specialty2) return -1;
}
