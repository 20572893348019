import { createSelector, createSlice } from "@reduxjs/toolkit";
import { groupBy, partition, remove } from "lodash";
import { arrToMap, differenceArr, unionArr } from "../utils";
import {
  joinProviderEntities,
  selectFilteredProviders,
  selectProvidersFullData,
} from "./providers";
import { selectSelectedNetworkId } from "./selectedNetwork";

const createInitialState = () => ({
  toAdd: [],
  toRemove: [],
});

export const stagedChangesSlice = createSlice({
  name: "stagedChanges",
  initialState: {},
  reducers: {
    toggleToAdd(state, { payload, networkId }) {
      state[networkId] ??= createInitialState();
      state[networkId].toAdd.includes(payload)
        ? remove(state[networkId].toAdd, (id) => id === payload)
        : state[networkId].toAdd.push(payload);
    },
    toggleToRemove(state, { payload, networkId }) {
      state[networkId] ??= createInitialState();
      state[networkId].toRemove.includes(payload)
        ? remove(state[networkId].toRemove, (id) => id === payload)
        : state[networkId].toRemove.push(payload);
    },
    addToAdd(state, { payload, networkId }) {
      state[networkId] ??= createInitialState();
      state[networkId].toAdd = unionArr(state[networkId].toAdd, payload);
    },
    removeToAdd(state, { payload, networkId }) {
      state[networkId] ??= createInitialState();
      state[networkId].toAdd = differenceArr(state[networkId].toAdd, payload);
    },
    addToRemove(state, { payload, networkId }) {
      state[networkId] ??= createInitialState();
      state[networkId].toRemove = unionArr(state[networkId].toRemove, payload);
    },
    removeToRemove(state, { payload, networkId }) {
      state[networkId] ??= createInitialState();
      state[networkId].toRemove = differenceArr(
        state[networkId].toRemove,
        payload
      );
    },
    setToAdd(state, { payload, networkId }) {
      state[networkId] ??= createInitialState();
      state[networkId].toAdd = payload ?? [];
    },
    setToRemove(state, { payload, networkId }) {
      state[networkId] ??= createInitialState();
      state[networkId].toRemove = payload ?? [];
    },
    resetState: () => ({}),
  },
});

export const {
  toggleToAdd,
  toggleToRemove,
  addToAdd,
  removeToAdd,
  addToRemove,
  removeToRemove,
  setToAdd,
  setToRemove,
} = stagedChangesSlice.actions;

const selectStagedSliceData = (state) => state[stagedChangesSlice.name];

export const selectStagedChanges = createSelector(
  selectStagedSliceData,
  selectSelectedNetworkId,
  (stagedChanges, networkId) => stagedChanges[networkId] ?? createInitialState()
);

export const selectStagedChangesLookup = createSelector(
  selectStagedChanges,
  ({ toAdd, toRemove }) => arrToMap(toAdd.concat(toRemove))
);

const selectDataCaches = createSelector(
  (state) => state.networksApi,
  ({ queries }) => {
    return groupBy(
      Object.values(queries).filter(({ status }) => status === "fulfilled"),
      "endpointName"
    );
  }
);

const selectProviderLocationsCache = createSelector(
  selectStagedChanges,
  selectDataCaches,
  selectSelectedNetworkId,
  ({ toAdd, toRemove }, cache, networkId) => {
    const added = [];
    return cache.getProviderLocations?.reduce((acc, req) => {
      if (req.originalArgs.networkId !== networkId) return acc;
      const newItems = (req.data || []).filter(
        ({ id }) =>
          !added.includes(id) && (toAdd.includes(id) || toRemove.includes(id))
      );
      added.push(...newItems.map(({ id }) => id));
      return acc.concat(newItems);
    }, []);
  }
);

const selectProvidersCache = createSelector(selectDataCaches, (cache) => {
  return cache.getProviders?.reduce((acc, req) => {
    if (req.data) Object.assign(acc, req.data);
    return acc;
  }, {});
});

const selectProviderScoresCache = createSelector(selectDataCaches, (cache) => {
  return cache.getProviderLocationScores?.reduce((acc, req) => {
    if (req.data) Object.assign(acc, req.data);
    return acc;
  }, {});
});

const selectProvidersTagsCache = createSelector(selectDataCaches, (cache) => {
  return cache.getProviderLocationTags?.reduce((acc, req) => {
    if (req.data) Object.assign(acc, req.data);
    return acc;
  }, {});
});

// TODO: if needed refactor to use chunked data
export const selectProviderData = createSelector(
  selectProviderLocationsCache,
  selectProvidersCache,
  selectProviderScoresCache,
  selectProvidersTagsCache,
  (providerLocations, providers, scores, tags) => {
    return providerLocations && providers && scores && tags
      ? partition(
          joinProviderEntities(providerLocations, providers, scores, tags),
          { is_inn: 1 }
        )
      : [];
  }
);

export const selectStagedFilteredProviders = createSelector(
  selectStagedChangesLookup,
  selectFilteredProviders,
  (lookup, providers) => {
    return providers.flatMap(({ id }) => (lookup[id] ? [id] : []));
  }
);

export const selectStagedProviders = createSelector(
  selectStagedChanges,
  selectProvidersFullData,
  ({ toAdd, toRemove }, providers) => {
    console.time("selectStagedProviders");
    const toAddLookup = new Set(toAdd);
    const toRemoveLookup = new Set(toRemove);
    let result = { toAdd: [], toRemove: [] };
    for (const p of providers) {
      if (toAddLookup.has(p.id)) result.toAdd.push(p);
      else if (toRemoveLookup.has(p.id)) result.toRemove.push(p);
    }
    console.timeEnd("selectStagedProviders");
    return result;
  }
);
