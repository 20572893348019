import { getReports } from "../components/Reports/definitions";
import { useGetContextForReportsDefinitions } from "./useGetContextForReportsDefinitions";

export function useGetReportsDefinitions(options = {}) {
  const { selectFromResult } = options;

  const { data = {}, isFetching } = useGetContextForReportsDefinitions();

  const result = {
    reports: isFetching ? undefined : getReports(data),
    isFetching,
  };

  if (selectFromResult) return selectFromResult(result);

  return result;
}
