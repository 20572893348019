import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";

export function PhiConfirmationCheckbox({ value, onChange, disabled }) {
  return (
    <FormControlLabel
      required
      control={
        <Checkbox
          size="small"
          sx={{ marginTop: "-5px" }}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={
        <FormHelperText sx={{ marginLeft: "0" }}>
          I confirm that no information in the file I am sharing is PHI
          (Personal Health Information).
          <br />
          <strong>Note:</strong> PHI is protected by federal law (HIPAA Privacy
          Rule) and is not for use in J2. If you are in doubt about whether data
          in the file you are loading is PHI, please consult your legal
          department before submitting it.
        </FormHelperText>
      }
      sx={{ alignItems: "flex-start", marginTop: 1, marginLeft: "2px" }}
    />
  );
}
