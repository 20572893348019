import { FormControl, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { usePrefetch } from "../../api/networksApi";
import { selectZipcodeMetrics } from "../../features/layersData";
import { selectMapTheme, setMapTheme } from "../../features/map";
import { selectSelectedNetworkId } from "../../features/selectedNetwork";
import { LayerLegend } from "./LayerLegend";
import "./LayerControl.css";

export default function LayerControl() {
  const networkId = useSelector(selectSelectedNetworkId);
  const zipcodeMetrics = useSelector(selectZipcodeMetrics);
  const theme = useSelector(selectMapTheme);
  const dispatch = useDispatch();
  const prefetchMetricValues = usePrefetch("getZipcodeMetricValues");

  return (
    <div className="layer-control">
      <FormControl fullWidth>
        <Select
          labelId="zipcode-display-attr"
          value={theme.metric_id}
          variant="standard"
          onChange={(event) => {
            dispatch(setMapTheme({ ...theme, metric_id: event.target.value }));
          }}
        >
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="adequacy">Adequacy</MenuItem>
          <MenuItem value="gapsTotal">Total gaps</MenuItem>
          {zipcodeMetrics?.map(({ metric_id: metricId, label }) => (
            <MenuItem
              key={metricId}
              value={metricId}
              onMouseEnter={() => {
                prefetchMetricValues({ networkId, metricId });
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LayerLegend sx={{ marginTop: 2 }} />
    </div>
  );
}
