import { createSelector } from "@reduxjs/toolkit";
import { getCountyScores } from "../api/networksApi";
import { selectStateAndNetwork } from "./networks";

export const selectNetworkCountiesScores = createSelector(
  selectStateAndNetwork,
  ([state, network]) =>
    network
      ? getCountyScores.select({ networkId: network.id })(state)?.data
      : undefined
);

export const selectBaselineCountiesScores = createSelector(
  selectStateAndNetwork,
  ([state, network]) =>
    network
      ? getCountyScores.select({ networkId: network.id, baseline: true })(state)
          ?.data
      : undefined
);
