import { TableContainer } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { useEditableGrid } from "../../../hooks/useEditableGrid";
import { updateDesignationsGridState } from "./updateDesignationsGridState";
import { addToGridState, makeAssignedCounties } from "./gridUtils";
import { makeColumns } from "./gridColumns";

export const CustomDesignationsGrid = forwardRef(
  function CustomDesignationsGrid(
    { rows, loading, statesWithCounties, readOnly },
    ref
  ) {
    const apiRef = useGridApiRef();
    // add statesWithCounties lookup to the grid state so that the MappingEditCell can use it
    useEffect(() => {
      addToGridState(apiRef.current, "statesWithCounties", statesWithCounties);
    }, [apiRef, statesWithCounties]);

    const { currentRows, updateRows } = useEditableGrid({
      rows,
      updateState: updateDesignationsGridState,
    });

    // expose currentRows to parent component
    useImperativeHandle(ref, () => ({ currentRows }), [currentRows]);

    // add used counties per state to the grid state so that the CountiesEditCell can use it
    useEffect(() => {
      addToGridState(
        apiRef.current,
        "assignedCounties",
        makeAssignedCounties(currentRows)
      );
    }, [apiRef, currentRows]);

    return (
      <TableContainer sx={{ height: "100%" }}>
        <DataGridPremium
          sx={{
            "--unstable_DataGrid-headWeight": "bold",
            "& .MuiDataGrid-cell": { padding: "8px 10px" },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "rgba(25, 118, 210, 0.18)",
            },
          }}
          apiRef={apiRef}
          rows={currentRows}
          density="compact"
          getRowHeight={() => "auto"}
          disableColumnSelector
          disableRowGrouping
          disableAggregation
          autoHeight
          hideFooter
          processRowUpdate={(updated, _before) => {
            updateRows(rows, updated);
            return updated;
          }}
          // onCellEditStart={() => setIsEditing(true)}
          // onCellEditStop={() => setIsEditing(false)}
          onProcessRowUpdateError={(error) => console.log(error)}
          disableRowSelectionOnClick
          columns={makeColumns(readOnly)}
          loading={loading}
        />
      </TableContainer>
    );
  }
);
