import { useSelector } from "react-redux";
import { selectSelectedNetwork } from "../../features/networks";
import { useGetDownloadFilingMutation } from "../../api/networksApi";
import { MenuItem } from "@mui/material";
import { CustomGridToolbarExportContainer } from "./CustomGridToolbarExportContainer";
import { useNetworkContextAmplitudeEvent } from "../../hooks/useNetworkContextAmplitudeEvent";

export function ProviderFilingExportToolbar({ sx }) {
  const network = useSelector(selectSelectedNetwork);
  const [download] = useGetDownloadFilingMutation();
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();
  if (network.taxonomy_id !== "HSD") return null;

  return (
    <CustomGridToolbarExportContainer sx={sx} name="Export in filing format">
      <FilingExportMenuItem
        onClick={() => {
          download({
            networkId: network.id,
            fileType: "csv",
            fileId: Date.now(),
          })
          trackNetworkContextAmplitudeEvent("Export data");
        }}
      >
        Export to CSV
      </FilingExportMenuItem>
      <FilingExportMenuItem
        onClick={() => {
          download({
            networkId: network.id,
            fileType: "txt",
            fileId: Date.now(),
          })
          trackNetworkContextAmplitudeEvent("Export data");
        }}
      >
        Export to TXT
      </FilingExportMenuItem>
    </CustomGridToolbarExportContainer>
  );
}

function FilingExportMenuItem({ onClick, hideMenu, children }) {
  return (
    <MenuItem
      onClick={() => {
        onClick();
        hideMenu();
      }}
    >
      {children}
    </MenuItem>
  );
}
