import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ArticleOutlined from "@mui/icons-material/ArticleOutlined";
import HubOutlined from "@mui/icons-material/HubOutlined";
import HelpOutline from "@mui/icons-material/HelpOutline";

import { AppDrawerHeader } from "../AppDrawer/AppDrawerHeader";
import { AppDrawerNavItem } from "../AppDrawer/AppDrawerNavItem";
import { AppDrawerContainer } from "../AppDrawer/AppDrawerContainer";
import { Stack } from "@mui/material";

export function DisruptionAppDrawer() {
  return (
    <AppDrawerContainer>
      <Stack height={"100%"}>
        <List>
          <AppDrawerHeader label="Analyze" />
          <AppDrawerNavItem
            to="/disruption/reports"
            label="Reports"
            icon={<ArticleOutlined />}
          />
        </List>
        <Divider />
        <List>
          <AppDrawerHeader label="Manage" />
          <AppDrawerNavItem
            to="/disruption/networks"
            isSelected={(pathname) =>
              pathname.startsWith("/disruption/networks")
            }
            label="Networks"
            icon={<HubOutlined />}
          />
        </List>
        <List sx={{ marginTop: "auto" }}>
          <AppDrawerHeader label="Account" />
          <AppDrawerNavItem
            to="/user-guide"
            target="_blank"
            rel="noopener noreferrer"
            label="User Guide"
            icon={<HelpOutline />}
            isSelected={(pathname) => pathname.startsWith("/user-guide")}
          />
        </List>
      </Stack>
    </AppDrawerContainer>
  );
}
