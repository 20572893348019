import { Breadcrumbs, Link as MUILink, Typography } from "@mui/material";
import { Link, useMatches } from "react-router-dom";
import { HomeElement } from "./HomeElement";
import { getCrumbLabel } from "./utils";

export function J2Breadcrumbs() {
  const matches = useMatches();
  const definitions = matches.flatMap((match) =>
    match.handle?.crumb ? [[match.pathname, getCrumbLabel(match)]] : []
  );

  const crumbs = definitions.map(([pathname, label], i) => {
    return i < definitions.length - 1 ? (
      <MUILink key={pathname} component={Link} underline="hover" to={pathname}>
        {i === 0 ? <HomeElement>{label}</HomeElement> : label}
      </MUILink>
    ) : (
      <Typography
        key={pathname}
        color="text.primary"
        sx={{ fontSize: "inherit", lineHeight: "inherit" }}
      >
        {label}
      </Typography>
    );
  });

  if (crumbs.length < 2) return null;

  return (
    <Breadcrumbs
      separator="›"
      sx={{
        fontSize: "14px",
        lineHeight: "22px",
        padding: "14px 0 12px",
      }}
    >
      {crumbs}
    </Breadcrumbs>
  );
}
