import React, { useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Tabs,
  Tab,
  DialogContent,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  removeToAdd,
  removeToRemove,
  selectStagedProviders,
} from "../../features/stagedChanges";
import { Table } from "../Table";
import {
  selectTableConfig_P_ALL_INN_TABLE_ROW,
  selectTableConfig_P_ALL_OON_TABLE_ROW,
} from "../../features/tableColumns";
import { useUpdateLabel } from "../labelEdit";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-premium";
import { Delete } from "@mui/icons-material";
import { useOigSanctionedColumnEnabled } from "../../hooks/useOigSanctionedColumnEnabled";

export function StagedChangesModal({ handleClose, showItems, applyChanges }) {
  const { toAdd, toRemove } = useSelector(selectStagedProviders);
  const [tab, switchTab] = useState(showItems ?? 0);
  const handleChange = (event, newValue) => switchTab(newValue);
  const [table1PageSize, setTable1PageSize] = useState(100);
  const apiRef = useGridApiRef();
  const onRowEditChange = useUpdateLabel(apiRef);

  const dispatch = useDispatch();

  let { columns: oonColumns } = useSelector(
    selectTableConfig_P_ALL_OON_TABLE_ROW
  );
  oonColumns = useOigSanctionedColumnEnabled(oonColumns);

  let { columns: innColumns } = useSelector(
    selectTableConfig_P_ALL_INN_TABLE_ROW
  );
  innColumns = useOigSanctionedColumnEnabled(innColumns);

  let rows = tab === 0 ? toAdd : toRemove;
  let columns = useMemo(() => {
    let cols = tab === 0 ? oonColumns : innColumns;
    let undoAction = tab === 0 ? removeToAdd : removeToRemove;
    return [
      {
        field: "actions",
        type: "actions",
        width: 50,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<Delete />}
            onClick={() => dispatch(undoAction([params.id]))}
            label="Delete"
          />,
        ],
      },
      ...cols,
    ];
  }, [tab, oonColumns, innColumns, dispatch]);

  return (
    <Dialog
      onClose={handleClose}
      open={showItems !== false}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Staged changes</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          className="subNavTabs"
          sx={{ marginBottom: "8px" }}
        >
          <Tab disabled={toAdd.length === 0} label="Add to Network" />
          <Tab disabled={toRemove.length === 0} label="Remove From Network" />
        </Tabs>
        <Table
          apiRef={apiRef}
          tableHeight="60dvh"
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          pageSize={table1PageSize}
          onPageSizeChange={setTable1PageSize}
          processRowUpdate={onRowEditChange}
        />

        <Stack
          direction={"row"}
          gap={"16px"}
          sx={{ alignSelf: "center", marginTop: "16px" }}
        >
          <Button
            variant="contained"
            onClick={() => {
              applyChanges();
              handleClose();
            }}
          >
            Apply changes
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
