export function makeAssignedCounties(currentRows) {
  return currentRows.reduce((acc, row) => {
    if (!acc[row.state_id]) {
      acc[row.state_id] = { [row.designation_id]: [...row.counties] };
      return acc;
    }

    if (!acc[row.state_id][row.designation_id]) {
      acc[row.state_id][row.designation_id] = [...row.counties];
      return acc;
    }

    for (const county of row.counties) {
      acc[row.state_id][row.designation_id].push(county);
    }

    return acc;
  }, {});
}

export function addToGridState(apiRef, key, value) {
  if (apiRef) {
    apiRef.setState((state) => ({ ...state, [key]: value }));
  }
}

export function aggregateByDesignations(gridRows) {
  return gridRows.reduce((acc, row) => {
    if (!acc[row.designation_id]) acc[row.designation_id] = [...row.counties];
    else
      acc[row.designation_id] = [...acc[row.designation_id], ...row.counties];
    return acc;
  }, {});
}

export function makeGridRows(states, designations, savedDesignations) {
  let result = [];
  for (const [state_id, state_name] of states) {
    for (const designation of designations) {
      result.push({
        id: `${state_id}-${designation.id}`,
        state_id,
        state_name,
        designation_id: designation.id,
        designation_name: designation.name,
        counties:
          savedDesignations.find((d) => d.id === designation.id)
            ?.counties_by_state[state_id] ?? [],
      });
    }
  }
  return result;
}
