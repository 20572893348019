import { Stack } from "@mui/system";
import { GridToolbarContainer } from "@mui/x-data-grid-premium";
import { ProviderExportToolbar } from "../ProviderExport/ProviderExportToolbar";
import { ProviderFilingExportToolbar } from "../ProviderExport/ProviderFilingExportToolbar";
import { PersistedFiltersToolbar } from "../persistedFilters/PersistedFiltersToolbar";
import { PersistedColumnsToolbar } from "../persistedColumns/PersistedColumnsToolbar";
import { useState } from "react";
import { Alert } from "@mui/material";

export function ProviderToolbar({
  showHSDFilingExport,
  syncModelsRef,
  ...rest
}) {
  const [exportError, setExportError] = useState(null);
  return (
    <>
      {exportError && (
        <Alert severity="error" onClose={() => setExportError(null)}>
          {exportError.name ?? "Error"}: {exportError.message}
        </Alert>
      )}
      <GridToolbarContainer sx={{ marginTop: "4px" }} {...rest}>
        <PersistedFiltersToolbar ref={syncModelsRef} />
        <PersistedColumnsToolbar ref={syncModelsRef} />
        <Stack direction="row" marginLeft="auto">
          {showHSDFilingExport && <ProviderFilingExportToolbar />}
          <ProviderExportToolbar onExportError={setExportError} />
        </Stack>
      </GridToolbarContainer>
    </>
  );
}
