import React, { useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import { Alert, Stack, CircularProgress } from "@mui/material";
import {
  useGetAdequacyMetricsQuery,
  usePutCustomStandardsMutation,
} from "../../api/j2/j2Api";
import { StandardScoreAttributesForm } from "../../components/SelfServeCustomStandards/StandardScoreAttributesForm";

export function StandardScoreAttributesEditRoute() {
  const { standard } = useOutletContext();

  const adequacyMetrics = useGetAdequacyMetricsQuery();

  const [putUpdateStandard, updateStandardResult] =
    usePutCustomStandardsMutation();

  const submitUpdateStandard = useCallback(
    async (data, onSuccess) => {
      try {
        await putUpdateStandard({
          standard,
          data: {
            metrics: [...data.accessMetrics, ...data.countMetrics],
            access_metrics_logical_link: data.accessMetricsLogicalLink,
          },
        }).unwrap();
        if (onSuccess) onSuccess();
      } catch (error) {
        console.error(error?.data ?? error);
      }
    },
    [putUpdateStandard, standard]
  );

  if (adequacyMetrics.isFetching) return <CircularProgress size={14} />;

  return (
    <Stack gap={2} alignItems={"flex-start"}>
      {updateStandardResult.isError && (
        <Alert severity="error">
          {typeof updateStandardResult.error.data === "object"
            ? Object.entries(
                updateStandardResult.error.data ?? {
                  error: "Something went wrong. Cannot update standard.",
                }
              ).map(([key, errors]) => (
                <React.Fragment key={key}>
                  {key}: {errors.join(", ")}
                  <br />
                </React.Fragment>
              ))
            : "Something went wrong. Cannot update standard."}
        </Alert>
      )}
      <StandardScoreAttributesForm
        initValues={standard}
        adequacyMetrics={adequacyMetrics.data}
        onSubmit={submitUpdateStandard}
        isSubmitted={updateStandardResult.isSuccess}
      />
    </Stack>
  );
}
