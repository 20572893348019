import { Badge, Button } from "@mui/material";
import {
  gridFilterModelSelector,
  GridPreferencePanelsValue,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";

const filterTypeFilterCountMapping = {
  id: {
    label: "Specialties",
    columnField: "id",
  },
  gapCount: { label: "Gaps", columnField: "gapCount" },
};

export function CustomFilterButton({ icon, columnId }) {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

  const toggleFilter = (_event) => {
    if (apiRef.current?.state?.rows?.loading) return;
    const { open, openedPanelValue } = apiRef.current.state.preferencePanel;
    if (open && openedPanelValue === GridPreferencePanelsValue.filters) {
      apiRef.current.hideFilterPanel();
      apiRef.current.setState(({ j2_filterColId, ...prev }) => prev);
    } else {
      apiRef.current.showFilterPanel(columnId);
      apiRef.current.setState((prev) => ({
        ...prev,
        j2_filterColId: columnId,
      }));
    }
  };
  const { label = "Missing button label", columnField } =
    filterTypeFilterCountMapping[columnId];
  const filterCount =
    filterModel.items?.filter(
      (f) => f.field === columnField && f.value !== undefined
    ).length ?? 0;

  return (
    <Button
      color="primary"
      size="small"
      startIcon={
        <Badge
          badgeContent={filterCount}
          color="primary"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          overlap="circular"
        >
          {icon}
        </Badge>
      }
      onClick={toggleFilter}
    >
      {label}
    </Button>
  );
}
