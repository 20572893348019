import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { SpecialtyTypeEditCell } from "../SpecialtyTypeEditCell";
import { MappingCell, MappingEditCell } from "./mappingCells";
import { DeleteOutline } from "@mui/icons-material";

export const makeColumns = (deleteSpecialty, readOnly) => {
  const columns = [
    {
      field: "created_at",
      headerName: "Created At",
      hideable: true,
      editable: false,
      type: "string",
    },
    {
      field: "name",
      headerName: "Specialty",
      hideable: false,
      width: 220,
      editable: !readOnly,
      preProcessEditCellProps: (params) => {
        const hasError = params.props.value.trim().length === 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "facility_only",
      headerName: "Type",
      width: 156,
      hideable: false,
      renderEditCell: SpecialtyTypeEditCell,
      editable: !readOnly,
      valueFormatter: ({ value }) => (value ? "Facility" : "Provider"),
    },
    {
      field: "j2_specialties",
      headerName: "Mappings",
      hideable: false,
      width: 440,
      renderCell: MappingCell,
      renderEditCell: MappingEditCell,
      editable: !readOnly,
    },
  ];
  if (!readOnly) {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Delete",
      width: 80,
      cellClassName: "actions",
      getActions: (props) => {
        return [
          <GridActionsCellItem
            icon={<DeleteOutline />}
            label="Delete"
            onClick={() => {
              deleteSpecialty(props.id);
            }}
            color="inherit"
          />,
        ];
      },
    });
  }
  return columns;
};
