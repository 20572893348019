import { symmetricDiffArr } from "../../../utils";

export function updateSpecialtiesGridState(originals, updatedRow) {
  function updateReducer(state) {
    const original = originals.find((s) => s.id === updatedRow.id) ?? {};
    const hasChangesToOriginal =
      ["name", "facility_only"].some(
        (prop) => updatedRow[prop] !== original[prop]
      ) ||
      symmetricDiffArr(updatedRow.j2_specialties, original.j2_specialties)
        .length > 0;

    const newState = new Map(state);

    if (hasChangesToOriginal) newState.set(updatedRow.id, updatedRow);
    else newState.delete(original.id);

    return newState;
  }

  return updateReducer;
}
