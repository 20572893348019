import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";
import { J2Breadcrumbs } from "../J2Breadcrumbs/J2Breadcrumbs";
import { useState } from "react";

export function AppMain() {
  const [alert, setAlert] = useState();

  return (
    <main>
      <Stack px={2} pt={0} pb={3} height="100%" className="disruption">
        {alert && <Stack mt={2}>{alert}</Stack>}
        <J2Breadcrumbs />
        <Outlet context={{ setAlert }} />
      </Stack>
    </main>
  );
}
