import { expandedAdequacyColumns } from "../AdequacyGridColumn/adequacyColumn";
import { exportAs } from "../ScoresTable/exportScorecardCsv";

export function exportToFile(data, options = {}) {
  const {
    utf8WithBom,
    mimeType = "text/csv",
    fileExtension = "csv",
    fileName = "export",
  } = options;
  if (utf8WithBom) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);

    if (data?.unshift) data.unshift(bom); // add bom to the beginning of the array
    if (typeof data === "string") data = [bom, data];
  }

  if (!Array.isArray(data)) data = [data];

  const blob = new Blob(data, { type: mimeType });

  exportAs(blob, fileExtension, fileName);
}

// helper to make template to store indexes of adequacy columns
const adequacyCols = expandedAdequacyColumns.reduce((acc, { field }) => {
  acc[field] = false;
  return acc;
}, {});

export function getRows(rows, headers, serializers, options) {
  // prepare for storing adequacy columns indexes
  let adequacyColIdx = { ...adequacyCols };

  return rows.flatMap(({ model: row }) => {
    const { quotedMatch, escape = '"' } = options ?? {};
    let serializedRows = [];
    const values = [];

    // headers gives as order, and we aggregate values into array to preserve it
    for (let i = 0; i < headers.length; i++) {
      const { field } = headers[i];

      // we store the composite adequacy column so to know when to insert additional adequacy items
      if (adequacyColIdx[field] === false) adequacyColIdx[field] = i;

      // get serializers
      let { valueGetter, valueFormatter } = serializers[field] || {};

      // serialize
      let value = row[field];
      if (valueGetter) value = valueGetter({ value, row });
      if (valueFormatter) value = valueFormatter({ value, row });
      value ??= "";

      // escape if requested
      if (
        quotedMatch &&
        typeof value === "string" &&
        value.indexOf(quotedMatch) >= 0
      ) {
        value = `${escape}${value}${escape}`;
      }

      values.push(value);
    }

    serializedRows.push(values);

    // now disagreggate composite adequacy value if the column is present
    let adequacyArr =
      serializers["adequacy_per_county"]?.valueGetter({ row }) ?? [];

    for (let i = 0; i < adequacyArr.length; i++) {
      // for first item use already existing row, otherwise make a copy

      let adequacyVals = i === 0 ? values : [...values];
      for (let { field } of expandedAdequacyColumns) {
        // escape strings if contain delimiter
        if (
          typeof adequacyArr[i][field.substring(1)] === "string" &&
          adequacyArr[i][field.substring(1)].indexOf(quotedMatch) >= 0
        ) {
          adequacyVals[adequacyColIdx[field]] = `${escape}${
            adequacyArr[i][field.substring(1)]
          }${escape}`;
        } else {
          adequacyVals[adequacyColIdx[field]] =
            adequacyArr[i][field.substring(1)];
        }
      }

      // if not the first item, add new rows
      if (i > 0) serializedRows.push(adequacyVals);
    }
    return serializedRows;
  });
}

export function getHeaders(columns, fieldDefs) {
  return columns.flatMap((col) =>
    fieldDefs[col].disableExport
      ? []
      : col === "adequacy_per_county"
      ? expandedAdequacyColumns
      : [
          {
            field: fieldDefs[col].field,
            headerName: fieldDefs[col].headerName ?? fieldDefs[col].field,
          },
        ]
  );
}
