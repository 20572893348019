import { Alert } from "@mui/material";
import { Fragment } from "react";

export function AlertServerError({
  serverError,
  fallbackMessage = "Something went wrong. We cannot process your request.",
  onClose,
  sx,
}) {
  return (
    <Alert severity="error" sx={sx} onClose={onClose}>
      There was an error processing your request.
      <br />
      {typeof serverError.data === "object"
        ? Object.entries(
            serverError.data ?? {
              error: fallbackMessage,
            }
          ).map(([key, error]) => (
            <Fragment key={key}>
              {key}: {Array.isArray(error) ? error.join(", ") : error}
              <br />
            </Fragment>
          ))
        : fallbackMessage}
    </Alert>
  );
}
