import { useFlags } from "launchdarkly-react-client-sdk";
import { useGetNetworkUpdateTasksQuery } from "../api/j2/j2Api";
import { useCallback } from "react";

export const PREVENT_NETWORKS_UPDATE_FLAG_KEY =
  "j2nm_1763_prevent_networks_update";
export const ONE_HOUR = 1000 * 60 * 60;
const isTimeout = (task) => Date.now() - new Date(task.started_at) > ONE_HOUR;

export function useGetNetworkUpdateTasks() {
  const { data, isFetching } = useGetNetworkUpdateTasksQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const isPreventNetworksEnabled = useFlags()[PREVENT_NETWORKS_UPDATE_FLAG_KEY];

  const isNetworkUpdateInProgress = useCallback(
    (network) => {
      if (!isPreventNetworksEnabled) return false;

      const task = data?.[network.id];
      return !!task && !task.finished_at && !isTimeout(task);
    },
    [isPreventNetworksEnabled, data]
  );

  return { data, isFetching, isNetworkUpdateInProgress };
}
