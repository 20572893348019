import isEqual from "lodash/isEqual";

const isEmptyValue = (f) =>
  f.value === undefined || f.value === "" || f.value === null;

export function isEmptyFilter(filterModel = {}) {
  return (
    !filterModel.items ||
    filterModel.items.length === 0 ||
    filterModel.items.every(isEmptyValue)
  );
}

export function stableFilterModel(m) {
  // remove id that changes across renders
  return { ...m, items: m.items.map(({ id, ...rest }) => rest) };
}

export function isNewFilter(savedFilters, newFilter) {
  if (!savedFilters) return true;
  return !savedFilters.find(([, filter]) => {
    const fm1 = stableFilterModel(filter);
    const fm2 = stableFilterModel(newFilter);
    return isEqual(fm1, fm2);
  });
}

export function prettyFilterModel(filterModel) {
  return JSON.stringify(stableFilterModel(filterModel), null, 2);
}

export function isNewColumns(savedColumns, newColumns) {
  if (!savedColumns) return true;
  return !savedColumns.find(([, columns]) => isEqual(columns, newColumns));
}
