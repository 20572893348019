import { adequacyOperators } from "./adequacyColumnFilters";
import { getAdequacyPerSpecialtyAndCounty } from "./utils";
import { AdequacyCell } from "./AdequacyCell";

const byAdequacy = (s) => s.adequacy;

const adequacyComparator = (v1, v2) => {
  return Math.max(...v1.map(byAdequacy)) - Math.max(...v2.map(byAdequacy));
};

function formatAdequacyImpactForExport({ value }) {
  if (value.length === 0) return "no adequacy impact";
  return value
    .map((v) => `${v.specialtyName}/${v.countyId} = ${v.adequacy}`)
    .join(" | ");
}

// for export that multiplies rows per adequacy impact item
export const expandedAdequacyColumns = [
  {
    field: "_specialtyName",
    headerName: "Impacted Specialty",
    width: 200,
    type: "string",
  },
  {
    field: "_countyId",
    headerName: "Impacted County",
    width: 200,
    type: "string",
  },
  {
    field: "_countyName",
    headerName: "Impacted County Name",
    width: 200,
    type: "string",
  },
  {
    field: "_adequacy",
    headerName: "Adequacy value",
    width: 100,
    type: "number",
  },
];

export function makeAdequacyColumn(rule) {
  const { field, label } = rule;
  return {
    field,
    headerName: label,
    width: 200,
    type: "string",
    renderCell: AdequacyCell,
    sortComparator: adequacyComparator,
    filterOperators: adequacyOperators,
    valueGetter: getAdequacyPerSpecialtyAndCounty,
    valueFormatter: formatAdequacyImpactForExport,
  };
}
