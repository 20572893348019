// import * as Sentry from "@sentry/browser";
import { useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import { useAuth0 } from "@auth0/auth0-react";
import "./Account.css";
import { useLocation, useNavigate } from "react-router-dom";
import { LS_PERSIST_KEY } from "../../store";
import { ListItemButton } from "@mui/material";
import { setUser as setSentryUser } from "@sentry/react";

export const LogoutButton = ({ onClick }) => {
  return (
    <Button variant="outlined" className="logout-button" onClick={onClick}>
      Log Out
    </Button>
  );
};
const Account = () => {
  const { user, isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();
  const from = useLocation().pathname;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!isAuthenticated) {
    return null;
  }

  if (isAuthenticated) {
    return (
      <div className="account-box">
        <List>
          <ListItemButton className="account" onClick={handleClick}>
            <ListItemAvatar>
              <img className="avatar" src={user.picture} alt={user.name} />
            </ListItemAvatar>
            <ListItemText
              primary={user.name}
              primaryTypographyProps={{ variant: "body2", noWrap: true }}
            />
          </ListItemButton>
        </List>
        <Menu
          className="logout-menu"
          elevation={0}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <ListItem>
            <ListItemAvatar>
              <img className="avatar" src={user.picture} alt={user.name} />
            </ListItemAvatar>
            <ListItemText
              primary={user.name}
              primaryTypographyProps={{ variant: "body2", noWrap: true }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <LogoutButton
              onClick={() => {
                logout({ returnTo: window.location.origin });
                localStorage.removeItem(LS_PERSIST_KEY);
                setSentryUser(null);
              }}
            />
            <Button
              className="logout-button"
              onClick={() => {
                navigate("/select-client", {
                  state: { reloadClients: true, from },
                });
              }}
            >
              Change client
            </Button>
          </ListItem>
        </Menu>
      </div>
    );
  }
  return null;
};

export default Account;
