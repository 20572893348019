import xor from "lodash/xor";

export function handleProviderRecordSelectionChange(
  selectionModel,
  newSelectionModel,
  lookup,
  dispatch,
  actions
) {
  if (selectionModel.length === newSelectionModel.length) return;
  console.time("selection model update");
  let toRemove = [],
    toAdd = [],
    idsToRemove = [],
    idsToAdd = [],
    diffA = [],
    diffB = [];

  for (const id of selectionModel) {
    if (lookup[id]) toRemove.push(id);
    else toAdd.push(id);
  }

  for (const id of newSelectionModel) {
    if (lookup[id]) idsToRemove.push(id);
    else idsToAdd.push(id);
  }

  diffA = xor(toRemove, idsToRemove);
  diffB = xor(toAdd, idsToAdd);
  let actionA =
    idsToRemove.length > toRemove.length
      ? actions.addToRemove
      : actions.removeToRemove;
  let actionB =
    idsToAdd.length > toAdd.length ? actions.addToAdd : actions.removeToAdd;
  if (toRemove.length !== idsToRemove.length) dispatch(actionA(diffA));
  if (toAdd.length !== idsToAdd.length) dispatch(actionB(diffB));

  console.timeEnd("selection model update");
}
