import { useSelector } from "react-redux";
import { selectSelectedNetwork } from "../features/networks";
import { useGetCountyScoresQuery } from "../api/networksApi";

export function useIsCountyScoresFetching() {
  const network = useSelector(selectSelectedNetwork);
  const countyScoresRequest = useGetCountyScoresQuery(
    { networkId: network?.id },
    { skip: !network }
  );

  const baselineCountyScoresRequest = useGetCountyScoresQuery(
    { networkId: network?.id, baseline: true },
    { skip: !network }
  );

  return (
    countyScoresRequest.isFetching || baselineCountyScoresRequest.isFetching
  );
}
