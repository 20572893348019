import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { makePrepareHeaders } from "../fetcher";

const API_HOST = process.env.REACT_APP_J2_API_HOST;

export const fetcher = (arg, api) => {
  const { currentClient } = api.getState();
  if (!currentClient) {
    return Promise.reject(new Error("Client not set"));
  }
  const baseUrl = `${API_HOST}/clients/${currentClient}`;
  const prepareHeaders = makePrepareHeaders({ j2ClientHeaders: false });
  const preparedFetcher = fetchBaseQuery({ baseUrl, prepareHeaders });
  return preparedFetcher(arg, api);
};
