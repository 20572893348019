export function toApiRepresentation({ rows, metrics }) {
  const data = [];
  for (const row of rows) {
    const hasAccessValue = metrics.some(
      (m) => m.type === "access" && row[m.code]
    );

    for (const metric of metrics) {
      const isAccessMetric = metric.type === "access";
      const metricValue = row[metric.code];

      // skip empty access metrics if operator is OR and there is another access metric
      if (
        row.has_access_metrics_alternative &&
        hasAccessValue &&
        isAccessMetric &&
        !metricValue
      )
        continue;

      const passing_percentage = isAccessMetric ? row.access_threshold : null;
      const passing_count = isAccessMetric ? row.access_count : null;

      // skip empty rows
      if (!metricValue && !passing_percentage && !passing_count) continue;

      // transpose row to metric row
      const metricRow = {
        id: row[`${metric.code}_id`] ?? null,
        specialty: row.specialty_id,
        designation: row.designation_id,
        metric: metric.id,
        value: metricValue,
        passing_percentage,
        passing_count,
      };

      //  add to data
      data.push(metricRow);
    }
  }
  return data;
}
