import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import ProviderCart from "./ProviderCart";
import { NetworkSelector } from "./NetworkSelector";
import { NetworkStats } from "./NetworkStats";
import "./AppTopBar.css";
import clsx from "clsx";
import { SpecialtySelector } from "./SpecialtySelector";
import { SpecialtyStats } from "./SpecialtyStats";
import { HoveredRegion } from "./HoveredRegion";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsSpecialtyDependentPathname } from "../../features/navigation";
import { ResetToBaselineButton } from "./ResetToBaselineButton";
import { Button } from "@mui/material";
import { useIsCountyScoresFetching } from "../../hooks/useIsCountyScoresFetching";
import { WhenNetworks } from "../WhenNetworks";
import { useAmplitudeEvent } from "../../hooks/useAmplitudeEvent";

export function AppTopBar() {
  const isSpecialtyDependent = useSelector(selectIsSpecialtyDependentPathname);
  const isCountiesFetching = useIsCountyScoresFetching();
  const { pathname } = useLocation();
  const trackAmplitudeEvent = useAmplitudeEvent();

  return (
    <AppBar position="fixed">
      <Toolbar
        className={clsx("custom", { dense: !pathname.startsWith("/self") })}
      >
        {pathname.startsWith("/self-serve") ? (
          <>
            {pathname.includes("networks") && (
              <Button
                size="small"
                component={Link}
                to="self-serve/networks/new"
                variant="contained"
                onClick={() => trackAmplitudeEvent("Start network creation")}
              >
                Create network
              </Button>
            )}
            {pathname.includes("standards") && (
              <Button
                size="small"
                component={Link}
                to="self-serve/standards/new"
                variant="contained"
                onClick={() => trackAmplitudeEvent("Start custom standards")}
              >
                Create standard
              </Button>
            )}
          </>
        ) : (
          <WhenNetworks message="j2health">
            <NetworkSelector />
            <NetworkStats isLoading={isCountiesFetching} />
            {isSpecialtyDependent && (
              <>
                <SpecialtySelector />
                <SpecialtyStats isLoading={isCountiesFetching} />
              </>
            )}
            {pathname === "/map" && <HoveredRegion />}
            <ResetToBaselineButton />
            <ProviderCart />
          </WhenNetworks>
        )}
      </Toolbar>
    </AppBar>
  );
}
