import { getGridStringOperators } from "@mui/x-data-grid-premium";

const stringOperators = getGridStringOperators();

export const customStringColumnType = {
  extendType: "string",
  filterOperators: [
    stringOperators[0],
    {
      value: "not contains",
      label: "not contains",
      InputComponent: stringOperators[0].InputComponent,
      InputComponentProps: { type: "string " },
      getApplyFilterFn: (filter) =>
        filter.value
          ? (row) =>
              (row.value || "")
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) === -1
          : null,
    },
    ...stringOperators.slice(1),
    {
      value: "is comma separated",
      label: "is comma separated",
      InputComponent: stringOperators[0].InputComponent,
      InputComponentProps: { type: "string " },
      getApplyFilterFn: (filter) => {
        return filter.value
          ? (row) =>
              row.value ? filter.value.includes(row.value.trim()) : false
          : null;
      },
    },
  ],
};
