import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/styles";
import { CountiesSelect } from "./CountiesSelect";
import { isPattern, isPresent, validate } from "../../utils/formValidate";
import { PhiConfirmationCheckbox } from "./PhiConfirmationCheckbox";
import { NotEditableFieldTooltip } from "./NonEditableFieldTooltip";
import { lobTemplates } from "./NewNetworkForm";

const Input = styled("input")({
  display: "none",
});

const isUniqueName = (input) => {
  const { field, values } = input;
  const name = values[field];
  const ok = !values.names.find((n) => n === name);
  return ok
    ? { ok: input }
    : { error: "network with this name already exists" };
};

const schema = {
  networkName: validate([isPresent("cannot be empty"), isUniqueName]),
  networkSelectId: validate([
    isPresent("cannot be empty"),
    isPattern(
      new RegExp("^[a-zA-Z0-9]+"),
      "the first letter must be either a letter or number, no special characters allowed"
    ),
    isPattern(
      new RegExp("^[a-zA-Z0-9_]*$"),
      "only letters, numbers and underscore allowed"
    ),
  ]),
};

export function UpdateNetworkForm({
  user,
  network,
  networkNames,
  regulatoryStandards,
  onSubmit,
  readOnly,
}) {
  const { taxonomy_id: lob, id: networkId } = network;
  const serviceAreaState = Object.keys(network.states ?? {})?.[0];
  const networkCountiesOptions = useMemo(
    () =>
      Object.entries(network.counties).map(([fips, county_name]) => ({
        fips,
        county_name,
      })),
    [network.counties]
  );

  const [networkSelectId, setNetworkSelectId] = useState("");
  const [networkSelectIdError, setNetworkSelectIdError] = useState();
  const [networkName] = useState(network.name);
  // const [networkNameError, setNetworkNameError] = useState();

  const [counties, setCounties] = useState([]);
  const [countiesError, setCountiesError] = useState();

  const [file, setFile] = useState();
  const [fileType, setFileType] = useState("full");
  const [phi, setPhi] = useState(false);

  const lobTemplate = lobTemplates[lob];
  const standard = regulatoryStandards?.find((l) => l.value === lob);

  const isFormValid =
    networkName &&
    // !networkNameError &&
    file &&
    counties.length &&
    networkSelectId &&
    !networkSelectIdError &&
    phi;

  const hasChanges =
    networkName !== network.name ||
    file ||
    hasChangedCounties(counties, networkCountiesOptions);

  return (
    <Stack width={"100%"} maxWidth={500} gap={2} mt={2}>
      <Typography component="h1" variant="h4">
        Update network
      </Typography>
      <Stack component="form" width={"100%"} maxWidth={500} gap={1}>
        <NotEditableFieldTooltip>
          <FormControl>
            <TextField
              id="network-name"
              variant="outlined"
              size="small"
              margin="dense"
              label="Network display name"
              value={networkName}
              disabled
              //
              // TODO: uncomment when the display name edititng is unblocked
              //
              // onChange={(e) => {
              //   setNetworkName(e.target.value);
              //   setNetworkNameError();
              // }}
              // onBlur={() => {
              //   setNetworkNameError(
              //     schema.networkName({
              //       field: "networkName",
              //       values: { networkName, names: networkNames },
              //     }).error
              //   );
              // }}
              // error={networkNameError}
              // helperText={networkNameError}
            />
          </FormControl>
        </NotEditableFieldTooltip>

        <NotEditableFieldTooltip>
          <FormControl sx={{ marginTop: "2px" }}>
            <InputLabel size="small" htmlFor="lob-select" id="lob-select-label">
              Regulatory Standard
            </InputLabel>
            <Select
              id="lob-select"
              size="small"
              labelId="lob-select-label"
              label="Regulatory Standard*"
              value={lob}
              disabled
            >
              <MenuItem value={lob}>{lob}</MenuItem>
            </Select>
          </FormControl>
        </NotEditableFieldTooltip>
        {lobTemplate && !readOnly && (
          <FormControl margin="dense">
            <Button
              size="small"
              variant="contained"
              href={lobTemplate}
              download
              sx={{ width: "fit-content", textTransform: "none" }}
              disabled={readOnly}
            >
              Download data template for {standard?.label ?? lob}
            </Button>
          </FormControl>
        )}

        <CountiesSelect
          onChange={setCounties}
          lob={lob}
          value={counties}
          error={countiesError}
          setError={setCountiesError}
          initState={serviceAreaState}
          initCounties={networkCountiesOptions}
          disableStateSelect
          disabled={readOnly}
          disableQuickSave={readOnly}
        />

        {!readOnly && (
          <FormControl>
            <Box sx={{ alignSelf: "flex-start" }}>
              <label htmlFor="select-file">
                <Input
                  id="select-file"
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  accept=".csv"
                />
                <Button variant="outlined" component="span">
                  Select CSV file
                </Button>
              </label>

              <FormHelperText sx={{ marginLeft: "14px" }}>
                {file ? `Selected file: ${file.name}` : "no file selected"}
              </FormHelperText>
              {file && (
                <PhiConfirmationCheckbox
                  value={phi}
                  onChange={(e) => setPhi(e.target.checked)}
                />
              )}
            </Box>
          </FormControl>
        )}

        {file && (
          <>
            <FormControl sx={{ marginLeft: "12px" }}>
              <RadioGroup
                value={fileType}
                onChange={(e) => setFileType(e.target.value)}
                name="radio-buttons-group"
              >
                {/* <FormControlLabel
                  value="partial"
                  control={<Radio />}
                  label="Add provider file to existing network"
                /> */}
                <FormControlLabel
                  value="full"
                  control={<Radio />}
                  label="Replace existing provider file with new provider file"
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <TextField
                id="network-select-id"
                variant="outlined"
                size="small"
                margin="dense"
                label="Network identifier"
                required
                value={networkSelectId}
                onChange={(e) => setNetworkSelectId(e.target.value)}
                onFocus={() => setNetworkSelectIdError()}
                onBlur={() => {
                  setNetworkSelectIdError(
                    schema.networkSelectId({
                      field: "networkSelectId",
                      values: { networkSelectId },
                    }).error
                  );
                }}
                error={networkSelectIdError}
                helperText={
                  networkSelectIdError ||
                  "Only providers whose Network ID in the loaded file EXACTLY matches this identifier will be loaded. It must include only letters, numbers and underscores, and must start with a letter."
                }
              />
            </FormControl>
          </>
        )}
      </Stack>

      {!readOnly && (
        <Stack direction="row" gap={2} mt={1}>
          <Button
            variant="contained"
            disabled={!isFormValid || !hasChanges}
            onClick={() => {
              onSubmit({
                user,
                file,
                updateNetworkId: networkId,
                networkId: networkSelectId,
                networkName,
                lob,
                counties,
                fileType,
                regulatoryStandards,
              });
            }}
          >
            Save data
          </Button>

          <Button component={Link} to="..">
            Cancel
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

function hasChangedCounties(v1, v2) {
  const c1 = v1.map(({ fips }) => fips).sort();
  const c2 = v2.map(({ fips }) => fips).sort();
  return c1.join(",") !== c2.join(",");
}
