import React from "react";
import {
  FormControlLabel,
  styled,
  switchClasses,
  unstable_composeClasses,
} from "@mui/material";
import {
  getDataGridUtilityClass,
  GridPanelContent,
  GridPanelFooter,
  GridPanelWrapper,
  useGridApiContext,
  useGridRootProps,
  // useGridSelector,
} from "@mui/x-data-grid-premium";
import { onlyCountyColumn, setColumns } from "../../features/scorecard";
import { filterByKey } from "../../utils";
import { useSyncWithAction } from "./utils";

// adapted version of default GridColumnsPanel
// https://github.com/mui/mui-x/blob/b47e8837c93b36f81706fd06fc05e778b8143723/packages/grid/x-data-grid/src/components/panel/GridColumnsPanel.tsx

const GridColumnsPanelRoot = styled("div", {
  name: "MuiDataGrid",
  slot: "ColumnsPanel",
  overridesResolver: (props, styles) => styles.columnsPanel,
})(() => ({
  padding: "8px 0px 8px 8px",
}));

const GridColumnsPanelRowRoot = styled("div", {
  name: "MuiDataGrid",
  slot: "ColumnsPanelRow",
  overridesResolver: (props, styles) => styles.columnsPanelRow,
})(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "1px 8px 1px 7px",
  [`& .${switchClasses.root}`]: {
    marginRight: theme.spacing(0.5),
  },
}));

const useUtilityClasses = (ownerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ["columnsPanel"],
    columnsPanelRow: ["columnsPanelRow"],
  };

  return unstable_composeClasses(slots, getDataGridUtilityClass, classes);
};

export const ID_COLUMN_ALWAYS_HIDDEN = { id: false };

export function CustomGridColumnsPanel(props) {
  const apiRef = useGridApiContext();
  const { orderedFields, lookup, columnVisibilityModel } =
    apiRef.current.state.columns;
  const columns = orderedFields.map((c) => lookup[c]); //useGridSelector(apiRef, gridColumnDefinitionsSelector);
  const currentColumns = columns.filter(({ field }) => onlyCountyColumn(field));
  // const columnVisibilityModel = useGridSelector(apiRef, gridColumnVisibilityModelSelector);
  const rootProps = useGridRootProps();
  const ownerState = { classes: rootProps.classes };
  const classes = useUtilityClasses(ownerState);

  const syncColumns = React.useCallback(
    (networkId) => {
      const { columnVisibilityModel } = apiRef.current.state.columns;
      const countyColumns = filterByKey(columnVisibilityModel, ([key]) =>
        onlyCountyColumn(key)
      );
      return setColumns({ columns: countyColumns, networkId });
    },
    [apiRef]
  );
  // on unmount sync columns with redux
  useSyncWithAction(syncColumns);

  const { columnNames, ...other } = props;

  const toggleColumn = (event) => {
    const { name: field } = event.target;
    apiRef.current.setColumnVisibility(
      field,
      columnVisibilityModel[field] === false
    );
    apiRef.current.unstable_applyFilters();
  };

  const toggleAllColumns = React.useCallback(
    (isVisible) => {
      if (isVisible) {
        apiRef.current.setColumnVisibilityModel(ID_COLUMN_ALWAYS_HIDDEN);
        return apiRef.current.unstable_applyFilters();
      }
      apiRef.current.setColumnVisibilityModel({
        ...Object.fromEntries(
          currentColumns
            .filter((col) => col.hideable !== false)
            .map((col) => [col.field, false])
        ),
        ...ID_COLUMN_ALWAYS_HIDDEN,
      });
      apiRef.current.unstable_applyFilters();
    },
    [apiRef, currentColumns]
  );

  const firstSwitchRef = React.useRef(null);

  let firstHideableColumnFound = false;
  const isFirstHideableColumn = (column) => {
    if (firstHideableColumnFound === false && column.hideable !== false) {
      firstHideableColumnFound = true;
      return true;
    }
    return false;
  };

  return (
    <GridPanelWrapper {...other}>
      <GridPanelContent>
        <GridColumnsPanelRoot className={classes.root}>
          {currentColumns.map((column) => (
            <GridColumnsPanelRowRoot
              className={classes.columnsPanelRow}
              key={column.field}
            >
              <FormControlLabel
                control={
                  <rootProps.slots.baseSwitch
                    disabled={column.hideable === false}
                    checked={columnVisibilityModel[column.field] !== false}
                    onClick={toggleColumn}
                    name={column.field}
                    size="small"
                    inputRef={
                      isFirstHideableColumn(column) ? firstSwitchRef : undefined
                    }
                    {...rootProps.slotProps?.baseSwitch}
                  />
                }
                label={columnNames[column.field]}
              />
            </GridColumnsPanelRowRoot>
          ))}
        </GridColumnsPanelRoot>
      </GridPanelContent>
      <GridPanelFooter>
        <rootProps.slots.baseButton
          onClick={() => toggleAllColumns(false)}
          {...rootProps.componentsProps?.baseButton}
        >
          {apiRef.current.getLocaleText("columnsPanelHideAllButton")}
        </rootProps.slots.baseButton>
        <rootProps.slots.baseButton
          onClick={() => toggleAllColumns(true)}
          {...rootProps.componentsProps?.baseButton}
        >
          {apiRef.current.getLocaleText("columnsPanelShowAllButton")}
        </rootProps.slots.baseButton>
      </GridPanelFooter>
    </GridPanelWrapper>
  );
}
