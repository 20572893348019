import { useEffect } from "react";
import {
  useArchiveCustomStandardsMutation,
  useDeleteCustomStandardsMutation,
  usePostCloneCustomStandardsMutation,
  useUnarchiveCustomStandardsMutation,
} from "../api/j2/j2Api";

export const actionsConfig = {
  postCloneCustomStandards: {
    cacheKey: "clone-standard",
    successMsg: "Your standard was successfully copied",
    errorMsg: "Standard has not been copied. Please try again.",
  },
  deleteCustomStandards: {
    cacheKey: "delete-standard",
    successMsg: "Your draft standard was deleted",
    errorMsg: "Standard has not been deleted. Please try again.",
  },
  archiveCustomStandards: {
    cacheKey: "archive-standard",
    successMsg: "Your standard was archived",
    errorMsg: "Standard has not been archived. Please try again.",
  },
  unarchiveCustomStandards: {
    cacheKey: "unarchive-standard",
    successMsg: "Your standard was unarchived",
    errorMsg: "Standard has not been unarchived. Please try again.",
  },
};

export function useHandleStandardActions() {
  // trigger functions are called in onClick handlers in action buttons

  const [, cloneRequestResult] = usePostCloneCustomStandardsMutation({
    fixedCacheKey: actionsConfig.postCloneCustomStandards.cacheKey,
  });

  const [, deleteRequestResult] = useDeleteCustomStandardsMutation({
    fixedCacheKey: actionsConfig.deleteCustomStandards.cacheKey,
  });

  const [, archiveRequestResult] = useArchiveCustomStandardsMutation({
    fixedCacheKey: actionsConfig.archiveCustomStandards.cacheKey,
  });

  const [, unarchiveRequestResult] = useUnarchiveCustomStandardsMutation({
    fixedCacheKey: actionsConfig.unarchiveCustomStandards.cacheKey,
  });

  const result = [
    cloneRequestResult,
    deleteRequestResult,
    archiveRequestResult,
    unarchiveRequestResult,
  ].sort(
    (a, b) =>
      (b.fulfilledTimeStamp ?? b.startedTimeStamp ?? 0) -
      (a.fulfilledTimeStamp ?? a.startedTimeStamp ?? 0)
  )[0];

  const successMsg = result.isSuccess
    ? actionsConfig[result.endpointName]?.successMsg
    : undefined;

  const errorMsg = result.isError
    ? result.error ?? actionsConfig[result.endpointName]?.errorMsg
    : undefined;

  useEffect(() => {
    // scroll to top of page to make sure user sees the message
    window.scrollTo(0, 0);

    // reset the result after showing the message
    if (result.isError || result.isSuccess) {
      return () => result.reset();
    }
  }, [result]);

  return { result, successMsg, errorMsg };
}
