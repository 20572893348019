import React, { forwardRef, useRef, useState } from "react";

import { Table } from "../Table";
import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import "../ProviderGrid/ProviderGrid.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTableConfig_P_ALL_INN_TABLE_ROW,
  selectTableConfig_P_ALL_OON_TABLE_ROW,
  selectTableConfig_P_EDITS_TABLE_ROW,
} from "../../features/tableColumns";
import { useUpdateLabel } from "../labelEdit";
import {
  addToAdd,
  addToRemove,
  removeToAdd,
  removeToRemove,
  selectStagedChangesLookup,
} from "../../features/stagedChanges";
import {
  selectEditedProviders,
  selectProviderInStatus,
  selectProvidersByStatus,
} from "../../features/providers";
import {
  gridColumnDefinitionsSelector,
  gridFilterModelSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useSelectionModelSync } from "../../hooks/useSelectionModelSync";
import { handleProviderRecordSelectionChange } from "../ProviderGrid/handleProviderRecordSelectionChange";
import { ProviderToolbar } from "./ProviderToolbar";
import { useOigSanctionedColumnEnabled } from "../../hooks/useOigSanctionedColumnEnabled";
import { useNetworkContextAmplitudeEvent } from "../../hooks/useNetworkContextAmplitudeEvent";

// const likeDislikeColumn = LikeDislikeColumn();
const modeViewModelMap = {
  DisplayCurrent: selectTableConfig_P_ALL_INN_TABLE_ROW,
  DisplayBuild: selectTableConfig_P_ALL_OON_TABLE_ROW,
  DisplayEdits: selectTableConfig_P_EDITS_TABLE_ROW,
};

const modeRowsSelectorMap = {
  DisplayCurrent: selectProvidersByStatus,
  DisplayBuild: selectProvidersByStatus,
  DisplayEdits: selectEditedProviders,
};

export function ProviderTable({ isLoading, mode }) {
  let rows = useSelector(modeRowsSelectorMap[mode]);

  if (mode === "DisplayBuild") {
    rows = rows.oonProviders;
  } else if (mode === "DisplayCurrent") {
    rows = rows.innProviders;
  }

  const statuses = useSelector(selectProviderInStatus);

  const dispatch = useDispatch();

  const [table1PageSize, setTable1PageSize] = useState(100);

  let { columns, sortModel } = useSelector(modeViewModelMap[mode]);
  columns = useOigSanctionedColumnEnabled(columns);
  const apiRef = useGridApiRef();
  const syncSelectionRef = useRef({});
  const syncModelsRef = useRef({});
  const onRowEditChange = useUpdateLabel(apiRef);
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  return isLoading ? (
    <LinearWithValueLabel />
  ) : (
    <div style={{ height: "100%" }}>
      <Table
        apiRef={apiRef}
        customSortModel={sortModel}
        tableHeight="100%"
        rows={rows}
        columns={columns}
        loading={isLoading}
        checkboxSelection
        disableRowSelectionOnClick
        pageSize={table1PageSize}
        onPageSizeChange={setTable1PageSize}
        processRowUpdate={onRowEditChange}
        slots={{ toolbar: ProviderToolbar }}
        slotProps={{
          toolbar: {
            showHSDFilingExport: mode === "DisplayCurrent",
            syncModelsRef,
          },
        }}
        onRowSelectionModelChange={(newSelectionModel) => {
          handleProviderRecordSelectionChange(
            syncSelectionRef.current.selectionModel,
            newSelectionModel,
            statuses,
            dispatch,
            { addToAdd, removeToAdd, addToRemove, removeToRemove }
          );
        }}
        onFilterModelChange={() => {
          const filterModel = gridFilterModelSelector(apiRef);
          syncModelsRef.current?.setFilterModel(filterModel);
          // Only send events for completed filter inputs
          const filteredColumns = filterModel.items?.filter(({value}) => value && value !== "").map(({field}) => field);
          if (filteredColumns.length > 0) {
            trackNetworkContextAmplitudeEvent("Filter providers", { filteredColumns });
          }
        }}
        onColumnVisibilityModelChange={(_params) => {
          let columnsModel = gridVisibleColumnFieldsSelector(apiRef);
          const allColumns = gridColumnDefinitionsSelector(apiRef);
          if (columnsModel.length === allColumns.length) columnsModel = null;

          syncModelsRef.current?.setColumnsModel(columnsModel);
        }}
      />
      <SelectionModelSync ref={syncSelectionRef} apiRef={apiRef} rows={rows} />
    </div>
  );
}
const SelectionModelSync = forwardRef(function SelectionModelSync(
  { apiRef, rows },
  ref
) {
  const lookup = useSelector(selectStagedChangesLookup);
  const selectionModel = rows.flatMap(({ id }) => (lookup[id] ? [id] : []));
  useSelectionModelSync(apiRef, ref, selectionModel);

  // nothing to render
  return null;
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box sx={{ width: "100%", flexShrink: 0 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
          lineHeight="1"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
      <Box sx={{ lineHeight: 0 }}>
        <CircularProgress color="inherit" size={12} />
      </Box>
    </Box>
  );
}

function LinearWithValueLabel() {
  const [total, setTotal] = React.useState();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const handleTotalEvent = (event) => {
      setTotal((current = 0) => current + event.detail);
    };
    const handleProgressEvent = () => {
      setProgress((current) => current + 1);
    };

    // listening to events emmited when loading provider univers and scores in fn makeFetchWithPages
    window.addEventListener("totalEvent", handleTotalEvent);
    window.addEventListener("progressEvent", handleProgressEvent);

    return () => {
      window.removeEventListener("totalEvent", handleTotalEvent);
      window.removeEventListener("progressEvent", handleProgressEvent);
    };
  }, []);

  if (!total)
    return (
      <div className="providerGrid-loading">
        <CircularProgress color="inherit" size={20} />
      </div>
    );
  return (
    <Box sx={{ width: "50%", margin: "auto" }}>
      <LinearProgressWithLabel
        value={(progress / total) * 100.0}
        sx={{
          height: 10,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "#efefef",
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: "hsl(213, 77%, 60%)",
          },
        }}
      />
    </Box>
  );
}
