import { Stack, Divider, List } from "@mui/material";

import ScoreOutlined from "@mui/icons-material/ScoreOutlined";
import MapOutlined from "@mui/icons-material/MapOutlined";
import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import InboxOutlined from "@mui/icons-material/InboxOutlined";
import AppRegistrationOutlined from "@mui/icons-material/AppRegistrationOutlined";
import ArticleOutlined from "@mui/icons-material/ArticleOutlined";
import HelpOutline from "@mui/icons-material/HelpOutline";
import HubOutlined from "@mui/icons-material/HubOutlined";
import TuneOutlined from "@mui/icons-material/TuneOutlined";

import { useSelfServeAllowed } from "../../hooks/useSelfServeAllowed";
import { useCustomStandardsEnabled } from "../../hooks/useCustomStandardsEnabled";
import { useNetworkContextAmplitudeEvent } from "../../hooks/useNetworkContextAmplitudeEvent";

import { AppDrawerContainer } from "../AppDrawer/AppDrawerContainer";
import { AppDrawerHeader } from "../AppDrawer/AppDrawerHeader";
import { AppDrawerNavItem } from "../AppDrawer/AppDrawerNavItem";

export function AdequacyAppDrawer() {
  const isSelfServeAllowed = useSelfServeAllowed();
  const isCustomStandardsEnabled = useCustomStandardsEnabled();
  const selfServeMenuItem = isCustomStandardsEnabled
    ? { label: "Networks", icon: <HubOutlined /> }
    : { label: "Self serve", icon: <TuneOutlined /> };
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  return (
    <AppDrawerContainer>
      <Stack height={"100%"}>
        <List>
          <AppDrawerHeader label="Analyze" />
          <AppDrawerNavItem
            to="/scorecard"
            label="Scorecard"
            icon={<ScoreOutlined />}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "Scorecard",
              })
            }
          />
          <AppDrawerNavItem
            to="/map"
            label="Map"
            icon={<MapOutlined />}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "Map",
              })
            }
          />
          <AppDrawerNavItem
            to="/reports"
            label="Reports"
            icon={<ArticleOutlined />}
            isSelected={(pathname) => pathname.startsWith("/reports")}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "Reports",
              })
            }
          />
        </List>
        <Divider />
        <List>
          <AppDrawerHeader label="Providers" />
          <AppDrawerNavItem
            to="/current-providers"
            label="Current Network"
            icon={<InboxOutlined />}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "In Network",
              })
            }
          />
          <AppDrawerNavItem
            to="/oon-providers"
            label="Out Of Network"
            icon={<AddBoxOutlined />}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "Out of Network",
              })
            }
            handle={{ title: "Out of Network" }}
          />
          <AppDrawerNavItem
            to="/edits"
            label="Edits"
            icon={<AppRegistrationOutlined />}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "Edits",
              })
            }
          />
        </List>
        {isSelfServeAllowed && (
          <>
            <Divider />
            <List>
              <AppDrawerHeader label="Manage" />
              <AppDrawerNavItem
                to="/self-serve/networks"
                {...selfServeMenuItem}
                isSelected={(pathname) =>
                  pathname.startsWith("/self-serve/networks")
                }
                onClick={() =>
                  trackNetworkContextAmplitudeEvent("View portal page", {
                    page: "Networks",
                  })
                }
              />
              {isCustomStandardsEnabled && (
                <AppDrawerNavItem
                  to="/self-serve/standards"
                  label="Standards"
                  icon={<TuneOutlined />}
                  isSelected={(pathname) =>
                    pathname.startsWith("/self-serve/standards")
                  }
                  onClick={() =>
                    trackNetworkContextAmplitudeEvent("View portal page", {
                      page: "Standards",
                    })
                  }
                />
              )}
            </List>
          </>
        )}
        <Divider />
        <List>
          <AppDrawerHeader label="Account" />
          <AppDrawerNavItem
            to="/user-guide"
            target="_blank"
            rel="noopener noreferrer"
            label="User Guide"
            icon={<HelpOutline />}
            isSelected={(pathname) => pathname.startsWith("/user-guide")}
            onClick={() =>
              trackNetworkContextAmplitudeEvent("View portal page", {
                page: "User Guide",
              })
            }
          />
        </List>
      </Stack>
    </AppDrawerContainer>
  );
}
