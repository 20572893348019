import { useMemo } from "react";
import { Typography, InputLabel, Divider, Stack, Box } from "@mui/material";
import {
  zipDemographicsBucketColor,
  gapsLayerBuckets,
  selectCurrentMetric,
} from "../../features/layersData";
import { useSelector } from "react-redux";

export function LayerLegend({ small = false, sx, id }) {
  const [selected, zipMetric] = useSelector(selectCurrentMetric);
  const currentMetric = zipMetric ?? selected;
  const f = useMemo(
    () => makeFormatter(currentMetric?.format),
    [currentMetric?.format]
  );

  const withSx = small ? currentMetric === "gapsTotal" || zipMetric : true;

  return (
    <Box sx={withSx ? sx : null} id={id}>
      {currentMetric === "none" && !small && <div>No metrics displayed.</div>}
      {currentMetric === "adequacy" && !small && (
        // adequacy is not demographis and has special legend
        <Stack gap={2}>
          <LegendItem color="#81c784" label="Passing Requirement" />
          <LegendItem color="#ef9a9a" label="Failing Requirement" />
        </Stack>
      )}
      {currentMetric === "gapsTotal" && (
        // gapsTotal is not demographis and has special legend
        <>
          {small && (
            <Typography mb={2} variant="subtitle2">
              Total gaps
            </Typography>
          )}
          <Stack gap={2}>
            {gapsLayerBuckets.map(({ bucket, from, to, color }) => {
              let toLabel = "";

              if (to === undefined) toLabel = "+";
              else if (to !== from) toLabel = " - " + f.format(to);

              return (
                <LegendItem
                  key={bucket}
                  color={color}
                  label={`${f.format(from)}${toLabel}`}
                />
              );
            })}
          </Stack>
        </>
      )}
      {zipMetric && (
        <>
          {small && (
            <Typography mb={2} variant="subtitle2">
              {currentMetric.label}
            </Typography>
          )}
          <Stack gap={2}>
            {currentMetric.buckets.map(({ bucket, from, to }) => {
              return (
                <LegendItem
                  key={bucket}
                  color={zipDemographicsBucketColor(bucket - 1)}
                  label={`${f.format(from)} – ${f.format(to)}`}
                />
              );
            })}
          </Stack>
          {!small && (
            <>
              <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
              <InputLabel>Each represents 20% of Zip Codes</InputLabel>
            </>
          )}
        </>
      )}
    </Box>
  );
}

function LegendItem({ label, color }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
    >
      <Typography variant="body1" className="legend-text">
        {label}
      </Typography>
      <div
        style={{
          border: "2px solid",
          width: 16,
          height: 16,
          backgroundColor: color,
        }}
      />
    </Stack>
  );
}

function makeFormatter(format) {
  switch (format) {
    case "currency":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      });

    case "percent":
      return new Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: 2,
      });

    case "number":
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
      });

    case "age":
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
      });

    case "density":
      return new Intl.NumberFormat("en-US", {
        notation: "compact",
        maximumFractionDigits: 0,
      });

    default:
      return { format: (value) => value };
  }
}
