import { MenuItem } from "@mui/material";
import { captureException } from "@sentry/react";
import {
  gridColumnLookupSelector,
  gridFilteredSortedRowEntriesSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { useState } from "react";
import { exportToFile } from "./exportData";
import { useNetworkContextAmplitudeEvent } from "../../hooks/useNetworkContextAmplitudeEvent";

export function ProviderExportToFileMenuItem({
  children,
  options,
  serializer,
  onExportError,
  ...rest
}) {
  const [processing, setProcessing] = useState(false);
  const { hideMenu } = rest;
  const apiRef = useGridApiContext();
  const rowIds = gridFilteredSortedRowEntriesSelector(apiRef);
  const columns = gridVisibleColumnFieldsSelector(apiRef);
  const fields = gridColumnLookupSelector(apiRef);
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  return processing ? (
    <MenuItem>Exporting ... </MenuItem>
  ) : (
    <MenuItem
      onClick={() => {
        if (rowIds.length > 5000) setProcessing(true);
        onExportError?.(null);
        setTimeout(async () => {
          try {
            const data = await serializer(rowIds, columns, fields, options);
            exportToFile(data, options);
          } catch (error) {
            console.error(error);
            captureException(error);
            onExportError?.(errorToDisplay(error));
          }
          trackNetworkContextAmplitudeEvent("Export data");
          setProcessing(false);
          hideMenu?.();
        }, 10);
      }}
    >
      {children}
    </MenuItem>
  );
}

function errorToDisplay(error) {
  if (error?.name !== "ExcelExportTooLarge") {
    return new Error(
      "Export failed. Try dividing the table into smaller chunks by filtering it and run export again."
    );
  }
  return error;
}
