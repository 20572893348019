import { useOutletContext } from "react-router-dom";
import { StandardGeneralForm } from "../../components/SelfServeCustomStandards/StandardGeneralForm";
import { CircularProgress, Stack } from "@mui/material";
import {
  useGetCustomStandardDesignationTypesQuery,
  useGetStandardMemberUniversesQuery,
} from "../../api/j2/j2Api";
import { useHandleStandardWithNestedObjects } from "../../hooks/useHandleStandardWithNestedObjects";

export function StandardGeneralRoute({ readOnly }) {
  const { standard } = useOutletContext();
  const memberUniverses = useGetStandardMemberUniversesQuery(standard.id);
  const designationTypes = useGetCustomStandardDesignationTypesQuery(
    standard.id
  );

  const { submitChanges, showServerErrors, serverErrorInfo } =
    useHandleStandardWithNestedObjects({ standard });

  if (memberUniverses.isFetching || designationTypes.isFetching)
    return <CircularProgress size={14} />;

  return (
    <Stack gap={1}>
      {serverErrorInfo}
      <StandardGeneralForm
        key={`${standard.designation_type}/${standard.member_universe}`}
        initValues={standard}
        memberUniversesOptions={memberUniverses.data}
        designationTypes={designationTypes.data}
        onSubmit={submitChanges}
        readOnly={readOnly}
        onFileValidationError={showServerErrors}
      />
    </Stack>
  );
}
