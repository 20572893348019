import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useCustomFilter } from "../../hooks/useCustomFilter";
import { selectedStatusFilterId } from "../../features/providers";

export function NetworkStatusFilter({
  onNoneButtonToggle,
  showNoneToggle = true,
}) {
  const [{ value }, setValue] = useCustomFilter("is_inn", 0, false);

  const hideAllFilter = useCustomFilter("_hide_all", 0, false);
  const [{ value: hideAll }, setHideAll, ...other] = hideAllFilter;
  const [, removeHideAll] = other;

  const selectedValue =
    hideAll === true ? false : selectedStatusFilterId(value);

  const onButtonChange = (_event, newVal) => {
    if (newVal) {
      // INN, OON, ALL clicked
      if (!selectedValue) {
        // NONE was selected before
        removeHideAll();
        onNoneButtonToggle();
      }
      return setValue(newVal === "ALL" ? [0, 1] : [newVal === "INN" ? 1 : 0]);
    }

    if (newVal === false) {
      // NONE clicked
      setHideAll(true);
      if (onNoneButtonToggle) onNoneButtonToggle();
    }
  };

  return (
    <ToggleButtonGroup
      value={selectedValue}
      exclusive
      size="small"
      onChange={onButtonChange}
    >
      <ToggleButton value="INN">INN</ToggleButton>
      <ToggleButton value="OON">OON</ToggleButton>
      <ToggleButton value="ALL">ALL</ToggleButton>
      {showNoneToggle && (
        <ToggleButton value={false} disabled={!onNoneButtonToggle}>
          NONE
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
}
