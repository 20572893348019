import { useOutletContext } from "react-router-dom";
import {
  CircularProgress,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/material";
import React, { useCallback } from "react";
import { RequirementsGrid } from "../../components/SelfServeCustomStandards/RequirementsGrid";
import {
  usePostCustomStandardRequirementsMutation,
  usePostPublishCustomStandardsMutation,
} from "../../api/j2/j2Api";
import { useTransformRequirementsData } from "../../hooks/standard-requirements/useTransformRequirementsData";
import { useLoadRequirementsData } from "../../hooks/standard-requirements/useLoadRequirementsData";
import { toApiRepresentation } from "../../hooks/standard-requirements/utils/toApiRepresentation";
import { useAmplitudeEvent } from "../../hooks/useAmplitudeEvent";

export function StandardRequirementsEditRoute() {
  const { standard, setAlert } = useOutletContext();
  const trackAmplitudeEvent = useAmplitudeEvent();

  const requirementsDependencies = useLoadRequirementsData(standard);

  const {
    hasRequirementsDependencies,
    isFetching,
    memberUniverses,
    designationTypes,
  } = requirementsDependencies;

  const { columns, rows, standardMetrics } = useTransformRequirementsData(
    standard,
    requirementsDependencies
  );

  const [postUpdateRequirements, updateRequirementsResult] =
    usePostCustomStandardRequirementsMutation();

  const submitUpdateRequirements = useCallback(
    (rows) => {
      const data = toApiRepresentation({ rows, metrics: standardMetrics });
      return postUpdateRequirements({
        standard_id: standard.id,
        data,
      }).unwrap();
    },
    [standardMetrics, postUpdateRequirements, standard.id]
  );

  const [postPublishCustomStandard, publishCustomStandardResult] =
    usePostPublishCustomStandardsMutation();

  const submitPublishStandard = useCallback(async () => {
    try {
      await postPublishCustomStandard({ standard }).unwrap();

      trackAmplitudeEvent("Complete custom standards", {
        displayName: standard.name,
        memberSet: memberUniverses?.data.find(
          (option) => option.id === standard.member_universe
        )?.name,
        designationType: designationTypes?.data.find(
          (option) => option.id === standard.designation_type
        )?.name,
      });

      if (setAlert)
        setAlert(
          <Alert severity="success" onClose={() => setAlert(null)}>
            Your standard is available for use
          </Alert>
        );
    } catch (error) {
      console.error(error?.data?.message ?? error);
    }
  }, [
    postPublishCustomStandard,
    standard,
    setAlert,
    memberUniverses,
    designationTypes,
    trackAmplitudeEvent,
  ]);

  return (
    <Stack gap={2} alignItems={"flex-start"} height={"100%"}>
      {updateRequirementsResult.isError && (
        <Alert severity="error">
          {typeof updateRequirementsResult.error.data === "object"
            ? Object.entries(
                updateRequirementsResult.error.data ?? {
                  error: "Something went wrong. Cannot update requirements.",
                }
              ).map(([key, errors]) => (
                <React.Fragment key={key}>
                  {key}: {errors.join(", ")}
                  <br />
                </React.Fragment>
              ))
            : "Something went wrong. Cannot update requirements."}
        </Alert>
      )}
      {publishCustomStandardResult.isError && (
        <Alert severity="error">
          {typeof publishCustomStandardResult.error.data === "object"
            ? Object.entries(
                publishCustomStandardResult.error.data ?? {
                  error: "Something went wrong. Cannot publish standard.",
                }
              ).map(([key, errors]) => (
                <React.Fragment key={key}>
                  {key}: {errors.join(", ")}
                  <br />
                </React.Fragment>
              ))
            : "Something went wrong. Cannot publish standard."}
        </Alert>
      )}

      {hasRequirementsDependencies ? (
        <>
          <Typography variant="overline" marginBottom={-1}>
            Add requirements
          </Typography>
          <RequirementsGrid
            rows={rows}
            columns={columns}
            loading={isFetching}
            onSave={submitUpdateRequirements}
            onStandardSubmit={submitPublishStandard}
          />
        </>
      ) : isFetching ? (
        <CircularProgress size={14} />
      ) : (
        <FormHelperText>
          Define specialties and score attributes and custom designations if
          necessary.
        </FormHelperText>
      )}
    </Stack>
  );
}
