const DATA = [
  { id: "adequacy", name: "Adequacy", path: "/", default: true },
  { id: "disruption", name: "Disruption", path: "/disruption" },
];

export class WorkspaceDB {
  constructor() {
    this.all = DATA;
    this.default = this.all.find((workspace) => workspace.default);
    this.lookup = this.#makeLookup();
  }

  #makeLookup() {
    return this.all.reduce((acc, workspace) => {
      acc[workspace.id] = workspace;
      return acc;
    }, {});
  }

  getByPathname(pathname) {
    return pathname?.startsWith("/disruption")
      ? this.lookup.disruption
      : this.default;
  }

  getById(id) {
    return this.lookup[id] ?? this.default;
  }
}

export const workspaces = new WorkspaceDB();
