import chunk from "lodash/chunk";

export function invalidateCaches(shouldInvalidate, apiName = "networksApi") {
  return function invalidateCachesThunk(dispatch, getState) {
    let queryCacheKeys = [];
    const subscriptions = Object.entries(getState()[apiName].subscriptions)
      .flatMap(([queryCacheKey, reqs]) => {
        if (shouldInvalidate(queryCacheKey, reqs)) {
          queryCacheKeys.push(queryCacheKey);
          return [
            Object.keys(reqs).map((requestId) => ({
              queryCacheKey,
              requestId,
            })),
          ];
        }
        return [];
      })
      .flat();
    // unsubscribe all the specialties scores other then currently updated
    for (const payload of subscriptions) {
      dispatch({
        type: `${apiName}/subscriptions/unsubscribeQueryResult`,
        payload,
      });
    }
    for (const queryCacheKey of queryCacheKeys) {
      dispatch({
        type: `${apiName}/queries/removeQueryResult`,
        payload: { queryCacheKey },
      });
    }
  };
}

export const arrToQueryParam = (values, key = "add_key") => {
  if (!values) return `${key}=`;
  let query = new URLSearchParams();
  for (const value of values) {
    query.append(key, value);
  }
  return query.toString();
};

export async function invalidateCacheOnNewNetworkState(
  arg,
  { dispatch, queryFulfilled }
) {
  const { networkId, specialtyId } = arg;
  const skipInvalidationKey = JSON.stringify({ networkId, specialtyId });
  // const editsKey = JSON.stringify({ edits: 1 }).replace(/{|}/g, "");
  try {
    await queryFulfilled;
    dispatch(
      invalidateCaches(
        (queryCacheKey) =>
          queryCacheKey.includes("Scores") &&
          !queryCacheKey.includes("CountyScores") &&
          !queryCacheKey.includes(skipInvalidationKey)
        // ||queryCacheKey.includes(editsKey)
      )
    );
  } catch (error) {}
}

// based on
// - exportAs: https://github.com/mui/mui-x/blob/b47e8837c93b36f81706fd06fc05e778b8143723/packages/grid/x-data-grid/src/utils/exportAs.ts

export function downloadAsFile(blob, extension, filename) {
  const fullName = `${filename}.${extension}`;

  // Test download attribute first
  // https://github.com/eligrey/FileSaver.js/issues/193
  if ("download" in HTMLAnchorElement.prototype) {
    // Create an object URL for the blob object
    const url = URL.createObjectURL(blob);

    // Create a new anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = fullName;

    // Programmatically trigger a click on the anchor element
    // Useful if you want the download to happen automatically
    // Without attaching the anchor element to the DOM
    a.click();

    // https://github.com/eligrey/FileSaver.js/issues/205
    setTimeout(() => {
      URL.revokeObjectURL(url);
    });
    return;
  }

  throw new Error("Download not supported");
}

const CHUNK_COUNT = 7;
const specials = [];

export function chunkSpecialties(specialties) {
  return chunk(
    specialties,
    Math.ceil(specialties.length / (CHUNK_COUNT - specials.length))
  );
}

export function makeFetchWithPages(makeRequestParams, mergeChunks, totalPages) {
  return async function rtkQueryFn(args, _state, _extraOptions, baseQuery) {
    const { networkId, specialtyId } =
      typeof args === "object" ? args : { networkId: args };
    // prepare url query string
    let urlParams = new URLSearchParams();
    urlParams.set("networkId", networkId);
    if (specialtyId) urlParams.set("specialtyId", specialtyId);

    // if single specialty or edits just call fetch once
    if (specialtyId) return baseQuery(makeRequestParams(urlParams));

    // send multiple requests for each chunk
    try {
      // urlParams.set("page", 0);
      // urlParams.set("pages", totalPages);

      // const { data: firstResult } = await baseQuery(
      //   makeRequestParams(urlParams)
      // );
      //
      // event to set up the loading progress bar in ProviderTable component
      window.dispatchEvent(
        new CustomEvent("totalEvent", { detail: totalPages })
      );
      let chunkedResult = await Promise.all(
        [...Array(totalPages).keys()].map((_, i) => {
          // urlParams.set("page", i + 1);
          urlParams.set("page", i);
          urlParams.set("pages", totalPages);
          // urlParams.set("totalCount", firstResult.totalCount);
          return baseQuery(makeRequestParams(urlParams)).then((result) => {
            // progress event to update progress bar in ProviderTable component
            window.dispatchEvent(new CustomEvent("progressEvent"));
            return result;
          });
        })
      );
      await new Promise((resolve) => setTimeout(resolve, 1)); // wait a bit to show 100% progress

      // check for errors
      const error = chunkedResult.find((result) => result.error);
      if (error) return { error };

      // return merged chunks
      return { data: mergeChunks(chunkedResult) };
      // return { data: mergeChunks([firstResult, ...chunkedResult]) };
    } catch (error) {
      console.error(error);
      return { error };
    }
  };
}
