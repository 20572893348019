import {
  DriveEtaOutlined,
  LocalHospitalOutlined,
  LocalPharmacyOutlined,
  StraightenOutlined,
} from "@mui/icons-material";
import clsx from "clsx";
import { convertToPercent } from "../utils";
import { SCORES } from "@j2nm/score-metric-keys";

const metrics = [
  [
    SCORES.DRIVE_TIME,
    <DriveEtaOutlined />,
    (val) => convertToPercent(val, true),
  ],
  [
    SCORES.DRIVE_DISTANCE,
    <StraightenOutlined />,
    (val) => convertToPercent(val, true),
  ],
  [SCORES.MINIMUM_COUNT, <LocalHospitalOutlined />],
  [SCORES.MINIMUM_COUNT_IN_COUNTY, <LocalPharmacyOutlined />],
];

export function GeoStats({ label, entityName, entityScore, className }) {
  return (
    <div className={clsx("geo-stats", className)}>
      <div className="metric">
        <span className="label">{label}</span>
        <span className="zipcode">{entityName || "-"}</span>
      </div>

      {metrics.map(([key, icon, format]) => {
        const value = entityScore[key];
        if (value === undefined) return null;

        return (
          <div key={key} className="metric">
            {icon}
            <span>{format ? format(value) : value}</span>
          </div>
        );
      })}
    </div>
  );
}
