import { createSelector } from "@reduxjs/toolkit";
import { selectBaselineScorecard, selectNetworkScorecard } from "./scorecard";
import { selectServiceArea } from "./networks";
import { getScoreComponents } from "../components/ScoresTable/utils";
import { convertToPercent } from "../utils";
import { ADEQUACIES, SCORE_TYPE_LABELS, SCORES } from "@j2nm/score-metric-keys";

const changesBaseColumns = ["id", "changeType", "specialtyName", "countyName"];

const scoreTypeColumns = Object.fromEntries(
  Object.entries(SCORES).map(([scoreType, scoreKey]) => [
    scoreType,
    {
      field: SCORE_TYPE_LABELS[scoreKey],
      headerName: SCORE_TYPE_LABELS[scoreKey],
    },
  ])
);

const changesBaseColumnsMap = {
  changeType: {
    field: "changeType",
    headerName: "Change type",
    width: 120,
    sort: 1,
  },
  countyName: {
    field: "countyName",
    headerName: "County Name",
    width: 200,
    sort: 2,
  },
  specialtyName: {
    field: "specialtyName",
    headerName: "Specialty Name",
    width: 200,
    sort: 3,
  },

  [scoreTypeColumns["DRIVE_DISTANCE"].headerName]: {
    ...scoreTypeColumns["DRIVE_DISTANCE"],
    width: 200,
    valueGetter: ({ value }) => (value ? convertToPercent(value[0], true) : ""),
    sort: 4,
  },
  [scoreTypeColumns["DRIVE_TIME"].headerName]: {
    ...scoreTypeColumns["DRIVE_TIME"],
    width: 200,
    valueGetter: ({ value }) => (value ? convertToPercent(value[0], true) : ""),
    sort: 5,
  },
  [scoreTypeColumns["MINIMUM_COUNT"].headerName]: {
    ...scoreTypeColumns["MINIMUM_COUNT"],
    width: 200,
    valueGetter: ({ value }) => (value ? `${value[0]} / ${value[2]}` : ""),
    sort: 6,
  },
  [scoreTypeColumns["MINIMUM_COUNT_IN_COUNTY"].headerName]: {
    ...scoreTypeColumns["MINIMUM_COUNT_IN_COUNTY"],
    width: 200,
    valueGetter: ({ value }) => (value ? `${value[0]} / ${value[2]}` : ""),
    sort: 7,
  },
  "Telehealth Credit": {
    field: "Telehealth Credit",
    headerName: "Telehealth Credit",
    width: 200,
    valueGetter: ({ value }) => (value ? value[0] : ""),
    sort: 8,
  },
  "Threshold Reduction": {
    field: "Threshold Reduction",
    headerName: "Threshold Reduction",
    width: 200,
    valueGetter: ({ value }) => (value ? value[0] : ""),
    sort: 9,
  },
};

function makeGridColumn(columnKey) {
  const column = changesBaseColumnsMap[columnKey];
  return column ? [column] : [];
}

export const selectChangesToBaseline = createSelector(
  selectBaselineScorecard,
  selectNetworkScorecard,
  selectServiceArea,
  (baseline, current, { countiesStateCode }) => {
    let changes = [];
    let columnsSet = new Set();

    for (const base of baseline) {
      const changed = current.find(
        (item) => item.id === base.id && item.gapCount !== base.gapCount
      );
      if (changed) {
        const changeType = changed.gapCount > base.gapCount ? "open" : "close";

        const { id, name, gapCount, ...countyScores } = changed;

        for (const [countyId, scores] of Object.entries(countyScores)) {
          if (
            scores[ADEQUACIES.OVERALL] !== base[countyId][ADEQUACIES.OVERALL]
          ) {
            let item = {
              id: `${id}-${countyId}`,
              changeType,
              specialtyId: id,
              countyId,
              countyName: countiesStateCode[countyId],
              specialtyName: name,
            };

            const baseScore = getScoreComponents(base[countyId]);
            const currentScore = getScoreComponents(scores);

            if (currentScore.telehealthCredit !== baseScore.telehealthCredit) {
              let label = "Telehealth Credit";
              baseScore.metrics.push({
                label,
                values: [baseScore.telehealthCredit ?? 0],
              });
              currentScore.metrics.push({
                label,
                values: [currentScore.telehealthCredit ?? 0],
              });
            }

            if (
              currentScore.thresholdReduction !== baseScore.thresholdReduction
            ) {
              let label = "Threshold Reduction";
              baseScore.metrics.push({
                label,
                values: [baseScore.thresholdReduction ?? 0],
              });
              currentScore.metrics.push({
                label,
                values: [currentScore.thresholdReduction ?? 0],
              });
            }

            baseScore.metrics.forEach((m) => {
              item[`Old ${m.label}`] = m.values;
            });

            currentScore.metrics.forEach((m) => {
              item[`New ${m.label}`] = m.values;
              columnsSet.add(m.label);
            });

            changes.push(item);
          }
        }
      }
    }

    const baseColumns = changesBaseColumns.flatMap(makeGridColumn);
    const metricColumns = Array.from(columnsSet)
      .flatMap(makeGridColumn)
      .map((column) => {
        const newName = `New ${column.field}`;
        const oldName = `Old ${column.field}`;
        return [
          {
            ...column,
            field: newName,
            headerName: newName,
          },
          {
            ...column,
            field: oldName,
            headerName: oldName,
          },
        ];
      })
      .flat();

    metricColumns.sort((a1, a2) => a1.sort - a2.sort);

    return {
      rows: changes,
      columns: baseColumns.concat(metricColumns),
    };
  }
);
