import { useMemo } from "react";
import { makeGridColumns } from "../../components/SelfServeCustomStandards/requirements/gridColumns";
import { toGridRowsRepresentation } from "./utils/toGridRowsRepresentation";
import { makeMetricsLookup } from "./utils/utils";

export function useTransformRequirementsData(
  standard,
  { metrics, specialties, designations, requirements }
) {
  const metricsLookup = useMemo(
    () => makeMetricsLookup(metrics.data?.all, standard.metrics),
    [metrics.data?.all, standard.metrics]
  );

  const rows = toGridRowsRepresentation({
    standard,
    specialties: specialties.data,
    designations: designations.data,
    requirements: requirements.data ?? {},
    metricsLookup,
  });

  const standardMetrics = Object.values(metricsLookup ?? {});
  const columns = makeGridColumns(standardMetrics, designations.data);
  const readOnlyColumns = makeGridColumns(
    standardMetrics,
    designations.data,
    true
  );

  return {
    rows,
    columns,
    readOnlyColumns,
    standardMetrics,
  };
}
