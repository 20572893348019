import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { tokenFetcher } from "../auth/RequireAuth";

const baseUrl = process.env.REACT_APP_API_URI;

export const makePrepareHeaders =
  ({ j2ClientHeaders = true } = {}) =>
  async (headers, api) => {
    if (tokenFetcher.getToken) {
      const token = await tokenFetcher.getToken();
      if (j2ClientHeaders)
        headers.set("J2-Client", api.getState().currentClient);
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  };

export const fetcher = fetchBaseQuery({
  baseUrl,
  prepareHeaders: makePrepareHeaders(),
});
