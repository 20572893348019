import { PhotoCamera, PictureAsPdf } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { exportAs } from "./ScoresTable/exportScorecardCsv";
import cameraSound from "../assets/camera-13695.mp3";

export function ExportMapButton({ mapRef, network, theme }) {
  const ctx = { network, theme };
  const exportAsPNG = makeExporter("png", mapRef, ctx);
  const exportAsPDF = makeExporter("pdf", mapRef, ctx);

  return (
    <div style={{ display: "flex", gap: "4px" }}>
      <IconButton size="small" onClick={exportAsPNG}>
        <PhotoCamera />
      </IconButton>
      <IconButton size="small" onClick={exportAsPDF}>
        <PictureAsPdf />
      </IconButton>
    </div>
  );
}

// utils
const dpi = 480;

function makeExporter(format, mapRef, { network, theme }) {
  const fileName = `map_${network.name.replaceAll(" ", "_")}_${
    theme.metric_id
  }`;

  // store device pixel ratio as we want to use higher density for export
  const actualDPR = window.devicePixelRatio;

  // restore device pixel ratio
  const onExportDone = () => {
    Object.defineProperty(window, "devicePixelRatio", {
      get: function () {
        return actualDPR;
      },
    });
  };

  return async () => {
    // define target density
    Object.defineProperty(window, "devicePixelRatio", {
      get: function () {
        return dpi / 96;
      },
    });

    // get map canvas
    const canvas = await getMapCanvas(mapRef.current);

    // look for map legend
    const layerLegend = document.getElementById("layer-legend");

    // no legend, export only canvas map
    if (!layerLegend?.childElementCount > 0)
      return exportCanvasWithSound(format, canvas, fileName, onExportDone);

    // there is a legend, so draw it on the map canvas and export canvas

    // turn legend html into svg
    const legendContent = layerLegend.innerHTML;
    const legendClasses = gatherClasses(layerLegend);
    const padding = 16;
    const width = layerLegend.clientWidth + 2 * padding;
    const height = layerLegend.clientHeight + 2 * padding;
    const containerStyle = `background: rgba(255,255,255,0.8); padding: ${padding}px`;

    // get legend element style tags so we can copy them to the svg
    const styles = [...document.styleSheets].flatMap((s) =>
      [...s.cssRules].flatMap((rule) =>
        legendClasses.has(rule.selectorText) ? [rule.cssText] : []
      )
    );

    // make svg
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
        <style>
          ${styles.join(" ")}
        </style>
        <foreignObject width="100%" height="100%">
          <div xmlns="http://www.w3.org/1999/xhtml" style="${containerStyle}">
            ${legendContent}
          </div>
        </foreignObject>
      </svg>`;

    // put legend svg image over map image
    const mapImg = new Image();

    mapImg.onload = function () {
      // make new canvas to be host for the map image
      const exportCanvas = document.createElement("canvas");
      exportCanvas.width = canvas.width;
      exportCanvas.height = canvas.height;

      // draw map image on the canvas
      const ctx = exportCanvas.getContext("2d");
      ctx.drawImage(mapImg, 0, 0);

      // prepare legend image
      const legendImg = new Image();

      // draw legend on the canvas and export it
      legendImg.onload = function () {
        ctx.drawImage(legendImg, 8, 8);
        exportCanvasWithSound(format, exportCanvas, fileName, onExportDone);
      };

      // turn svg into image src
      legendImg.src =
        "data:image/svg+xml; charset=utf8, " + encodeURIComponent(svg);
    };

    // turn map canvas to img
    mapImg.src = canvas.toDataURL();
  };
}

function getMapCanvas(map) {
  return new Promise((resolve, reject) => {
    if (!map) return reject(new Error("Missing map handle"));
    map.once("render", () => {
      resolve(map.getCanvas());
    });
    /* trigger render */
    map.triggerRepaint();
  });
}

function playSound() {
  // Sound Effect by <a href="https://pixabay.com/users/irinairinafomicheva-25140203/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=music&amp;utm_content=13695">irinairinafomicheva</a>
  // from <a href="https://pixabay.com//?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=music&amp;utm_content=13695">Pixabay</a>
  const sound = new Audio(cameraSound);
  sound.play();
}

function exportCanvasWithSound(format, canvas, fileName, onExportDone) {
  canvas?.toBlob(async (blob) => {
    playSound();
    const blobBytes = await converters[format](blob);
    exportAs(blobBytes, format, fileName);
    onExportDone();
  });
}

const converters = {
  png: (blob) => blob,
  pdf: async (blob) => {
    const { PDFDocument } = await import("pdf-lib");

    const pdfDoc = await PDFDocument.create();

    const pngImage = await pdfDoc.embedPng(await blob.arrayBuffer());

    const pngDims = pngImage.scale(1);

    const page = pdfDoc.addPage([pngDims.width, pngDims.height]);

    page.drawImage(pngImage, {
      x: 0,
      y: 0,
      width: pngDims.width,
      height: pngDims.height,
    });

    const pdfBytes = await pdfDoc.save();

    const blobBytes = new Blob([pdfBytes.buffer]);

    return blobBytes;
  },
};

function gatherClasses(element) {
  const classes = new Set();

  function traverse(node) {
    if (node.classList?.length > 0) {
      for (const className of node.classList) classes.add("." + className);
    }

    if (node.hasChildNodes()) {
      for (const childNode of node.childNodes) {
        if (childNode.nodeType === 1) traverse(childNode);
      }
    }
  }

  traverse(element);
  return classes;
}
