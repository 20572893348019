import React, { forwardRef, useImperativeHandle } from "react";
import {
  gridColumnDefinitionsSelector,
  gridColumnLookupSelector,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { usePersistInput } from "../persistInput/usePersistInput";
import { PersistedItems } from "../persistInput/PersistedItems";
import { NotPersistedtem } from "../persistInput/NotPersistedtem";
import { ViewWeekOutlined } from "@mui/icons-material";
import { columnsStorage } from "./storage";
import { isNewColumns } from "./utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { List } from "@mui/material";

const labels = ["column", "columns"];

function renderItem(value, context) {
  return (
    <pre>
      <List>
        {value.map((v) => (
          <li key={v}>{context[v]?.headerName ?? v}</li>
        ))}
      </List>
    </pre>
  );
}

export const PersistedColumnsToolbar = forwardRef(
  function PersistedColumnsToolbar(_props, ref) {
    const apiRef = useGridApiContext();

    const setColumnVisibilityModel = apiRef.current?.setColumnVisibilityModel;
    const columnsLookup = gridColumnLookupSelector(apiRef);

    const onApply = (columns) => {
      const allColumnModels = gridColumnDefinitionsSelector(apiRef);
      let newColumnModels = allColumnModels.reduce((agg, c) => {
        agg[c.field] = columns.includes(c.field);
        return agg;
      }, {});
      setColumnVisibilityModel(newColumnModels);
    };

    const [state, api] = usePersistInput({
      storage: columnsStorage,
      onApply,
      labels,
      renderItem,
    });

    useImperativeHandle(ref, () => {
      return {
        ...ref.current,
        setColumnsModel: (newColumnsModel) => {
          const unsavedColumnsModel = isNewColumns(
            state.persistedItems,
            newColumnsModel
          )
            ? newColumnsModel
            : null;
          api.setNotPersistedValue(unsavedColumnsModel);
        },
      };
    });

    if (!useFlags()["j2nm_2036_saved_columns"]) return null;

    if (!state.hasContent) return null;

    return (
      <>
        {state.persistedItems.length > 0 && (
          <PersistedItems
            state={state}
            api={api}
            icon={<ViewWeekOutlined />}
            context={columnsLookup}
          />
        )}
        {state.notPersistedValue && (
          <NotPersistedtem state={state} api={api} context={columnsLookup} />
        )}
      </>
    );
  }
);
