import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectProviderFilter,
  removeFilter,
  selectProvidersFilters,
  updateFilters,
} from "../features/providers";
import { useDebounced } from "../utils/useDebounced";
import { useMakeSelector } from "./useMakeSelector";
import { useNetworkContextAmplitudeEvent } from "./useNetworkContextAmplitudeEvent";
import { getCustomFilterEventProps } from "../utils/getCustomFilterEventProps";

export function useCustomFilter(field, syncDelay, localState = true) {
  const globalFilter = useMakeSelector(makeSelectProviderFilter, field);
  const allFilters = useSelector(selectProvidersFilters);
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  const { value: filterVal, operator: filterOp } = globalFilter ?? {};
  const [value, setValue] = useState(filterVal);
  const [operator, setOperator] = useState(filterOp);

  const newFilter = { value: localState ? value : filterVal, operator };

  const dispatch = useDispatch();

  const syncWithRedux = useCallback((filter) => {
    dispatch(updateFilters(filter));
    if (filter.value !== "") {
      trackNetworkContextAmplitudeEvent("Filter data", getCustomFilterEventProps(allFilters, filter));
    }
  }, [dispatch, allFilters, trackNetworkContextAmplitudeEvent]);

  const debouncedSyncWithRedux = useDebounced(syncWithRedux, syncDelay);

  const setFilterValue = (val) => {
    if (localState) setValue(val);
    debouncedSyncWithRedux({ field, value: val, operator });
  };

  const setOperatorValue = (val) => {
    if (localState) setOperator(val);
    syncWithRedux({ field, value, operator: val });
  };

  const removeFilterItem = () => dispatch(removeFilter(field));

  return [newFilter, setFilterValue, setOperatorValue, removeFilterItem];
}
