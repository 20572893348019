import { useSelector } from "react-redux";
import { selectSelectedNetwork } from "../features/networks";
import { useLocation, useMatches } from "react-router-dom";
import { useCallback } from "react";
import { useAmplitudeEvent } from "./useAmplitudeEvent";

export function useNetworkContextAmplitudeEvent() {
    const network = useSelector(selectSelectedNetwork);
    const { id, name } = network ?? {};
    const { pathname } = useLocation();
    const matches = useMatches();
    const trackAmplitudeEvent = useAmplitudeEvent();

    const trackNetworkContextAmplitudeEvent = useCallback((eventName, eventProps = {}) => {
        return trackAmplitudeEvent(eventName, {
            networkId: id,
            networkName: name,
            page: matches.find((match) => match.pathname === pathname)?.handle?.title,
            ...eventProps,
        });
    }, [trackAmplitudeEvent, id, name, matches, pathname]);

    return trackNetworkContextAmplitudeEvent;
}
