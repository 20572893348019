import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  selectBaselineCountiesScores,
  selectNetworkCountiesScores,
} from "./scores";
import { selectServiceArea } from "./networks";
import { selectSpecialties } from "./specialties";
import { SCORE_TYPES, ADEQUACIES, SCORES } from "@j2nm/score-metric-keys";

const initialFilters = {
  filters: { items: [], logicOperator: "and" },
  columns: {},
};

const excludedColumns = ["name", "gapCount", "id"];
export const onlyCountyColumn = (col) => !excludedColumns.includes(col);

function initState(state, networkId) {
  if (!state[networkId]) state[networkId] = { ...initialFilters };
}

function allColumnsVisible(columns = {}) {
  return Object.values(columns).every((visibility) => visibility === true);
}

const isEmptyFilterValue = (f) =>
  f.value === undefined || f.value === "" || f.value === null;

export function isEmptyFilter(filterModel = {}) {
  return (
    !filterModel.items ||
    filterModel.items.length === 0 ||
    filterModel.items.every(isEmptyFilterValue)
  );
}

export const scorecardSlice = createSlice({
  name: "scorecardConfig",
  initialState: {},
  reducers: {
    setFilter(state, { payload: { networkId, filters } }) {
      if (
        isEmptyFilter(filters) &&
        allColumnsVisible(state[networkId]?.columns)
      ) {
        delete state[networkId];
      } else {
        initState(state, networkId);
        state[networkId].filters = filters;
      }
    },
    setColumns(state, { payload: { networkId, columns } }) {
      if (
        isEmptyFilter(state[networkId]?.filters) &&
        allColumnsVisible(columns)
      ) {
        delete state[networkId];
      } else {
        initState(state, networkId);
        state[networkId].columns = columns;
      }
    },
    resetScorecardConfig(state) {
      state = { ...initState };
    },
  },
});

export const { setFilter, setColumns, resetScorecardConfig } =
  scorecardSlice.actions;

export const selectScorecardConfig = (state, networkId) =>
  state[scorecardSlice.name][networkId] || initialFilters;

export function gapCount(countyScore) {
  return countyScore[ADEQUACIES.OVERALL] ? 0 : 1;
}

export const sumCountyGaps = (scores) => {
  if (!scores || scores.length === 0) return -1;
  // `ao` means: is it adequate? 0 - no, 1 - yes
  return scores.reduce((acc, score) => acc + gapCount(score), 0);
};

function padMetricComponents(counties, scores, countyMetricKeys) {
  // add ao = 0 to specialty/county pairs where no score is calculated
  // due to eg missing providers of given specialty affecting given county
  if (!countyMetricKeys) return {};

  const result = {};
  for (const countyId in counties) {
    result[countyId] = {
      ...(scores[countyId] ?? { [ADEQUACIES.OVERALL]: 0 }),
    };
    let metricKeys = countyMetricKeys[countyId];
    for (const key of metricKeys) {
      result[countyId][key] ??= null; // turn undefined into null, important
    }
  }
  return result;
}

export const selectNetworkScorecardMetrics = createSelector(
  selectNetworkCountiesScores,
  (scores) => {
    const scorecardKeys = SCORE_TYPES.map((t) => SCORES[t]);
    let result;
    for (const specialtyCounties of Object.values(scores ?? {})) {
      for (const [countyId, metric] of Object.entries(specialtyCounties)) {
        if (!result) result = {};
        result[countyId] = [];
        for (const metricKey of Object.keys(metric)) {
          if (scorecardKeys.includes(metricKey))
            result[countyId].push(metricKey);
        }
      }
    }
    return result;
  }
);

const makeScorecardSelector = (countiesScoreSelector) =>
  createSelector(
    selectSpecialties,
    selectServiceArea,
    countiesScoreSelector,
    selectNetworkScorecardMetrics,
    (specialties, { counties }, scores, countyMetricKeys) => {
      if (!counties || !scores) return [];
      let countyGaps = {};
      const result = specialties.map(({ id, name, telehealth }) => {
        const countyScores = padMetricComponents(
          counties,
          scores[id] || {},
          countyMetricKeys
        );
        countyGaps = Object.fromEntries(
          Object.entries(countyScores).map(([id, score]) => [
            id,
            (countyGaps[id] ?? 0) + gapCount(score),
          ])
        );
        const specialtyGapCount = sumCountyGaps(Object.values(countyScores));
        return {
          id,
          name,
          telehealth,
          gapCount: specialtyGapCount,
          ...countyScores,
        };
      });
      result.countyGaps = countyGaps;
      return result;
    }
  );

export const selectNetworkScorecard = makeScorecardSelector(
  selectNetworkCountiesScores
);

const makeTotalGapsSelector = (scorecardSelector) =>
  createSelector(scorecardSelector, (scorecard) =>
    scorecard.reduce(
      (acc, { gapCount }) => acc + (gapCount > -1 ? gapCount : 0),
      0
    )
  );

export const selectNetworkTotalGaps = makeTotalGapsSelector(
  selectNetworkScorecard
);

export const selectBaselineScorecard = makeScorecardSelector(
  selectBaselineCountiesScores
);

export const selectBaselineTotalGaps = makeTotalGapsSelector(
  selectBaselineScorecard
);
