import { Link } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import EditOutlined from "@mui/icons-material/EditOutlined";

export const networksGridColumns = [
  { field: "name", headerName: "Network Name", width: 300 },
  { field: "createdBy", headerName: "Created By", width: 250 },
  {
    field: "created_at",
    type: "date",
    headerName: "Created Date",
    width: 200,
    valueGetter: ({ row }) => new Date(row.created_at),
    valueFormatter: ({ value }) =>
      new Intl.DateTimeFormat("en-US").format(value),
  },
  // { field: "updated_at", headerName: "Last updated at", width: 120 },
  {
    field: "actions",
    type: "actions",
    getActions: ({ id, row }) => {
      const { canBeEdited, userCanEdit, hasUpdateInProgress, handleClick } =
        row;
      if (!canBeEdited) return [];

      const actionEnabled = userCanEdit && !hasUpdateInProgress;

      const actionButton = (
        <Button
          size="small"
          startIcon={<EditOutlined />}
          component={Link}
          to={`${id}/edit`}
          disabled={!actionEnabled}
          onClick={handleClick}
        >
          Update
        </Button>
      );
      return [
        actionEnabled ? (
          actionButton
        ) : (
          <Tooltip
            title={
              !userCanEdit
                ? "Only networks you have created are editable"
                : "There is ongoing update network task"
            }
          >
            <div>{actionButton}</div>
          </Tooltip>
        ),
      ];
    },
  },
];
