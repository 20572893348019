import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const DarkBlueButton = styled(Button)({
  backgroundColor: "white",
  color: "#00529b", // dark blue
  "&:hover": {
    backgroundColor: "#f9f9f9", // slightly lighter blue on hover
  },
});

export function CustomStandardReleaseButton({ onPublish, standard }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <DarkBlueButton
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => setOpen(true)}
      >
        Save & Submit
      </DarkBlueButton>
      <Dialog open={open}>
        <DialogTitle>Save and submit {standard.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            After submission, your standard will no longer be editable. Please
            confirm that the standard is complete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              setOpen(false);
              onPublish();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
