import { Stack, Tooltip } from "@mui/material";
import { ErrorOutlineOutlined } from "@mui/icons-material";

function InvalidCellValue({ children, error }) {
  return (
    <Tooltip title={error}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"4px"}
        sx={{ color: "#d32f2f" }}
      >
        {children}
        <ErrorOutlineOutlined color="error" fontSize={"small"} />
      </Stack>
    </Tooltip>
  );
}

export function renderMetricCell(params) {
  const { api, colDef, value, row } = params;
  const error = api.state.validationErrors?.[row.id]?.[colDef.field];
  if (error) return <InvalidCellValue error={error}>{value}</InvalidCellValue>;
  return params.value;
}
