import { yesNoFormatter } from "../utils";

export const adequacyReport = {
  id: "adequacy-by-county",
  name: "Adequacy Report",
  columns: [
    { field: "network_name", headerName: "Network Name", width: 200 },

    { field: "taxonomy_id", headerName: "Standard" },

    { field: "specialty_code", headerName: "Specialty Code" },

    { field: "specialty_name", headerName: "Specialty", width: 250 },

    { field: "county_ssa", headerName: "SSA County" },

    { field: "county_id", headerName: "FIPS County" },

    { field: "county_name", headerName: "County Name" },

    { field: "state_name", headerName: "State" },

    { field: "county_designation", headerName: "County Designation" },

    {
      field: "met_overall",
      headerName: "Met Overall (Access and Provider)",
      type: "boolean",
      width: 150,
      valueFormatter: yesNoFormatter,
    },

    {
      field: "met_access",
      headerName: "Met Access",
      type: "boolean",
      valueFormatter: yesNoFormatter,
    },

    {
      field: "overall_score",
      headerName: "Access Score (%)",
      type: "number",
      width: 150,
    },
    {
      field: "pass_criteria",
      headerName: "Access Threshold (%)",
      type: "number",
      width: 150,
    },

    {
      field: "tel_credit_perc",
      headerName: "Telehealth Credit (%)",
      type: "number",
      width: 150,
    },
    {
      field: "con_credit_perc",
      headerName: "CON Credit (%)",
      type: "number",
      width: 150,
    },
    {
      field: "exp_credit_perc",
      headerName: "Expansion Credit (%)",
      type: "number",
      width: 150,
    },

    {
      field: "met_distance",
      headerName: "Met Drive Distance",
      type: "boolean",
      valueFormatter: yesNoFormatter,
    },

    {
      field: "distance_requirement",
      headerName: "Drive Distance Requirement",
      type: "number",
      width: 150,
    },

    {
      field: "distance_score",
      headerName: "Drive Distance Score (%)",
      type: "number",
    },

    {
      field: "met_time",
      headerName: "Met Drive Time",
      type: "boolean",
      valueFormatter: yesNoFormatter,
    },

    {
      field: "time_requirement",
      headerName: "Drive Time Requirement",
      type: "number",
      width: 150,
    },

    {
      field: "time_score",
      headerName: "Drive Time Score (%)",
      type: "number",
    },

    {
      field: "met_providers",
      headerName: "Met Provider Requirement",
      type: "boolean",
      valueFormatter: yesNoFormatter,
    },

    {
      field: "met_min_count",
      headerName: "Met Servicing Provider Count",
      type: "boolean",
      valueFormatter: yesNoFormatter,
    },

    {
      field: "min_count_requirement",
      headerName: "Covering Count Requirement",
      type: "number",
    },

    {
      field: "n_covering",
      headerName: "Servicing Provider Count",
      type: "number",
    },

    {
      field: "met_n_in_county",
      headerName: "Met Provider County",
      type: "boolean",
      valueFormatter: yesNoFormatter,
    },

    {
      field: "n_in_county_requirement",
      headerName: "Provider Count Requirement",
      type: "number",
    },

    {
      field: "n_in_county",
      headerName: "Provider Count",
      type: "number",
    },
  ],
};
