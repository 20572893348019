export function withTenantInBody(query, { tenantKey = "client_name" } = {}) {
  return function endpointQueryFn(
    data,
    { getState },
    _extraOptions,
    baseQuery
  ) {
    const tenant = getState().currentClient;
    const body = { ...data, [tenantKey]: tenant };
    return baseQuery(query(body));
  };
}
