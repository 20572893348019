const specialtiesTemplate = [
  {
    id: 1,
    name: "Allergy",
    facilityOnly: false,
    telehealth: false,
    mappings: [7, 8],
  },
  {
    id: 2,
    name: "Cardiology",
    facilityOnly: false,
    telehealth: false,
    mappings: [],
  },
];

const requirementsTemplate = [
  {
    id: 1,
    standard_id: "Test",
    specialty_id: 1,
    specialty: "Allergy",
    rurality: "Metro",
    drive_distance_req: 20,
    threshold: 90,
    drive_time_req: null,
    drive_time_thr: null,
    min_count_req: 2,
    min_count_in_c_req: null,
    beds: null,
  },
  {
    id: 2,
    standard_id: "Test",
    specialty_id: 1,
    specialty: "Allergy",
    rurality: "Rural",
    drive_distance_req: 30,
    threshold: 90,
    drive_time_req: null,
    drive_time_thr: null,
    min_count_req: 3,
    min_count_in_c_req: null,
    beds: null,
  },
  {
    id: 3,
    standard_id: "Test",
    specialty_id: 1,
    specialty: "Allergy",
    rurality: "Large Metro",
    drive_distance_req: 10,
    threshold: 90,
    drive_time_req: null,
    drive_time_thr: null,
    min_count_req: 1,
    min_count_in_c_req: null,
    beds: null,
  },
  {
    id: 101,
    standard_id: "Test",
    specialty_id: 2,
    specialty: "Cardiology",
    county_id: "32001",
    rurality: "Metro",
    drive_distance_req: 20,
    threshold: 90,
    drive_time_req: null,
    drive_time_thr: null,
    min_count_req: 2,
    min_count_in_c_req: null,
    beds: null,
  },
  {
    id: 102,
    standard_id: "Test",
    specialty_id: 2,
    specialty: "Cardiology",
    rurality: "Rural",
    drive_distance_req: 30,
    threshold: 90,
    drive_time_req: null,
    drive_time_thr: null,
    min_count_req: 3,
    min_count_in_c_req: null,
    beds: null,
  },
  {
    id: 103,
    standard_id: "Test",
    specialty_id: 2,
    specialty: "Cardiology",
    rurality: "Large Metro",
    drive_distance_req: 10,
    threshold: 90,
    drive_time_req: null,
    drive_time_thr: null,
    min_count_req: 1,
    min_count_in_c_req: null,
    beds: null,
  },
];

const db = {
  configs: [
    {
      display_name: "Test",
      state_ids: ["01", "60"],
      member_taxonomy_id: "QHP",
      id: "Test",
    },
  ],
  specialties: specialtiesTemplate.map((v) => ({
    ...v,
    standard_id: "Test",
    mappings: [...v.mappings],
  })),
  requirements: [...requirementsTemplate],
};

export function standardsFetcherMock(args, options, extraOptions) {
  console.log("FETCHER", args, options, extraOptions);

  if (args.url.endsWith("/specialties")) {
    if (args.method === "GET") {
      console.log({ db });
      return simulateFetch(() => ({
        data: db.specialties
          .filter(({ standard_id }) => standard_id === args.id)
          .sort((a, b) => (a.name > b.name ? 1 : -1)),
      }));
    }
    if (args.method === "POST") {
      return simulateFetch(() => {
        let newSpecialties;
        const { id: standard_id, ...formData } = args.body;
        if (args.body.file) {
          newSpecialties = specialtiesTemplate.map((s) => ({
            ...s,
            id: s.id + Date.now(),
            name: s.name + s.id,
            mappings: [...s.mappings],
            standard_id,
          }));
        } else {
          newSpecialties = [
            { standard_id, id: formData.name + Date.now(), ...formData },
          ];
        }
        db.specialties = [...db.specialties, ...newSpecialties];
        const tmpl = requirementsTemplate.slice(0, 3);
        const newRequirements = newSpecialties.flatMap((s) =>
          tmpl.map((t) => ({
            ...t,
            id: `${s.id}-${t.id}` + Date.now(),
            standard_id: s.standard_id,
            specialty_id: s.id,
            specialty: s.name,
          }))
        );

        db.requirements = [...db.requirements, ...newRequirements];
        console.log({ db });
        return { data: { result: "ok" } };
      });
    }

    if (args.method === "PUT") {
      return simulateFetch(() => {
        const filtered = db.specialties.filter(
          (s) => s.standard_id !== args.id
        );
        db.specialties = [
          ...filtered,
          ...args.body.flatMap(({ _deleted, ...s }) =>
            _deleted ? [] : [{ ...s, standard_id: args.id }]
          ),
        ];
        console.log({ db });
        return { data: { result: "ok" } };
      });
    }

    if (args.method === "DELETE") {
      return simulateFetch(() => {
        let result = [];
        for (const specialty of db.specialties) {
          if (!args.body.includes(specialty.id)) result.push(specialty);
        }
        db.specialties = result;
        db.requirements = db.requirements.filter(
          (r) => !args.body.includes(r.specialty_id)
        );

        console.log({ db });
        return { data: { result: "ok" } };
      });
    }
  }
  if (args.url.endsWith("/mappings")) {
    if (args.method === "POST") {
      return simulateFetch(() => {
        let newRows = [];
        for (const oldRow of db.specialties) {
          const row = { ...oldRow };
          const mappings = args.body.mappings.flatMap((m) =>
            m.specialty_id === oldRow.id ? [...m.j2_specialty_ids] : []
          );
          if (mappings.length > 0) row.mappings = [...mappings];
          newRows.push(row);
        }
        db.specialties = newRows;
        console.log({ db });
        return { data: { result: "ok" } };
      });
    }
  }

  if (args.url.endsWith("/requirements")) {
    if (args.method === "GET") {
      return simulateFetch(() => ({
        data: db.requirements.filter(
          ({ standard_id }) => standard_id === args.id
        ),
      }));
    }
    if (args.method === "POST") {
      return simulateFetch(() => {
        const newRequirements = requirementsTemplate.map((s, i) => ({
          ...s,
          id: Date.now() + i,
          standard_id: args.body.id,
        }));
        db.requirements = newRequirements;
        console.log({ db });
        return { data: { result: "ok" } };
      });
    }

    if (args.method === "PUT") {
      return simulateFetch(() => {
        const filtered = db.requirements.filter(
          (s) => s.standard_id !== args.id
        );
        db.requirements = [
          ...filtered,
          ...args.body.map((r) => ({ ...r, standard_id: args.id })),
        ];
        console.log({ db });
        return { data: { result: "ok" } };
      });
    }
  }

  //
  if (args.method === "GET") {
    return simulateFetch(() => ({ data: [...db.configs] }), 500);
  }

  if (args.method === "POST") {
    return simulateFetch(() => {
      const id = args.body.display_name.replaceAll(" ", "");
      db.configs.push({
        ...args.body,
        state_ids: [...args.body.state_ids],
        id,
      });

      return { data: { id } };
      // return { error: { data: { message: "error" } } };
    });
  }
  if (args.method === "PUT") {
    return simulateFetch(() => {
      const idx = db.configs.findIndex(({ id }) => id === args.body.id);
      db.configs[idx] = { ...args.body, state_ids: [...args.body.state_ids] };
      return { data: { result: "ok" } };
    });
  }
  return Promise.resolve({ data: [] });
}

function simulateFetch(fn, duration = 1000) {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      try {
        resolve(fn());
      } catch (error) {
        reject({ error: { data: { message: error.message } } });
      }
    }, duration)
  );
}
