import { gridFilteredSortedTopLevelRowEntriesSelector } from "@mui/x-data-grid-premium";

export function updateRequirementsGridState(
  originals,
  updatedRow,
  { apiRef, onUpdate }
) {
  function updateReducer(state) {
    const changedField = apiRef.current.state.changedField;

    let toUpdate = [{ id: updatedRow.id, model: updatedRow }];
    const value = updatedRow[changedField];

    if (apiRef.current.state.fillButtonClicked) {
      const visibleRows = gridFilteredSortedTopLevelRowEntriesSelector(apiRef);
      toUpdate = visibleRows;

      // clear fillButtonClicked, changedField in the state
      apiRef.current.setState(
        ({ fillButtonClicked, changedField, ...state }) => state
      );
    }

    const newState = new Map(state);
    for (const row of toUpdate) {
      const hasChangesToOriginal = originals.some((r) => {
        return r.id === row.id && r[changedField] !== value;
      });
      if (hasChangesToOriginal)
        newState.set(row.id, { ...row.model, [changedField]: value });
      else newState.delete(row.id);
    }
    if (onUpdate && changedField) onUpdate(toUpdate, changedField, newState);
    return newState;
  }

  return updateReducer;
}
