import { Autocomplete, Checkbox, Chip, Stack, TextField } from "@mui/material";

export function CountiesEditCell(props) {
  const { id, value: currentIds = [], field, api, row } = props;
  const { statesWithCounties, assignedCounties } = api.state;
  const stateCounties =
    statesWithCounties?.find((s) => s.code === row.state_id)?.counties ?? [];
  const countiesLookup = stateCounties.reduce((acc, c) => {
    acc[c.fips] = c.county_name;
    return acc;
  }, {});
  const otherRowsCounties = Object.entries(
    assignedCounties[row.state_id]
  ).flatMap(([designation_id, counties]) => {
    if (designation_id === String(row.designation_id)) return [];
    return counties;
  });

  return (
    <Autocomplete
      id="counties-select"
      size="small"
      multiple
      disableCloseOnSelect
      openOnFocus
      fullWidth
      options={Object.entries(countiesLookup)}
      isOptionEqualToValue={(option, value) => option[1] === value[1]}
      value={currentIds.map((id) => [id, countiesLookup[id] ?? "missing name"])}
      getOptionLabel={(option) => option[1]}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            checked={selected || isAlreadAssigned(otherRowsCounties, option)}
          />
          {option[1]} ({option[0]})
        </li>
      )}
      getOptionDisabled={(option) =>
        isAlreadAssigned(otherRowsCounties, option)
      }
      onChange={(_, value) => {
        api.setEditCellValue({ id, field, value: value.map(([id]) => id) });
      }}
      // onChange={(_event, selections) => setCounties(selections)}
      // open={open}
      // onOpen={() => setOpen(true)}
      // onClose={() => setOpen(false)}
      // onPaste={(e) => {
      //   e.preventDefault();

      //   const paste = e.clipboardData?.getData("text") ?? "";
      //   if (!paste) return;

      //   const inputValuesSet = new Set(
      //     paste.split(pasteSplitRx).filter((i) => i)
      //   );
      //   const inputValues = [...inputValuesSet];
      //   let notMatched = [];
      //   let selections = [];
      //   for (const input of inputValues) {
      //     const matched = stateCounties.filter(
      //       (o) =>
      //         o.fips === input ||
      //         o.county_name.toLowerCase() === input.toLowerCase()
      //     );
      //     if (matched.length > 0) selections.push(matched);
      //     else notMatched.push(input);
      //   }
      //   setNotMatchedPaste(notMatched);
      //   setCounties(selections.flat());
      //   setOpen(false);
      // }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          margin="none"
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // disable browser autocomplete and autofill
          }}
        />
      )}
    />
  );
}

function isAlreadAssigned(assignedValues, option) {
  return assignedValues.includes(option[0]);
}

export function CountiesCell({ row, api }) {
  const { statesWithCounties = [] } = api.state;
  if (!statesWithCounties?.length) return null;
  const stateCounties =
    statesWithCounties?.find((s) => s.code === row.state_id)?.counties ?? [];
  const countiesLookup = stateCounties.reduce((acc, c) => {
    acc[c.fips] = c.county_name;
    return acc;
  }, {});
  const names = row.counties.map((id) => countiesLookup[id]).sort();

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      height="100%"
      sx={{
        flexWrap: "wrap",
        gap: "4px",
        overflowX: "auto",
      }}
    >
      {names.map((name) => (
        <Chip
          key={name}
          size="small"
          label={name}
          sx={{ lineHeight: "1.1", marginLeft: "0 !important" }}
        />
      ))}
    </Stack>
  );
}
