import { ListItem, Typography } from "@mui/material";

export function AppDrawerHeader({ label, children }) {
  return (
    <ListItem sx={{ padding: "0px 16px" }}>
      <Typography variant="overline" component="h3" className="sectionHeader">
        {label}
      </Typography>
      {children}
    </ListItem>
  );
}
