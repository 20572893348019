import { Alert } from "@mui/material";
import { Fragment } from "react";

export function AlertServerWarning({
  warnings,
  fallbackMessage = "Your request was processed but some inputs have been adjusted.",
  onClose,
  sx,
}) {
    return (
        <Alert severity="success" color="warning" sx={sx} onClose={onClose}>
        Your network is being processed with warnings.
        <br />
        {typeof warnings === "object"
            ? Object.entries(
                warnings ?? {
                    warning: fallbackMessage,
                }
            ).map(([key, warning]) => (
                <Fragment key={key}>
                {key}: {typeof warning === "object" ? warning.map(item => Object.values(item)).join(', ') : warning}
                <br />
                </Fragment>
            ))
            : fallbackMessage}
        </Alert>
    );
}
