import React from "react";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import "./ProviderPopup.css";
import { IconButton, Popover } from "@mui/material";
import { Brightness1Rounded, HelpOutline } from "@mui/icons-material";
import { MapLegend } from "../../components/DataPanel/MapLegend";
import { oonAdditiveColors } from "../layers/layerStyles";
import {
  selectStagedChanges,
  toggleToAdd,
  toggleToRemove,
} from "../../features/stagedChanges";
import { selectTableConfig_P_MAP_TABLE_ROW } from "../../features/tableColumns";
import { selectFilteredProviders } from "../../features/providers";
import { useOigSanctionedColumnEnabled } from "../../hooks/useOigSanctionedColumnEnabled";

function step(steps = [], value) {
  let i = 1;
  while (i < steps.length && value > steps[i]) i = i + 2;
  return steps[i - 1];
}

const ProviderAttributes = ({ attribute, provider }) => {
  const { field, headerName, valueGetter, renderCell } = attribute;
  const params = { row: provider, id: provider.id };
  const value = valueGetter ? valueGetter(params) : provider[field];
  let renderedField = renderCell ? renderCell({ ...params, value }) : value;
  renderedField =
    typeof renderedField === "boolean"
      ? renderedField.toString()
      : renderedField;
  return (
    <li
      key={field}
      className={`field ${field.replace(/\s/g, "-").toLowerCase()}`}
    >
      <div className="label">{headerName}: </div>
      <div className="value">{renderedField}</div>
    </li>
  );
};

const ProviderPopup = ({ popupInfo }) => {
  const providers = useSelector(selectFilteredProviders);
  const { toAdd, toRemove } = useSelector(selectStagedChanges);
  let { columns: fields } = useSelector(selectTableConfig_P_MAP_TABLE_ROW);
  fields = useOigSanctionedColumnEnabled(fields);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const handleHelpClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (popupInfo == null) return null;

  return (
    <div className="providerPopup">
      <IconButton className="legend-button" onClick={handleHelpClick}>
        <HelpOutline />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MapLegend />
      </Popover>
      <List>
        {popupInfo.map(({ properties: point }) => {
          const provider = providers.find((p) => p.id === point.id) ?? {};
          return (
            <ListItem divider key={point.id}>
              <div className="providerPopupSection">
                {point.is_inn ? (
                  <>
                    <Chip
                      label="In-Network"
                      color="primary"
                      size="small"
                      variant={point.isEdited ? "outlined" : "default"}
                      icon={<Brightness1Rounded style={{ color: "#53b1d6" }} />}
                      onClick={handleHelpClick}
                    />
                    <ul className="fields">
                      {fields.map((fieldDef) => (
                        <ProviderAttributes
                          key={fieldDef.field}
                          attribute={fieldDef}
                          provider={provider}
                        />
                      ))}
                    </ul>
                    <Button
                      size="small"
                      onClick={() => dispatch(toggleToRemove(point.id))}
                      className="providerPopupActionButton"
                      variant="outlined"
                    >
                      {!toRemove.includes(point.id)
                        ? `Remove from Network`
                        : `Remove from Staging`}
                    </Button>
                  </>
                ) : (
                  <>
                    <Chip
                      label="Out-Of-Network"
                      size="small"
                      variant={point.isEdited ? "outlined" : "default"}
                      icon={
                        <Brightness1Rounded
                          style={{
                            color: step(
                              oonAdditiveColors,
                              point.incrementalAdequacy || 0
                            ),
                          }}
                        />
                      }
                      onClick={handleHelpClick}
                    />
                    <ul className="fields">
                      {fields.map((fieldDef) => (
                        <ProviderAttributes
                          key={fieldDef.field}
                          attribute={fieldDef}
                          provider={provider}
                        />
                      ))}
                    </ul>
                    <Button
                      size="small"
                      onClick={() => dispatch(toggleToAdd(point.id))}
                      className="providerPopupActionButton"
                      variant="outlined"
                    >
                      {!toAdd.includes(point.id)
                        ? `Add to Network`
                        : `Remove from Staging`}
                    </Button>
                  </>
                )}
              </div>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ProviderPopup;
