import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { useState } from "react";
import {
  fieldValidator,
  isEmptyArray,
  isPresent,
  validate,
} from "../../utils/formValidate";
import { UnsavedChangesWarning } from "../UnsavedChangesWarning";
import { symmetricDiffArr } from "../../utils";

const byId = (m) => m.id;
const makeOnChangeHandler = (setter, errorSetter) => (e) => {
  const metricId = Number(e.target.value);
  if (errorSetter) errorSetter();
  setter((curr) => {
    let result = e.target.checked
      ? [...curr, metricId]
      : curr.filter((id) => id !== metricId);
    result.sort();
    return result;
  });
};

const schema = {
  accessMetrics: validate([
    isPresent("at least one metric must be selected", isEmptyArray),
  ]),
  accessMetricsLogicalLink: validate([isPresent("cannot be empty")]),
};

export function StandardScoreAttributesForm({
  readOnly,
  initValues,
  adequacyMetrics,
  onSubmit,
  isSubmitting,
  isSubmitted,
}) {
  const [accessMetrics, setAccessMetrics] = useState(
    adequacyMetrics.access
      .map(byId)
      .filter((id) => initValues?.metrics.includes(Number(id)))
  );
  const [accessMetricsError, setAccessMetricsError] = useState();

  const [countMetrics, setCountMetrics] = useState(
    adequacyMetrics.count
      .map(byId)
      .filter((id) => initValues?.metrics.includes(Number(id)))
  );
  const [accessMetricsLogicalLink, setAccessMetricsLogicalLink] = useState(
    initValues?.access_metrics_logical_link ?? "AND"
  );
  const [accessMetricsLogicalLinkError, setAccessMetricsLogicalLinkError] =
    useState();

  const validateField = fieldValidator(schema, {
    accessMetrics,
    countMetrics,
    accessMetricsLogicalLink,
  });

  const validateForm = () => {
    const accessMetricsError = validateField("accessMetrics").error;
    const accessMetricsLogicalLinkError = validateField(
      "accessMetricsLogicalLink"
    ).error;
    if (accessMetricsError) setAccessMetricsError(accessMetricsError);
    if (accessMetricsLogicalLinkError)
      setAccessMetricsLogicalLinkError(accessMetricsLogicalLinkError);
    if (accessMetricsError || accessMetricsLogicalLinkError) return false;
    return true;
  };

  const hasChanges =
    !isSubmitted &&
    hasUnsavedChanges(initValues, {
      accessMetrics,
      countMetrics,
      accessMetricsLogicalLink,
    });

  return (
    <Stack
      sx={{ textAlign: "left", marginTop: "8px" }}
      gap={3}
      maxWidth={600}
      minWidth={300}
    >
      <Stack gap={2}>
        <FormControl
          required
          error={!!accessMetricsError}
          component="fieldset"
          variant="standard"
        >
          <FormLabel component="legend">Access Metrics</FormLabel>
          <FormGroup>
            {adequacyMetrics.access.map(({ id, name }) => (
              <FormControlLabel
                key={id}
                control={
                  <Checkbox
                    size="small"
                    checked={accessMetrics.includes(id)}
                    value={id}
                    onChange={makeOnChangeHandler(
                      setAccessMetrics,
                      setAccessMetricsError
                    )}
                    disabled={readOnly}
                  />
                }
                label={name}
              />
            ))}
            {accessMetricsError && (
              <FormHelperText>{accessMetricsError}</FormHelperText>
            )}
          </FormGroup>
        </FormControl>
        {accessMetrics.length > 1 && (
          <FormControl
            size="small"
            error={!!accessMetricsLogicalLinkError}
            required
          >
            <InputLabel
              size="small"
              id="access-metrics-logical-link-label"
              htmlFor="access-metrics-logical-link"
            >
              Operator
            </InputLabel>
            <Select
              id="access-metrics-logical-link"
              size="small"
              labelId="access-metrics-logical-link-label"
              label="Operator"
              value={accessMetricsLogicalLink}
              onChange={(e) => {
                setAccessMetricsLogicalLink(e.target.value);
              }}
              onFocus={() => setAccessMetricsLogicalLinkError()}
              onBlur={() =>
                setAccessMetricsLogicalLinkError(
                  validateField("accessMetricsLogicalLink").error
                )
              }
              disabled={readOnly}
            >
              <MenuItem value={"AND"}>AND</MenuItem>
              <MenuItem value={"OR"}>OR</MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
      <Stack>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">Count Metrics</FormLabel>
          <FormGroup>
            {adequacyMetrics.count.map(({ id, name }) => (
              <FormControlLabel
                key={id}
                control={
                  <Checkbox
                    size="small"
                    checked={countMetrics.includes(id)}
                    value={id}
                    onChange={makeOnChangeHandler(setCountMetrics)}
                    disabled={readOnly}
                  />
                }
                label={name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Stack>

      {!readOnly && (
        <Stack direction="row" gap={2} sx={{ marginTop: "24px" }}>
          <Button
            variant="contained"
            disabled={isSubmitting}
            onClick={() => {
              if (!validateForm()) return;
              onSubmit({
                accessMetrics,
                countMetrics,
                accessMetricsLogicalLink,
              });
            }}
          >
            {isSubmitting ? "...saving" : initValues ? "save" : "next"}
          </Button>
        </Stack>
      )}
      <UnsavedChangesWarning when={hasChanges} />
    </Stack>
  );
}

function hasUnsavedChanges(initValues, currentValues) {
  const { accessMetrics, countMetrics, accessMetricsLogicalLink } =
    currentValues;
  if (!initValues) return accessMetrics.length > 0 || countMetrics.length > 0;
  return (
    symmetricDiffArr(initValues.metrics, [...accessMetrics, ...countMetrics])
      .length > 0 ||
    accessMetricsLogicalLink !== initValues.access_metrics_logical_link
  );
}
