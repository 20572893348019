import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { MessageDetails } from "./MessageDetails";

export function FileUploadUserActionDialog({
  open,
  uploadResult,
  onCancel,
  onProceed,
  canProceed,
}) {
  const {
    Fatals = [],
    Errors = [],
    Warnings = [],
    FatalRowCount = 0,
    ErrorRowCount = 0,
  } = uploadResult?.data ?? {};

  const errorsToShow = canProceed ? Errors : Fatals;
  const hasFatalsErrors = errorsToShow.length > 0;
  const hasFatals = Fatals.length > 0;
  const hasErrors = canProceed && Errors.length > 0;
  const hasWarnings = canProceed && Warnings.length > 0;
  const hasContent = hasFatals || hasErrors || hasWarnings;

  if (uploadResult.isUninitialized || !hasContent)
    return <Dialog open={open}></Dialog>;

  return (
    <Dialog open={open}>
      <DialogTitle>We found issues in your file </DialogTitle>
      <DialogContent>
        {hasFatalsErrors && (
          <MessageDetails name="errors" items={errorsToShow}>
            {Fatals.length > 0
              ? `${errorsToShow.length.toLocaleString(
                  "en-US"
                )} critical error(s) across ${FatalRowCount} row(s)`
              : `${ErrorRowCount.toLocaleString(
                  "en-US"
                )} row(s) not assessed due to ${errorsToShow.length.toLocaleString(
                  "en-US"
                )} data issue(s)`}
          </MessageDetails>
        )}

        {hasWarnings && (
          <MessageDetails name="warnings" items={Warnings}>
            {Warnings.length.toLocaleString("en-US")} data correction(s) made by
            J2 at upload
          </MessageDetails>
        )}

        {hasErrors && (
          <DialogContentText sx={{ marginTop: 2 }}>
            Would you like to proceed with your upload, using only valid rows?
            <br />
            <br />
            NOTE: Invalid rows will be dropped.
          </DialogContentText>
        )}

        {hasWarnings && Errors.length === 0 && (
          <DialogContentText sx={{ marginTop: 2 }}>
            Would you like to proceed with your upload?
          </DialogContentText>
        )}

        {hasFatals && (
          <DialogContentText sx={{ marginTop: 2 }}>
            Please correct errors and upload the file again.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{canProceed ? "Cancel" : "OK"}</Button>
        {canProceed && (
          <Button onClick={onProceed} autoFocus>
            Proceed
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
