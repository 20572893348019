import { createSlice } from "@reduxjs/toolkit";

export const selectedNetworkSlice = createSlice({
  name: "selectedNetwork",
  initialState: null,
  reducers: {
    setSelectedNetworkId(_state, { payload }) {
      return payload;
    },
  },
});

export const { setSelectedNetworkId } = selectedNetworkSlice.actions;

export const selectSelectedNetworkId = (state) =>
  state[selectedNetworkSlice.name];
