import { useSelector } from "react-redux";
import { selectSelectedNetwork } from "../features/networks";
import {
  useGetCustomStandardsQuery,
  useGetStandardMemberUniversesQuery,
} from "../api/j2/j2Api";

export function useGetContextForReportsDefinitions(options = {}) {
  const { skip } = options;
  const network = useSelector(selectSelectedNetwork);
  const skipStandardsFetching = skip || !network;

  const customStandardsRequest = useGetCustomStandardsQuery(undefined, {
    skip: skipStandardsFetching,
    selectFromResult: (args) => ({
      ...args,
      data: args.data?.find(
        ({ j2_taxonomy }) => j2_taxonomy === network?.taxonomy_id
      ),
    }),
  });
  const standard = { ...customStandardsRequest.data };

  const skipMUsFetching = !standard?.id;

  const memberUniverseRequest = useGetStandardMemberUniversesQuery(
    standard?.id,
    {
      skip: skipMUsFetching,
      selectFromResult: (args) => ({
        ...args,
        data: args.data?.find(({ id }) => id === standard?.member_universe),
      }),
    }
  );

  if (skipStandardsFetching) return { data: undefined, isFetching: false };

  const isFetching =
    customStandardsRequest.isUninitialized ||
    customStandardsRequest.isFetching ||
    (!!standard.id && memberUniverseRequest.isFetching); // if customStandard is fetched wait for memberUniverse

  standard.member_universe = memberUniverseRequest.data;

  return {
    data: isFetching ? undefined : { network, standard },
    isFetching,
  };
}
