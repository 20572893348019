import { Stack, Typography } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { networksGridColumns } from "../../components/workspace-disruption/networks/networksGridColumns";
import { useGetDisruptionNetworksQuery } from "../../api/j2/j2Api";

const networkTypes = {source: "Incumbent", target: "Replacement"};

export function NetworksIndexRoute() {
  const {
      data: disruptionNetworks = [],
      isFetching,

  } = useGetDisruptionNetworksQuery();

  return (
      <Stack mt={1} width={"100%"} maxWidth={"1000px"}>
        <Typography variant="h4">Networks</Typography>
        <Stack mt={2}>
          <DataGridPremium
            rows={disruptionNetworks.map((n) => {
              return { ...n, type: networkTypes[n.type] }
            })}
            autoHeight
            hideFooter
            disableRowSelectionOnClick
            columns={networksGridColumns}
            loading={isFetching}
          />
        </Stack>
      </Stack>
  );
}