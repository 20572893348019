import { Chip } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { convertToPercent } from "../../utils";

const IMPACT_IDX = 800;
const NON_IMPACT_IDX = 100;

export function AdequacyChip({
  isInn,
  item: { countyName, adequacy, specialtyName, isOneSpecialty, impact },
}) {
  const chipColor = isInn ? red : green;
  const colorIndex = impact === 0 ? NON_IMPACT_IDX : IMPACT_IDX;
  const textColor = colorIndex === IMPACT_IDX ? "white" : "#222";

  const specialtyLabel = isOneSpecialty ? "" : specialtyName + "-";
  const adequacyPerc = convertToPercent(adequacy);
  const chipLabel = `${specialtyLabel}${countyName}: ${adequacyPerc}`;

  return (
    <Chip
      size="small"
      label={chipLabel}
      sx={{
        lineHeight: "1",
        color: textColor,
        background: chipColor[colorIndex],
      }}
    />
  );
}
