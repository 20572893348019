import { useEffect, useImperativeHandle } from "react";

export function useSelectionModelSync(gridApiRef, syncRef, selectionModel) {
  // expose current selectionIds to be used inside parent component without it rerendering
  useImperativeHandle(syncRef, () => ({ selectionModel }));

  // sync table state with redux
  useEffect(() => {
    gridApiRef.current.setRowSelectionModel(selectionModel);
  }, [gridApiRef, selectionModel]);
}
