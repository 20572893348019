import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  FormHelperText,
  Box,
  Input,
} from "@mui/material";
import { useCallback, useState } from "react";
import { fieldValidator, isPresent, validate } from "../../utils/formValidate";
import { UnsavedChangesWarning } from "../UnsavedChangesWarning";
import { CustomDesignations } from "./designations/CustomDesignations";
import customFileTemplate from "../../assets/Custom Member File Template.xlsx";
import { usePostValidateMemberFileMutation } from "../../api/j2/j2Api";

const schema = {
  name: validate([
    isPresent(
      "cannot be empty",
      ({ field, values }) => values[field].trim().length > 0
    ),
  ]),
  memberUniverse: validate([isPresent("cannot be empty")]),
  designationType: validate([isPresent("cannot be empty")]),
  file: validate([isPresent("cannot be empty")]),
};

export function StandardGeneralForm({
  readOnly,
  initValues,
  memberUniversesOptions,
  designationTypes,
  onSubmit,
  isSubmitting,
  onFileValidationError,
}) {
  const [name, setName] = useState(initValues?.name ?? "");
  const [nameError, setNameError] = useState();
  const [memberUniverse, setMemberUniverse] = useState(
    initValues?.member_universe
      ? memberUniversesOptions?.find(
          (option) => initValues?.member_universe === option.id
        )?.id ?? ""
      : ""
  );
  const [memberUniverseError, setMemberUniverseError] = useState();

  const [designationType, setDesignationType] = useState(
    initValues?.designation_type
      ? designationTypes?.find(
          (option) => initValues?.designation_type === option.id
        )?.id ?? ""
      : ""
  );
  const [designationTypeError, setDesignationTypeError] = useState();

  const [file, setFile] = useState();
  const [fileError, setFileError] = useState();

  const hasCustomDesignationType = designationTypes?.some(
    (d) => d.source_standard_id === initValues?.id
  );

  const hasCustomMemberUniverse = memberUniversesOptions?.some(
    (m) => m.source_standard === initValues?.id
  );

  const selectedDesignationType = designationTypes?.find(
    ({ id }) => id === designationType
  );
  const customDesignationTypeSelected =
    selectedDesignationType &&
    selectedDesignationType.source_standard_id === initValues?.id;

  const addCustomDesignationSelected = designationType === -1;

  const canShowCustomDesignations =
    addCustomDesignationSelected || customDesignationTypeSelected;

  const designationTypeId = addCustomDesignationSelected
    ? null
    : designationType;

  const selectedMemberUniverse = memberUniversesOptions?.find(
    ({ id }) => id === memberUniverse
  );
  const customMemberUniverseSelected =
    selectedMemberUniverse &&
    selectedMemberUniverse.source_standard === initValues?.id;

  const addCustomMemberUniverseSelected = memberUniverse === -1;

  const canShowCustomMemberFile =
    addCustomMemberUniverseSelected || customMemberUniverseSelected;

  const memberUniverseId = addCustomMemberUniverseSelected
    ? null
    : memberUniverse;

  const validateField = fieldValidator(schema, {
    name,
    memberUniverse,
    designationType,
    file: addCustomMemberUniverseSelected ? Boolean(file) : true,
  });

  const validateForm = useCallback(() => {
    const nameError = validateField("name").error;
    const memberUniverseError = validateField("memberUniverse").error;
    const designationTypeError = validateField("designationType").error;
    const fileError = validateField("file").error;

    if (nameError) setNameError(nameError);
    if (memberUniverseError) setMemberUniverseError(memberUniverseError);
    if (designationTypeError) setDesignationTypeError(designationTypeError);
    if (fileError) setFileError(fileError);
    if (nameError || memberUniverseError || designationTypeError || fileError)
      return false;
    return true;
  }, [validateField]);

  const onSave = useCallback(
    (designationFormData) => {
      if (!validateForm()) return;

      const payload = {
        name,
        member_universe: memberUniverseId,
        designation_type: designationTypeId,
      };

      if (initValues?.id) payload.id = initValues?.id;

      onSubmit({
        ...payload,
        designationFormData,
        memberFormData: file ? { file, memberUniverseId } : null,
      });
    },
    [
      designationTypeId,
      file,
      initValues?.id,
      memberUniverseId,
      name,
      onSubmit,
      validateForm,
    ]
  );

  const [validateFileOnServer, fileValidationResult] =
    usePostValidateMemberFileMutation();

  const validateFile = useCallback(
    async (e) => {
      setFileError();
      const file = e.target.files[0];
      try {
        await validateFileOnServer({ file }).unwrap();
        onFileValidationError({});
        setFile(file);
      } catch (error) {
        setFile();
        onFileValidationError({ source: "file", data: error.data });
      }
    },
    [onFileValidationError, validateFileOnServer]
  );

  return (
    <Stack gap={3}>
      <Stack sx={{ textAlign: "left" }} gap={3} maxWidth={600}>
        <FormControl>
          <TextField
            id="standard-name"
            variant="outlined"
            size="small"
            margin="dense"
            label="Display name"
            required
            value={name}
            disabled={readOnly}
            onChange={(e) => setName(e.target.value)}
            onFocus={() => setNameError()}
            onBlur={() => {
              setNameError(validateField("name").error);
            }}
            error={!!nameError}
            helperText={nameError}
          />
        </FormControl>
        <Divider />
        <FormControl
          size="small"
          margin="dense"
          disabled={readOnly}
          required
          error={!!memberUniverseError}
        >
          <InputLabel size="small" id="member-universe-select-label">
            Member set
          </InputLabel>
          <Select
            labelId="member-universes-select-label"
            id="member-universes-select"
            value={memberUniverse}
            label="Member Set"
            size="small"
            error={!!memberUniverseError || !!fileError}
            onChange={(e) => setMemberUniverse(e.target.value)}
            onFocus={() => {
              setMemberUniverseError();
              setFileError();
            }}
            onBlur={() => {
              setMemberUniverseError(validateField("memberUniverse").error);
            }}
            disabled={readOnly}
          >
            {memberUniversesOptions?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
            {!hasCustomMemberUniverse && (
              <MenuItem value={-1}>Add Custom Member File</MenuItem>
            )}
          </Select>
          {memberUniverseError && (
            <FormHelperText>{memberUniverseError}</FormHelperText>
          )}
          {canShowCustomMemberFile && (
            <Stack>
              <FormControl margin="dense">
                <Button
                  size="small"
                  variant="contained"
                  href={customFileTemplate}
                  download
                  sx={{ width: "fit-content", textTransform: "none" }}
                >
                  Download template for custom member file
                </Button>
              </FormControl>
              <FormControl margin="dense">
                <Box sx={{ alignSelf: "flex-start", marginTop: "8px" }}>
                  <label htmlFor="select-file">
                    <Input
                      key={fileValidationResult.requestId ?? -1}
                      sx={{ display: "none" }}
                      id="select-file"
                      type="file"
                      onChange={validateFile}
                      disabled={readOnly}
                      inputProps={{ accept: ".csv" }}
                    />
                    <Stack direction={"row"} alignItems={"center"}>
                      {!readOnly && (
                        <Button variant="outlined" component="span">
                          Select CSV file
                        </Button>
                      )}
                      {selectedMemberUniverse && (
                        <FormHelperText>
                          Recently uploaded file:{" "}
                          {selectedMemberUniverse.member_file_name}
                        </FormHelperText>
                      )}
                    </Stack>
                  </label>
                  {!readOnly && (
                    <FormHelperText sx={{ marginLeft: "14px" }}>
                      {file
                        ? `Selected file: ${file.name}`
                        : "no file selected"}
                    </FormHelperText>
                  )}
                </Box>
                {fileError && (
                  <FormHelperText error>{fileError}</FormHelperText>
                )}
              </FormControl>
            </Stack>
          )}
        </FormControl>
        <Stack>
          <FormControl size="small" error={!!designationTypeError} required>
            <InputLabel
              size="small"
              id="designation-type-label"
              htmlFor="designation-type"
            >
              Designation type
            </InputLabel>
            <Select
              id="designation-type"
              size="small"
              labelId="designation-type-label"
              label="Designation type"
              value={designationType}
              onChange={(e) => {
                setDesignationType(e.target.value);
              }}
              onFocus={() => setDesignationTypeError()}
              onBlur={() =>
                setDesignationTypeError(validateField("designationType").error)
              }
              disabled={readOnly}
            >
              {designationTypes?.map(({ id, name }) => {
                return (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                );
              })}
              {!hasCustomDesignationType && (
                <MenuItem value={-1}>
                  Add Custom County Type Designation
                </MenuItem>
              )}
            </Select>
            {designationTypeError && (
              <FormHelperText>{designationTypeError}</FormHelperText>
            )}
          </FormControl>
        </Stack>

        {!readOnly && !canShowCustomDesignations && (
          <Stack direction="row" gap={2} sx={{ marginTop: "24px" }}>
            <Button
              variant="contained"
              disabled={isSubmitting}
              onClick={() => onSave()}
            >
              {isSubmitting ? "...saving" : initValues ? "save" : "next"}
            </Button>
          </Stack>
        )}
        <UnsavedChangesWarning
          when={
            initValues &&
            hasUnsavedChanges(initValues, {
              name,
              memberUniverse,
              designationType,
            })
          }
        />
      </Stack>
      <Stack sx={{ paddingBottom: "64px" }}>
        {canShowCustomDesignations && (
          <CustomDesignations
            readOnly={readOnly}
            standard={initValues}
            designationTypeId={designationType === -1 ? null : designationType}
            onSubmit={onSave}
          />
        )}
      </Stack>
    </Stack>
  );
}

function hasUnsavedChanges(
  initValues,
  { name, memberUniverse, designationType, file }
) {
  if (!initValues) return name || memberUniverse || designationType;
  return (
    initValues.name !== name ||
    initValues.member_universe !== memberUniverse ||
    (memberUniverse > 0 && file) ||
    initValues.designation_type !== designationType
  );
}
