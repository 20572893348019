import { useOutletContext } from "react-router-dom";
import { useGetJ2SpecialtiesQuery } from "../../api/networksApi";
import { SpecialtiesGrid } from "../../components/SelfServeCustomStandards/SpecialtiesGrid";
import { useGetCustomSpecialtiesQuery } from "../../api/j2/j2Api";

export function StandardSpecialtiesRoute() {
  const { standard } = useOutletContext();
  const j2Specialties = useGetJ2SpecialtiesQuery();
  const specialties = useGetCustomSpecialtiesQuery(standard.id);

  return (
    <SpecialtiesGrid
      readOnly
      rows={specialties.data ?? []}
      j2Specialties={j2Specialties.data}
      loading={specialties.isFetching || j2Specialties.isFetching}
    />
  );
}
