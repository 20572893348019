import { HighlightOffOutlined, SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Collapse,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHighlitedCounty } from "../../features/map";
import { selectServiceArea } from "../../features/networks";

export function HighlightCounty() {
  const { counties = {} } = useSelector(selectServiceArea);
  const [county, setCounty] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const inputRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isExpanded]);

  const onChange = (_e, value) => {
    setCounty(value);
    dispatch(setHighlitedCounty(value?.[0] ?? null));
  };

  return (
    <Stack direction="row" alignItems="center">
      <IconButton
        size="small"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{ margin: 0 }}
      >
        {isExpanded ? <HighlightOffOutlined /> : <SearchOutlined />}
      </IconButton>
      <Collapse in={isExpanded} timeout={200} orientation="horizontal">
        <Autocomplete
          options={Object.entries(counties)}
          getOptionLabel={([, label = ""]) => label}
          isOptionEqualToValue={(option, value) => option[0] === value[0]}
          value={county}
          onChange={onChange}
          size="small"
          sx={{
            background: "tomato",
            "& .MuiAutocomplete-root": { padding: "0" },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={inputRef}
              variant="outlined"
              size="small"
              sx={{
                margin: 0,
                width: "180px",
                background: "white",
                "& .MuiOutlinedInput-root": {
                  fontSize: "14px",
                },
                "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                  paddingY: "2px",
                },
              }}
            />
          )}
        />
      </Collapse>
    </Stack>
  );
}
