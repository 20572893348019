import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "../features/currentClient";

const selfServeClientsBlacklist = { Oscar: true, covet: true };

const selfServeUsersWhitelist = {
  covet: ["@j2health.com"],
  Oscar: ["@j2health.com"],
};

export function useSelfServeAllowed() {
  const { user } = useAuth0();
  const client = useSelector(selectCurrentClient);

  const isClientBlaclisted = selfServeClientsBlacklist[client];

  if (!isClientBlaclisted) return true;

  const whiteListedUsers = selfServeUsersWhitelist[client];

  if (!whiteListedUsers) return false;

  const isUserAllowed = whiteListedUsers.some((s) => user?.email?.includes(s));

  return isUserAllowed;
}
