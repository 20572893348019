import {
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-premium";

const includesSubstring = (v) => (fv) =>
  v.toLowerCase().includes(fv.toLowerCase());

const isAnyOfFilterFn = (filterVal) => (v) =>
  filterVal.some(includesSubstring(v));

const adequacyNumericFiltersMap = {
  "=": (filterVal) => (v) => v === Number(filterVal),
  "!=": (filterVal) => (v) => v !== Number(filterVal),
  ">": (filterVal) => (v) => v > Number(filterVal),
  ">=": (filterVal) => (v) => v >= Number(filterVal),
  "<": (filterVal) => (v) => v < Number(filterVal),
  "<=": (filterVal) => (v) => v <= Number(filterVal),
};

const templateNumberOperator = getGridNumericOperators().find(
  ({ value }) => value === "="
);

const templateIsEmptyOperator = getGridStringOperators().find(
  ({ value }) => value === "isEmpty"
);

const templateIsAnyOperator = getGridStringOperators().find(
  ({ value }) => value === "isAnyOf"
);

function wrapWithEmptyFilterCheck(fn) {
  return function getApplyAdequacyFilterFn(filterItem) {
    if (
      !filterItem.field ||
      !filterItem.value ||
      !filterItem.operator ||
      !filterItem.value?.length
    )
      return null;
    return fn(filterItem);
  };
}

function adequacyNumericFilterFn(filterItem) {
  const { operator, value } = filterItem;
  const pred = adequacyNumericFiltersMap[operator](value);
  return (params) => params.value.some((s) => pred(s.adequacy));
}

export const adequacyOperators = [
  ...["=", "!=", ">", ">=", "<", "<="].map((op) => ({
    ...templateNumberOperator,
    value: op,
    label: op,
    getApplyFilterFn: wrapWithEmptyFilterCheck(adequacyNumericFilterFn),
  })),
  {
    ...templateIsEmptyOperator,
    value: "isEmpty",
    label: "is empty",
    getApplyFilterFn: () => (p) => p.value.length === 0,
  },
  {
    ...templateIsEmptyOperator,
    value: "isNotEmpty",
    label: "is not empty",
    getApplyFilterFn: () => (p) => p.value.length > 0,
  },
  {
    ...templateIsAnyOperator,
    value: "isCounty",
    label: "impacts county",
    getApplyFilterFn: wrapWithEmptyFilterCheck(
      (filterItem) => (params) =>
        params.value.some((s) =>
          isAnyOfFilterFn(filterItem.value)(s.countyName)
        )
    ),
  },
  {
    ...templateIsAnyOperator,
    value: "isFocusCounty",
    label: "impacts focus county",
    getApplyFilterFn: wrapWithEmptyFilterCheck(
      (filterItem) => (params) =>
        params.value.some((s) =>
          isAnyOfFilterFn(filterItem.value)(s.countyName)
        )
    ),
  },
  {
    ...templateIsAnyOperator,
    value: "isSpecialty",
    label: "impacts specialty",
    getApplyFilterFn: wrapWithEmptyFilterCheck(
      (filterItem) => (params) =>
        params.value.some((s) =>
          isAnyOfFilterFn(filterItem.value)(s.specialtyName)
        )
    ),
  },
  {
    ...templateIsEmptyOperator,
    value: "isClosesGaps",
    label: "closes gap(s)",
    getApplyFilterFn:
      () =>
      ({ value }) =>
        value.some((s) => s.impact === 1),
  },
  {
    ...templateIsEmptyOperator,
    value: "isOpensGaps",
    label: "opens gap(s)",
    getApplyFilterFn:
      () =>
      ({ value }) =>
        value.some((s) => s.impact === -1),
  },
];
