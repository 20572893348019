import React, { useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  selectFilteredProvidersByStatus,
  selectProviderInStatus,
  selectStatusFilterId,
} from "../../features/providers";
import { Table } from "../Table";
import { setPointToMark } from "../../features/map";
import {
  selectTableConfig_P_ALL_INN_TABLE_ROW,
  selectTableConfig_P_ALL_OON_TABLE_ROW,
  selectTableConfig_P_MAP_TABLE_ROW_extended,
} from "../../features/tableColumns";
import { useUpdateLabel } from "../labelEdit";
import {
  addToAdd,
  addToRemove,
  removeToAdd,
  removeToRemove,
  selectStagedFilteredProviders,
} from "../../features/stagedChanges";
import { forwardRef } from "react";
import {
  gridFilteredSortedRowIdsSelector,
  gridFilterModelSelector,
  gridVisibleColumnFieldsSelector,
  gridColumnDefinitionsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useSelectionModelSync } from "../../hooks/useSelectionModelSync";
import { handleProviderRecordSelectionChange } from "./handleProviderRecordSelectionChange";
import { ProviderGridToolbar } from "./ProviderGridToolbar";
import { useOigSanctionedColumnEnabled } from "../../hooks/useOigSanctionedColumnEnabled";
import { useNetworkContextAmplitudeEvent } from "../../hooks/useNetworkContextAmplitudeEvent";

const modeViewModelMap = {
  INN: selectTableConfig_P_ALL_INN_TABLE_ROW,
  OON: selectTableConfig_P_ALL_OON_TABLE_ROW,
  ALL: selectTableConfig_P_MAP_TABLE_ROW_extended,
};

export function ProviderGrid({ loading, onFilterChange }) {
  const dispatch = useDispatch();
  const { innProviders, oonProviders } = useSelector(
    selectFilteredProvidersByStatus
  );
  const statuses = useSelector(selectProviderInStatus);
  const mode = useSelector(selectStatusFilterId);
  let { columns, sortModel } = useSelector(modeViewModelMap[mode]);
  columns = useOigSanctionedColumnEnabled(columns);
  const rows = innProviders.concat(oonProviders);
  const [table1PageSize, setTable1PageSize] = useState(100);
  const apiRef = useGridApiRef();
  const syncSelectionRef = useRef({});
  const syncModelsRef = useRef({});
  const onRowEditChange = useUpdateLabel(apiRef);
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  return (
    <div
      onMouseOver={(e) => {
        const doNotMark = false;
        dispatch(
          setPointToMark(
            doNotMark
              ? null
              : e.target.closest(".MuiDataGrid-row")?.getAttribute("data-id") ||
                  null
          )
        );
      }}
    >
      <Table
        apiRef={apiRef}
        customSortModel={sortModel}
        disableColumnPinning
        tableHeight="100%"
        rows={rows}
        columns={columns}
        loading={loading}
        checkboxSelection
        pageSize={table1PageSize}
        disableRowSelectionOnClick
        onPageSizeChange={setTable1PageSize}
        processRowUpdate={onRowEditChange}
        onProcessRowUpdateError={(e) => console.log(e)}
        onFilterModelChange={() => {
          const filterModel = gridFilterModelSelector(apiRef);
          syncModelsRef.current?.setFilterModel(filterModel);
          if (!onFilterChange) return;
          setTimeout(() => {
            const filteredIds = gridFilteredSortedRowIdsSelector(apiRef);
            onFilterChange(filteredIds);
          }, 0);
          // Only send events for completed filter inputs
          const filteredColumns = filterModel.items?.filter(({value}) => value && value !== "").map(({field}) => field);
          if (filteredColumns.length > 0) {
            trackNetworkContextAmplitudeEvent("Filter providers", { filteredColumns });
          }
        }}
        onRowSelectionModelChange={(newSelectionModel) => {
          handleProviderRecordSelectionChange(
            syncSelectionRef.current.selectionModel,
            newSelectionModel,
            statuses,
            dispatch,
            { addToAdd, removeToAdd, addToRemove, removeToRemove }
          );
        }}
        onColumnVisibilityModelChange={(_params) => {
          let columnsModel = gridVisibleColumnFieldsSelector(apiRef);
          const allColumns = gridColumnDefinitionsSelector(apiRef);
          if (columnsModel.length === allColumns.length) columnsModel = null;

          syncModelsRef.current?.setColumnsModel(columnsModel);
        }}
        slots={{ toolbar: ProviderGridToolbar }}
        slotProps={{ toolbar: { syncModelsRef } }}
      />
      <SelectionModelSync ref={syncSelectionRef} apiRef={apiRef} />
    </div>
  );
}

const SelectionModelSync = forwardRef(function SelectionModelSync(
  { apiRef },
  ref
) {
  const selectionModel = useSelector(selectStagedFilteredProviders);
  useSelectionModelSync(apiRef, ref, selectionModel);
  // nothing to render
  return null;
});
