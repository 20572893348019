import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Brightness1Rounded } from "@mui/icons-material";

export function MapLegend({ title }) {
  return (
    <List>
      {title && (
        <ListItem>
          <ListItemText>Map Points Legend</ListItemText>
        </ListItem>
      )}
      <ListItem>
        <ListItemIcon>
          <Brightness1Rounded style={{ color: "#53b1d6" }} />
        </ListItemIcon>
        <ListItemText>In Network</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Brightness1Rounded style={{ color: "black" }} />
        </ListItemIcon>
        <ListItemText>Out of Network</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Brightness1Rounded style={{ color: "gray" }} />
        </ListItemIcon>
        <ListItemText>Mixed</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Brightness1Rounded style={{ color: "orange" }} />
        </ListItemIcon>
        <ListItemText>Additive</ListItemText>
      </ListItem>
    </List>
  );
}
