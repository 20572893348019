export const accessDetailsByCounty = {
  id: "access-details-by-county",
  fetchFromId: "adequacy-by-county",
  name: "Access Details by County",
  columns: [
    { field: "county_id", headerName: "County" },

    { field: "county_name", headerName: "County Name" },

    { field: "specialty_name", headerName: "Specialty", width: 250 },

    {
      field: "overall_score",
      headerName: "Access Score (%)",
      type: "number",
      width: 150,
    },

    {
      field: "total_count",
      headerName: "Member Count",
      type: "number",
      width: 150,
    },

    {
      field: "with_access_count",
      headerName: "Members with Access",
      type: "number",
      width: 200,
    },

    {
      field: "with_access_count_perc",
      headerName: "Members with Access (%)",
      type: "number",
      width: 200,
    },

    {
      field: "with_sufficient_access_count_perc",
      headerName: "Members with Sufficient Access (%)",
      type: "number",
      width: 200,
    },

    {
      field: "wo_access_count",
      headerName: "Members without Access",
      type: "number",
      width: 200,
    },

    {
      field: "wo_access_count_perc",
      headerName: "Members without Access (%)",
      type: "number",
      width: 200,
    },

    {
      field: "with_distance_access_count",
      headerName: "Members with Distance Access",
      type: "number",
      width: 200,
    },

    {
      field: "with_time_access_count",
      headerName: "Members with Time Access",
      type: "number",
      width: 200,
    },

    {
      field: "max_distance",
      headerName: "Maximum Distance",
      type: "number",
    },
    {
      field: "avg_distance",
      headerName: "Average Distance",
      type: "number",
    },

    {
      field: "max_time",
      headerName: "Maximum Time",
      type: "number",
    },

    {
      field: "avg_time",
      headerName: "Average Time",
      type: "number",
    },
  ],
};
