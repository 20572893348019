import { roundToDecimal } from "../../utils";
import { SCORES, THRESHOLDS } from "@j2nm/score-metric-keys";

export function getAdequacyPerSpecialtyAndCounty({ row }) {
  if (!row) return [];
  const adequacyScores = row.adequacyScores ?? {};

  const result = [];
  for (const specialtyId of row.specialty_ids) {
    const scores = Object.entries(adequacyScores[specialtyId] ?? {});

    for (const [countyId, score] of scores) {
      const adequacy = roundToDecimal(score);

      if (adequacy > 0) {
        const countyScores = row.countyScores[specialtyId]?.[countyId];
        const countyAdequacy = countyScores?.[SCORES.ACCESS] ?? 0;
        const threshold = countyScores?.[THRESHOLDS.ACCESS] ?? 0;
        const impact = adequacyImpact(
          row.is_inn,
          100 * countyAdequacy,
          adequacy,
          100 * threshold
        );

        result.push({
          specialtyId,
          specialtyName:
            row.specialtyLookup[specialtyId] ?? String(specialtyId),
          countyId,
          countyName: row.countyLookup[countyId] ?? String(countyId),
          adequacy,
          isOneSpecialty: row.specialty_ids.length === 1,
          impact,
        });
      }
    }
  }
  // sort impactful adequacies first
  return result.sort((a, b) => {
    if (a.impact && !b.impact) return -1;
    if (b.impact && !a.impact) return 1;
    return b.adequacy - a.adequacy;
  });
}

export function adequacyImpact(
  adequacyType,
  currentAdequacy,
  deltaAdequacy,
  threshold
) {
  if (threshold === 0) return 0; // no impact on adequacy if no threshold

  if (
    adequacyType === 1 &&
    currentAdequacy >= threshold &&
    currentAdequacy - deltaAdequacy < threshold
  )
    return -1; // deltaAdequacy opens gap
  if (
    adequacyType === 0 &&
    currentAdequacy < threshold &&
    currentAdequacy + deltaAdequacy >= threshold
  )
    return 1; // delta adequacy closes gap

  return 0; // no impact on adequacy
}
