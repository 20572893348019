import { useEffect, useRef } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { HeightOutlined } from "@mui/icons-material";

export function MetricEditCell(props) {
  const { id, value, field, api } = props;
  const canFill = true;
  const ref = useRef();
  const numberRange = field === "access_threshold" ? { min: 0, max: 100 } : {};

  const handleValueChange = (event) => {
    const newValue = event.target.value; // The new value entered by the user
    api.setEditCellValue({ id: id, field, value: newValue });
    api.setState((state) => ({
      ...state,
      fillButtonClicked: false,
      changedField: field,
    }));
  };

  const handleOnClick = () => {
    api.stopCellEditMode({ id, field });
    api.setState((state) => ({
      ...state,
      fillButtonClicked: true,
      changedField: field,
    }));
  };

  const handleAltEnter = (e) => {
    if (e.keyCode === 13 && e.altKey) {
      e.preventDefault();
      handleOnClick();
    }
  };

  useEffect(() => {
    ref.current.focus();
  }, []);

  return (
    <div
      className="MuiInputBase-root MuiDataGrid-editInputCell MuiInputBase-fullWidth"
      style={{ height: "100%", width: "100%" }}
    >
      <input
        style={{
          width: canFill ? "80%" : "100%",
          border: "0px",
          height: "100%",
          padding: "0 8px",
          boxSizing: "border-box",
        }}
        ref={ref}
        className="MuiInputBase-input"
        type="number"
        {...numberRange}
        value={value || ""}
        onChange={handleValueChange}
        onKeyDown={canFill ? handleAltEnter : undefined}
      />
      {canFill && (
        <Tooltip title="set this value to all rows">
          <IconButton size="small" onClick={handleOnClick}>
            <HeightOutlined />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

export function renderMetricEditCell(params) {
  return <MetricEditCell {...params} />;
}
