import logoUrl from "../assets/j2logo.png";

export function Splash({ children = "Working ..." }) {
  return (
    <div className="splash">
      <img src={logoUrl} alt="splash screen logo" width="250" height="250" />
      {children}
    </div>
  );
}
