import { SCORES, ADEQUACIES } from "@j2nm/score-metric-keys";
const ao = ADEQUACIES.OVERALL;
const ac = ADEQUACIES.MINIMUM_COUNT;
const so = SCORES.ACCESS;

const adequacyFillColor = [
  "case",
  // If there exists `a` variable (exists on counties and also zips), and if its equal to 1, show green
  ["all", ["boolean", ["has", ao]], ["==", ["get", ao], 1]],
  "#81c784",
  // If there exists `a` variable (exists on counties and also zips), and if its equal to 0, show red
  ["all", ["boolean", ["has", ao]], ["==", ["get", ao], 0]],
  "#ef9a9a",
  // Otherwise, show grey
  "grey",
];

const tileScoreValue = [
  "concat",
  [
    "number-format",
    ["*", 100, ["get", so]],
    { "min-fraction-digits": 2, "max-fraction-digits": 2 },
  ],
  "%",
];

export const getCountyFillLayer = (id) => ({
  id,
  type: "fill",
  paint: {
    "fill-color": [
      "case",
      ["has", "fillColor"],
      ["get", "fillColor"],
      adequacyFillColor,
    ],
    "fill-opacity": ["interpolate", ["linear"], ["zoom"], 8, 0.4, 8.01, 0],
  },
});

export const getAreaFillLayer = (id) => ({
  id,
  type: "fill",
  paint: {
    "fill-color": [
      "case",
      ["has", "fillColor"],
      ["get", "fillColor"],
      adequacyFillColor,
    ],
    "fill-opacity": [
      "case",
      ["has", "fillColor"],
      // ["interpolate", ["linear"], ["zoom"], 8, 0.85, 12, 0.5],
      0.8,
      0.4,
    ],
  },
});

export const getFillLayer = (id, theme) => ({
  id,
  type: "fill",
  paint: {
    "fill-color": [
      "case",
      ["has", theme.property],
      theme.mapboxGradientString,
      "gray",
    ],
    "fill-opacity": ["interpolate", ["linear"], ["zoom"], 8, 0.85, 12, 0.5],
  },
});

export const getLineLayer = (id) => ({
  id,
  type: "line",
  paint: {
    "line-color": "#000",
    "line-width": id === "county-line-zip" ? 3 : 1,
  },
});

export const getHighlitedLineLayer = {
  type: "line",
  paint: {
    "line-color": "#ffd600",
    "line-width": ["step", ["zoom"], 1, 8, 3],
  },
  filter: ["==", ["get", "highlight"], ["get", "GEOID"]],
};

export const oonAdditiveColors = [
  "black",
  0.2,
  "#ffedcc",
  0.3,
  "#ffdb99",
  0.5,
  "#ffc966",
  0.7,
  "#ffa600",
];

export const clusterLayer = {
  id: "provider-clusters",
  type: "circle",
  paint: {
    "circle-color": [
      "case",
      ["==", ["get", "isSelected"], true],
      "#fff7a1",
      [
        "all",
        ["boolean", [">", ["get", "hasINN"], 0]],
        ["boolean", [">", ["get", "hasOON"], 0]],
      ],
      "gray",
      ["boolean", [">", ["get", "hasINN"], 0]],
      "#11b4da",
      ["step", ["get", "maxIncrementalAdequacy"], ...oonAdditiveColors],
      // "black",
    ],
    "circle-radius": [
      "case",
      ["==", ["get", "isMarked"], true],
      ["step", ["get", "point_count"], 25, 100, 35],
      ["step", ["get", "point_count"], 15, 100, 25],
    ],

    "circle-stroke-width": 2,
    "circle-stroke-color": [
      "case",
      ["==", ["get", "isSelected"], true],
      [
        "case",
        [
          "all",
          ["boolean", [">", ["get", "hasINN"], 0]],
          ["boolean", [">", ["get", "hasOON"], 0]],
        ],
        "gray",
        ["boolean", [">", ["get", "hasINN"], 0]],
        "#11b4da",
        "black",
      ],
      "white",
    ],
  },
};

export const clusterCountLayer = {
  id: "provider-cluster-count",
  type: "symbol",
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
  paint: {
    "text-color": [
      "case",
      [">", ["get", "hasINN"], 0],
      "black",
      [
        "case",
        ["==", ["get", "isSelected"], true],
        "black",
        [
          "case",
          [
            "==",
            "black",
            ["step", ["get", "maxIncrementalAdequacy"], ...oonAdditiveColors],
          ],
          "white",
          "black",
        ],
      ],
    ],
  },
};

export const unclusteredPointLayer = {
  id: "provider-unclustered-point",
  type: "circle",
  paint: {
    "circle-color": [
      "case",
      ["==", ["get", "isSelected"], true],
      "#fff7a1",
      ["==", ["get", "is_inn"], 1],
      "#11b4da",
      ["step", ["get", "incrementalAdequacy"], ...oonAdditiveColors],
    ],
    "circle-radius": ["case", ["==", ["get", "isMarked"], true], 18, 9],
    "circle-stroke-width": 2,
    "circle-stroke-color": [
      "case",
      ["==", ["get", "isSelected"], true],
      ["case", ["==", ["get", "isINN"], 1], "#11b4da", "black"],
      "white",
    ],
  },
};

export const zipSymbolsLayer = {
  id: "zipSymbols",
  type: "symbol",
  layout: {
    "text-field": [
      "format",
      // show zip code
      ["get", "GEOID20"],
      { "text-color": "black", "font-scale": 0.7 },
      // show score only for inadequate; add new line to the zip name
      ["case", ["==", ["get", ao], 0], ["concat", "\n", tileScoreValue], ""],
      {
        "text-color": "hsl(0, 72%, 42%)",
        "font-scale": 0.8,
        "text-font": [
          "literal",
          ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        ],
      },
    ],
  },
  filter: ["has", ao],
};

export const countySymbolsLayer = {
  id: "countySymbols",
  type: "symbol",
  layout: {
    "text-field": [
      "format",
      // show county name
      ["get", "NAME"],
      { "text-color": "black", "font-scale": 0.7 },
      // show score only for inadequate; add new line to the county name
      ["case", ["==", ["get", ao], 0], ["concat", "\n", tileScoreValue], ""],
      {
        "text-color": "hsl(0, 72%, 42%)",
        "font-scale": 0.8,
        "text-font": [
          "literal",
          ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        ],
      },
      // show min count not met info
      [
        "case",
        ["all", ["boolean", ["has", ac]], ["==", ["get", ac], 0]],
        "!min count",
        "",
      ],
      {
        "text-color": "hsl(0, 72%, 42%)",
        "font-scale": 0.6,
        "text-font": [
          "literal",
          ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        ],
      },
    ],
  },
  filter: ["has", "hasMetric"],
};
