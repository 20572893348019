import { makeAdequacyColumn } from "../AdequacyGridColumn/adequacyColumn";

const adequacyFilterSetup = makeAdequacyColumn({
  field: "adequacy_per_county",
});

function getSpecialtyNames(providerRow) {
  return (
    providerRow?.specialty_ids?.flatMap((id) => {
      const name = providerRow.specialtyLookup?.[id];
      return name ? [name.toLowerCase()] : [];
    }) ?? []
  );
}

const parseFilter = ({ field, value, operator }) => {
  if (value == null || value === "" || Number.isNaN(value)) return () => true;

  if (field === "adequacy_per_county") {
    const _operator = operator ?? "=";
    const { valueGetter, filterOperators } = adequacyFilterSetup;
    const applyFilter = filterOperators
      .find((op) => op.value === _operator)
      ?.getApplyFilterFn({ field, value, operator: _operator });
    return applyFilter
      ? (row) => applyFilter({ value: valueGetter({ row }) })
      : () => true;
  }

  if (field === "is_inn") {
    if (Array.isArray(value) && value.length !== 0) {
      return ({ is_inn }) => {
        if (is_inn && value.includes(1)) return true;
        if (!is_inn && value.includes(0)) return true;
        return false;
      };
    } else return () => true;
  }

  if (field === "specialty_id") {
    return (item) =>
      item?.specialty_id === value || item?.specialty_ids.includes(value);
  }

  if (field === "specialties") {
    return operator === undefined || operator === "contains"
      ? (item) =>
          getSpecialtyNames(item).some((name) =>
            name.includes(value.toLowerCase())
          )
      : (item) =>
          getSpecialtyNames(item).every(
            (name) => name.indexOf(value.toLowerCase()) === -1
          );
  }

  if (value instanceof Array) {
    return (item) => value.includes(item[field]);
  }

  if (operator) {
    if (operator === "==") {
      return (item) => parseFloat(item[field]) === value;
    }
    if (operator === "<=") {
      return (item) => parseFloat(item[field]) <= value;
    }
    if (operator === ">=") {
      return (item) => parseFloat(item[field]) >= value;
    }
    if (operator === "!=") {
      return (item) => parseFloat(item[field]) !== value;
    }
    if (operator === "contains") {
      return (item) => item[field]?.toLowerCase().includes(value.toLowerCase());
    }
    if (operator === "not-contains") {
      return (item) =>
        item[field]?.toLowerCase().indexOf(value.toLowerCase()) === -1;
    }
  }

  return (item) =>
    typeof item[field] === "string" &&
    typeof value === "string" &&
    item[field].toLowerCase().includes(value.toLowerCase());
};

export function applyCustomFilters(providers, filters) {
  if (!providers) return [];
  if (!Array.isArray(filters) || !filters.length > 0) return providers;

  const applyFilters = (filterFns) => (row) => {
    for (const filterFn of filterFns) if (!filterFn(row)) return false;
    return true;
  };

  return providers.filter(applyFilters(filters.map(parseFilter)));
}
