import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useDeleteCustomStandardsMutation } from "../../../api/j2/j2Api";
import { actionsConfig } from "../../../hooks/useHandleStandardActions";

export function CustomStandardDeleteButton({ standard }) {
  const [open, setOpen] = useState(false);
  const [deleteStandard] = useDeleteCustomStandardsMutation({
    fixedCacheKey: actionsConfig.deleteCustomStandards.cacheKey,
  });

  return (
    <>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        Delete
      </Button>
      <Dialog open={open}>
        <DialogTitle>Delete {standard.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm to delete your draft standard. This will erase all saved
            details and remove the draft from the Standards page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              setOpen(false);
              deleteStandard({ standard });
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
