import React, { useEffect, useMemo } from "react";
import { usePersistInput } from "../persistInput/usePersistInput";
import { PersistedItems } from "../persistInput/PersistedItems";
import { NotPersistedtem } from "../persistInput/NotPersistedtem";
import { countiesStorage } from "./storage";
import { Stack } from "@mui/material";

const byFips = (c) => c.fips;

const stateEntries = (entries, stateName) => {
  return entries.filter(([, values]) => values[stateName]);
};

function isPersisted(store, newStateName, newCounties) {
  const newFips = newCounties.map(byFips);
  const found = store.find(([, values]) => {
    let counties = values[newStateName];
    return counties
      ? counties.length === newFips.length &&
          newFips.every((fips) => counties.map(byFips).includes(fips))
      : false;
  });
  return !!found;
}

const labels = ["county", "counties"];

export function SaveCountiesToolbar({
  onApply,
  selectedState,
  selectedCounties,
}) {
  const [state, api] = usePersistInput({
    storage: countiesStorage,
    onApply,
    labels,
  });

  const valueToPersist = useMemo(
    () =>
      selectedCounties.length > 0
        ? { [selectedState]: selectedCounties }
        : null,
    [selectedCounties, selectedState]
  );

  const isSelectionsPersisted =
    valueToPersist &&
    isPersisted(state.persistedItems, selectedState, selectedCounties);

  const statePersistedItems = stateEntries(state.persistedItems, selectedState);

  useEffect(() => {
    api.setNotPersistedValue(isSelectionsPersisted ? null : valueToPersist);
  }, [api, isSelectionsPersisted, valueToPersist]);

  if (!state.hasContent) return null;

  return (
    <Stack direction={"row"} gap={2} my={"4px"}>
      {statePersistedItems.length > 0 && (
        <PersistedItems state={state} api={api} items={statePersistedItems} />
      )}
      {state.notPersistedValue && (
        <NotPersistedtem state={state} api={api} items={statePersistedItems} />
      )}
    </Stack>
  );
}
