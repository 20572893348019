import { useState } from "react";

import { useSelector } from "react-redux";
import "./DataPanel.css";
import { MapLegend } from "./MapLegend";
import {
  selectCustomFiltersCount,
  selectSpecialtyFilterVal,
} from "../../features/providers";
import { Badge, Button, Dialog, IconButton } from "@mui/material";
import LayerControl from "../MapControl/LayerControl";
import { HelpOutline, LocalLibraryOutlined, Map } from "@mui/icons-material";
import { GridFilterAltIcon } from "@mui/x-data-grid-premium";
import { Standards } from "./Standards";
import { CustomFilters } from "../CustomFilters/CustomFilters";
import { HighlightCounty } from "./HighlightCounty";

export function DataPanel({ slots }) {
  const [dialogType, setDialogType] = useState(false);

  const customFiltersCount = useSelector(selectCustomFiltersCount);

  const specialtyId = useSelector(selectSpecialtyFilterVal);

  return (
    <div className="data-panel">
      <div className="points-legend">
        <Button
          size="small"
          onClick={() => setDialogType("map-legend")}
          startIcon={<HelpOutline />}
        >
          legend
        </Button>
      </div>

      <DataPanelButton label="providers">{slots[0]}</DataPanelButton>

      <DataPanelButton label="filters">
        <Badge
          badgeContent={customFiltersCount}
          color="primary"
          overlap="circular"
        >
          <IconButton size="small" onClick={() => setDialogType("filters")}>
            <GridFilterAltIcon />
          </IconButton>
        </Badge>
      </DataPanelButton>

      <DataPanelButton label="layers">
        <IconButton size="small" onClick={() => setDialogType("layers")}>
          <Map />
        </IconButton>
      </DataPanelButton>

      <DataPanelButton label="standards">
        <IconButton size="small" onClick={() => setDialogType("standards")}>
          <LocalLibraryOutlined />
        </IconButton>
      </DataPanelButton>

      <DataPanelButton label="county">
        <HighlightCounty />
      </DataPanelButton>

      <DataPanelButton label="export">{slots[1]}</DataPanelButton>

      <Dialog
        open={!!dialogType && dialogType !== "standards"}
        fullWidth
        onClose={() => setDialogType(false)}
        container={() => document.querySelector(".mapOverview")}
      >
        {dialogType === "filters" && <CustomFilters />}
        {dialogType === "layers" && <LayerControl />}
        {dialogType === "map-legend" && <MapLegend title="Map legend" />}
      </Dialog>

      {dialogType === "standards" && (
        <Standards
          show
          onClose={() => setDialogType(false)}
          specialtyId={specialtyId}
        />
      )}
    </div>
  );
}

function DataPanelButton({ label, children }) {
  return (
    <div className="panel-button">
      <span className="label">{label}</span>
      {children}
    </div>
  );
}
