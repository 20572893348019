import { useGridApiContext } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import { setFilter } from "../../features/scorecard";
import { SpecialtyFilterPanel } from "./SpecialtyFilterPanel";
import { useSyncWithAction } from "./utils";

export function CustomGridFilterPanel(props) {
  const apiRef = useGridApiContext();
  const { j2_filterColId } = apiRef.current.state;
  const actionConstructor = useCallback(
    (networkId) =>
      setFilter({
        filters: apiRef.current.state.filter.filterModel,
        networkId,
      }),
    [apiRef]
  );
  // on unmount sync filters with redux
  useSyncWithAction(actionConstructor);

  if (j2_filterColId === "id") return <SpecialtyFilterPanel {...props} />;
  return null;
}
