import { Chip, Stack } from "@mui/material";
import {
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-premium";

export function makeBedsColumn(rule) {
  const { field, label } = rule;
  return {
    field,
    headerName: label,
    width: 200,
    type: "string",
    renderCell: renderBedsCell,
    sortComparator: bedsComparator,
    filterOperators: bedsOperators(),
    valueGetter: getBedsValue,
    valueFormatter: formatBedsValueForExport,
  };
}

// value getter from object of specialty id to count, to array of tuples [specialty name, count]

function getBedsValue({ row }) {
  const { beds, specialtyLookup } = row;
  if (typeof beds !== "object") return beds;
  return Object.entries(beds).map(([id, value]) => [
    specialtyLookup[id] ?? id,
    value,
  ]);
}

// value renderer

function renderBedsCell({ value }) {
  if (!Array.isArray(value)) return value;
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      height="100%"
      sx={{ overflowX: "scroll" }}
    >
      {value.map(([specialty, count]) => {
        return (
          <Chip
            key={specialty}
            size="small"
            label={`${specialty}: ${count}`}
            sx={{ lineHeight: "1" }}
          />
        );
      })}
    </Stack>
  );
}

// formatter for csv export

function formatBedsValueForExport({ value = "" }) {
  if (!Array.isArray(value)) return value;
  return value.map(([specialty, count]) => `${specialty}: ${count}`).join(", ");
}

// sorter

const byBedsValue = ([, value]) => value;

function bedsComparator(a1, a2) {
  let v1 = a1 ?? [[1, -1]];
  v1 = Array.isArray(v1) ? v1 : [[1, v1]];
  let v2 = a2 ?? [[1, -1]];
  v2 = Array.isArray(v2) ? v2 : [[2, v2]];
  return Math.max(...v1.map(byBedsValue)) - Math.max(...v2.map(byBedsValue));
}

// filters

const numericFilterPredicates = {
  "=": (fVal) => (arr) => arr.some((v) => v[1] === fVal),
  "!=": (fVal) => (arr) => arr.some((v) => v[1] !== fVal),
  ">": (fVal) => (arr) => arr.some((v) => v[1] > fVal),
  ">=": (fVal) => (arr) => arr.some((v) => v[1] >= fVal),
  "<": (fVal) => (arr) => arr.some((v) => v[1] < fVal),
  "<=": (fVal) => (arr) => arr.some((v) => v[1] <= fVal),
};

function isEmptyValidFilterItem(filterItem) {
  return (
    !filterItem.field ||
    !filterItem.value ||
    !filterItem.operator ||
    !filterItem.value?.length
  );
}

function bedsOperators() {
  return [
    ...["=", "!=", ">", ">=", "<", "<="].map((op) => ({
      ...getGridNumericOperators().find((op) => op.value === "="), // template operator
      value: op,
      label: op,
      getApplyFilterFn: (filterItem) => {
        if (isEmptyValidFilterItem(filterItem)) return null;
        const { operator, value } = filterItem;

        const pred = numericFilterPredicates[operator](Number(value));

        return (p) => {
          if (!Array.isArray(p.value))
            return getGridNumericOperators()
              .find((_op) => _op.value === op)
              .getApplyFilterFn(filterItem)?.(p);
          return pred(p.value);
        };
      },
    })),

    {
      ...getGridStringOperators().find((op) => op.value === "isEmpty"), // template operator
      value: "isEmpty",
      label: "is empty",
      getApplyFilterFn: () => (p) => !p.value,
    },

    {
      ...getGridStringOperators().find((op) => op.value === "isAnyOf"), // template operator
      value: "isSpecialty",
      label: "has specialty",

      getApplyFilterFn: (filterItem) => {
        if (isEmptyValidFilterItem(filterItem)) return null;

        return (p) => {
          if (Array.isArray(p.value))
            return (
              p.value.filter(([v]) =>
                filterItem.value.some((fv) =>
                  v.toLowerCase().includes(fv.toLowerCase())
                )
              ).length > 0
            );
          if (p.value === undefined) return false; // no beds attributes
          // beds are not array so pass it
          return true;
        };
      },
    },
  ];
}
