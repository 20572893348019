export const networksGridColumns = [
  { field: "name", headerName: "Network Name", width: 300 },
  { field: "created_by_email", headerName: "Created By", width: 250 },
  {
    field: "created_at",
    type: "date",
    headerName: "Created Date",
    width: 200,
    valueGetter: ({ row }) => new Date(row.created_at),
    valueFormatter: ({ value }) =>
      new Intl.DateTimeFormat("en-US").format(value),
  },
  { field: "type", headerName: "Network Type", width: 200 },
];
