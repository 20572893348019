import { withTenantInBody } from "./utils";

//
// POST /file/process
//

export const requiredColumns = [
  "Network_ID",
  "Provider_Name",
  "NPI",
  "Address",
  "Address_2",
  "City",
  "State",
  "Zip_Code",
  "Regulatory_Specialty",
  "In_Network",
];

export const postFileProcessApi = (build) =>
  build.mutation({
    queryFn: withTenantInBody((args) => {
      const {
        user,
        networkId,
        networkName,
        file,
        lob,
        counties,
        Columns,
        ExtraColumns,
        client_name,
        updateNetworkId,
        fileType,
      } = args;
      let body = {
        client_name,
        file_name: file?.name ?? "Empty_Network.csv",
        fips: counties.map((c) => c.fips),
        lob,
        network: networkId === "" ? null : networkId,
        user_id: user.email,
        user_name: user.nickname,
        extra_cols: ExtraColumns ?? [],
        cols: Columns ?? requiredColumns,
      };

      if (networkName) body.network_name = networkName;
      if (fileType) body.is_partial_file = fileType === "partial";
      if (updateNetworkId) body.update_network_id = String(updateNetworkId);

      return {
        method: "POST",
        url: "/v2/portal/file/process",
        body,
      };
    }),
  });

//
// POST /file/update
//

export const postFileUpdateApi = (build) =>
  build.mutation({
    query: (body) => ({
      method: "POST",
      url: "/v2/portal/file/update",
      body,
    }),
  });

//
// POST /file/merge
//

export const postFileMergeApi = (build) =>
  build.mutation({
    query: (body) => ({
      method: "POST",
      url: "/v2/portal/file/merge",
      body,
    }),
  });
