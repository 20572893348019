import {
  gridExpandedSortedRowIdsSelector,
  gridVisibleColumnDefinitionsSelector,
} from "@mui/x-data-grid-premium";
import { useRef } from "react";
import { ADEQUACIES } from "@j2nm/score-metric-keys";

export function useGridScrollToGap(gridApiRef) {
  const cellPos = useRef({ x: 0, y: 0 });

  const scrollToGap = (params) => {
    const { id: rowId, row } = params;
    const visibleCols = gridVisibleColumnDefinitionsSelector(gridApiRef);

    const rowIndex = gridExpandedSortedRowIdsSelector(gridApiRef).findIndex(
      (id) => id === rowId
    );

    const gapColumnsIndexes = Object.entries(row).flatMap(([key, v]) => {
      if (v?.[ADEQUACIES.OVERALL] === 0)
        return [
          Math.max(
            visibleCols.findIndex((c) => c.field === key),
            0
          ),
        ];
      return [];
    });

    const { x, y } = cellPos.current;
    const colIndex = gapColumnsIndexes[rowIndex === y ? x : 0];
    gridApiRef.current.scrollToIndexes({ colIndex, rowIndex });

    const nextX = x < gapColumnsIndexes.length - 1 ? x + 1 : 0;
    cellPos.current = { x: nextX, y: rowIndex };
  };

  return scrollToGap;
}
