import { ArrowForwardIosSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemText,
} from "@mui/material";
import { red, yellow } from "@mui/material/colors";
import { useState } from "react";

export function MessageDetails({ name, items, children }) {
  const [expanded, setExpanded] = useState(false);
  const bgColor = name === "errors" ? red[50] : yellow[50];
  const color = name === "errors" ? red[900] : "#333";

  const handleChange = (panel) => (_event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      expanded={expanded === name}
      onChange={handleChange(name)}
      sx={{
        "&:before": {
          display: "none",
        },
        width: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
        sx={{
          marginTop: 1,
          flexDirection: "row-reverse",
          alignItems: "center",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
          backgroundColor: bgColor,
          "& .MuiAccordionSummary-content": {
            marginLeft: "8px",
          },
        }}
      >
        <ListItemText
          primary={children}
          sx={{ display: "flex", alignItems: "center" }}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "8px 16px",
          borderTop: "1px solid rgba(0, 0, 0, .125)",
          color,
          backgroundColor: bgColor,
        }}
      >
        <div>
          <ol style={{ margin: 0, fontSize: "12px", paddingLeft: "16px" }}>
            {items.map((item) => (
              <li key={item}>
                <ListItemText
                  primary={item}
                  sx={{ "> span": { fontSize: 12 } }}
                />
              </li>
            ))}
          </ol>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
