import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { Splash } from "../Splash";

export function WithLDClientReady({ children }) {
  const ldClient = useLDClient();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!ldClient) return;
    ldClient.waitUntilReady().then(() => setIsInitialized(true));
  }, [ldClient]);

  if (!isInitialized)
    return (
      <Splash>
        <p>Loading features ...</p>
      </Splash>
    );

  return children;
}
