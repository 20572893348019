export const identity = (a) => a;

export function transpose(original, init = {}) {
  return Object.keys(original).reduce(
    // eslint-disable-next-line no-sequences
    (agg, key) => ((agg[original[key]] = key), agg),
    init
  );
}

export function convertToPercent(number, fraction = false) {
  return typeof number === "number"
    ? `${(fraction ? Math.floor(number * 1000) / 10 : number).toFixed(1)}%`
    : number;
}

export function arrToMap(arr, idKey, valueFn = identity) {
  return (
    (arr ?? []).reduce((acc, item) => {
      let id = idKey
        ? typeof idKey === "string"
          ? item[idKey]
          : idKey(item)
        : item;
      acc[id] = valueFn(item);
      return acc;
    }, {}) ?? {}
  );
}

export function union(setA, setB) {
  const _union = new Set(setA);
  for (const elem of setB) {
    _union.add(elem);
  }
  return _union;
}

export function unionArr(arrA, arrB) {
  return [...union(new Set(arrA), new Set(arrB))];
}

export function difference(setA, setB) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

export function symmetricDifference(setA, setB) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    if (_difference.has(elem)) {
      _difference.delete(elem);
    } else {
      _difference.add(elem);
    }
  }
  return _difference;
}

export function differenceArr(arrA, arrB) {
  return [...difference(new Set(arrA), new Set(arrB))];
}

export function symmetricDiffArr(arrA, arrB) {
  return [...symmetricDifference(new Set(arrA), new Set(arrB))];
}

export function filterByKey(obj, pred) {
  return Object.fromEntries(Object.entries(obj).filter(pred));
}

export function filterByIdLookup(arr, lookup, predicate = () => true) {
  return arr.filter((item) => lookup.includes(item.id) && predicate(item));
}

export function findById(arr, val) {
  return arr.find(({ id }) => id === val);
}

export function roundToDecimal(score) {
  // round to 1 decimal (10 means this)
  return Math.round(10 * 100 * (score ?? 0)) / 10;
}

export function isNotVal(v) {
  return Array.isArray(v)
    ? v.length === 0
    : v === undefined || v === null || v.length === 0 || isNaN(v);
}

// const capitalise = (str) => str[0].toUpperCase() + str.substring(1);

export const dataToGeoJSON = (data) => ({
  type: "FeatureCollection",
  features: data,
});

export function withPrefixSuffix(string, prefix, suffix) {
  if (!string) return "";
  return `${prefix ?? ""}${string}${suffix ?? ""}`;
}
