import { HomeOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";

export function HomeElement({ icon, children }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      {icon || (
        <HomeOutlined sx={{ mr: "4px", fontSize: "20px", mt: "-2px" }} />
      )}
      <span>{children}</span>
    </Stack>
  );
}
