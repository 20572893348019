import React from "react";
import { Source, Layer } from "react-map-gl";
import { dataToGeoJSON } from "../../utils";
import {
  countySymbolsLayer,
  getAreaFillLayer,
  getLineLayer,
  zipSymbolsLayer,
  getHighlitedLineLayer,
} from "./layerStyles";

function Polygons({ shapes, fillLayerId, lineLayerId, layerProperties }) {
  if (fillLayerId === "county-fill") {
    return (
      <Source type="geojson" data={dataToGeoJSON(shapes)}>
        <Layer {...getAreaFillLayer(fillLayerId)} {...layerProperties} />
        <Layer {...getLineLayer(lineLayerId)} {...layerProperties} />
        <Layer {...countySymbolsLayer} minzoom={6.5} maxzoom={8} />
        <Layer {...getLineLayer("county-line-zip")} minzoom={8} />
        <Layer
          {...{
            id: "counties-over-zips",
            type: "fill",
            paint: { "fill-color": "transparent" },
          }}
          minzoom={8}
        />
        <Layer {...getHighlitedLineLayer} />
      </Source>
    );
  }
  return (
    <Source type="geojson" data={dataToGeoJSON(shapes)}>
      <Layer {...getAreaFillLayer(fillLayerId)} {...layerProperties} />
      <Layer {...getLineLayer(lineLayerId)} {...layerProperties} />
      <Layer {...zipSymbolsLayer} minzoom={8} />
    </Source>
  );
}

export default Polygons;
