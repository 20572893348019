import { CircularProgress } from "@mui/material";
import {
  useGetTaxonomyGeographiesQuery,
  useGetUsStatesQuery,
} from "../../../api/networksApi";
import { CustomDesignationsConfigForm } from "./CustomDesignationsConfigForm";
import { useGetCustomStandardDesignationTypeQuery } from "../../../api/j2/j2Api";

export function CustomDesignations({
  standard,
  designationTypeId,
  readOnly,
  onSubmit,
}) {
  const usStates = useGetUsStatesQuery();
  const statesWithCounties = useGetTaxonomyGeographiesQuery("*");

  const designationType = useGetCustomStandardDesignationTypeQuery(
    { standard_id: standard?.id, designation_type_id: designationTypeId },
    { skip: !designationTypeId }
  );

  if (
    usStates.isFetching ||
    statesWithCounties.isFetching ||
    designationType.isFetching
  )
    return <CircularProgress size={14} />;

  return (
    <CustomDesignationsConfigForm
      readOnly={readOnly}
      statesOptions={usStates.data?.map((s) => [s.statefp, s.name])}
      statesWithCounties={statesWithCounties.data}
      initValues={designationType.data}
      onSubmit={(data) => {
        onSubmit({ ...data, designation_type_id: designationTypeId });
      }}
    />
  );
}
