import { useSelector } from "react-redux";
import { selectSelectedNetworkId } from "../features/selectedNetwork";
import { Stack, Typography } from "@mui/material";

export function WhenNetworks({ children, message }) {
  const networkId = useSelector(selectSelectedNetworkId);
  if (networkId) return children;
  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      paddingX={3}
    >
      <Typography component="h1" variant="overline" align="center">
        {message || (
          <>
            There are no networks. <br />
            Please create at least one to use this feature.
          </>
        )}
      </Typography>
    </Stack>
  );
}
