import { getHeaders, getRows } from "./exportData";

const EOL = "\r\n";

export function serializeToCsv(
  rows,
  columns,
  serializers,
  { delimiter, eol = EOL }
) {
  let headers = getHeaders(columns, serializers);
  let headerString = headers.map((h) => h.headerName).join(delimiter);

  let blobParts = [`${headerString}${eol}`];

  const _rows = getRows(rows, headers, serializers, {
    quotedMatch: delimiter,
  });

  for (let rowArr of _rows) {
    blobParts.push(`${rowArr.join(delimiter)}${eol}`);
  }
  return blobParts;
}

const getExcelJs = async () => {
  const excelJsModule = await import("exceljs");
  return excelJsModule.default ?? excelJsModule;
};

const MAX_EXCEL_CELL_COUNT = 3_000_000;

export async function serializeToExcel(rows, columns, serializers) {
  const excelJS = await getExcelJs();

  const workbook = new excelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  worksheet.columns = columns.map(serializeExcelColumn);

  let headers = getHeaders(columns, serializers);
  worksheet.addRow(headers.map((h) => h.headerName));

  let serializedRows = getRows(rows, headers, serializers);
  const excelCellCount = serializedRows.length * headers.length;

  if (excelCellCount > MAX_EXCEL_CELL_COUNT) {
    let error = new Error(
      `This table's cell count ${excelCellCount} exceeds the maximum limit for Excel export. Please use "Export to CSV" instead.`
    );
    error.name = "ExcelExportTooLarge";
    throw error;
  }
  worksheet.addRows(serializedRows);

  const content = await workbook.xlsx.writeBuffer();
  return content;
}

function serializeExcelColumn(column, columnsStyles) {
  const { field, width } = column;

  return {
    key: field,
    headerText: column.headerName ?? column.field,
    // Excel width must stay between 0 and 255 (https://support.microsoft.com/en-us/office/change-the-column-width-and-row-height-72f5e3cc-994d-43e8-ae58-9774a0905f46)
    // From the example of column width behavior (https://docs.microsoft.com/en-US/office/troubleshoot/excel/determine-column-widths#example-of-column-width-behavior)
    // a value of 10 corresponds to 75px. This is an approximation, because column width depends on the the font-size
    width: Math.min(255, width ? width / 7.5 : 8.43),
    style: { ...columnsStyles?.[field] },
  };
}
