import { ScorecardTable } from "../components/ScoresTable/ScorecardTable";
import { WhenNetworks } from "../components/WhenNetworks";
import { useIsCountyScoresFetching } from "../hooks/useIsCountyScoresFetching";

export function ScorecardRoute() {
  const isFetching = useIsCountyScoresFetching();
  return (
    <WhenNetworks>
      <ScorecardTable loading={isFetching} />
    </WhenNetworks>
  );
}
