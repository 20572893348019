import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { selectNonOptimisticApiActivity } from "../features/apiActivity";
import { selectCurrentClient } from "../features/currentClient";
import { setPathname } from "../features/navigation";
import { AppDrawer } from "./AppDrawer";
import { AppTopBar } from "./AppTopBar/AppTopBar";
import { RequireMasterData } from "./RequireMasterData";
import { useAllowDisruptionReports } from "../hooks/useAllowDisruptionReports";
import { AdequacyAppDrawer } from "./workspace-adequacy/AppDrawer";

export function AdequacyAppRoot() {
  const currentClient = useSelector(selectCurrentClient);
  const { updating } = useSelector(selectNonOptimisticApiActivity);
  const isUpdating = updating.length > 0;
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const canAccessDisruptionReports = useAllowDisruptionReports();
  const AppDrawerComponent = canAccessDisruptionReports
    ? AdequacyAppDrawer
    : AppDrawer;

  useEffect(() => {
    dispatch(setPathname(pathname));
  }, [pathname, dispatch]);

  return currentClient ? (
    <RequireMasterData>
      <div className="App">
        <AppTopBar />
        <AppDrawerComponent />
        <main>
          <Outlet />
        </main>
        <Backdrop className="loadingBackdrop" open={isUpdating}>
          <CircularProgress color="primary" size={60} thickness={4} />
        </Backdrop>
      </div>
    </RequireMasterData>
  ) : (
    <Navigate to="select-client" replace state={{ from: pathname }} />
  );
}
