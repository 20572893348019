import { createApi } from "@reduxjs/toolkit/query/react";
import { fetcher } from "./fetcher";
import {
  getNetworksApi,
  getCountyShapesApi,
  getZipShapesApi,
  postNetworkChangesApi,
  getCountyScoresApi,
  getZipScoresApi,
  getProviderLocationTagsApi,
  getTenantsApi,
  getStandardsApi,
  getZipcodeMetricsApi,
  getZipcodeMetricValuesApi,
  postNetworkResetChangesApi,
  getDownloadFilingApi,
  getReportDataApi,
  getTaxonomiesApi,
  getTaxonomyGeographiesApi,
  getDownloadUserGuideApi,
  getZipGapsApi,
  getProviderUniverseApi,
  getProviderScoresApi,
  getProviderStatusesApi,
  getMemberServedApi,
  getUsStatesApi,
  getJ2SpecialtiesApi,
} from "./handlers";

export const networksApi = createApi({
  reducerPath: "networksApi",
  keepUnusedDataFor: 300,
  baseQuery: fetcher,
  tagTypes: ["Network", "Stage"],
  endpoints: (build) => ({
    getTenants: getTenantsApi(build),
    getNetworks: getNetworksApi(build),

    getCountyShapes: getCountyShapesApi(build),
    getZipShapes: getZipShapesApi(build),

    getProviderUniverse: getProviderUniverseApi(build),
    getProviderScores: getProviderScoresApi(build),
    getProviderStatuses: getProviderStatusesApi(build),
    getProviderLocationTags: getProviderLocationTagsApi(build),

    getStandards: getStandardsApi(build),

    putProviderLocationTags: build.mutation({
      queryFn(data, { getState }, _extraOptions, baseQuery) {
        const { label, providerLocationIds } = data;
        const networkId = getState().selectedNetwork;
        return baseQuery({
          method: "PUT",
          url: "/provider-location-tags",
          body: { networkId, tag: label, providerLocationIds },
        });
      },
      async onQueryStarted(data, context) {
        const networkId = context.getState().selectedNetwork;
        const caches = [
          {
            key: "getProviderLocationTags",
            args: networkId,
          },
        ];
        const patchResults = caches.map((cache) =>
          context.dispatch(
            networksApi.util.updateQueryData(cache.key, cache.args, (draft) => {
              for (const id of data.providerLocationIds) {
                draft[id] = data.label;
              }
            })
          )
        );

        try {
          await context.queryFulfilled;
        } catch {
          patchResults.forEach((p) => p.undo());
        }
      },
    }),

    getCountyScores: getCountyScoresApi(build),
    getZipScores: getZipScoresApi(build),
    getZipGaps: getZipGapsApi(build),

    postNetworkChanges: postNetworkChangesApi(build),
    postNetworkResetChanges: postNetworkResetChangesApi(build),

    getZipcodeMetrics: getZipcodeMetricsApi(build),
    getZipcodeMetricValues: getZipcodeMetricValuesApi(build),

    getDownloadFiling: getDownloadFilingApi(build),

    getReportData: getReportDataApi(build),
    postTelehealthCredits: build.mutation({
      queryFn(args, { getState }, _extraOptions, baseQuery) {
        const networkId = getState().selectedNetwork;
        return baseQuery({
          method: "POST",
          url: "/telehealth",
          body: { networkId, ...args },
        });
      },
      invalidatesTags: (_result, error, arg) => {
        return error
          ? []
          : [
              { type: "Scores", id: "BASELINE-ALL" },
              { type: "Scores", id: "COUNTY-ALL" },
            ];
      },
    }),
    deleteTelehealthCredits: build.mutation({
      queryFn(args, { getState }, _extraOptions, baseQuery) {
        const networkId = getState().selectedNetwork;
        return baseQuery({
          method: "DELETE",
          url: "/telehealth",
          body: { networkId, ...args },
        });
      },
      invalidatesTags: (_result, error, arg) => {
        return error
          ? []
          : [
              { type: "Scores", id: "BASELINE-ALL" },
              { type: "Scores", id: "COUNTY-ALL" },
            ];
      },
    }),

    getTaxonomies: getTaxonomiesApi(build),
    getTaxonomyGeographies: getTaxonomyGeographiesApi(build),
    getUsStates: getUsStatesApi(build),
    getJ2Specialties: getJ2SpecialtiesApi(build),

    getDownloadUserGuide: getDownloadUserGuideApi(build),

    getMemberServed: getMemberServedApi(build),
  }),
});

export const {
  usePrefetch,

  useGetTenantsQuery,
  useGetNetworksQuery,

  useGetCountyShapesQuery,
  useGetZipShapesQuery,

  useGetProviderUniverseQuery,
  useGetProviderScoresQuery,
  useGetProviderStatusesQuery,

  useGetProviderLocationTagsQuery,
  usePutProviderLocationTagsMutation,

  useGetCountyScoresQuery,
  useGetZipScoresQuery,
  useGetZipGapsQuery,

  useGetStandardsQuery,

  usePostNetworkChangesMutation,
  usePostNetworkResetChangesMutation,

  useGetZipcodeMetricsQuery,
  useGetZipcodeMetricValuesQuery,

  useGetDownloadFilingMutation,
  useGetReportDataQuery,

  usePostTelehealthCreditsMutation,
  useDeleteTelehealthCreditsMutation,

  useGetTaxonomiesQuery,
  useGetTaxonomyGeographiesQuery,
  useGetUsStatesQuery,
  useGetJ2SpecialtiesQuery,

  useGetDownloadUserGuideMutation,

  useGetMemberServedQuery,
} = networksApi;

export const {
  getTenants,
  getNetworks,
  getSpecialties,
  getCountyShapes,
  getZipShapes,

  getProviderUniverse,
  getProviderScores,
  getProviderStatuses,

  getProviderLocationTags,
  putProviderLocationTags,

  getCountyScores,
  getZipScores,
  getZipGaps,

  postNetworkChanges,

  getZipcodeMetrics,
  getZipcodeMetricValues,
  getReportData,

  postTelehealthCredits,
  deleteTelehealthCredits,

  getTaxonomies,
  getTaxonomyGeographies,
  getUsStates,
  getJ2Specialties,

  getDownloadUserGuide,

  getMemberServed,
} = networksApi.endpoints;
