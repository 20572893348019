import React, { forwardRef, useImperativeHandle } from "react";
import { isEmptyFilter, isNewFilter, prettyFilterModel } from "./utils";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { usePersistInput } from "../persistInput/usePersistInput";
import { PersistedItems } from "../persistInput/PersistedItems";
import { NotPersistedtem } from "../persistInput/NotPersistedtem";
import { GridFilterAltIcon } from "@mui/x-data-grid-premium";
import { filtersStorage } from "./storage";

const labels = ["filter", "filters"];

function renderFilterItem(value) {
  return <pre>{prettyFilterModel(value)}</pre>;
}

export const PersistedFiltersToolbar = forwardRef(
  function PersistedFiltersToolbar(_props, ref) {
    const apiRef = useGridApiContext();

    const setMuiGridFilterModel = apiRef.current?.setFilterModel;

    const [state, api] = usePersistInput({
      storage: filtersStorage,
      onApply: setMuiGridFilterModel,
      renderItem: renderFilterItem,
      labels,
    });

    useImperativeHandle(ref, () => {
      return {
        ...ref.current,
        setFilterModel: (newFilterModel) => {
          const unsavedFilterModel =
            !isEmptyFilter(newFilterModel) &&
            isNewFilter(state.persistedItems, newFilterModel)
              ? newFilterModel
              : null;
          api.setNotPersistedValue(unsavedFilterModel);
        },
      };
    });

    if (!state.hasContent) return null;

    return (
      <>
        {state.persistedItems.length > 0 && (
          <PersistedItems
            state={state}
            api={api}
            icon={<GridFilterAltIcon />}
          />
        )}
        {state.notPersistedValue && <NotPersistedtem state={state} api={api} />}
      </>
    );
  }
);
