import { useEffect, useMemo, useState } from "react";

function defaultRenderItem(value) {
  return <pre>{JSON.stringify(value, null, 2)}</pre>;
}

export function usePersistInput({
  storage,
  onApply,
  renderItem = defaultRenderItem,
  labels = ["Item", "Items"],
}) {
  const [notPersistedValue, setNotPersistedValue] = useState(null);
  const [persistedItems, setPersistedItems] = useState(null);
  const hasContent = !!(persistedItems || notPersistedValue);

  const api = useMemo(() => {
    return {
      setNotPersistedValue,
      setPersistedItems,
      applyPersistedItem: onApply,
      storage,
      renderItem,
      labels,
    };
  }, [onApply, storage, renderItem, labels]);

  const state = useMemo(() => {
    return {
      persistedItems: Object.entries(persistedItems ?? {}),
      notPersistedValue,
      hasContent,
    };
  }, [persistedItems, notPersistedValue, hasContent]);

  useEffect(() => {
    const persistedItems = storage.getItems();
    setPersistedItems(persistedItems);
  }, [storage]);

  return [state, api];
}
