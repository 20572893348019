import {
  Breadcrumbs,
  Stack,
  Typography,
  Link as MUILink,
  CircularProgress,
} from "@mui/material";
import {
  DataGridPremium,
  gridColumnDefinitionsSelector,
  gridFilterModelSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useGetReportDataQuery } from "../../api/networksApi";
import { selectSelectedNetworkId } from "../../features/selectedNetwork";
import { WhenNetworks } from "../../components/WhenNetworks";
import { useGetReportsDefinitions } from "../../hooks/useGetReportsDefinitions";
import { useEffect, useRef } from "react";
import { GridToolbarWithPersistModels } from "../../components/persistInput/GridToolbarWithPersisModels";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useExcludeHSDOutpatientBehavioralHealth } from "../../hooks/useExcludeHSDOutpatientBehavioralHealth";
import { GridToolbarWithExport } from "../../components/GridToolbarWithExport/GridToolbarWithExport";

const commonProps = {
  initialState: {
    pagination: { paginationModel: { pageSize: 100 } },
  },
  pagination: true,
  density: "compact",
  pageSizeOptions: [100, 200, 500],
  slots: { toolbar: GridToolbarWithExport },
};

export function ReportRoute() {
  const { reportId } = useParams();

  const report = useGetReportsDefinitions({
    selectFromResult: ({ reports, isFetching }) => {
      if (isFetching) return {};
      return reports?.find((r) => r.id === reportId);
    },
  });

  if (!report) throw new Error("Report is not avalable for selected network.");

  const apiRef = useGridApiRef();
  const syncModelsRef = useRef({});

  const { name, columns, id, fetchFromId, ReportGrid } = report;

  const networkId = useSelector(selectSelectedNetworkId);

  const { data, isFetching, isUninitialized, refetch } = useGetReportDataQuery(
    {
      networkId,
      reportId: fetchFromId ?? id,
    },
    { skip: !id || !networkId }
  );
  const isJ2NM2286FixFlagOn =
    useFlags()["j2nm_2286_missing_zip_codes_in_adequacy_by_zip_report"];

  useEffect(() => {
    // this effect is only because of the flag
    if (id === "adequacy-by-zip" && !isUninitialized) refetch();
  }, [isJ2NM2286FixFlagOn, isUninitialized, id, refetch]);

  const GridComponent = ReportGrid ?? DataGridPremium;

  const rows = useExcludeHSDOutpatientBehavioralHealth(
    data ?? [],
    "specialty_name"
  );

  const crumbs = [
    <MUILink key={1} underline="hover" component={Link} to="/reports">
      Reports
    </MUILink>,
    <Typography
      key={2}
      color="text.primary"
      sx={{ fontSize: "inherit", lineHeight: 0 }}
    >
      {report.name}
    </Typography>,
  ];

  if (!id) return <CircularProgress size={14} sx={{ mt: 3 }} />;

  return (
    <WhenNetworks>
      <Breadcrumbs
        separator="›"
        sx={{
          fontSize: "0.9rem",
          paddingTop: "14px",
        }}
      >
        {crumbs}
      </Breadcrumbs>
      <Stack mt={3} height="100%">
        <Typography variant="h4" component="h1">
          {name}
        </Typography>
        <Stack height="100%" mt={2}>
          <GridComponent
            key={networkId}
            apiRef={apiRef}
            {...commonProps}
            rows={rows}
            columns={columns}
            loading={isFetching}
            onFilterModelChange={() => {
              const filterModel = gridFilterModelSelector(apiRef);
              syncModelsRef.current?.setFilterModel(filterModel);
            }}
            onColumnVisibilityModelChange={(_params) => {
              let columnsModel = gridVisibleColumnFieldsSelector(apiRef);
              const allColumns = gridColumnDefinitionsSelector(apiRef);
              if (columnsModel.length === allColumns.length)
                columnsModel = null;

              syncModelsRef.current?.setColumnsModel(columnsModel);
            }}
            slots={{ toolbar: GridToolbarWithPersistModels }}
            slotProps={{ toolbar: { syncModelsRef, page: name } }}
          />
        </Stack>
      </Stack>
    </WhenNetworks>
  );
}
