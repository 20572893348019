import { useAuth0 } from "@auth0/auth0-react";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "../../features/currentClient";
import { WithLDClientReady } from "./WithLDClientReady";

const defaultLDConfig = {
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
};

export function RequireFeatureFlags({ children, config = defaultLDConfig }) {
  const { user } = useAuth0();
  const client = useSelector(selectCurrentClient);
  const context = ldContext(user, client);

  if (!config.clientSideID || !context) return children;

  return (
    <LDProvider key={`${user}/${client}`} {...config} context={context}>
      <WithLDClientReady>{children}</WithLDClientReady>
    </LDProvider>
  );
}

function ldContext(user, client) {
  if (!user || !client) return null;
  return {
    kind: "multi",
    user: {
      key: user.email,
      client,
    },
    client: {
      key: client,
    },
  };
}
