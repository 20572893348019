import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useArchiveCustomStandardsMutation } from "../../../api/j2/j2Api";
import { actionsConfig } from "../../../hooks/useHandleStandardActions";

export function CustomStandardArchiveButton({ standard }) {
  const [open, setOpen] = useState(false);
  const [archiveStandard] = useArchiveCustomStandardsMutation({
    fixedCacheKey: actionsConfig.archiveCustomStandards.cacheKey,
  });

  return (
    <>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        Archive
      </Button>
      <Dialog open={open}>
        <DialogTitle>Confirm archive</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Archived standards will be moved to an archived standards page. You
            will have the ability to unarchive a standard.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              setOpen(false);
              archiveStandard({ standard });
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
