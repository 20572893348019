// customised functions
// based on
// - exportAs: https://github.com/mui/mui-x/blob/b47e8837c93b36f81706fd06fc05e778b8143723/packages/grid/x-data-grid/src/utils/exportAs.ts
// - exportScorecardCsv: https://github.com/mui/mui-x/blob/b47e8837c93b36f81706fd06fc05e778b8143723/packages/grid/x-data-grid/src/hooks/features/export/useGridCsvExport.tsx

export function exportAs(blob, extension, filename) {
  const fullName = `${filename}.${extension}`;

  // Test download attribute first
  // https://github.com/eligrey/FileSaver.js/issues/193
  if ("download" in HTMLAnchorElement.prototype) {
    // Create an object URL for the blob object
    const url = URL.createObjectURL(blob);

    // Create a new anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = fullName;

    // Programmatically trigger a click on the anchor element
    // Useful if you want the download to happen automatically
    // Without attaching the anchor element to the DOM
    a.click();

    // https://github.com/eligrey/FileSaver.js/issues/205
    setTimeout(() => {
      URL.revokeObjectURL(url);
    });
    return;
  }

  throw new Error("MUI: exportAs not supported");
}

export const exportScorecardCsv = (options) => {
  const csv = options.getDataAsCsv();

  const blob = new Blob(
    [options?.utf8WithBom ? new Uint8Array([0xef, 0xbb, 0xbf]) : "", csv],
    { type: "text/csv" }
  );

  exportAs(blob, "csv", options?.fileName || "export");
};
