import { createSelector, createSlice } from "@reduxjs/toolkit";
import { partition, uniqBy } from "lodash";
import {
  getMemberServed,
  getProviderLocationTags,
  getProviderScores,
  getProviderStatuses,
  getProviderUniverse,
} from "../api/networksApi";
import { LABEL_FIELD } from "../components/labelEdit";
import { applyCustomFilters } from "../components/CustomFilters/applyCustomFilter";
import { selectIsSpecialtyDependentPathname } from "./navigation";
import { selectServiceArea, selectStateAndNetwork } from "./networks";
import { selectSpecialtiesMap } from "./specialties";
import { getServedValue } from "../components/MemberServedGridColumn/memberServedColumn";
import { selectNetworkCountiesScores } from "./scores";

const initialFilters = [{ field: "is_inn", value: [1] }];
const specialFilters = ["specialty_id", "is_inn", "_hide_all"];

const initialState = {
  providerType: 1,
  filters: initialFilters,
  isUpdating: false,
};

export const providersSlice = createSlice({
  name: "providersNetwork",
  initialState,
  reducers: {
    setProviderType(state, { payload }) {
      state.providerType = payload ?? 1;
    },
    updateFilters(state, { payload }) {
      if (Object.keys(payload).length === 0) {
        state.filters = initialFilters;
        return;
      }

      if (!payload.value) {
        state.filters = state.filters.filter(
          ({ field }) => field !== payload.field
        );
        return;
      }

      state.filters = uniqBy([payload, ...state.filters], "field");
    },
    removeFilter(state, { payload }) {
      state.filters = state.filters.filter(({ field }) => field !== payload);
    },

    resetFilters(state) {
      state.filters = state.filters.filter((f) =>
        specialFilters.includes(f.field)
      );
    },
  },
});

export const { setProviderType, updateFilters, resetFilters, removeFilter } =
  providersSlice.actions;

// providers network selectors
export const selectProvidersNetwork = (state) => state[providersSlice.name];

export const selectProvidersFilters = createSelector(
  selectProvidersNetwork,
  ({ filters }) => filters
);

export const selectCustomFiltersCount = createSelector(
  selectProvidersFilters,
  (filters) => filters.filter((f) => !specialFilters.includes(f.field)).length
);

export const makeSelectProviderFilter = () =>
  createSelector(
    selectProvidersFilters,
    (_state, field) => field,
    (filters, field) => filters.find((f) => f.field === field)
  );

export const selectSpecialtyFilterVal = createSelector(
  selectProvidersFilters,
  (filters) => filters.find((f) => f.field === "specialty_id")?.value
);

export function selectedStatusFilterId(filterVal) {
  return !filterVal || filterVal.length > 1 || filterVal.length === 0
    ? "ALL"
    : filterVal.includes(1)
    ? "INN"
    : "OON";
}

export const selectStatusFilterVal = createSelector(
  selectProvidersFilters,
  (filters) => filters.find((f) => f.field === "is_inn")?.value
);

export const selectStatusFilterId = createSelector(
  selectStatusFilterVal,
  (filterValue) => selectedStatusFilterId(filterValue)
);

export const selectIsAllInNetworkFilterVal = createSelector(
  selectStatusFilterId,
  (statusFilterId) => statusFilterId === "ALL"
);

export const selectHideAllFilterVal = createSelector(
  selectProvidersFilters,
  (filters) => filters.find((f) => f.field === "_hide_all")?.value ?? false
);

const selectSpecialtyFromFilterAndPathname = createSelector(
  selectSpecialtyFilterVal,
  selectIsSpecialtyDependentPathname,
  (specialtyId, isSpecialtyDependent) =>
    isSpecialtyDependent ? specialtyId : undefined
);

export const selectProviderUniverse = createSelector(
  selectStateAndNetwork,
  selectSpecialtyFromFilterAndPathname,
  ([state, network], specialtyId) =>
    network
      ? getProviderUniverse.select({
          networkId: network.id,
          specialtyId,
        })(state)?.data
      : undefined
);

export const selectProviderScores = createSelector(
  selectStateAndNetwork,
  selectSpecialtyFromFilterAndPathname,
  ([state, network], specialtyId) => {
    const response = getProviderScores.select({
      networkId: network.id,
      specialtyId,
    })(state);
    if (response?.error) return {};
    return network ? response?.data : undefined;
  }
);

export const selectProviderInStatus = createSelector(
  selectStateAndNetwork,
  ([state, network]) => {
    const response = getProviderStatuses.select({ networkId: network.id })(
      state
    );
    if (response?.error) return {};
    return network ? response?.data : undefined;
  }
);

export const selectProviderBaselineInStatus = createSelector(
  selectStateAndNetwork,
  ([state, network]) =>
    network
      ? getProviderStatuses.select({ networkId: network.id, baseline: 1 })(
          state
        )?.data
      : undefined
);

export const selectProvidersTags = createSelector(
  selectStateAndNetwork,
  ([state, network]) => {
    const response = getProviderLocationTags.select(network.id)(state);
    if (response?.error) return {};
    return network ? response?.data : undefined;
  }
);

export const selectProvidersMemberServed = createSelector(
  selectStateAndNetwork,
  selectSpecialtyFromFilterAndPathname,
  ([state, network], specialtyId) => {
    const response = getMemberServed.select({
      networkId: network.id,
      specialtyId,
    })(state);
    if (response?.error) return {};

    return network ? response?.data : undefined;
  }
);

export const selectProvidersFullData = createSelector(
  selectProviderUniverse,
  selectProviderScores,
  selectProviderInStatus,
  selectProviderBaselineInStatus,
  selectProvidersTags,
  selectProvidersMemberServed,
  selectServiceArea,
  selectSpecialtiesMap,
  selectNetworkCountiesScores,
  (
    providerUniverse,
    providerScores,
    inStatuses,
    baselineStatuses,
    tags,
    memberServed,
    { counties },
    specialties,
    countyScores
  ) => {
    return providerUniverse &&
      providerScores &&
      inStatuses &&
      tags &&
      memberServed
      ? joinProviderEntitiesV2(
          providerUniverse,
          providerScores,
          inStatuses,
          baselineStatuses,
          tags,
          memberServed,
          counties,
          specialties,
          countyScores
        )
      : [];
  }
);

export const selectFilteredProviders = createSelector(
  selectProvidersFullData,
  selectProvidersFilters,
  (providers, filters) => applyCustomFilters(providers, filters)
);

const partitionByIsInn = (providers) => {
  const [innProviders, oonProviders] = partition(providers, { is_inn: 1 });
  return { innProviders, oonProviders };
};

export const selectProvidersByStatus = createSelector(
  selectProvidersFullData,
  (providers) => partitionByIsInn(providers)
);

export const selectEditedProviders = createSelector(
  selectProvidersFullData,
  (providers) => providers.filter((p) => p.baseline !== p.current)
);

export const selectFilteredProvidersByStatus = createSelector(
  selectFilteredProviders,
  (providers) => partitionByIsInn(providers)
);

export function joinProviderEntities(
  locations,
  providersAttrs = {},
  scores,
  tags,
  counties = {},
  specialties
) {
  const result = [];
  for (const location of locations) {
    const { id, ...locationAttrs } = location;
    const { is_inn = 0, scores: county_scores } = scores[id] || {};
    const provAttrs = providersAttrs[locationAttrs.provider_id] || {};
    result.push({
      ...locationAttrs,
      ...provAttrs,
      provider_name: locationAttrs.provider_name ?? provAttrs.provider_name,
      is_inn,
      is_oon: +!is_inn,
      county_scores,
      // TODO: move lookups creation to backend
      countyLookup: counties,
      specialtyLookup: specialties,
      [LABEL_FIELD]: tags[id],
      id,
    });
  }
  return result;
}
export function joinProviderEntitiesV2(
  providerUniverse,
  providerScores,
  inStatuses,
  baselineStatuses = {},
  tags,
  memberServed = {},
  counties = {},
  specialties = {},
  countyScores = {}
) {
  const result = [];
  for (const location of providerUniverse) {
    const { id, location_id, address, attrs, ...rest } = location;
    const is_inn = inStatuses[id] || 0;
    const b_inn = baselineStatuses[id] || 0;
    const adequacyScores = providerScores[id] || {};
    if (!address.lat || !address.lon) continue;
    const countyLookup = counties;
    const specialtyLookup = specialties;
    const member_served = memberServed[location_id];
    const providerSpecialtyLookup = Object.fromEntries(
      rest.specialty_ids.map((id) => [id, true])
    );

    result.push({
      ...attrs,
      ...address,
      ...rest,
      is_inn,
      is_oon: is_inn ? 0 : 1,
      baseline: b_inn,
      current: is_inn,
      adequacyScores,
      countyScores,
      member_served: getServedValue({
        member_served,
        countyLookup,
        specialtyLookup,
        providerSpecialtyLookup,
      }),

      // TODO: move lookups creation to backend
      countyLookup,
      specialtyLookup,
      [LABEL_FIELD]: tags[id],
      id,
    });
  }
  return result;
}
