import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useGetJ2SpecialtiesQuery } from "../../api/networksApi";
import { useCustomStandardsEnabled } from "../../hooks/useCustomStandardsEnabled";
import { useGetCustomStandardsQuery } from "../../api/j2/j2Api";
import { useAmplitudeEvent } from "../../hooks/useAmplitudeEvent";

export const routeTabMapping = [
  { label: "Population Attributes", path: "." },
  { label: "Specialties", path: "specialties" },
  { label: "Score Attributes", path: "score-attributes" },
  { label: "Requirements", path: "requirements" },
];

export function StandardRoot() {
  const { setAlert } = useOutletContext();
  const { pathname } = useLocation();
  const { standardId } = useParams();
  const [value, setValue] = useState(0);
  const mode = pathname.endsWith("edit") ? "/edit" : "";

  const standards = useGetCustomStandardsQuery();
  useGetJ2SpecialtiesQuery();

  const standard = standards.data?.find(({ id }) => String(id) === standardId);
  const isReleased = !standard?.draft;
  const shouldDisallowEdit =
    !standards.isFetching && isReleased && mode === "/edit";
  const canShowEditBtn = mode !== "/edit" && !isReleased;

  const trackAmplitudeEvent = useAmplitudeEvent();

  useEffect(() => {
    const index = routeTabMapping
      .slice(1) // skip the first element as it does not have a distinct path
      .findIndex(({ path }) => new RegExp(path).test(pathname));
    setValue(Math.max(0, index + 1));
    trackAmplitudeEvent("View custom standards page", { page: routeTabMapping[index + 1].label });
  }, [pathname, trackAmplitudeEvent]);

  if (!useCustomStandardsEnabled()) return <Navigate to="/self-serve" />;
  if (shouldDisallowEdit)
    return <Navigate to={pathname.replace("/edit", "")} />;

  if (!standard && !standards.isFetching) throw new Error("Not found");

  return (
    <Stack height="100%" mt={1}>
      {standards.isFetching ? (
        <CircularProgress size={14} />
      ) : (
        <Stack direction={"row"} alignItems={"flex-end"}>
          <Typography variant="h4" component="h1">
            {standard?.name}
          </Typography>
          {canShowEditBtn && (
            <Button size="small" component={Link} to={pathname + "/edit"}>
              Edit
            </Button>
          )}
        </Stack>
      )}

      <Stack height="100%" mt={2}>
        <Tabs
          value={value}
          sx={{ mb: 2, borderBottom: 1, borderColor: "divider" }}
          color={"#ccc"}
        >
          {routeTabMapping.map(({ label, path }) => (
            <Tab key={path} label={label} to={path + mode} component={Link} />
          ))}
        </Tabs>
        {standard && <Outlet context={{ standard, setAlert }} />}
      </Stack>
    </Stack>
  );
}
