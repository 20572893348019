import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { networksApi } from "./api/networksApi";
import { apiActivitySlice } from "./features/apiActivity";
import { currentClientSlice } from "./features/currentClient";
import { mapSlice } from "./features/map";
import { providersSlice } from "./features/providers";
import { selectedNetworkSlice } from "./features/selectedNetwork";
import { fetchErrorHandler } from "./features/handleFetchErrors";
import { stagedChangesSlice } from "./features/stagedChanges";
import { addNetworkIdToAction } from "./features/addNetworkIdToAction";
import { scorecardSlice } from "./features/scorecard";
import { navigationSlice } from "./features/navigation";
import createMigrate from "redux-persist/es/createMigrate";
import { changeFieldNameToField } from "./utils/persistMigrations";
import { selfServeApi } from "./api/selfServe/selfServeApi";
import { APP_ENV_KEY } from "./utils/appEnv";
import { withPrefixSuffix } from "./utils";
import { j2Api } from "./api/j2/j2Api";

const appEnvKey = withPrefixSuffix(APP_ENV_KEY, "-");
export const PERSIST_KEY = `j2-network-manager-data${appEnvKey}`;
export const LS_PERSIST_KEY = `persist:${PERSIST_KEY}`;

export const store = configureStore({
  reducer: persistReducer(
    {
      key: PERSIST_KEY,
      whitelist: [
        selectedNetworkSlice.name,
        currentClientSlice.name,
        scorecardSlice.name,
        providersSlice.name,
      ],
      storage,
      version: 0,
      migrate: createMigrate({ 0: changeFieldNameToField }),
    },
    combineReducers({
      [currentClientSlice.name]: currentClientSlice.reducer,
      [selectedNetworkSlice.name]: selectedNetworkSlice.reducer,
      [mapSlice.name]: mapSlice.reducer,
      [scorecardSlice.name]: scorecardSlice.reducer,
      [providersSlice.name]: providersSlice.reducer,
      [stagedChangesSlice.name]: stagedChangesSlice.reducer,
      [apiActivitySlice.name]: apiActivitySlice.reducer,
      [navigationSlice.name]: navigationSlice.reducer,
      [networksApi.reducerPath]: networksApi.reducer,
      [selfServeApi.reducerPath]: selfServeApi.reducer,
      [j2Api.reducerPath]: j2Api.reducer,
    })
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      networksApi.middleware,
      selfServeApi.middleware,
      j2Api.middleware,
      fetchErrorHandler,
      addNetworkIdToAction
    ),

  devTools:
    process.env.NODE_ENV === "production"
      ? false
      : {
          stateSanitizer: ({ networksApi, selfServeApi, j2Api, ...state }) => {
            // const { queries: q1, ...restNetworkApi } = networksApi;
            // const { queries: q2, ...restSelfServeApi } = networksApi;
            // const { queries: q2, ...restJ2Api } = networksApi;
            return {
              ...state,
              // networksApi: restNetworkApi,
              // selfServeApi: restSelfServeApi,
              // j2Api: restJ2Api,
            };
          },
        },
});
export const persistor = persistStore(store);
