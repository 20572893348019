export function pipeline(fns) {
  const bound = fns.map(bind);
  return (v) => bound.reduce((result, fn) => fn(result), make(v));
}

// how to use pipeline
// const run = pipeline([
//   isPresent(),
//   isPattern(new RegExp("^[a-zA-Z0-9]+"), "notAllowedFirstLetter"),
//   isPattern(new RegExp("^[a-zA-Z0-9_]*$"), "notAllowedChar"),
// ]);
// run({ field: "networkName", values: {networkName: ""} })

//
// monadic pipeline definitions :P
//

function isDefined(val) {
  return val !== undefined;
}

function isSuccess(result) {
  return isDefined(result.ok);
}

function isFailure(result) {
  return isDefined(result.error);
}

export function make(value, error) {
  const r = { ok: undefined, error: undefined };
  if (value) {
    r.ok = value;
  } else {
    r.error = error;
  }
  return r;
}

export function bind(switchFn) {
  return function bindResult(r) {
    if (isSuccess(r)) return switchFn(r.ok);
    else if (isFailure(r)) return r;

    throw new Error(`
Error: Not a Result value passed to the switch function.
Your previous switch function should return either "ok" or "error"`);
  };
}

// end monadic pipeline definitions
