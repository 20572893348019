import { useState } from "react";
import { Button } from "@mui/material";
import { Standards } from "../DataPanel/Standards";
import { LocalLibraryOutlined } from "@mui/icons-material";

export function StandardsButton() {
  const [showStandards, setShowStandards] = useState();
  return (
    <>
      <Button
        size="small"
        onClick={() => setShowStandards(true)}
        startIcon={<LocalLibraryOutlined />}
      >
        Standards
      </Button>
      <Standards show={showStandards} onClose={() => setShowStandards(false)} />
    </>
  );
}
