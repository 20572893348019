import { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectBaselineTotalGaps,
  selectNetworkTotalGaps,
} from "../../features/scorecard";
import { GapsChanges } from "./GapsChanges";
import { BaselineChanges } from "../ScoresTable/BaselineChanges";

export function NetworkStats({ isLoading }) {
  const totalGaps = useSelector(selectNetworkTotalGaps);
  const baselineTotalGaps = useSelector(selectBaselineTotalGaps);
  const [open, setOpen] = useState(false);

  return (
    <>
      <GapsChanges
        gaps={totalGaps}
        changes={totalGaps - baselineTotalGaps}
        show={!isLoading}
        onChangesClick={() => setOpen(true)}
      />
      {open && <BaselineChanges open={open} setOpen={setOpen} />}
    </>
  );
}
