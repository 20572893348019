import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "../features/currentClient";
import { useFlags } from "launchdarkly-react-client-sdk";

const selfServeClientsWhiteList = ["tog", "amhealth", "pphp"];
const EDIT_NETWORK_FLAG_KEY = "Update-Network-Edit-Restrictions";

export function useCanEditSelfServeNetwork(flagKey = EDIT_NETWORK_FLAG_KEY) {
  const { user } = useAuth0();
  const client = useSelector(selectCurrentClient);
  const editNetworkFlag = useFlags()[flagKey];

  const canEdit = (createdByEmail) => {
    return (
      editNetworkFlag ||
      user.email === createdByEmail ||
      selfServeClientsWhiteList.includes(client)
    );
  };

  return canEdit;
}
