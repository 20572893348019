import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Splash } from "../components/Splash";
import { useAmplitudeEvent } from "../hooks/useAmplitudeEvent";
import {
  setUser as setSentryUser,
  setContext as setSentryContext,
} from "@sentry/react";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "../features/currentClient";

export const tokenFetcher = {};

export function RequireAuth({ children }) {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
  } = useAuth0();
  const trackAmplitudeEvent = useAmplitudeEvent();

  // const currentClient = useSelector(selectCurrentClient);
  tokenFetcher.getToken = getAccessTokenSilently;

  const currentClient = useSelector(selectCurrentClient);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      trackAmplitudeEvent("Attempt Login");
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, trackAmplitudeEvent]);

  useEffect(() => {
    if (user && user.email) {
      setSentryUser({ email: user.email });
    }
  }, [user]);

  useEffect(() => {
    if (currentClient) {
      setSentryContext("Tenant", { public_id: currentClient });
    }
  }, [currentClient]);

  if (isLoading)
    // wait for auth data;
    return (
      <Splash>
        <p>Authenticating. Please wait ...</p>
      </Splash>
    );
  if (!isAuthenticated)
    return (
      <Splash>
        <p>Checking credentials ...</p>
      </Splash>
    );
  return children;
}
