import { RotateLeft } from "@mui/icons-material";
import {
  Chip,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters } from "../../features/providers";
import { selectTableConfig_P_MAP_TABLE_ROW } from "../../features/tableColumns";
import { useCustomFilter } from "../../hooks/useCustomFilter";
import { NetworkStatusFilter } from "./NetworkStatusFilter";
import { useOigSanctionedColumnEnabled } from "../../hooks/useOigSanctionedColumnEnabled";

export function CustomFilters() {
  let { columns: fields } = useSelector(selectTableConfig_P_MAP_TABLE_ROW);
  fields = useOigSanctionedColumnEnabled(fields);
  const [resetKey, setResetKey] = useState(makeResetKey());

  const dispatch = useDispatch();

  return (
    <Stack sx={{ padding: "24px", gap: "16px" }}>
      <Typography>Filter providers</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <NetworkStatusFilter showNoneToggle={false} />
        <Chip
          className="filter-panel-reset"
          icon={<RotateLeft />}
          label="Reset Filters"
          onClick={() => {
            setResetKey(makeResetKey());
            dispatch(resetFilters());
          }}
        />
      </Stack>
      {fields.map((f) => (
        <FilterField key={f.field + resetKey} field={f} />
      ))}
    </Stack>
  );
}

function makeResetKey() {
  return new Date().getTime();
}
const numberLike = ["adequacy_per_county"];

function FilterField({ field }) {
  const [{ value, operator }, setValue, setOperator] = useCustomFilter(
    field.field
  );

  const isNumberField =
    field.type === "number" || numberLike.includes(field.field);

  return (
    <TextField
      id={field.field}
      variant="outlined"
      size="small"
      margin="none"
      label={field.headerName}
      type={field.type}
      value={value || ""}
      onChange={(e) => setValue(e.target.value)}
      inputProps={{
        autoComplete: "off", // disable browser autocomplete and autofill
      }}
      InputProps={{
        endAdornment: isNumberField ? (
          <NumberFilterOperators
            value={operator ?? "="}
            onChange={setOperator}
          />
        ) : (
          <StringFilterOperators
            value={operator ?? "contains"}
            onChange={setOperator}
          />
        ),
      }}
    />
  );
}

function StringFilterOperators({ value, onChange }) {
  return (
    <ToggleButton
      onChange={(e) => {
        onChange(e.target.value === "contains" ? "not-contains" : "contains");
      }}
      value={value}
      sx={{ height: "24px" }}
      selected={value === "not-contains"}
      aria-label="not contains"
    >
      {"<>"}
    </ToggleButton>
  );
}

const numberFilterOperators = [">=", "<=", "="];

function NumberFilterOperators({ value, onChange }) {
  return (
    <ToggleButtonGroup
      value={value}
      size={"small"}
      style={{ height: "24px" }}
      exclusive
      onChange={(_e, v) => onChange(v)}
      aria-label="numeric value comparison"
    >
      {numberFilterOperators.map((op) => (
        <ToggleButton key={op} value={op} aria-label={op}>
          {op}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
