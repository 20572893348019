import { Dialog, DialogContent, Typography } from "@mui/material";
import { Table } from "../Table";
import { useSelector } from "react-redux";
import { selectChangesToBaseline } from "../../features/baselineChanges";
import { selectSpecialtiesMap } from "../../features/specialties";

export function BaselineChanges({ open, setOpen, specialtyId }) {
  let { rows, columns } = useSelector(selectChangesToBaseline);
  const specialtiesMap = useSelector(selectSpecialtiesMap);
  const specialtyName = specialtiesMap[specialtyId];

  if (specialtyId) {
    rows = rows.filter((r) => r.specialtyId === specialtyId);
  }
  const title = specialtyName
    ? `Changes to baseline in ${specialtyName}`
    : "All changes to baseline";

  return (
    <Dialog
      onClose={() => setOpen(false)}
      open={open}
      sx={{ "& .MuiPaper-root": { width: "80%", maxWidth: "initial" } }}
    >
      <DialogContent>
        <Typography variant="h5" mb={"16px"}>
          {title}
        </Typography>

        <Table columns={columns} rows={rows} disableRowSelectionOnClick />
      </DialogContent>
    </Dialog>
  );
}
