import { useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { fieldValidator, isPresent, validate } from "../../utils/formValidate";
import { makeNewRowId } from "../../hooks/useEditableGrid.utils";

const schema = {
  name: validate([
    isPresent(
      "cannot be empty",
      ({ field, values }) => values[field].trim().length > 0
    ),
    (input) => {
      const { field, values } = input;
      const name = values[field].trim();
      const ok = !values.specialties.find((sp) => sp.name === name);
      return ok ? { ok: input } : { error: "specialty name already exists" };
    },
  ]),
  specialtyType: validate([isPresent("cannot be empty")]),
};

export function NewSpecialtyForm({
  j2Specialties,
  specialties = [],
  onSubmit,
}) {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState();
  const [specialtyType, setSpecialtyType] = useState("");
  const [specialtyTypeError, setSpecialtyTypeError] = useState("");
  const [mappings, setMappings] = useState([]);

  const validateField = fieldValidator(schema, {
    name,
    specialtyType,
    mappings,
    specialties,
  });

  const validateForm = () => {
    const nameError = validateField("name").error;
    const typeError = validateField("specialtyType").error;
    if (nameError) setNameError(nameError);
    if (typeError) setSpecialtyTypeError(typeError);
    if (nameError || typeError) return false;
    return true;
  };

  const j2SpOptions = useMemo(
    () =>
      Object.entries(j2Specialties ?? {}).sort((a, b) =>
        a[1] > b[1] ? 1 : -1
      ),
    [j2Specialties]
  );

  const clearForm = () => {
    setName("");
    setMappings([]);
    setSpecialtyType("");
    setNameError();
    setSpecialtyTypeError();
  };

  return (
    <Box width={"100%"}>
      <Typography variant="overline" marginBottom={-2}>
        Add specialty
      </Typography>

      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={2}
        sx={{
          "&  label": { fontSize: "14px" },
          marginTop: -1,
          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          gap={2}
          alignItems={"center"}
          sx={{ paddingY: 2 }}
        >
          <FormControl sx={{ minWidth: 220 }}>
            <TextField
              name="specialty"
              variant="outlined"
              size="small"
              margin="none"
              label="Specialty name"
              required
              sx={{
                "& .MuiFormHelperText-root": {
                  position: "absolute",
                  bottom: "-20px",
                },
              }}
              value={name}
              error={Boolean(nameError)}
              helperText={nameError}
              onChange={(e) => {
                setName(e.target.value);
                setNameError();
                // if (e.target.value === "") return setMappings([]);
                // const value = e.target.value.toLowerCase();
                // if (value.length > 3)
                //   setMappings(
                //     j2SpOptions
                //       .filter(([, name]) => name.toLowerCase().includes(value))
                //       .sort(mappingsSorter(value))
                //       .map((o) => o[0])
                //   );
              }}
              onBlur={() => {
                setNameError(validateField("name").error);
              }}
              onFocus={() => {
                setNameError();
              }}
            />
          </FormControl>

          <FormControl
            size="small"
            sx={{ minWidth: "140px" }}
            error={Boolean(specialtyTypeError)}
            required
          >
            <InputLabel
              size="small"
              id="select-specialty-type-label"
              htmlFor="specialty-type-select"
            >
              Type
            </InputLabel>
            <Select
              id="specialty-type-select"
              size="small"
              labelId="select-specialty-type-label"
              label="Type"
              value={specialtyType}
              onChange={(e) => {
                setSpecialtyType(e.target.value);
              }}
              onFocus={() => setSpecialtyTypeError()}
              onBlur={() =>
                setSpecialtyTypeError(validateField("specialtyType").error)
              }
            >
              <MenuItem value={"provider"}>Provider</MenuItem>
              <MenuItem value={"facility"}>Facility</MenuItem>
            </Select>
            {specialtyTypeError && (
              <FormHelperText sx={{ position: "absolute", bottom: "-20px" }}>
                {specialtyTypeError}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl sx={{ width: 448, flex: 1 }}>
            <Autocomplete
              id="mapping-autocomplete"
              size="small"
              multiple
              disableCloseOnSelect
              openOnFocus
              fullWidth
              options={j2SpOptions}
              isOptionEqualToValue={(option, value) => option[1] === value[1]}
              value={mappings.map((id) => [
                id,
                j2Specialties[id] ?? "missing name",
              ])}
              getOptionLabel={(option) => option[1]}
              renderOption={renderJ2SpecialtiesOptions}
              onChange={(_, value) => {
                setMappings(value.map(([id]) => id));
              }}
              // sx={{
              //   "& .MuiInputBase-root": {
              //     maxHeight: "260px",
              //     overflow: "auto",
              //   },
              // }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="none"
                  label="Mappings (optional)"
                  InputLabelProps={{ sx: { fontSize: "14px" } }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable browser autocomplete and autofill
                  }}
                />
              )}
            />
          </FormControl>
        </Stack>
        <Button
          variant="contained"
          onClick={() => {
            if (!validateForm()) return;

            const createdISO = new Date().toISOString();
            const formattedISO = `${createdISO.slice(0, createdISO.length - 1)}000Z`;

            onSubmit(
              {
                id: makeNewRowId(), // this is needed to MUI Grid work correclty, it requires unique ids
                name,
                facility_only: specialtyType === "facility",
                j2_specialties: mappings,
                created_at: formattedISO,
              },
              clearForm
            );
          }}
        >
          Add
        </Button>
      </Stack>
    </Box>
  );
}

export const renderJ2SpecialtiesOptions = (props, option, { selected }) => (
  <li {...props}>
    <input
      type="checkbox"
      checked={selected}
      readOnly
      style={{ marginRight: "8px" }}
    />
    {option[1]}
  </li>
);

export function mappingsSorter(value) {
  return ([, a], [, b]) => {
    let result;

    const aStarts = a.startsWith(value);
    const bStarts = b.startsWith(value);
    if (aStarts && !bStarts) result = -1;
    if (bStarts && !aStarts) result = 1;

    return result || a.localeCompare(b);
  };
}
