import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { CountiesSelect } from "./CountiesSelect";
import {
  fieldValidator,
  isPattern,
  isPresent,
  validate,
} from "../../utils/formValidate";

import hsdTemplate from "../../assets/HSD Loading Template.xlsx";
import qhpTemplate from "../../assets/QHP Loading Template.xlsx";
import gamTemplate from "../../assets/GAMEDICAID Loading Template.xlsx";
import nydTemplate from "../../assets/NYDOH Loading Template.xlsx";
import { PhiConfirmationCheckbox } from "./PhiConfirmationCheckbox";

export const lobTemplates = {
  HSD: hsdTemplate,
  QHP: qhpTemplate,
  GAMEDICAID: gamTemplate,
  NYDOH: nydTemplate,
};

const Input = styled("input")({
  display: "none",
});

const schema = {
  networkId: validate([
    isPresent("cannot be empty"),
    isPattern(
      new RegExp("^[a-zA-Z0-9]+"),
      "the first letter must be either a letter or number, no special characters allowed"
    ),
    isPattern(
      new RegExp("^[a-zA-Z0-9_]*$"),
      "only letters, numbers and underscore allowed"
    ),
  ]),
  lob: validate([isPresent("cannot be empty")]),
};

export function NewNetworkForm({
  user,
  regulatoryStandards,
  isSubmitted,
  onSubmit,
  uploadResult,
}) {
  const [networkId, setNetworkId] = useState("");
  const [networkName, setNetworkName] = useState("");
  const [lob, setLob] = useState("");
  const [counties, setCounties] = useState([]);
  const [countiesError, setCountiesError] = useState();

  const [file, setFile] = useState();

  const [phi, setPhi] = useState(false);

  const [networkIdError, setNetworkIdError] = useState();
  const [lobError, setLobError] = useState();

  const lobTemplate = lobTemplates[lob];

  const validateField = fieldValidator(schema, { networkId, lob });
  const isFormValid =
    !networkIdError && !lobError && (file ? phi : true) && counties.length;

  const [emptyFileAlert, setEmptyFileAlert] = useState(false);
  const closeEmptyFileAlert = () => setEmptyFileAlert(false);

  const standard = regulatoryStandards?.find((l) => l.value === lob);
  return (
    <>
      <Stack sx={{ textAlign: "left", paddingBottom: "64px" }} mt={2}>
        <Typography component="h1" variant="h4">
          {isSubmitted ? "Submitted data" : "Create new network"}
        </Typography>
        {!isSubmitted && (
          <Typography marginTop="16px">
            Please fill all the required fields.
          </Typography>
        )}
        <Stack component="form" width={"100%"} maxWidth={500} mt={2}>
          <TextField
            id="network-id"
            variant="outlined"
            size="small"
            margin="dense"
            label="Network identifier"
            required
            value={networkId}
            disabled={isSubmitted}
            onChange={(e) => setNetworkId(e.target.value)}
            onFocus={() => setNetworkIdError()}
            onBlur={() => {
              setNetworkIdError(validateField("networkId").error);
            }}
            error={!!networkIdError}
            helperText={
              networkIdError
                ? networkIdError
                : "Only providers whose Network ID in the loaded file EXACTLY matches this identifier will be loaded. It must include only letters, numbers and underscores, and must start with a letter."
            }
          />

          <TextField
            id="network-name"
            variant="outlined"
            size="small"
            margin="dense"
            label="Network display name"
            value={networkName}
            disabled={isSubmitted}
            onChange={(e) => setNetworkName(e.target.value)}
          />

          <FormControl
            size="small"
            margin="dense"
            error={!!lobError}
            disabled={isSubmitted}
            required
          >
            <InputLabel size="small" htmlFor="lob-select" id="lob-select-label">
              Regulatory Standard
            </InputLabel>
            <Select
              id="lob-select"
              size="small"
              labelId="lob-select-label"
              label="Regulatory Standard*"
              value={lob}
              onChange={(e) => {
                setLob(e.target.value);
              }}
              onFocus={() => setLobError()}
              onBlur={() => setLobError(validateField("lob").error)}
            >
              {regulatoryStandards.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {lobError && <FormHelperText>{lobError}</FormHelperText>}
          </FormControl>
          {lobTemplate && (
            <FormControl margin="dense">
              <Button
                size="small"
                variant="contained"
                href={lobTemplate}
                download
                sx={{ width: "fit-content", textTransform: "none" }}
              >
                Download data template for {standard?.label ?? lob}
              </Button>
            </FormControl>
          )}

          <CountiesSelect
            onChange={setCounties}
            lob={lob}
            value={counties}
            error={countiesError}
            setError={setCountiesError}
            disabled={isSubmitted}
          />

          <Box sx={{ alignSelf: "flex-start", marginTop: "8px" }}>
            <label htmlFor="select-file">
              <Input
                key={uploadResult.requestId ?? -1}
                id="select-file"
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                disabled={isSubmitted}
                accept=".csv"
              />
              {!isSubmitted && (
                <Button variant="outlined" component="span">
                  Select CSV file
                </Button>
              )}
            </label>
            <FormHelperText sx={{ marginLeft: "14px" }}>
              {file ? `Selected file: ${file.name}` : "no file selected"}
            </FormHelperText>
            {file && (
              <PhiConfirmationCheckbox
                value={phi}
                onChange={(e) => setPhi(e.target.checked)}
                disabled={isSubmitted}
              />
            )}
          </Box>

          {isSubmitted ? (
            <Box mt={1}>
              <Button variant="outlined" component={Link} to="..">
                Back to self serve networks home
              </Button>
            </Box>
          ) : (
            <Stack direction="row" gap={2} sx={{ marginTop: "24px" }}>
              <Button
                variant="contained"
                disabled={!isFormValid}
                onClick={() => {
                  if (!file) return setEmptyFileAlert(true);
                  onSubmit({
                    user,
                    file,
                    networkId,
                    networkName,
                    lob,
                    counties,
                    regulatoryStandards,
                  });
                }}
              >
                Save data
              </Button>
              <Button component={Link} to="..">
                Cancel
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Dialog open={emptyFileAlert} onClose={closeEmptyFileAlert}>
        <DialogTitle>No file selected</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to create a network without loading any in-network
            providers
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEmptyFileAlert}>Cancel</Button>
          <Button
            onClick={() => {
              closeEmptyFileAlert();
              onSubmit({
                user,
                file,
                networkId,
                networkName,
                lob,
                counties,
                regulatoryStandards,
              });
            }}
            autoFocus
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
