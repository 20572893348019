import { Navigate } from "react-router-dom";
import { Alert, Backdrop, CircularProgress, Stack } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { NewNetworkForm } from "../../components/SelfServe/NewNetworkForm";
import { useSelfServeAllowed } from "../../hooks/useSelfServeAllowed";
import { FileUploadUserActionDialog } from "../../components/SelfServe/FileUploadUserActionDialog";
import { useProcessSelfServeData } from "../../components/SelfServe/useProcessSelfServeData";
import { useWithFlagGetFormStandards } from "../../hooks/useWithFlagGetFormStandards";

export function NetworksCreateRoute() {
  const { user } = useAuth0();
  const isSelfServeAllowed = useSelfServeAllowed();

  const { data: regulatoryStandards, isFetching } =
    useWithFlagGetFormStandards();

  const {
    submitProcessing,
    proceedProcessing,
    fileUploadResult,
    fileProcessResult,
    processingPaused,
    canResumeProcessing,
    inProgress,
    isSuccess,
    isFetchError,
    errorMessage,
  } = useProcessSelfServeData();

  const busy = inProgress || isFetching;

  if (!isSelfServeAllowed) return <Navigate to="/" />;

  const alertSx = { justifyContent: "center", marginTop: "8px" };
  return (
    <>
      <Stack>
        {isFetchError && (
          <Alert severity="error" sx={alertSx}>
            {errorMessage}
          </Alert>
        )}

        {isSuccess && (
          <Alert severity="success" sx={alertSx}>
            {fileProcessResult.data?.message || "File processing initiated!"}
          </Alert>
        )}

        {regulatoryStandards && (
          <NewNetworkForm
            user={user}
            regulatoryStandards={regulatoryStandards}
            isSubmitted={!!fileProcessResult.data}
            onSubmit={submitProcessing}
            uploadResult={fileUploadResult}
          />
        )}
        {busy && (
          <Backdrop className="loadingBackdrop" open>
            <CircularProgress color="primary" size={60} thickness={4} />
          </Backdrop>
        )}
      </Stack>
      <FileUploadUserActionDialog
        open={processingPaused}
        uploadResult={fileUploadResult}
        onCancel={() => fileUploadResult.reset()}
        onProceed={proceedProcessing}
        canProceed={canResumeProcessing}
      />
    </>
  );
}
