import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Alert, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentClient,
  setCurrentClient,
} from "../features/currentClient";
import { Splash } from "../components/Splash";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getTenants, networksApi } from "../api/networksApi";
import { LogoutButton } from "../components/Account";
import { setSelectedNetworkId } from "../features/selectedNetwork";
import { resetScorecardConfig } from "../features/scorecard";
import { resetFilters } from "../features/providers";
import { j2Api } from "../api/j2/j2Api";
import { useAmplitudeEvent } from "../hooks/useAmplitudeEvent";
import { workspaces } from "../features/workspaces/workspaces";
import { setContext as setSentryContext } from "@sentry/react";

const namespacedRoles = "https://j2health.com/roles";

export function SelectClientRoute() {
  const {
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
    logout,
    loginWithRedirect,
  } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const redirectTo = workspaces.getByPathname(state?.from).path;
  const currentClient = useSelector(selectCurrentClient);
  const [claimsError, setClaimsError] = useState();
  const trackAmplitudeEvent = useAmplitudeEvent();

  const dispatchSetClientAndRedirect = useCallback(
    (client) => {
      if (currentClient !== client) {
        dispatch(setSelectedNetworkId(null));
        dispatch(resetScorecardConfig());
        dispatch(resetFilters());
        dispatch(setCurrentClient(client));
        setSentryContext("Tenant", { public_id: client });
      }
      navigate(redirectTo, { replace: true });
    },
    [currentClient, dispatch, navigate, redirectTo]
  );

  const [trigger, { data: clients, error: fetchTenantsError }] =
    getTenants.useLazyQuery();

  useEffect(() => {
    getIdTokenClaims()
      .then((claims) => {
        if (!claims) {
          console.log("No claims, try relogging");
          if (isLoading) return;
          return loginWithRedirect();
        }
        const roles = claims[namespacedRoles];
        if (!roles || roles.length === 0) {
          return setClaimsError("Missing permissions (1)");
        }
        if (!clients) trigger(roles);
      })
      .catch((e) => {
        console.log(e);
        setClaimsError(e.message);
      });
  }, [
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
    trigger,
    clients,
    dispatch,
    loginWithRedirect,
  ]);

  useEffect(() => {
    if (!clients) return;

    if (clients.length === 0) {
      return setClaimsError("Missing permissions (2)");
    }

    if (clients.length === 1 && !state?.reloadClients) {
      dispatchSetClientAndRedirect(clients[0]);
      trackAmplitudeEvent("Complete login");
    }
  }, [
    clients,
    dispatch,
    dispatchSetClientAndRedirect,
    navigate,
    redirectTo,
    state,
    trackAmplitudeEvent,
  ]);

  if (clients || claimsError || fetchTenantsError)
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        paddingY={3}
        sx={{
          backgroundColor: "#f0f2f5",
          boxSizing: "border-box",
        }}
      >
        {claimsError || fetchTenantsError ? (
          <Alert
            severity="error"
            sx={{ marginBottom: "16px", maxWidth: "650px" }}
          >
            <Typography variant="body2">
              We encountered issues when authorizing your user. You may try to
              log out and log in again.
              <br />
              If the problem persists please contact support.
            </Typography>
            <Typography variant="caption" display={"block"} mt={3}>
              Error: {claimsError || JSON.stringify(fetchTenantsError.data)}
            </Typography>
          </Alert>
        ) : (
          <>
            <Typography variant="h4" component="h2" gutterBottom>
              Select client
            </Typography>
            {currentClient === null && (
              <Alert severity="info" sx={{ marginBottom: "16px" }}>
                Please set client to be your working space.
              </Alert>
            )}
            {state?.message && (
              <Alert severity="warning" sx={{ marginBottom: "16px" }}>
                {state.message}
              </Alert>
            )}
            <List
              component="nav"
              aria-label="main mailbox folders"
              sx={{
                minWidth: "320px",
                bgcolor: "background.paper",
                padding: "16px 0",
                marginBottom: "32px",
                overflowY: "auto",
              }}
            >
              {clients.map((client) => (
                <ListItemButton
                  key={client}
                  onClick={() => {
                    dispatch(networksApi.util.resetApiState());
                    dispatch(j2Api.util.resetApiState());
                    dispatchSetClientAndRedirect(client);
                  }}
                >
                  <ListItemText primary={client} sx={{ textAlign: "center" }} />
                </ListItemButton>
              ))}
            </List>
          </>
        )}
        <LogoutButton
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        />
      </Stack>
    );

  if (currentClient && !state?.reloadClients)
    return <Navigate to={redirectTo} replace />;

  return (
    <Splash>
      <p>Authorizing ...</p>
    </Splash>
  );
}
