import { ArticleOutlined } from "@mui/icons-material";
import {
  Stack,
  Typography,
  Card,
  CardActionArea,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useGetReportsDefinitions } from "../../hooks/useGetReportsDefinitions";
import { useNetworkContextAmplitudeEvent } from "../../hooks/useNetworkContextAmplitudeEvent";

export function ReportsIndexRoute() {
  const { reports, isFetching } = useGetReportsDefinitions();

  return (
    <Stack mt={3} height="100%">
      <Typography variant="h4" component="h1">
        Reports
      </Typography>

      <Stack direction="row" flexWrap="wrap" mt={4} gap={2}>
        {isFetching ? (
          <CircularProgress size={14} />
        ) : (
          reports?.map(({ id, name }) => (
            <ReportCard key={id} id={id} name={name} />
          ))
        )}
      </Stack>
    </Stack>
  );
}

function ReportCard({ id, name }) {
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  return (
    <Card
      role="article"
      sx={{ height: 150, width: 150, backgroundColor: "#253761" }}
    >
      <CardActionArea
        component={Link}
        to={String(id)}
        underline="hover"
        height="100%"
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
        onClick={() => trackNetworkContextAmplitudeEvent("View report", { reportType: name })}
      >
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
            textAlign: "center",
            color: "white",
          }}
        >
          <ArticleOutlined fontSize="large" />
          <Typography mt={1}>{name}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
}
