export const reportResultsGridColumnsBase = [
  { field: "match_type", headerName: "Match Type", width: 100 },
  { field: "npi", headerName: "NPI", width: 100 },
  { field: "tin", headerName: "TIN", width: 100 },
  { field: "provider_name", headerName: "Provider Name", width: 200 },
  { field: "address", headerName: "Address", width: 250 },
  { field: "address_2", headerName: "Address_2", width: 150 },
  { field: "city", headerName: "City", width: 100 },
  { field: "state", headerName: "State", width: 100 },
  { field: "zip_code", headerName: "Zip_Code", width: 100 },
  { field: "matched_provider_name", headerName: "Matched Provider Name", width: 200 },
];
