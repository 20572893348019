import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { PersistedFiltersToolbar } from "../persistedFilters/PersistedFiltersToolbar";
import { PersistedColumnsToolbar } from "../persistedColumns/PersistedColumnsToolbar";
import { useNetworkContextAmplitudeEvent } from "../../hooks/useNetworkContextAmplitudeEvent";

export function GridToolbarWithPersistModels({ syncModelsRef, page, ...rest }) {
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  return (
    <GridToolbarContainer sx={{ marginTop: "4px" }} {...rest}>
      <PersistedFiltersToolbar ref={syncModelsRef} />
      <PersistedColumnsToolbar ref={syncModelsRef} />
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        sx={{ marginLeft: "auto" }}
        onClick={() => trackNetworkContextAmplitudeEvent("Export data", { page })}
      />
    </GridToolbarContainer>
  );
}
