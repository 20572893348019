import { Alert, Stack, Link as MUILink } from "@mui/material";
import { useRouteError, Link } from "react-router-dom";

export function ReportError({ error }) {
  const routeError = useRouteError();
  const displayError = error ?? routeError;
  return (
    <Stack gap={2} mt={3}>
      <Alert severity="error">
        {displayError?.message ?? "An error occurred"}
      </Alert>
      <MUILink key={1} underline="hover" component={Link} to="/reports">
        Back to reports
      </MUILink>
    </Stack>
  );
}
