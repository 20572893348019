import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useRouteError,
} from "react-router-dom";
import "./App.css";
import { RequireAuth } from "./auth/RequireAuth";
import { AdequacyAppRoot } from "./components/AppRoot";

import { ProvidersRoute } from "./routes/ProvidersRoute";
import { ComingSoonRoute } from "./routes/ComingSoonRoute";
import { MapRoute } from "./routes/MapRoute";
import { ScorecardRoute } from "./routes/ScorecardRoute";
import { SelectClientRoute } from "./routes/SelectClientRoute";
import { ReportsIndexRoute } from "./routes/reports/ReportsIndexRoute";
import { ReportRoute } from "./routes/reports/ReportRoute";
import { ReportsRoot } from "./components/Reports/ReportsRoot";
import { UserGuideDownload } from "./routes/UserGuideDownload";
import { StandardRoot } from "./routes/selfServe/StandardRoot";
import { StandardsIndexRoute } from "./routes/selfServe/StandardsIndexRoute";
import { StandardGeneralRoute } from "./routes/selfServe/StandardGeneralRoute";
import { SelfServeRoot } from "./routes/selfServe/SelfServeRoot";
import { NetworksIndexRoute } from "./routes/selfServe/NetworksIndexRoute";
import { NetworksCreateRoute } from "./routes/selfServe/NetworksCreateRoute";
import { NetworkUpdateRoute } from "./routes/selfServe/NetworkUpdateRoute";
import { StandardsNewRoute } from "./routes/selfServe/StandardsNewRoute";
import { StandardSpecialtiesRoute } from "./routes/selfServe/StandardSpecialtiesRoute";
import { StandardRequirementsRoute } from "./routes/selfServe/StandardRequirementsRoute";
import { Typography } from "@mui/material";
import { RequireFeatureFlags } from "./components/launchDarklyIntegration/RequireFeatureFlags";
import { StandardSpecialtiesEditRoute } from "./routes/selfServe/StandardSpecialtiesEditRoute";
import { StandardRequirementsEditRoute } from "./routes/selfServe/StandardRequirementsEditRoute";
import { StandardScoreAttributesRoute } from "./routes/selfServe/StandardScoreAttributesRoute";
import { StandardScoreAttributesEditRoute } from "./routes/selfServe/StandardScoreAttributesEditRoute";
import { ReportError } from "./components/Reports/ReportError";
import { DisruptionAppRoot } from "./components/workspace-disruption/AppRoot";
import { workspaces } from "./features/workspaces/workspaces";
import { NetworksNewRoute } from "./routes/disruption/NetworksNewRoute";
import { NetworksIndexRoute as DisruptionNetworksIndexRoute } from "./routes/disruption/NetworksIndexRoute";
import { ReportsIndexRoute as DisruptionReportsIndexRoute } from "./routes/disruption/ReportsIndexRoute";
import { ReportsNewRoute } from "./routes/disruption/ReportsNewRoute";
import { ReportsResultsRoute } from "./routes/disruption/ReportsResultsRoute";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route path="/select-client" element={<SelectClientRoute />} />
      <Route path="/user-guide" element={<UserGuideDownload />} />
      <Route path={workspaces.default.path} element={<AdequacyAppRoot />}>
        <Route index element={<Navigate to="scorecard" replace />} />
        <Route path="market-dash" element={<ComingSoonRoute />} />
        <Route
          path="scorecard"
          element={<ScorecardRoute />}
          handle={{ title: "Scorecard" }}
        />
        <Route path="gap-stats" element={<ComingSoonRoute />} />
        <Route path="map" element={<MapRoute />} handle={{ title: "Map" }} />
        <Route path="reports" element={<ReportsRoot />}>
          <Route index element={<ReportsIndexRoute />} />
          <Route
            path=":reportId"
            element={<ReportRoute />}
            errorElement={<ReportError />}
          />
        </Route>
        <Route
          path="current-providers"
          element={<ProvidersRoute mode="DisplayCurrent" />}
          handle={{ title: "In Network" }}
        />
        <Route
          path="oon-providers"
          element={<ProvidersRoute mode="DisplayBuild" />}
          handle={{ title: "Out of Network" }}
        />
        <Route
          path="edits"
          element={<ProvidersRoute mode="DisplayEdits" />}
          handle={{ title: "Edits" }}
        />
        <Route path="self-serve" element={<SelfServeRoot />}>
          <Route index element={<Navigate to="networks" />} />
          <Route path="networks" handle={{ crumb: "Networks" }}>
            <Route index element={<NetworksIndexRoute />} />
            <Route
              path="new"
              element={<NetworksCreateRoute />}
              handle={{ crumb: "New network" }}
            />
            <Route
              path=":networkId/edit"
              element={<NetworkUpdateRoute />}
              handle={{ crumb: "Update network" }}
            />
          </Route>
          <Route path="standards" handle={{ crumb: "Standards" }}>
            <Route index element={<StandardsIndexRoute />} />
            <Route
              path="new"
              element={<StandardsNewRoute />}
              handle={{ crumb: "New standard" }}
            />
            <Route
              path=":standardId"
              element={<StandardRoot />}
              handle={{ crumb: ":standardId" }}
              errorElement={<div>Not found</div>}
            >
              <Route index element={<StandardGeneralRoute readOnly />} />
              <Route
                path="edit"
                element={<StandardGeneralRoute />}
                handle={{ crumb: "Edit" }}
              />

              <Route path="specialties" handle={{ crumb: "Specialties" }}>
                <Route index element={<StandardSpecialtiesRoute />} />
                <Route
                  path="edit"
                  element={<StandardSpecialtiesEditRoute />}
                  handle={{ crumb: "Edit" }}
                />
              </Route>

              <Route
                path="score-attributes"
                handle={{ crumb: "Score Attributes" }}
              >
                <Route index element={<StandardScoreAttributesRoute />} />
                <Route
                  path="edit"
                  element={<StandardScoreAttributesEditRoute />}
                  handle={{ crumb: "Edit" }}
                />
              </Route>

              <Route path="requirements" handle={{ crumb: "Requirements" }}>
                <Route index element={<StandardRequirementsRoute />} />
                <Route
                  path="edit"
                  element={<StandardRequirementsEditRoute />}
                  handle={{ crumb: "Edit" }}
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          path="*"
          element={<div className="full-container">404 Not Found</div>}
        />
      </Route>
      <Route
        path={workspaces.getById("disruption").path}
        element={<DisruptionAppRoot />}
      >
        <Route index element={<Navigate to="reports" replace />} />

        <Route path="reports" handle={{ crumb: "Reports" }}>
          <Route index element={<DisruptionReportsIndexRoute />} />
          <Route
            path="new"
            element={<ReportsNewRoute />}
            handle={{ crumb: "New Report" }}
          />
          <Route
            path=":reportId/edit"
            element={<ComingSoonRoute />}
            handle={{ crumb: "Edit report" }}
          />
          <Route
            path=":reportId/results"
            element={<ReportsResultsRoute />}
            handle={{ crumb: "View results" }}
          />
        </Route>
        <Route path="networks" handle={{ crumb: "Networks" }}>
          <Route index element={<DisruptionNetworksIndexRoute />} />
          <Route
            path="new"
            element={<NetworksNewRoute />}
            handle={{ crumb: "New network" }}
          />
        </Route>
        <Route
          path="*"
          element={<div className="full-container">404 Not Found</div>}
        />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  )
);

export function App() {
  return (
    <RequireAuth>
      <RequireFeatureFlags>
        <RouterProvider router={router} />
      </RequireFeatureFlags>
    </RequireAuth>
  );
}

function RootErrorBoundary() {
  const error = useRouteError();
  return (
    <div className="splash">
      <Typography variant="h4" component="h1">
        Something went wrong.
      </Typography>
      <Typography marginTop={3}>{error.message}</Typography>
    </div>
  );
}
