import React from 'react';
import {
    GridCsvExportMenuItem,
    GridExcelExportMenuItem,
    GridToolbarContainer,
    GridToolbarExportContainer,
  } from "@mui/x-data-grid-premium";

export function GridToolbarWithExport({ onClickEvent }) {
  const handleClick = () => {
    if (onClickEvent) {
      onClickEvent();
    }
  };

  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <GridToolbarExportContainer onClick={handleClick}>
        <GridCsvExportMenuItem />
        <GridExcelExportMenuItem />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}