import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import RotateLeft from "@mui/icons-material/RotateLeft";

import {
  selectStagedChanges,
  setToAdd,
  setToRemove,
} from "../../features/stagedChanges";
import { usePostNetworkChangesMutation } from "../../api/networksApi";
import { selectSelectedNetworkId } from "../../features/selectedNetwork";
import { selectSpecialtyFilterVal } from "../../features/providers";
import { Alert, Snackbar } from "@mui/material";
import { StagedChangesModal } from "../StagedChanges/StagedChangesModal";
import { useNetworkContextAmplitudeEvent } from "../../hooks/useNetworkContextAmplitudeEvent";

function ProviderCart() {
  const [showItems, setShowItems] = useState(false);
  const dispatch = useDispatch();
  const clearStage = useCallback(() => {
    dispatch(setToAdd());
    dispatch(setToRemove());
  }, [dispatch]);

  const handleOpen = (val) => setShowItems(val);
  const handleClose = () => setShowItems(false);

  const [openSnack, setOpenSnack] = useState(false);

  const handleOpenSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    setOpenSnack(false);
  };

  const networkId = useSelector(selectSelectedNetworkId);
  const specialtyId = useSelector(selectSpecialtyFilterVal);
  const { toAdd, toRemove } = useSelector(selectStagedChanges);

  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  const [applyNetworkChanges, applyNetworkChangesResult] =
    usePostNetworkChangesMutation();

  const applyChanges = useCallback(() => {
    applyNetworkChanges({
      networkId,
      specialtyId,
      toAdd,
      toRemove,
    })
      .unwrap()
      .then((r) => {
        console.log("Applying change", r);
        trackNetworkContextAmplitudeEvent("Submit Provider Update", {
          addedProviderCount: toAdd.length,
          removedProviderCount: toRemove.length,
        });
        clearStage();
      })
      .catch(handleOpenSnack);
  }, [
    applyNetworkChanges,
    clearStage,
    networkId,
    specialtyId,
    toAdd,
    toRemove,
    trackNetworkContextAmplitudeEvent,
  ]);

  const hasChanges = toAdd.length > 0 || toRemove.length > 0;
  return (
    <div className="provider-cart">
      {hasChanges ? (
        <>
          <div className="changes">
            {toAdd.length !== 0 && (
              <span
                className="positive-changes"
                onClick={() => handleOpen(0)}
              >{`+${toAdd.length}`}</span>
            )}
            {toAdd.length !== 0 && toRemove.length !== 0 && (
              <span>&nbsp;|&nbsp;</span>
            )}
            {toRemove.length !== 0 && (
              <span
                className="negative-changes"
                onClick={() => handleOpen(1)}
              >{`-${toRemove.length}`}</span>
            )}
            <span className="undo-icon" onClick={clearStage}>
              <RotateLeft color="primary" fontSize="small" />
            </span>
          </div>
          {(toAdd.length !== 0 || toRemove.length !== 0) && (
            <Button
              variant="text"
              onClick={applyChanges}
              size="small"
              id="provider-cart-apply-edits"
            >
              <strong>Apply</strong>
            </Button>
          )}
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="body1"
            style={{
              whiteSpace: "pre-wrap",
              alignSelf: "center",
            }}
          >
            <i>No changes</i>
          </Typography>
        </div>
      )}

      {showItems !== false && (
        <StagedChangesModal
          handleClose={handleClose}
          showItems={showItems}
          applyChanges={applyChanges}
        />
      )}

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          top: [49, null, null, null, null],
          right: [0, null, null, null, null],
        }}
      >
        <Alert onClose={handleCloseSnack} severity="error">
          {applyNetworkChangesResult?.error?.data?.message ??
            "Your changes has not been saved. Please try again with a smaller number of specialties affected."}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProviderCart;
