import { createSelector, createSlice } from "@reduxjs/toolkit";

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: { pathname: "" },
  reducers: {
    setPathname(state, { payload }) {
      state.pathname = payload;
    },
  },
});

export const { setPathname } = navigationSlice.actions;

export const selectPathname = (state) => state.navigation.pathname;

export const selectIsSpecialtyDependentPathname = createSelector(
  selectPathname,
  (pathname) => pathname === "/map"
);
