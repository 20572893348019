import {
  useGetAdequacyMetricsQuery,
  useGetCustomSpecialtiesQuery,
  useGetCustomStandardDesignationsQuery,
  useGetCustomStandardRequirementsQuery,
  useGetStandardMemberUniversesQuery,
  useGetCustomStandardDesignationTypesQuery,
} from "../../api/j2/j2Api";

export function useLoadRequirementsData(standard) {
  const metrics = useGetAdequacyMetricsQuery();
  const specialties = useGetCustomSpecialtiesQuery(standard.id);
  const designations = useGetCustomStandardDesignationsQuery(standard.id);
  const requirements = useGetCustomStandardRequirementsQuery(standard.id);
  const designationTypes = useGetCustomStandardDesignationTypesQuery(standard.id);
  const memberUniverses = useGetStandardMemberUniversesQuery(standard.id);

  const hasSpecialties = specialties.data?.length > 0;
  const hasDesignations = designations.data?.length > 0;
  const hasMetrics = standard.metrics?.length > 0;
  const hasDesignationTypes = designationTypes.data?.length > 0;
  const hasMemberUniverses = memberUniverses.data?.length > 0;
  const hasRequirementsDependencies =
    hasSpecialties && hasDesignations && hasMetrics && hasDesignationTypes && hasMemberUniverses;
  const isFetching =
    specialties.isFetching ||
    designations.isFetching ||
    requirements.isFetching;

  return {
    metrics,
    specialties,
    designations,
    requirements,
    memberUniverses,
    designationTypes,
    isFetching,
    hasRequirementsDependencies,
  };
}
