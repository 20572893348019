import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";

import { useCanEditSelfServeNetwork } from "../../hooks/useCanEditSelfServeNetwork";
import { useSelfServeAllowed } from "../../hooks/useSelfServeAllowed";
import { UpdateNetworkForm } from "../../components/SelfServe/UpdateNetworkForm";
import { selectNetworks } from "../../features/networks";
import { FileUploadUserActionDialog } from "../../components/SelfServe/FileUploadUserActionDialog";
import { useProcessSelfServeData } from "../../components/SelfServe/useProcessSelfServeData";
import { useWithFlagGetFormStandards } from "../../hooks/useWithFlagGetFormStandards";
import { useGetNetworkUpdateTasks } from "../../hooks/useGetUpdateNetworkTasks";

export function NetworkUpdateRoute() {
  const { user } = useAuth0();
  const { networkId } = useParams();
  const isSelfServeAllowed = useSelfServeAllowed();

  const { data: regulatoryStandards } = useWithFlagGetFormStandards();
  const { isFetching, isNetworkUpdateInProgress } = useGetNetworkUpdateTasks();

  const canEdit = useCanEditSelfServeNetwork();
  const networks = useSelector(selectNetworks);
  const network = networks.find(({ id }) => Number(networkId) === id);
  const canBeEdited = Boolean(network?.params); // only self-serve network can be updated
  const userCanEdit = canEdit(network?.params?.user_email);
  const hasUpdateInProgress = isNetworkUpdateInProgress(network);
  const actionEnabled = canBeEdited && userCanEdit && !hasUpdateInProgress;

  const {
    submitProcessing,
    proceedProcessing,
    fileUploadResult,
    fileProcessResult,
    processingPaused,
    canResumeProcessing,
    inProgress,
    isSuccess,
    isFetchError,
    errorMessage,
  } = useProcessSelfServeData();

  useEffect(() => {
    if (isFetchError) window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isFetchError, isSuccess]);

  const alertSx = { justifyContent: "center", marginTop: "8px" };

  if (!isSelfServeAllowed) return <Navigate to="/" />;

  return (
    <>
      <Stack>
        {isFetchError && (
          <Alert severity="error" sx={alertSx}>
            {errorMessage}
          </Alert>
        )}

        {isSuccess && (
          <Alert severity="success" sx={alertSx}>
            {fileProcessResult.data?.message || "File processing initiated!"}
          </Alert>
        )}
        {!actionEnabled && (
          <Stack gap={2}>
            <Alert severity="error" sx={alertSx}>
              {!canBeEdited
                ? "Only networks you have created are editable"
                : "There is ongoing update network task. Please wait until it is completed."}
            </Alert>
            <Button component={Link} to="..">
              Back to self serve networks home
            </Button>
          </Stack>
        )}

        {!fileProcessResult.data ? (
          <UpdateNetworkForm
            user={user}
            network={network}
            networkNames={networks.flatMap(({ name, id }) =>
              id !== network.id ? [name] : []
            )}
            regulatoryStandards={regulatoryStandards}
            onSubmit={submitProcessing}
            uploadResult={fileUploadResult}
            readOnly={!actionEnabled}
          />
        ) : (
          <Button component={Link} to=".." sx={{ marginTop: 2 }}>
            Back to self serve networks home
          </Button>
        )}
        {(inProgress || isFetching) && (
          <Backdrop open sx={{ backgroundColor: "transparent" }}>
            <CircularProgress color="primary" size={60} thickness={4} />
          </Backdrop>
        )}
      </Stack>
      <FileUploadUserActionDialog
        open={processingPaused}
        uploadResult={fileUploadResult}
        onCancel={fileUploadResult.reset}
        onProceed={proceedProcessing}
        canProceed={canResumeProcessing}
      />
    </>
  );
}
