import { useCallback, useState } from "react";
import {
  mergeChanges,
  addToState,
  deleteFromState,
} from "./useEditableGrid.utils";

export function useEditableGrid({ rows, updateState }) {
  const [changedRows, setChangedRows] = useState(new Map());
  const hasChanges = changedRows.size > 0;
  const currentRows = mergeChanges(rows, changedRows);
  const addRow = (row) => setChangedRows(addToState(row));
  const deleteRow = (rowId) => setChangedRows(deleteFromState(rowId));
  const updateRows = (originals, updatedRow, context) =>
    setChangedRows(updateState(originals, updatedRow, context));

  // only this may be used in useEffect
  // if you ever wanted to use other returned functions (but why) then wrap them with useCallback
  const resetChanges = useCallback(() => setChangedRows(new Map()), []);

  return {
    changedRows,
    hasChanges,
    currentRows,
    addRow,
    deleteRow,
    updateRows,
    resetChanges,
  };
}
