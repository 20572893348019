import { SCORES, SCORE_TYPE_LABELS } from "@j2nm/score-metric-keys";

export const standardsColumns = [
  {
    field: "specialtyName",
    headerName: "Specialty",
    width: 120,
  },
  { field: "county_name", headerName: "County Name", width: 120 },
  {
    field: "county_designation",
    headerName: "County Designation",
    width: 150,
  },
  {
    field: "drive_time_value",
    headerName: SCORE_TYPE_LABELS[SCORES.DRIVE_TIME],
    type: "string",
    valueGetter: ({ value }) => (value ? `${value} min` : ""),
    width: 150,
  },
  // {
  //   field: "drive_time_threshold",
  //   headerName: "Drive Time Threshold",
  //   valueGetter: ({ value }) => convertToPercent(value, true),
  //   width: 150,
  // },
  {
    field: "drive_distance_value",
    headerName: SCORE_TYPE_LABELS[SCORES.DRIVE_DISTANCE],
    valueGetter: ({ value }) => (value ? `${value} mi` : ""),
    width: 150,
  },
  // {
  //   field: "drive_distance_threshold",
  //   headerName: "Drive Distance Threshold",
  //   valueGetter: ({ value }) => convertToPercent(value, true),
  //   width: 150,
  // },
  {
    field: "minimum_count_value",
    type: "number",
    headerName: SCORE_TYPE_LABELS[SCORES.MINIMUM_COUNT],
    width: 150,
  },
  {
    field: "minimum_count_in_county_value",
    type: "number",
    headerName: SCORE_TYPE_LABELS[SCORES.MINIMUM_COUNT_IN_COUNTY],
    width: 150,
  },
  {
    field: "bed_count_value",
    headerName: "Bed Count",
    width: 150,
  },
];

export function getStandardsColumns(rows = []) {
  // aggregate all the possible row keys in one place
  const row = rows.reduce((agg, r) => {
    Object.assign(agg, r);
    return agg;
  }, {});

  return standardsColumns.filter(({ field }) => row[field] !== undefined);
}

export function getRowsSpecialtyName(data, specialtiesMap) {
  return data
    .map((s) => ({
      ...s,
      specialtyName:
        specialtiesMap[s.regulator_specialty_id] ||
        `no name for id ${s.regulator_specialty_id}`,
    }))
    .sort((a, b) =>
      a.specialtyName < b.specialtyName
        ? -1
        : a.specialtyName > b.specialtyName
        ? 1
        : 0
    );
}
