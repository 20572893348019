import { GridToolbarContainer } from "@mui/x-data-grid-premium";
import { ProviderExportToolbar } from "../ProviderExport/ProviderExportToolbar";
import { PersistedFiltersToolbar } from "../persistedFilters/PersistedFiltersToolbar";
import { PersistedColumnsToolbar } from "../persistedColumns/PersistedColumnsToolbar";

export function ProviderGridToolbar({ syncModelsRef, ...rest }) {
  return (
    <GridToolbarContainer sx={{ marginTop: "4px" }} {...rest}>
      <PersistedFiltersToolbar ref={syncModelsRef} />
      <PersistedColumnsToolbar ref={syncModelsRef} />
      <ProviderExportToolbar sx={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
}
