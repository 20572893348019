import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useGridApiRef, gridClasses } from "@mui/x-data-grid-premium";

import { Table } from "../Table";
import { selectSpecialties } from "../../features/specialties";
import { updateFilters } from "../../features/providers";
import {
  selectBaselineScorecard,
  selectNetworkScorecard,
  selectScorecardConfig,
} from "../../features/scorecard";
import { selectSelectedNetwork } from "../../features/networks";
import { useScorecardColumns } from "./scorecardColumns";
import {
  CustomGridColumnsPanel,
  ID_COLUMN_ALWAYS_HIDDEN,
} from "./CustomGridColumnsPanel";
import { CustomGridFilterPanel } from "./CustomGridFilterPanel";
import { CustomToolbar } from "./CustomToolbar";
import "./ScorecardTable.css";
import { ScorecardCellPopup } from "./ScorecardCellPopup";
import {
  Backdrop,
  Chip,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useGridScrollToGap } from "../../hooks/useGridScroolToGap";

const isNumberRx = /^\d*$/;

export function ScorecardTable({ loading }) {
  const {
    id: networkId,
    name: networkName,
    expansion_credits,
  } = useSelector(selectSelectedNetwork) ?? {};
  const { filters: filterModel, columns: visibleColumns = {} } = useSelector(
    (state) => selectScorecardConfig(state, networkId)
  );
  const specialties = useSelector(selectSpecialties);
  const scorecardRows = useSelector(selectNetworkScorecard);
  const { columns, columnNames } = useScorecardColumns();
  const baselineScorecard = useSelector(selectBaselineScorecard);
  const popupRef = useRef({});
  const apiRef = useGridApiRef();

  const dataLoaded = scorecardRows.length > 0 && baselineScorecard.length > 0;

  const scrollToNextGapCell = useGridScrollToGap(apiRef);

  useEffect(() => {
    // we sync the stored redux values with internal grid state
    apiRef.current.setFilterModel(filterModel, "restoreState");
    apiRef.current.setColumnVisibilityModel({
      ...visibleColumns,
      ...ID_COLUMN_ALWAYS_HIDDEN,
    });
  }, [apiRef, filterModel, networkId, visibleColumns, loading]);

  useEffect(() => {
    apiRef.current?.setState((state) => ({
      ...state,
      baselineScorecard,
      countyGaps: scorecardRows?.countyGaps ?? {},
    }));
  }, [apiRef, baselineScorecard, scorecardRows?.countyGaps]);

  return (
    <div className="tableOverrides">
      {expansion_credits && (
        <Stack direction={"row"} alignItems={"center"} gap={1} m={1}>
          <Chip
            color="success"
            label="EXP"
            sx={{ height: "24px", fontSize: "12px" }}
          />
          <Typography variant="overline">Expansion credits active</Typography>
        </Stack>
      )}
      <Table
        apiRef={apiRef}
        rows={[{ id: -1, name: "", gapCount: +Infinity }, ...scorecardRows]} // first row is county gap count sum
        initialState={{
          filter: { filterModel },
          columns: {
            columnVisibilityModel: {
              ...visibleColumns,
              ...ID_COLUMN_ALWAYS_HIDDEN,
            },
          },
        }}
        columns={columns}
        loading={loading && !dataLoaded} // show table spinner when loading for the first time
        pagination={false}
        headerHeight={100}
        disableColumnMenu
        rowHeight={64}
        hideFooter
        tableHeight={"calc(100vh - 48px)"}
        pinnedColumns={{ left: ["name", "gapCount"] }}
        disableRowSelectionOnClick
        onCellClick={(params, event) => {
          if (params.id === -1) return; // no popper when clicked on sum row
          const { setAnchorEl, setPopActions, setCell, cell } =
            popupRef.current;
          if (isNumberRx.test(params.field)) {
            setPopActions([
              updateFilters({
                field: "specialty_id",
                value: Number(params.row.id),
              }),
            ]);
            setAnchorEl(event.target.closest(".MuiDataGrid-cell"));
            let newCell = {
              id: `${params.field}:${params.id}`,
              row: params.row,
            };
            return setCell(newCell.id === cell?.id ? null : newCell);
          }
          if (params.field === "gapCount" && params.value > 0) {
            scrollToNextGapCell(params);
          }
          setCell(null);
        }}
        localeText={{ toolbarColumns: "Counties" }}
        components={{
          ColumnsPanel: CustomGridColumnsPanel,
          FilterPanel: CustomGridFilterPanel,
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          filterPanel: { specialties },
          columnsPanel: { columnNames },
          toolbar: { networkName },
        }}
        customSortModel={[{ field: "specialty", sort: "asc" }]}
        sx={{
          [`& .${gridClasses.columnHeaderTitleContainer}`]: {
            justifyContent: "space-evenly",
          },
        }}
      />
      <ScorecardCellPopup ref={popupRef} />
      {loading && dataLoaded && (
        // show overlay spinner when reloading after telehealth update
        <Backdrop className="loadingBackdrop" open>
          <CircularProgress color="primary" size={60} thickness={4} />
        </Backdrop>
      )}
    </div>
  );
}
