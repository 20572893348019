import { useGetNetworkFormStandardsQuery } from "../api/j2/j2Api";
import { useGetTaxonomiesQuery } from "../api/networksApi";
import { useCustomStandardsEnabled } from "./useCustomStandardsEnabled";

export function useWithFlagGetFormStandards(args, options = {}) {
  const skip = !!options.skip;
  const isCustomStandardsEnabled = useCustomStandardsEnabled();
  const newRequest = useGetNetworkFormStandardsQuery(args, {
    ...options,
    skip: skip || !isCustomStandardsEnabled,
  });
  const oldRequest = useGetTaxonomiesQuery(args, {
    ...options,
    skip: skip || isCustomStandardsEnabled,
  });

  return isCustomStandardsEnabled ? newRequest : oldRequest;
}
