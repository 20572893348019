import { CountiesCell, CountiesEditCell } from "./countiesCells";

export function makeColumns(readOnly) {
  return [
    { field: "state_name", headerName: "State" },
    { field: "designation_name", headerName: "Designation" },
    {
      field: "counties",
      headerName: "Counties",
      flex: 2,
      renderCell: CountiesCell,
      renderEditCell: CountiesEditCell,
      editable: !readOnly,
    },
  ];
}
