import { useOutletContext } from "react-router-dom";
import { StandardScoreAttributesForm } from "../../components/SelfServeCustomStandards/StandardScoreAttributesForm";
import { Stack, CircularProgress } from "@mui/material";
import { useGetAdequacyMetricsQuery } from "../../api/j2/j2Api";

export function StandardScoreAttributesRoute() {
  const { standard } = useOutletContext();
  const adequacyMetrics = useGetAdequacyMetricsQuery();

  if (adequacyMetrics.isFetching) return <CircularProgress size={14} />;

  return (
    <Stack gap={2} alignItems={"flex-start"}>
      <StandardScoreAttributesForm
        initValues={standard}
        adequacyMetrics={adequacyMetrics.data}
        readOnly
      />
    </Stack>
  );
}
