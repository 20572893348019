import {
  createSelector,
  createSlice,
  isAllOf,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { remove } from "lodash";

export const apiActivitySlice = createSlice({
  name: "apiActivity",
  initialState: { loading: [], updating: [], toProcess: 0 },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAllOf(isAnyOf(isMutation, isQuery), isPending),
        (state, action) => {
          state[apiActivityType(action)].push(makeActivityName(action));
          state.toProcess++;
        }
      )
      .addMatcher(
        isAnyOf(
          isAllOf(isAnyOf(isMutation, isQuery), isFulfilled),
          isAllOf(isQuery, isRejectedWithValue),
          isAllOf(isMutation, isRejected)
        ),
        (state, action) => {
          remove(
            state[apiActivityType(action)],
            (id) => id === makeActivityName(action)
          );
          if (state[apiActivityType(action)].length === 0) {
            state.toProcess = 0;
          }
        }
      );
  },
});

const optimisticUpdates = ["putProviderLocationTags"];
// selectors

export const selectApiActivity = (state) => state[apiActivitySlice.name];

// filtering optimistic updates that should run "in the UI background"
export const selectNonOptimisticApiActivity = createSelector(
  selectApiActivity,
  ({ loading, updating }) => ({
    loading,
    updating: updating.filter((name) => !optimisticUpdates.includes(name)),
  })
);

export const selectAllApiActivity = createSelector(
  selectNonOptimisticApiActivity,
  ({ loading, updating }) => loading.concat(updating)
);
export const selectIsApiBusy = createSelector(
  selectNonOptimisticApiActivity,
  ({ loading, updating }) =>
    loading.length > 0 ||
    (updating.length > 0 && !updating.find((name) => name.includes("Stage")))
);

// helpers

export function isMutation(action) {
  return action.type.indexOf("executeMutation") > -1;
}

export function isQuery(action) {
  return action.type.indexOf("executeQuery") > -1;
}

export function apiActivityType(action) {
  return isMutation(action) ? "updating" : "loading";
}

function makeActivityName(action) {
  // const act = apiActivityType(action);
  return (
    action.meta.arg.queryCacheKey ||
    `${action.meta.arg.endpointName}_${action.meta.requestId}`
  );
}
