import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import { Button, TableContainer, Alert, Stack } from "@mui/material";
import { UnsavedChangesWarning } from "../UnsavedChangesWarning";
import { CustomStandardReleaseButton } from "./standardsGrid/CustomStandardReleaseButton";
import { useEditableGrid } from "../../hooks/useEditableGrid";
import { updateRequirementsGridState as updateState } from "./requirements/updateRequirementsGridState";
import { validateGrid } from "./requirements/gridValidators";
import { clearCellErrors } from "./requirements/clearCellErrors";
import { useOutletContext } from "react-router-dom";

export function RequirementsGrid({
  rows,
  columns,
  loading,
  onSave,
  onStandardSubmit,
  readOnly,
}) {
  const { standard } = useOutletContext();
  const [validationErrors, setValidationErrors] = useState({});
  const { currentRows, hasChanges, updateRows, resetChanges } = useEditableGrid(
    { rows, updateState }
  );
  const [isEditing, setIsEditing] = useState(false);

  const apiRef = useGridApiRef();
  const hasValidationErrors = Object.keys(validationErrors).length > 0;

  const saveData = async () => {
    onSave(currentRows);
  };

  // reset changes when rows are loaded
  useEffect(() => {
    if (!loading) resetChanges();
  }, [loading, resetChanges]);

  useEffect(() => {
    // sync validation errors with grid state so the cell renderers can access them
    apiRef.current.setState((state) => ({ ...state, validationErrors }));
  }, [validationErrors, apiRef]);

  const publishStandard = async () => {
    await onSave(currentRows);
    const gridErrors = validateGrid({
      rows: currentRows,
      columns,
    });
    setValidationErrors(gridErrors);
    if (Object.keys(gridErrors).length === 0) {
      onStandardSubmit();
    }
  };

  return (
    <>
      {hasValidationErrors && (
        <Alert severity="error">
          There are errors preventing standard from being submitted. Please
          correct them before submission.
        </Alert>
      )}
      <TableContainer sx={{ height: "100%" }}>
        <DataGridPremium
          apiRef={apiRef}
          sx={{
            "--unstable_DataGrid-headWeight": "bold",
          }}
          density="compact"
          rows={currentRows}
          disableRowSelectionOnClick
          disableFooter
          columns={columns}
          loading={loading}
          processRowUpdate={(updatedRow, _before) => {
            updateRows(rows, updatedRow, {
              apiRef,
              onUpdate: (updatedRows, updatedColumn) => {
                const clearErrors = clearCellErrors(updatedRows, updatedColumn);
                setValidationErrors(clearErrors(validationErrors));
              },
            });

            // there seems to be a MUI bug of api.stopCellEditMode({ id, field })
            // which we call in client/src/components/SelfServeCustomStandards/requirements/metricEdit.jsx
            // not dispatching cellEditEnd event
            // so we need to manually set isEditing to false
            setIsEditing(false);
            return updatedRow;
          }}
          onCellEditStart={() => setIsEditing(true)}
          onCellEditStop={() => setIsEditing(false)}
        />
      </TableContainer>

      {!readOnly && (
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            onClick={saveData}
            disabled={!hasChanges || isEditing}
          >
            Save
          </Button>
          <CustomStandardReleaseButton
            standard={standard}
            onPublish={publishStandard}
          />
        </Stack>
      )}

      <UnsavedChangesWarning when={hasChanges} />
    </>
  );
}
