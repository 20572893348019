import { Check } from "@mui/icons-material";
import { Badge, Box, ButtonBase, Grid, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectServiceArea } from "../../features/networks";
import { selectNetworkScorecardMetrics } from "../../features/scorecard";
import {
  csvDelimiter,
  exportedColumns,
  getAdequateChange,
  getCurrentGapCount,
  getScoreComponents,
  shouldBeMarkedAsGap,
} from "./utils";
import { useUpdateTeleHealthCredit } from "../../hooks/useUpdateTeleHealthCredit";
import { SCORE_TYPE_LABELS } from "@j2nm/score-metric-keys";

function makeCountyColumnRenderer(columnName, metricKeys) {
  return () => {
    return (
      <Grid container className="county-header">
        <Grid item sm={12}>
          <Typography variant="h6" sx={{ lineHeight: 1 }}>
            {columnName}
          </Typography>
        </Grid>
        <Grid item className="attributes-header">
          <Grid container justifyContent="space-around">
            {metricKeys.map((metricKey) => {
              return (
                <div key={metricKey}>
                  <Grid item>{SCORE_TYPE_LABELS[metricKey]}</Grid>
                  <Grid item></Grid>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  };
}

function renderCountyCell({ id, field, value, api, row }) {
  const { baselineScorecard, countyGaps } = api.state;

  if (row?.id === -1) {
    const totalGaps = countyGaps[field];
    return (
      <div className="county-cell__inner">
        <Stack direction="column" justifyContent="space-around">
          <Box
            className="tableCellData"
            sx={{ "& > strong": { fontSize: "16px" } }}
          >
            <strong>{totalGaps}</strong>{" "}
            <Typography component={"span"} variant="body2">
              {totalGaps === 1 ? "gap" : "gaps"}
            </Typography>
          </Box>
        </Stack>
      </div>
    );
  }
  if (!value) return <div className="county-cell__inner" />;

  const { isAdequate, isAdequateAccess, metrics, conThresholdReduction } =
    getScoreComponents(value);

  const baselineValue = baselineScorecard.find((bs) => bs.id === id)?.[field];
  const { isAdequate: isBaselineAdequate } = getScoreComponents(baselineValue);

  if (metrics === undefined || isBaselineAdequate === undefined) return "";

  const gapChangeVal = getAdequateChange(isAdequate, isBaselineAdequate);

  return (
    <div
      className={clsx("county-cell__inner", {
        "has-gap": !isAdequate,
        "gap-up": gapChangeVal === 1,
        "gap-down": gapChangeVal === -1,
      })}
    >
      <Grid container justifyContent="space-around" alignItems="center">
        {metrics.map(
          ({ label, type, values: [value, isValueAdequate, threshold] }) => {
            const fail = shouldBeMarkedAsGap(
              type,
              isValueAdequate,
              isAdequateAccess
            );
            return (
              <Grid
                key={label}
                item
                className={clsx("tableCellData", { fail })}
              >
                <div className={clsx("dynamic-standard-wrapper", { fail })}>
                  <Typography variant="body1">{`${value ?? ""}${
                    threshold ? " / " + threshold : ""
                  }`}</Typography>
                </div>
              </Grid>
            );
          }
        )}
        {conThresholdReduction > 0 && (
          <Badge
            color="success"
            badgeContent="CON"
            sx={{
              position: "absolute",
              top: "10px",
              right: "20px",
              "> span": { fontSize: "8px", height: "14px" },
            }}
          />
        )}
      </Grid>
    </div>
  );
}

function ToggleTeleHealthCredit({ telehealthCredit, specialtyId }) {
  const updateTHC = useUpdateTeleHealthCredit({ specialtyId });
  return (
    <ButtonBase
      sx={{ position: "absolute", top: "10px", right: "20px" }}
      onClick={(e) => {
        e.stopPropagation();
        updateTHC(!!!telehealthCredit);
      }}
    >
      <Badge
        color={telehealthCredit ? "warning" : "default"}
        badgeContent="TEL"
        sx={{
          "> span": {
            fontSize: "8px",
            height: "14px",
            ...(telehealthCredit ? {} : { backgroundColor: "#ddd" }),
          },
        }}
      />
    </ButtonBase>
  );
}

function renderGapCountCell({ row, api }) {
  const gapCount = getCurrentGapCount(row, api.state.columns);

  if (row.id === -1) {
    return <div>Total</div>;
  }

  if (gapCount === -1) {
    return <Typography variant="body2">N/D</Typography>;
  }

  if (gapCount === 0) {
    return <Check className="gaps-cell--pass" />;
  }

  return (
    <>
      <div style={{ lineHeight: 1 }}>{gapCount}</div>
      <Typography variant="body2">{gapCount === 1 ? "gap" : "gaps"}</Typography>
    </>
  );
}

function makeCsvCountyHeader(countyName, metricKeys) {
  return exportedColumns(metricKeys)
    .map((label) => `${countyName}: ${label}`)
    .join(";");
}

function makeCsvCountyCell(params, metricKeys) {
  const { value, id, field, api } = params;
  if (!value)
    return exportedColumns(metricKeys)
      .map((_) => "")
      .join(csvDelimiter);
  const { isAdequate, metrics } = getScoreComponents(value);
  const { baselineScorecard, countyGaps } = api.state;
  const baselineValue = baselineScorecard.find((bs) => bs.id === id)?.[field];
  const { isAdequate: isBaselineAdequate } = getScoreComponents(baselineValue);

  const isGap = isAdequate ? "FALSE" : "TRUE";
  const countyGapCount = countyGaps[field];

  const gapChangeVal = getAdequateChange(isAdequate, isBaselineAdequate);
  const gapChange =
    gapChangeVal === 1
      ? "UP"
      : gapChangeVal === -1
      ? "DOWN"
      : gapChangeVal === 0
      ? "NO"
      : "";

  const values = metrics.map(({ values }) => values[0]);

  return [isGap, countyGapCount, gapChange, ...values].join(csvDelimiter);
}

function makeCsvGapCountCell({ id, api }) {
  const gapCount = getCurrentGapCount(api.getRow(id), api.state.columns);
  return gapCount === -1 ? "" : gapCount;
}

function makeCountyColumnConstructor(counties, columnNames, countyMetricKeys) {
  return (id) => {
    const metricKeys = countyMetricKeys[id];
    const metricLabelsCount = metricKeys?.length;
    const width = 280 + Math.max(0, metricLabelsCount - 2) * 120;

    const countyColumnName = columnNames[id];
    const countyCsvHeaderName = counties[id];
    return {
      field: id,
      filterable: false,
      sortable: false,
      hideSortIcons: true,
      renderHeader: makeCountyColumnRenderer(countyColumnName, metricKeys),
      renderCell: renderCountyCell,
      align: "center",
      width: width,
      minWidth: width,
      cellClassName: "county-cell",
      // for csv export
      headerName: makeCsvCountyHeader(countyCsvHeaderName, metricKeys),
      valueFormatter: (params) => makeCsvCountyCell(params, metricKeys),
    };
  };
}

export const scorecardStaticColumns = [
  {
    headerName: "ID",
    field: "id",
    sortable: false,
    filterable: true,
    hide: true,
    type: "number",
  },
  {
    headerName: "Specialty",
    headerClassName: "specialty-header",
    field: "name",
    sortable: true,
    filterable: false,
    headerAlign: "center",
    align: "center",
    cellClassName: "specialty-cell",
    sortingOrder: ["desc", "asc", null],
    renderCell: ({ id, value, row }) => {
      const score = Object.values(row).find((v) => typeof v === "object");
      const { telehealthCredit } = getScoreComponents(score);

      const teleHealthEligible = row.telehealth;

      return (
        <div className="MuiDataGrid-cellContent" title={value}>
          {value}
          {teleHealthEligible && (
            <ToggleTeleHealthCredit
              telehealthCredit={telehealthCredit}
              specialtyId={id}
            />
          )}
        </div>
      );
    },
    width: 300,
    minWidth: 300,
  },
  {
    width: 120,
    minWidth: 120,
    headerName: "Gaps",
    field: "gapCount",
    type: "number",
    sortable: true,
    filterable: true,
    headerAlign: "center",
    align: "center",
    renderCell: renderGapCountCell,
    headerClassName: "gaps-header",
    cellClassName: "gaps-cell",
    sortingOrder: ["desc", "asc", null],
    valueGetter: ({ id, api }) =>
      getCurrentGapCount(api.getRow(id), api.state.columns),
    // for csv export
    valueFormatter: makeCsvGapCountCell,
  },
];

export const useScorecardColumns = () => {
  const { counties, countiesStateCode: columnNames } =
    useSelector(selectServiceArea);
  const countyMetricKeys = useSelector(selectNetworkScorecardMetrics);

  const columns = useMemo(() => {
    if (!countyMetricKeys) return [];
    return scorecardStaticColumns.concat(
      Object.keys(counties).map(
        makeCountyColumnConstructor(counties, columnNames, countyMetricKeys)
      )
    );
  }, [columnNames, counties, countyMetricKeys]);

  return { columns, columnNames };
};
