import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { makePrepareHeaders } from "../fetcher";
import { standardsFetcherMock } from "./standardsFetcherMock";

const API_HOST = process.env.REACT_APP_SELF_SERVE_API_HOST;

const baseUrl = `${API_HOST}/api`;

// export const fetcher = fetchBaseQuery({
//   baseUrl,
//   prepareHeaders: makePrepareHeaders({ j2ClientHeaders: false }),
// });

export const fetcher = (args, options, extraOptions) => {
  if (args.url.startsWith("/standard-configs"))
    return standardsFetcherMock(args, options, extraOptions);
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: makePrepareHeaders({ j2ClientHeaders: false }),
  })(args, options, extraOptions);
};
