import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";

export function AppTopBar() {
  const { pathname } = useLocation();
  const areNetworksRoutes = pathname.startsWith("/disruption/networks");
  const areReportsRoutes = pathname.startsWith("/disruption/reports");
  return (
    <AppBar position="fixed">
      <Toolbar className="custom">
        {areNetworksRoutes && (
          <Button
            size="small"
            component={Link}
            to="/disruption/networks/new"
            variant="contained"
          >
            Create network
          </Button>
        )}
        {areReportsRoutes && (
          <Button
            size="small"
            component={Link}
            to="/disruption/reports/new"
            variant="contained"
          >
            Create report
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}
