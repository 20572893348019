import {
  useDeleteTelehealthCreditsMutation,
  usePostTelehealthCreditsMutation,
} from "../api/networksApi";

export function useUpdateTeleHealthCredit(payload) {
  const [triggerPostTHCredit] = usePostTelehealthCreditsMutation();
  const [triggerDeleteTHCredit] = useDeleteTelehealthCreditsMutation();
  return (hasTeleHealthCredit) => {
    if (hasTeleHealthCredit) return triggerPostTHCredit(payload);
    return triggerDeleteTHCredit(payload);
  };
}
