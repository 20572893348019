import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
} from "@mui/material";
import { LocalLibraryOutlined, MapOutlined } from "@mui/icons-material";
import { Standards } from "../DataPanel/Standards";

export const ScorecardCellPopup = forwardRef(function ScorecardCellPopup(
  _props,
  ref
) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [cell, setCell] = useState(null);
  const [popActions, setPopActions] = useState([]);
  const [showStandards, setShowStandards] = useState();
  const navigate = useNavigate();

  let specialtyId = cell?.row?.id;
  let countyId = cell?.id.split(":")[0] ?? null;

  useImperativeHandle(
    ref,
    () => ({ setAnchorEl, setPopActions, setCell, cell }),
    [cell]
  );

  const onPopItemClick = (route) => () => {
    popActions.forEach((action) => dispatch(action));
    setCell(null);
    navigate(route, {
      state: { zoomCounty: countyId },
    });
  };

  return (
    <>
      <Popper open={!!cell} anchorEl={anchorEl} disablePortal>
        <ClickAwayListener
          onClickAway={(event) => {
            if (anchorEl?.contains(event.target)) {
              return;
            }
            setCell(null);
          }}
        >
          <List className="cellPopper">
            <ListItemButton onClick={onPopItemClick("/map")}>
              <ListItemIcon>
                <MapOutlined />
              </ListItemIcon>
              <ListItemText primary="Map" />
            </ListItemButton>
            <ListItemButton onClick={() => setShowStandards(true)}>
              <ListItemIcon>
                <LocalLibraryOutlined />
              </ListItemIcon>
              <ListItemText primary="Standards" />
            </ListItemButton>
          </List>
        </ClickAwayListener>
      </Popper>
      <Standards
        show={showStandards}
        onClose={() => setShowStandards(false)}
        specialtyId={specialtyId}
      />
    </>
  );
});
