import React, { useEffect, useRef, useState } from "react";
import {
  IconButton,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSpecialtyFilterVal,
  updateFilters,
} from "../../features/providers";
import { selectSpecialties } from "../../features/specialties";
import clsx from "clsx";
import Check from "@mui/icons-material/Check";
import { selectNetworkScorecard } from "../../features/scorecard";
import { CloseOutlined } from "@mui/icons-material";

export function SpecialtySelector() {
  const specialties = useSelector(selectSpecialties);

  const specialtyId = useSelector(selectSpecialtyFilterVal);

  const hasProperSpecialtyId = (specialties || []).some(
    ({ id }) => specialtyId === id
  );

  const scoresBySpecialty = useSelector(selectNetworkScorecard);

  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!hasProperSpecialtyId && specialties?.length > 0) {
      dispatch(
        updateFilters({
          field: "specialty_id",
          value: specialties[0].id,
        })
      );
    }
  }, [dispatch, specialties, hasProperSpecialtyId]);

  const timer = useRef();

  useEffect(() => {
    const currTimer = timer.current;
    return () => clearTimeout(currTimer);
  }, []);

  return hasProperSpecialtyId ? (
    <div className="specialty-selector">
      <Select
        value={scoresBySpecialty.length ? specialtyId : ""}
        onChange={(e) =>
          setTimeout(() => {
            dispatch(
              updateFilters({
                field: "specialty_id",
                value: e.target.value,
              })
            );
          }, 0)
        }
      >
        <ListItem
          sx={{
            padding: "0 16px",
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 2,
          }}
          // onKeyDown={(e) => e.stopPropagation()}
        >
          <TextField
            id="outlined-basic"
            label="type to search"
            variant="outlined"
            size="small"
            margin="dense"
            fullWidth
            value={search}
            sx={{ marginTop: "2px", marginBottom: "8px" }}
            onChange={(e) => {
              setSearch(e.target.value.toLowerCase());
            }}
            onKeyDown={(e) => {
              if (e.key === "Escape") setSearch("");
              e.stopPropagation();
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setSearch("");
                    }}
                    edge="end"
                  >
                    <CloseOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </ListItem>
        {scoresBySpecialty.filter(({ name }) =>
          name.toLowerCase().includes(search)
        ).length === 0 && (
          <ListItem
            sx={{
              width: "500px",
            }}
          >
            <ListItemText>nothing found</ListItemText>
          </ListItem>
        )}
        {scoresBySpecialty.map(({ id, name, gapCount }) => {
          return (
            <MenuItem
              key={id}
              value={id}
              sx={{
                width: "500px",
                whiteSpace: "normal",
                display: name.toLowerCase().includes(search) ? "block" : "none",
              }}
            >
              {/* <Divider variant="middle" /> */}
              <ListItemButton
                selected={specialtyId === id}
                className={clsx("scoreListRow", {
                  fail: gapCount > 0,
                })}
                // onMouseEnter={() => {
                //   clearTimeout(timer.current);
                //   timer.current = setTimeout(() => {
                //     prefetchData({
                //       networkId,
                //       specialtyId: id,
                //     });
                //   }, 750);
                // }}
              >
                <ListItemIcon>
                  {gapCount === -1 ? (
                    <Typography variant="body2">N/D</Typography>
                  ) : gapCount === 0 ? (
                    <Check className="pass-gaps" />
                  ) : (
                    <span className="fail-gaps">{gapCount}</span>
                  )}
                </ListItemIcon>
                <ListItemText>{name}</ListItemText>
                {/* <Divider variant="middle" /> */}
              </ListItemButton>
            </MenuItem>
          );
        })}
      </Select>
    </div>
  ) : null;
}
