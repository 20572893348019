import { MenuItem, Select } from "@mui/material";

export function SpecialtyTypeEditCell(props) {
  const { id, value, field, api } = props;
  return (
    <Select
      value={value ? "facility" : "provider"}
      size="small"
      onChange={(e) => {
        api.setEditCellValue({
          id,
          field,
          value: e.target.value === "facility",
        });
      }}
      name="dropdown-edit-cell"
    >
      <MenuItem value={"provider"}>Provider</MenuItem>
      <MenuItem value={"facility"}>Facility</MenuItem>
    </Select>
  );
}
