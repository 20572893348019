const NEW_ROWID_PREFIX = "!";

// this is needed to MUI Grid work correclty when adding new rows to the grid,
// it requires unique ids
export function makeNewRowId() {
  return `${NEW_ROWID_PREFIX}${Date.now()}`;
}

export function isNewRowId(id) {
  return typeof id === "string" && id.startsWith(NEW_ROWID_PREFIX);
}

export function addToState(newRow) {
  function addReducer(state) {
    const newState = new Map(state);
    newState.set(newRow.id, newRow);
    return newState;
  }

  return addReducer;
}

export function deleteFromState(rowId) {
  function deleteReducer(state) {
    const newState = new Map(state);
    if (isNewRowId(rowId)) newState.delete(rowId);
    else newState.set(rowId, { ...state.get(rowId), __deleted: true });
    return newState;
  }

  return deleteReducer;
}

export function mergeChanges(originals, changes) {
  const changedEntries = Array.from(changes.entries());
  return (
    originals
      // update existing rows and remove deleted rows
      .flatMap((row) => {
        const updatedRow = changes.get(row.id);
        if (!updatedRow) return row;
        if (!updatedRow.__deleted) return [updatedRow];
        return [];
      })
      // add new rows
      .concat(
        changedEntries.flatMap(([id, value]) => (isNewRowId(id) ? [value] : []))
      )
  );
}
