import { isRejectedWithValue } from "@reduxjs/toolkit";
import { setCurrentClient } from "./currentClient";

export const fetchErrorHandler = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 403) {
      console.error("Fetch error:", action.payload);
      return next(setCurrentClient(null));
    }
  }

  return next(action);
};
