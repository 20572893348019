import { pipeline } from "./validations/pipeline.js";

export * from "./validations/validators.js";
export * from "./validations/checks.js";

export const validate = pipeline;

export const fieldValidator =
  (schema, values, initValues, onError) => (field) => {
    const input = { field, values, initValues };
    const result = schema[field]?.(input) ?? { ok: input };
    if (result.error && onError?.[field]) onError[field](result.error);
    return result;
  };

export const formValidator = (schema, validateField) => {
  return () => {
    const _isFormValid = Object.keys(schema).reduce((acc, key, i) => {
      const { error } = validateField(key);
      return acc && !error;
    }, true);

    return _isFormValid;
  };
};
