import { adequacyDetailsByZip } from "./adequacyDetailsByZip";
import { adequacyReport } from "./adequacyReport";
import { accessDetailsByCounty } from "./accessDetailsByCounty";
import { gapSummary } from "./gapSummary/gapSummary";
import { providerRatioReport } from "./providerRatio";

export const reports = [
  gapSummary,
  adequacyReport,
  accessDetailsByCounty,
  adequacyDetailsByZip,
  providerRatioReport,
];

export function getReports(ctx) {
  return reports.filter((report) =>
    report.isAvailableFn ? report.isAvailableFn(ctx) : true
  );
}
