import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Stack,
} from "@mui/material";

export function MatchGroupConditions({
  title,
  options,
  state,
  errorState,
  readOnly,
}) {
  const [value, setValue] = state;
  const [error, setError] = errorState;
  return (
    <FormControl
      required
      error={!!error}
      component="fieldset"
      variant="standard"
    >
      <FormLabel component="legend">{title}</FormLabel>
      <FormGroup>
        <Stack direction={"row"} gap={1}>
          {options.map(({ key, name }) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  size="small"
                  checked={value.includes(key)}
                  value={key}
                  onChange={({ target }) => {
                    const _key = target.value;
                    const newValue = target.checked
                      ? [...value, _key]
                      : value.filter((key) => key !== _key);
                    setValue(newValue);
                    setError(newValue);
                  }}
                  disabled={readOnly}
                />
              }
              label={name}
            />
          ))}
        </Stack>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormGroup>
    </FormControl>
  );
}
