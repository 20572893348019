import React from "react";
import {
  FormControlLabel,
  styled,
  switchClasses,
  unstable_composeClasses,
} from "@mui/material";
import {
  getDataGridUtilityClass,
  gridFilterModelSelector,
  GridPanelContent,
  GridPanelFooter,
  GridPanelWrapper,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import { CustomFilterButton } from "./CustomFilterButton";
import { FilterListOutlined } from "@mui/icons-material";

const useUtilityClasses = (ownerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ["columnsPanel"],
    columnsPanelRow: ["columnsPanelRow"],
  };

  return unstable_composeClasses(slots, getDataGridUtilityClass, classes);
};

const GridColumnsPanelRoot = styled("div", {
  name: "MuiDataGrid",
  slot: "ColumnsPanel",
  overridesResolver: (props, styles) => styles.columnsPanel,
})(() => ({
  padding: "8px 0px 8px 8px",
}));

const GridColumnsPanelRowRoot = styled("div", {
  name: "MuiDataGrid",
  slot: "ColumnsPanelRow",
  overridesResolver: (props, styles) => styles.columnsPanelRow,
})(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "1px 8px 1px 7px",
  [`& .${switchClasses.root}`]: {
    marginRight: theme.spacing(0.5),
  },
}));

const makeFilterItem = ({ id }) => ({
  id,
  field: "id",
  value: id,
  operator: "!=",
});

// adapted version of default FilterPanel

export function SpecialtyFilterPanel({ specialties, ...other }) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const ownerState = { classes: rootProps.classes };
  const classes = useUtilityClasses(ownerState);
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

  const toggleSpecialty = (event) => {
    const { name, checked } = event.target;
    const id = Number(name);
    const newItems = checked
      ? filterModel.items.filter(noUndefinedValue).filter((f) => f.id !== id)
      : filterModel.items
          .filter(noUndefinedValue)
          .concat([makeFilterItem({ id })]);
    apiRef.current.setFilterModel({
      items: newItems,
      logicOperator: "and",
    });
  };

  const toggleAllValues = React.useCallback(
    (isVisible) => {
      if (isVisible) {
        return apiRef.current.setFilterModel({
          items: filterModel.items.filter(({ field }) => field !== "id"),
          logicOperator: "and",
        });
      }
      return apiRef.current.setFilterModel({
        items: filterModel.items
          .filter((f) => notSpecialtyFilter(f) && noUndefinedValue(f))
          .concat(specialties.map(makeFilterItem)),
        logicOperator: "and",
      });
    },
    [apiRef, filterModel.items, specialties]
  );

  return (
    <GridPanelWrapper {...other}>
      <GridPanelContent>
        <GridColumnsPanelRoot className={classes.root}>
          {specialties.map((specialty) => (
            <GridColumnsPanelRowRoot
              className={classes.columnsPanelRow}
              key={specialty.id}
            >
              <FormControlLabel
                control={
                  <rootProps.slots.baseSwitch
                    checked={
                      filterModel.items.length === 0 ||
                      !filterModel.items.find(
                        ({ id }) => id === Number(specialty.id)
                      )
                    }
                    onClick={toggleSpecialty}
                    name={String(specialty.id)}
                    size="small"
                    {...rootProps.slotProps?.baseSwitch}
                  />
                }
                label={specialty.name}
              />
            </GridColumnsPanelRowRoot>
          ))}
        </GridColumnsPanelRoot>
      </GridPanelContent>
      <GridPanelFooter>
        <rootProps.slots.baseButton
          onClick={() => toggleAllValues(false)}
          {...rootProps.slotProps?.baseButton}
        >
          {apiRef.current.getLocaleText("columnsPanelHideAllButton")}
        </rootProps.slots.baseButton>
        <rootProps.slots.baseButton
          onClick={() => toggleAllValues(true)}
          {...rootProps.slotProps?.baseButton}
        >
          {apiRef.current.getLocaleText("columnsPanelShowAllButton")}
        </rootProps.slots.baseButton>
      </GridPanelFooter>
    </GridPanelWrapper>
  );
}

export function SpecialtyFilterButton() {
  return <CustomFilterButton columnId="id" icon={<FilterListOutlined />} />;
}

function noUndefinedValue(f) {
  return f.value !== undefined;
}

function notSpecialtyFilter(f) {
  return f.field !== "id";
}
