import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { StandardGeneralForm } from "../../components/SelfServeCustomStandards/StandardGeneralForm";
import { routeTabMapping } from "./StandardRoot";
import { useCustomStandardsEnabled } from "../../hooks/useCustomStandardsEnabled";
import {
  useGetCustomStandardDesignationTypesQuery,
  useGetMemberUniversesQuery,
} from "../../api/j2/j2Api";
import { useHandleStandardWithNestedObjects } from "../../hooks/useHandleStandardWithNestedObjects";

export function StandardsNewRoute() {
  const navigate = useNavigate();
  const memberUniverses = useGetMemberUniversesQuery();
  const designationTypes = useGetCustomStandardDesignationTypesQuery();

  const { submitChanges, showServerErrors, serverErrorInfo } =
    useHandleStandardWithNestedObjects({
      onSuccess: (standard) => {
        navigate(`../${standard.id}/specialties/edit`, {
          state: { justCreated: true },
        });
      },
    });

  if (!useCustomStandardsEnabled()) return <Navigate to="/self-serve" />;

  return (
    <Box sx={{ width: "100%", mt: 1 }}>
      <Typography variant="h4" component="h1">
        New standard
      </Typography>
      <Tabs
        value={0}
        sx={{ my: 2, borderBottom: 1, borderColor: "divider" }}
        color={"#ccc"}
      >
        {routeTabMapping.map(({ label, path }, index) => (
          <Tab
            key={path}
            label={label}
            to={path}
            component={Link}
            disabled={index > 0}
          />
        ))}
      </Tabs>
      {memberUniverses.isFetching || designationTypes.isFetching ? (
        <CircularProgress size={14} />
      ) : (
        <Stack gap={1}>
          {serverErrorInfo}
          <StandardGeneralForm
            memberUniversesOptions={memberUniverses.data}
            designationTypes={designationTypes.data}
            onSubmit={submitChanges}
            onFileValidationError={showServerErrors}
          />
        </Stack>
      )}
    </Box>
  );
}
