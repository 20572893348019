import { useOutletContext } from "react-router-dom";
import { RequirementsGrid } from "../../components/SelfServeCustomStandards/RequirementsGrid";
import { useTransformRequirementsData } from "../../hooks/standard-requirements/useTransformRequirementsData";
import { useLoadRequirementsData } from "../../hooks/standard-requirements/useLoadRequirementsData";

export function StandardRequirementsRoute() {
  const { standard } = useOutletContext();
  const requirementsDependencies = useLoadRequirementsData(standard);

  const { isFetching, requirements } = requirementsDependencies;

  const { readOnlyColumns, rows } = useTransformRequirementsData(
    standard,
    requirementsDependencies
  );

  return (
    <RequirementsGrid
      originalRows={requirements.data}
      rows={rows}
      columns={readOnlyColumns}
      loading={isFetching}
      readOnly
    />
  );
}
