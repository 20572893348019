import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import { UnsavedChangesWarning } from "../UnsavedChangesWarning";
import { Button, TableContainer } from "@mui/material";
import { NewSpecialtyForm } from "./NewSpecialtyForm";
import { makeColumns } from "./specialties/gridColumns";
import { useEditableGrid } from "../../hooks/useEditableGrid";
import { updateSpecialtiesGridState as updateState } from "./specialties/updateSpecialtiesGridState";

export function SpecialtiesGrid({
  rows,
  loading,
  readOnly,
  j2Specialties,
  onSubmit,
}) {
  // get handle to the grid object
  const apiRef = useGridApiRef();
  const {
    currentRows,
    hasChanges,
    addRow,
    deleteRow,
    updateRows,
    resetChanges,
  } = useEditableGrid({ rows, updateState });
  const [isEditing, setIsEditing] = useState(false);

  const columns = makeColumns(deleteRow, readOnly);

  // add j2 specialties lookup to the grid state so that the MappingEditCell can use it
  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.setState((state) => ({ ...state, j2Specialties }));
    }
  }, [apiRef, j2Specialties]);

  // reset changes when rows are loaded
  useEffect(() => {
    if (!loading) resetChanges();
  }, [loading, resetChanges]);

  return (
    <>
      <TableContainer
        sx={{ height: "100%" }}
        style= {{ display: "flex", flexDirection: "column" }}
      >
        {!readOnly && (
          <NewSpecialtyForm
            specialties={rows}
            j2Specialties={j2Specialties}
            onSubmit={(newSpecialty, onAdd) => {
              addRow(newSpecialty);
              onAdd();
            }}
          />
        )}
        <DataGridPremium
          columnVisibilityModel={{
            created_at: false,
          }}
          sortModel={[{ field: "created_at", sort: "desc" }]}
          sx={{
            width: "920px",
            "--unstable_DataGrid-headWeight": "bold",
            "& .MuiDataGrid-cell": { padding: "8px 10px" },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "rgba(25, 118, 210, 0.18)",
            },
          }}
          apiRef={apiRef}
          rows={currentRows}
          density="compact"
          getRowHeight={() => "auto"}
          disableColumnSelector
          disableRowGrouping
          disableAggregation
          processRowUpdate={(updated, _before) => {
            updateRows(rows, updated);
            return updated;
          }}
          onCellEditStart={() => setIsEditing(true)}
          onCellEditStop={() => setIsEditing(false)}
          onProcessRowUpdateError={(error) => console.log(error)}
          disableRowSelectionOnClick
          columns={columns}
          loading={loading}
        />
      </TableContainer>

      {!readOnly && (
        <Button
          variant="contained"
          onClick={() => {
            onSubmit(currentRows);
          }}
          disabled={!hasChanges || isEditing}
        >
          Save
        </Button>
      )}

      <UnsavedChangesWarning when={hasChanges} />
    </>
  );
}
