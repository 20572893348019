import { useNavigate, useOutletContext } from "react-router-dom";
import { Alert, Stack, Typography } from "@mui/material";
import { NewNetworkForm } from "../../components/workspace-disruption/networks/NewNetworkForm";
import { usePostDisruptionNetworksMutation } from "../../api/j2/j2Api";
import { AlertServerError } from "../../components/SelfServeCustomStandards/AlertServerError";
import { useAuth0 } from "@auth0/auth0-react";
import { AlertServerWarning } from "../../components/workspace-disruption/AlertServerWarning";

export function NetworksNewRoute() {
  const { user } = useAuth0();
  const { setAlert } = useOutletContext();
  const navigate = useNavigate();
  const [postCreateNetwork, createNetworkResult] =
    usePostDisruptionNetworksMutation();

  const onSubmit = async (payload) => {
    try {
      const networkResponse = await postCreateNetwork({ ...payload, user }).unwrap();
      const warnings = networkResponse.warnings;
      // set alert that will be displayed in target route
      setAlert(
        Object.keys(warnings).length === 0 ? (
          <Alert severity="success" onClose={() => setAlert(null)}>
            Your network is being processed
          </Alert>
        ) : (
          <AlertServerWarning warnings={warnings} onClose={() => setAlert(null)} />
        )
      );
      navigate("..");
    } catch (error) {
      // error rendering is handled in the same route by AlertServerError below
      console.log(error);
    }
  };
  return (
    <Stack gap={3}>
      <Typography component="h1" variant="h4">
        Create new network
      </Typography>
      {createNetworkResult.isError && (
        <AlertServerError
          serverError={createNetworkResult.error}
          onClose={() => createNetworkResult.reset()}
        />
      )}
      <NewNetworkForm onSubmit={onSubmit} />
    </Stack>
  );
}
