import { createSelector } from "@reduxjs/toolkit";
import { networksApi } from "../api/networksApi";
import { selectSelectedNetworkId } from "./selectedNetwork";

export const selectNetworks = createSelector(
  networksApi.endpoints.getNetworks.select(),
  ({ data }) => data
);

export const selectSelectedNetwork = createSelector(
  selectNetworks,
  selectSelectedNetworkId,
  (networks, selected) => (networks ?? []).find(({ id }) => id === selected)
);

export const selectStateAndNetwork = createSelector(
  (state) => state,
  selectSelectedNetwork,
  (state, network) => [state, network]
);

export const selectServiceArea = createSelector(
  selectSelectedNetwork,
  (network) => ({
    ...network,
    countiesStateCode: countiesWithStateCode(network.counties, network.states),
  })
);

function countiesWithStateCode(counties, states) {
  return Object.fromEntries(
    Object.entries(counties ?? {}).map(([id, name]) => {
      const state_code = (states ?? {})[id.substring(0, 2)]?.code ?? "";
      return [id, `${name}, ${state_code}`];
    })
  );
}
