import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import {
  fieldValidator,
  isPresent,
  validate,
  isEmptyString,
  formValidator,
} from "../../../utils/formValidate";
import { Link } from "react-router-dom";
import { MatchGroupConditions } from "./MatchGroupConditions";
import {
  initialMatchGroupErrorState,
  initialMatchGroupState,
  matchConditionsArePresent,
} from "./formUtils";

const schema = {
  name: validate([isPresent("cannot be empty", isEmptyString)]),
  sourceNetwork: validate([isPresent("cannot be empty")]),
  targetNetwork: validate([isPresent("cannot be empty")]),
  matchConditions: validate([matchConditionsArePresent("cannot be empty")]),
};

export function NewReportForm({
  readOnly,
  initValues,
  onSubmit,
  generateReport,
  matchGroups,
  networks,
}) {
  const [name, setName] = useState(initValues?.name ?? "");
  const [nameError, setNameError] = useState();

  const [sourceNetwork, setSourceNetwork] = useState(initValues?.source ?? "");
  const [sourceNetworkError, setSourceNetworkError] = useState();

  const [targetNetwork, setTargetNetwork] = useState(initValues?.target ?? "");
  const [targetNetworkError, setTargetNetworkError] = useState();

  const [matchConditions, setMatchConditions] = useState(
    initValues?.matchConditions ?? initialMatchGroupState(matchGroups)
  );
  const [matchConditionsError, setMatchConditionsError] = useState(
    initialMatchGroupErrorState(matchGroups)
  );

  const hasSomeValues =
    name &&
    sourceNetwork &&
    targetNetwork &&
    Object.values(matchConditions).every((v) => v.length > 0);

  const hasErrors =
    nameError ||
    sourceNetworkError ||
    targetNetworkError ||
    Object.values(setMatchConditionsError).some((v) => v);

  const isFormValid = hasSomeValues && !hasErrors;

  const validateField = fieldValidator(
    schema,
    {
      name,
      sourceNetwork,
      targetNetwork,
      matchConditions,
    },
    undefined,
    {
      name: setNameError,
      sourceNetwork: setSourceNetworkError,
      targetNetwork: setTargetNetworkError,
      matchConditions: setMatchConditionsError,
    }
  );

  const validateForm = formValidator(schema, validateField);

  const onGenerate = () => {
    if (!validateForm()) return;

    const payload = {
      name,
      source_network: sourceNetwork,
      target_network: targetNetwork,
      match_conditions: matchConditions,
    };
    generateReport(payload);
  };

  return (
    <Stack sx={{ textAlign: "left" }} gap={3} maxWidth={600}>
      <FormControl>
        <TextField
          id="report-name"
          variant="outlined"
          size="small"
          margin="dense"
          label="Display name"
          required
          value={name}
          disabled={readOnly}
          onChange={(e) => setName(e.target.value)}
          onFocus={() => setNameError()}
          onBlur={() => validateField("name")}
          error={!!nameError}
          helperText={nameError}
        />
      </FormControl>
      <FormControl size="small" error={!!sourceNetworkError} required>
        <InputLabel
          size="small"
          id="source-network-label"
          htmlFor="source-network"
        >
          Incumbent Network
        </InputLabel>
        <Select
          id="source-network"
          size="small"
          labelId="source-network-label"
          label="Incumbent Network"
          value={sourceNetwork}
          onChange={(e) => {
            setSourceNetwork(e.target.value);
          }}
          onFocus={() => setSourceNetworkError()}
          onBlur={() => validateField("sourceNetwork")}
          disabled={readOnly}
        >
          {networks.source.map(({ id, name }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
        {sourceNetworkError && (
          <FormHelperText>{sourceNetworkError}</FormHelperText>
        )}
        <FormHelperText error={false}>
          Network that will be replaced by the 'Replacement' network
        </FormHelperText>
      </FormControl>
      <FormControl size="small" error={!!targetNetworkError} required>
        <InputLabel
          size="small"
          id="target-network-label"
          htmlFor="target-network"
        >
          Replacement Network
        </InputLabel>
        <Select
          id="target-network"
          size="small"
          labelId="target-network-label"
          label="Replacement Network"
          value={targetNetwork}
          onChange={(e) => {
            setTargetNetwork(e.target.value);
          }}
          onFocus={() => setTargetNetworkError()}
          onBlur={() => validateField("targetNetwork")}
          disabled={readOnly}
        >
          {networks.target.map(({ id, name }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
        {targetNetworkError && (
          <FormHelperText>{targetNetworkError}</FormHelperText>
        )}
        <FormHelperText error={false}>
          Network that will be replacing the ‘Incumbent’ network
        </FormHelperText>
      </FormControl>
      {matchGroups.map((group) => {
        const { key, name, match_fields } = group;
        const value = matchConditions[key];
        const error = matchConditionsError[key];

        const setValue = (newValue) =>
          setMatchConditions((curr) => ({
            ...curr,
            [key]: newValue,
          }));

        const setError = (newValue) => {
          // get validator
          const validate = schema.matchConditions;

          // prepare values for validation; only update given group value
          const values = {
            matchConditions: { ...matchConditions, [key]: newValue },
          };

          // validate
          const result = validate({ values });

          // set/clear error only for given group
          setMatchConditionsError((curr) => ({
            ...curr,
            [key]: result.error?.[key],
          }));
        };

        return (
          <MatchGroupConditions
            key={key}
            title={name}
            options={match_fields}
            state={[value, setValue]}
            errorState={[error, setError]}
            readOnly={readOnly}
          />
        );
      })}

      {!readOnly && (
        <Stack direction="row" gap={2}>
          <Button variant="contained" disabled={!isFormValid} onClick={onGenerate}>
            Generate report
          </Button>
          <Button component={Link} to="..">
            Cancel
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
