import { Stack } from "@mui/system";
import { Outlet } from "react-router-dom";
import { J2Breadcrumbs } from "../../components/J2Breadcrumbs/J2Breadcrumbs";
import "../../components/SelfServe/SelfServe.css";
import { useState } from "react";

export function SelfServeRoot() {
  const [alert, setAlert] = useState();
  return (
    <Stack px={2} pt={0} pb={3} height="100%" className="self-serve">
      {alert && <Stack mt={2}>{alert}</Stack>}
      <J2Breadcrumbs />
      <Outlet context={{ setAlert }} />
    </Stack>
  );
}
