import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { colors, FormControl, MenuItem, Select } from "@mui/material";
import { workspaces } from "../../features/workspaces/workspaces";

export function WorkspaceSelector() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const initWorkspace = workspaces.getByPathname(pathname);
  const [workspaceId, setWorkspaceId] = useState(initWorkspace.id);

  return (
    <FormControl
      size="small"
      sx={{ width: "120px", margin: "auto", marginBottom: "4px" }}
    >
      <Select
        variant="outlined"
        value={workspaceId}
        onChange={(e) => {
          const id = e.target.value;
          setWorkspaceId(id);
          navigate(workspaces.getById(id).path);
        }}
        sx={{
          backgroundColor: colors.blue[700],
          color: "white",
          " > .MuiSelect-select": { paddingY: "8px" },
          "> svg": { color: "white" },
        }}
      >
        {workspaces.all.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
