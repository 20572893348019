import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useUnarchiveCustomStandardsMutation } from "../../../api/j2/j2Api";
import { actionsConfig } from "../../../hooks/useHandleStandardActions";

export function CustomStandardUnarchiveButton({ standard }) {
  const [open, setOpen] = useState(false);
  const [unarchiveStandard] = useUnarchiveCustomStandardsMutation({
    fixedCacheKey: actionsConfig.unarchiveCustomStandards.cacheKey,
  });

  return (
    <>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        Unarchive
      </Button>
      <Dialog open={open}>
        <DialogTitle>Unarchive {standard.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm to unarchive your standard. This will reactivate your
            published standard.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              setOpen(false);
              unarchiveStandard({ standard });
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
