import { useNavigate, useOutletContext } from "react-router-dom";
import { Alert, CircularProgress, Stack, Typography } from "@mui/material";
import { AlertServerError } from "../../components/SelfServeCustomStandards/AlertServerError";
import { useAuth0 } from "@auth0/auth0-react";
import { NewReportForm } from "../../components/workspace-disruption/reports/NewReportForm";
import {
  useGetDisruptionNetworksQuery,
  useGetMatchGroupsQuery,
  usePostDisruptionReportsMutation,
  usePostDisruptionReportsGenerateMutation,
} from "../../api/j2/j2Api";

export function ReportsNewRoute() {
  const { user } = useAuth0();
  const { setAlert } = useOutletContext();
  const navigate = useNavigate();

  const matchGroupsRequest = useGetMatchGroupsQuery();
  const networksRequest = useGetDisruptionNetworksQuery(undefined, {
    selectFromResult: (result) => {
      const data = result.data?.reduce(
        (acc, network) => {
          if (network.type === "source") acc.source.push(network);
          else acc.target.push(network);
          return acc;
        },
        { source: [], target: [] }
      );
      return { ...result, data };
    },
  });
  const fetchingData =
    matchGroupsRequest.isFetching || networksRequest.isFetching;

  const [postCreateReport, createReportResult] =
    usePostDisruptionReportsMutation();
  const [postGenerateReport, generateReportResult] =
    usePostDisruptionReportsGenerateMutation();

  const onSubmit = async (payload) => {
    try {
      await postCreateReport({
        ...payload,
        created_by_email: user.email,
      }).unwrap();
      // set alert that will be displayed in target route
      setAlert(
        <Alert severity="success" onClose={() => setAlert(null)}>
          Your report has been created.
        </Alert>
      );
      navigate("..");
    } catch (error) {
      // error rendering is handled in the same route by AlertServerError below
      console.log(error);
    }
  };

  const generateReport = async (payload) => {
    try {
      const res = await postGenerateReport({
        ...payload,
        created_by_email: user.email,
      }).unwrap();
      
      navigate(`../${res.id}/results`);
    } catch (error) {
      // error rendering is handled in the same route by AlertServerError below
      console.log(error);
    }
  };

  return (
    <Stack gap={3}>
      <Typography component="h1" variant="h4">
        Create new report
      </Typography>
      {createReportResult.isError && (
        <AlertServerError
          serverError={createReportResult.error}
          onClose={() => createReportResult.reset()}
        />
      )}
      {generateReportResult.isError && (
        <AlertServerError
          serverError={generateReportResult.error} // TODO make this generic for the user
          onClose={() => generateReportResult.reset()}
        />
      )}
      {fetchingData ? (
        <CircularProgress size={14} />
      ) : (
        <NewReportForm
          onSubmit={onSubmit}
          generateReport={generateReport}
          matchGroups={matchGroupsRequest.data}
          networks={networksRequest.data}
        />
      )}
    </Stack>
  );
}
