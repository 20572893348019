import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";

export function ReportsRoot() {
  return (
    <Stack padding={2} paddingTop={0} height="100%">
      <Outlet />
    </Stack>
  );
}
