import { getGridStringOperators } from "@mui/x-data-grid-premium";
import { LABEL_FIELD, makeEditLabelCell } from "../../components/labelEdit";
import { makeAdequacyColumn } from "../../components/AdequacyGridColumn/adequacyColumn";
import { makeBedsColumn } from "../../components/BedsGridColumn/bedsColumn";
import { makeMemberServedColumn } from "../../components/MemberServedGridColumn/memberServedColumn";

const stringOperators = getGridStringOperators();

export const virtualFieldHandlers = {
  [LABEL_FIELD]: makeEditLabelCell,
  adequacy_per_county: makeAdequacyColumn,
  specialties: ({ label, field }, { specialties }) => ({
    headerName: label,
    field,
    width: 250,
    valueGetter: ({ row }) => {
      return (row.specialty_ids ?? []).map(
        (id) => `${specialties[id] ?? "unknown specialty"}`
      );
    },
    sortComparator: (v1, v2) => {
      let a = (v1 ?? []).join("");
      let b = (v2 ?? []).join("");
      return a < b ? -1 : a > b ? 1 : 0;
    },
    valueFormatter: ({ value }) => value.join("; "),
    filterOperators: [
      stringOperators[0],
      {
        value: "not contains",
        label: "not contains",
        InputComponent: stringOperators[0].InputComponent,
        InputComponentProps: { type: "string " },

        getApplyFilterFn: (filter) =>
          filter.value
            ? (row) => {
                return (row.value || [""]).every((s) => {
                  return (
                    s.toLowerCase().indexOf(filter.value.toLowerCase()) === -1
                  );
                });
              }
            : null,
      },
      ...stringOperators.slice(1),
    ],
  }),

  beds: makeBedsColumn,
  member_served: makeMemberServedColumn,

  baseline: ({ label, field }) => ({
    headerName: label,
    field,
    valueGetter: ({ row }) => (row.baseline ? "INN" : "OON"),
  }),
  current: ({ label, field }) => ({
    headerName: label,
    field,
    valueGetter: networkStatusColumn.valueGetter,
  }),
};

export const networkStatusColumn = {
  field: "is_inn",
  headerName: "Status",
  valueGetter: ({ row }) => (row.is_inn ? "INN" : "OON"),
};

export const oigSanctionedColumn = {
  field: "oig_sanctioned",
  headerName: "Active",
  type: "boolean",
  filterable: true,
  sortable: true,

  // required for exporting, so the default formatter is not run with undefined when the field is not sent by the server
  valueFormatter: ({ value }) => (value ? "Yes" : "No"),
  valueGetter: ({ row }) => row.oig_sanctioned !== "true",
};
