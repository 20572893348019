import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import ErrorOutlineOutlined from "@mui/icons-material/ErrorOutlineOutlined";
import SyncAltOutlined from "@mui/icons-material/SyncAltOutlined";
import { CircularProgress } from "@mui/material";

export function GapsChanges({ gaps, changes, show, onChangesClick }) {
  return (
    <div className="gaps-changes">
      {show ? (
        <>
          <Typography variant="body2">
            <strong>{gaps > -1 ? gaps : "n/d"}</strong>
          </Typography>
          <Tooltip title="gap count">
            <ErrorOutlineOutlined color="currentColor" />
          </Tooltip>

          <Typography variant="body2">
            <strong>{gaps > -1 ? changes : "n/d"}</strong>
          </Typography>
          <Tooltip title="changes to the baseline">
            {onChangesClick ? (
              <IconButton size="small" onClick={onChangesClick}>
                <SyncAltOutlined />
              </IconButton>
            ) : (
              <SyncAltOutlined />
            )}
          </Tooltip>
        </>
      ) : (
        <CircularProgress color="inherit" size={20} />
      )}
    </div>
  );
}
