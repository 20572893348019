import { useEffect, useState } from "react";
import { useGetDownloadUserGuideMutation } from "../api/networksApi";
import { Splash } from "../components/Splash";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";

export function UserGuideDownload() {
  // use mutation to not cache anything
  const [openPDFInTab] = useGetDownloadUserGuideMutation();
  const [started, setStarted] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setStarted(true);
    if (!started)
      openPDFInTab().then(({ data, error }) => {
        if (error) return setError(true);
        const fileURL = URL.createObjectURL(data);
        window.location.replace(fileURL + "#toolbar=0");
      });
  }, [openPDFInTab, started]);

  if (error)
    return (
      <div className="splash">
        <Typography>Something went wrong. Please try again later.</Typography>
        <br />
        <Button variant="contained" component={Link} to="/">
          Back to application
        </Button>
      </div>
    );
  return <Splash>Loading User Guide ...</Splash>;
}
