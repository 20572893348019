import { GridToolbarExportContainer } from "@mui/x-data-grid-premium";
import { ProviderExportToFileMenuItem } from "../ProviderTable/ProviderExportToFileMenuItem";
import { serializeToCsv, serializeToExcel } from "../ProviderTable/serializers";

export function ProviderExportToolbar({ sx, onExportError }) {
  return (
    <GridToolbarExportContainer sx={sx}>
      <ProviderExportToFileMenuItem
        options={{
          delimiter: ",",
          mimeType: "text/csv",
          fileExtension: "csv",
          fileName: "export",
        }}
        serializer={serializeToCsv}
        onExportError={onExportError}
      >
        Export to Csv
      </ProviderExportToFileMenuItem>
      <ProviderExportToFileMenuItem
        options={{
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          fileExtension: "xlsx",
          fileName: "export",
        }}
        serializer={serializeToExcel}
        onExportError={onExportError}
      >
        Export to Excel
      </ProviderExportToFileMenuItem>
    </GridToolbarExportContainer>
  );
}
