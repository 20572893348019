import { Alert } from "@mui/material";
import { AlertServerError } from "./AlertServerError";

export function ServerResponseInfo({ successMsg, errorMsg }) {
  if (successMsg)
    return (
      <Alert severity="success" sx={{ my: 2 }}>
        {successMsg ?? "Action successfully completed"}
      </Alert>
    );

  if (errorMsg)
    return <AlertServerError serverError={errorMsg} sx={{ my: 2 }} />;

  return null;
}
