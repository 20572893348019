import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { selectSelectedNetworkId } from "../features/selectedNetwork";

export function useExcludeHSDOutpatientBehavioralHealth(rows, nameKey) {
  const network = useSelector(selectSelectedNetworkId);
  const showSpecialty =
    useFlags()["j2nm-2125-hsd-outpatient-behavioral-health-specialty"];

  return network.taxonomy_id === "HSD" && !showSpecialty
    ? rows.filter((row) => row[nameKey] !== "OUTPATIENT BEHAVIORAL HEALTH")
    : rows;
}
