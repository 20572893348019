import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemText,
} from "@mui/material";
import { red } from "@mui/material/colors";

export function DialogServerError({
  open,
  serverError,
  onDialogClose,
  onCloseClick,
}) {
  return (
    <Dialog open={open} onClose={onDialogClose}>
      <DialogTitle>We found issues when processing your file</DialogTitle>
      <DialogContent>
        <List sx={{ backgroundColor: red[50], color: red[900], paddingX: 2 }}>
          {Object.entries(serverError.data ?? {}).map(([key, error]) => {
            return (
              <ListItemText
                key={key}
                primary={`${key}: ${
                  Array.isArray(error) ? error.join(", ") : error
                }`}
                sx={{ "> span": { fontSize: 12 } }}
              />
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseClick}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
