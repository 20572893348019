import { Stack, Typography } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";

import { useGetDisruptionReportsQuery } from "../../api/j2/j2Api";
import { reportsGridColumns } from "../../components/workspace-disruption/reports/reportsGridColumns";

export function ReportsIndexRoute() {
    const {
        data: disruptionReports = [],
        isFetching,
    } = useGetDisruptionReportsQuery();

    const reportsRows = disruptionReports.map((report) => {
        return {
            ...report,
            status: report?.status
        };
    });
  
    return (
        <Stack mt={1} width={"100%"}>
          <Typography variant="h4">Disruption Reports</Typography>
          <Stack mt={2}>
            <DataGridPremium
              rows={reportsRows}
              autoHeight
              hideFooter
              disableRowSelectionOnClick
              columns={reportsGridColumns}
              loading={isFetching}
            />
          </Stack>
        </Stack>
    );
  }