import { oneDecimalFormatter } from "../utils";

export const adequacyDetailsByZip = {
  id: "adequacy-by-zip",
  name: "Access Details by Zip",
  columns: [
    { field: "county_id", headerName: "County" },

    { field: "county_name", headerName: "County Name" },

    { field: "zipcode", headerName: "Zip" },

    { field: "specialty_name", headerName: "Specialty", width: 250 },

    {
      field: "overal_score_perc",
      headerName: "Access Score (%)",
      type: "number",
      width: 150,
    },
    {
      field: "total_count",
      headerName: "Total Members (#)",
      type: "number",
      width: 150,
    },
    {
      field: "passing_count",
      headerName: "Members with Access (#)",
      type: "number",
      width: 200,
    },
    {
      field: "not_passing_count",
      headerName: "Members without Access (#)",
      type: "number",
      width: 200,
    },
    {
      field: "max_time",
      headerName: "Maximum Time",
      type: "number",
      valueFormatter: oneDecimalFormatter,
    },
    {
      field: "max_distance",
      headerName: "Maximum Distance",
      type: "number",
      valueFormatter: oneDecimalFormatter,
    },
  ],
};
