import {
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { ColumnsButton } from "./ColumnsButton";
import { ExportScorecardAsExcelButton } from "./ExportScorecardAsExcelButton";
import { GapCountFilterButton } from "./GapCountFilterPanel";
import { SpecialtyFilterButton } from "./SpecialtyFilterPanel";
import { StandardsButton } from "./StandardsButton";
import { csvDelimiter, exportFileName } from "./utils";

export function CustomToolbar({ networkName }) {
  const apiRef = useGridApiContext();
  return (
    <GridToolbarContainer
      style={{ justifyContent: "flex-start", marginTop: "4px" }}
    >
      <SpecialtyFilterButton />
      <GapCountFilterButton />
      <ColumnsButton />
      <StandardsButton />
      <ExportScorecardAsExcelButton
        fileName={exportFileName(networkName)}
        getScorecardDataAsCsv={() =>
          apiRef.current
            .getDataAsCsv({ delimiter: csvDelimiter })
            .replace(/"/g, "")
        }
      />
    </GridToolbarContainer>
  );
}
