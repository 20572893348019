import { symmetricDiffArr } from "../../../utils";

export function updateDesignationsGridState(originals, updatedRow) {
  function updateReducer(state) {
    const original = originals.find((s) => s.id === updatedRow.id) ?? {};
    const hasChangesToOriginal =
      ["state_id", "designation_id"].some(
        (prop) => updatedRow[prop] !== original[prop]
      ) || symmetricDiffArr(updatedRow.counties, original.counties).length > 0;

    const newState = new Map(state);

    if (hasChangesToOriginal) newState.set(updatedRow.id, updatedRow);
    else newState.delete(original.id);

    return newState;
  }

  return updateReducer;
}
