import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { isPresent, validate } from "../../../utils/formValidate";
import { CustomDesignationsGrid } from "./CustomDesignationsGrid";
import { makeNewRowId } from "../../../hooks/useEditableGrid.utils";
import { aggregateByDesignations, makeGridRows } from "./gridUtils";

const emptyArrayCheck = ({ field, values }) => values[field].length > 0;

const schema = {
  states: validate([isPresent("cannot be empty", emptyArrayCheck)]),
  designations: validate([isPresent("cannot be empty", emptyArrayCheck)]),
};

export function CustomDesignationsConfigForm({
  initValues,
  statesOptions,
  statesWithCounties,
  readOnly,
  onSubmit,
}) {
  const [states, setStates] = useState(
    initValues?.us_states
      ? statesOptions?.filter(([id]) => initValues?.us_states.includes(id))
      : []
  );
  const [statesError, setStatesError] = useState();

  const [designations, setDesignations] = useState(
    initValues?.designations ?? []
  );
  const rows = useMemo(() => {
    return makeGridRows(states, designations, initValues?.designations ?? []);
  }, [states, designations, initValues?.designations]);

  const gridRef = useRef();

  return (
    <Stack gap={2} maxWidth={1200}>
      <Typography variant="overline" marginBottom={-2}>
        Add county type
      </Typography>
      <FormControl component="fieldset">
        <Autocomplete
          id="state-select"
          size="small"
          multiple
          disableCloseOnSelect
          fullWidth
          options={statesOptions}
          isOptionEqualToValue={(option, value) => option[1] === value[1]}
          value={states}
          disabled={readOnly}
          getOptionLabel={(option) => option[1]}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {option[1]}
            </li>
          )}
          onChange={(_, value) => {
            setStates(value);
            setStatesError(
              schema.states({ field: "states", values: { states: value } })
                .error
            );
          }}
          renderInput={(params) => (
            <Stack direction="row" spacing={1}>
              <TextField
                {...params}
                margin="dense"
                label="Applicable state(s)"
                required
                disabled={statesOptions === undefined}
                onFocus={() => setStatesError()}
                error={!!statesError}
                helperText={statesError}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable browser autocomplete and autofill
                }}
              />
              {!readOnly && (
                <Button
                  size="small"
                  sx={{
                    height: "fit-content",
                    width: "92px",
                    marginTop: "14px !important",
                    flexShrink: 0,
                  }}
                  onClick={() => {
                    setStates(states.length ? [] : statesOptions);
                  }}
                >
                  {states.length ? "Clear all" : "Select all"}
                </Button>
              )}
            </Stack>
          )}
        />
      </FormControl>
      <FormControl component="fieldset">
        <Autocomplete
          id="desingation-select"
          size="small"
          multiple
          disableCloseOnSelect
          fullWidth
          options={[]}
          value={designations}
          disabled={readOnly}
          filterOptions={(options, params) => {
            const { inputValue } = params;
            if (inputValue === "") return [];
            if (designations.some((d) => d.name === inputValue)) return [];

            return [
              {
                inputValue,
                label: `Add "${inputValue}"`,
              },
            ];
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name;
          }}
          freeSolo
          renderOption={(props, option) => {
            return <li {...props}>{option.label}</li>;
          }}
          onChange={(_, value) => {
            setDesignations((ds) => {
              return value.map((v) => {
                let s = typeof v === "string" ? v : v.name ?? v.inputValue;
                return (
                  ds.find((d) => d.name === s) ?? {
                    id: makeNewRowId(),
                    name: s,
                  }
                );
              });
            });
          }}
          renderInput={(params) => (
            <Stack direction="row" spacing={1}>
              <TextField
                {...params}
                margin="dense"
                label="County type designations"
                required
                disabled={statesOptions === undefined}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable browser autocomplete and autofill
                }}
              />
              {!readOnly && (
                <Button
                  size="small"
                  sx={{
                    height: "fit-content",
                    width: "92px",
                    marginTop: "14px !important",
                    flexShrink: 0,
                  }}
                  onClick={() => {
                    setDesignations([]);
                  }}
                >
                  Clear all
                </Button>
              )}
            </Stack>
          )}
        />
      </FormControl>

      <FormControl component="fieldset">
        <FormLabel component="legend">County assignments</FormLabel>
        <CustomDesignationsGrid
          ref={gridRef}
          rows={rows}
          statesWithCounties={statesWithCounties}
          readOnly={readOnly}
        />
      </FormControl>
      {!readOnly && (
        <Stack direction="row" gap={2} sx={{ marginTop: "24px" }}>
          <Button
            variant="contained"
            onClick={() => {
              const designationsCounties = aggregateByDesignations(
                gridRef.current.currentRows
              );
              onSubmit({
                states: states.map(([state_id]) => state_id),
                designations: designations.map(
                  ({ counties_by_state, ...d }) => ({
                    ...d,
                    counties: designationsCounties[d.id],
                  })
                ),
              });
            }}
          >
            Save
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
