import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { PersistedItemsList } from "./PersistedItemsList";
import { PersistItemForm } from "./PersistItemForm";

export function NotPersistedtem({
  state,
  api,
  items = state.persistedItems,
  context,
}) {
  const [open, setOpen] = useState(false);
  const { notPersistedValue } = state;
  const {
    setPersistedItems,
    setNotPersistedValue,
    storage,
    renderItem,
    labels,
  } = api;
  return (
    <>
      <Button
        className="action-save"
        variant="contained"
        size="small"
        color="success"
        startIcon={<Save />}
        onClick={() => setOpen(true)}
      >
        Save {labels[1]}
      </Button>
      <Dialog fullWidth onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Save {labels[1]}</DialogTitle>
        <DialogContent>
          <PersistItemForm
            onSave={(key) => {
              setOpen(false);
              const savedItems = storage.setItem(key, notPersistedValue);
              setPersistedItems(savedItems);
              setNotPersistedValue(null);
            }}
          />
          <DialogContentText marginTop="24px">
            Current {labels[1]}:
          </DialogContentText>
          {api.renderItem(notPersistedValue, context)}

          {items && (
            <>
              <DialogContentText marginTop="24px">
                or update existing items
              </DialogContentText>
              <PersistedItemsList
                items={items}
                context={context}
                renderItem={renderItem}
                renderItemActions={(item) => (
                  <Button
                    size="small"
                    onClick={() => {
                      const [name] = item;
                      const savedItems = storage.setItem(
                        name,
                        notPersistedValue
                      );
                      setOpen(false);
                      setPersistedItems(savedItems);
                      setNotPersistedValue(null);
                    }}
                  >
                    update
                  </Button>
                )}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
