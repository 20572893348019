import { useEffect, useRef } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { HeightOutlined } from "@mui/icons-material";
import { gridFilteredSortedRowEntriesSelector } from "@mui/x-data-grid-premium";
import { usePutProviderLocationTagsMutation } from "../api/networksApi";

export const LABEL_FIELD = "j2_label";

function LabelEditCell(props) {
  const { id, value, field, api } = props;
  const canFill = api.state.filter.filterModel.items.length > 0;
  const ref = useRef();

  const handleValueChange = (event) => {
    const newValue = event.target.value; // The new value entered by the user
    api.setEditCellValue({ id, field, value: newValue });
  };

  const handleOnClick = () => {
    api.stopCellEditMode({ id, field });
    api.setState((state) => ({ ...state, fillButtonClicked: true }));
  };

  const handleAltEnter = (e) => {
    if (e.keyCode === 13 && e.altKey) {
      e.preventDefault();
      handleOnClick();
    }
  };

  useEffect(() => {
    ref.current.focus();
  }, []);

  return (
    <div
      className="MuiInputBase-root MuiDataGrid-editInputCell MuiInputBase-fullWidth"
      style={{ height: "100%", width: "100%" }}
    >
      <input
        style={{
          width: canFill ? "80%" : "100%",
          border: "0px",
          height: "100%",
          padding: "0 8px",
          boxSizing: "border-box",
        }}
        ref={ref}
        className="MuiInputBase-input"
        type="text"
        value={value || ""}
        onChange={handleValueChange}
        onKeyDown={canFill ? handleAltEnter : undefined}
      />
      {canFill && (
        <Tooltip title="set this value to all rows">
          <IconButton size="small" onClick={handleOnClick}>
            <HeightOutlined />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

export function renderEditLabelCell(params) {
  return <LabelEditCell {...params} />;
}

export function makeEditLabelCell(rule) {
  const { field, label = field, type } = rule;
  return field === LABEL_FIELD
    ? {
        field: field,
        filterable: true,
        sortable: true,
        headerName: label,
        type: type ?? "string",
        editable: true,
        width: 150,
        renderEditCell: renderEditLabelCell,
      }
    : {};
}

export function useUpdateLabel(apiRef) {
  const [updateTags] = usePutProviderLocationTagsMutation();

  async function onRowUpdate(after, before) {
    let providerLocationIds = [after.id];
    let hasChanges = before[LABEL_FIELD] !== after[LABEL_FIELD];

    if (apiRef.current.state.fillButtonClicked) {
      const visibleRows = gridFilteredSortedRowEntriesSelector(apiRef);
      providerLocationIds = visibleRows.map(({ id }) => id);
      hasChanges = visibleRows.some(
        ({ model }) => model[LABEL_FIELD] !== after[LABEL_FIELD]
      );
    }

    if (hasChanges) {
      updateTags({ providerLocationIds, label: after[LABEL_FIELD] });
      apiRef.current.setState(({ fillButtonClicked, ...state }) => state);
    }

    return after;
  }
  return onRowUpdate;
}
