import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { selectNonOptimisticApiActivity } from "../../features/apiActivity";
import { selectCurrentClient } from "../../features/currentClient";
import { setPathname } from "../../features/navigation";
import { DisruptionAppDrawer } from "./AppDrawer";
import { AppTopBar } from "./AppTopBar/AppTopBar";
import { useAllowDisruptionReports } from "../../hooks/useAllowDisruptionReports";
import { workspaces } from "../../features/workspaces/workspaces";
import { AppMain } from "./AppMain";

export function DisruptionAppRoot() {
  const currentClient = useSelector(selectCurrentClient);
  const { updating } = useSelector(selectNonOptimisticApiActivity);
  const isUpdating = updating.length > 0;
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const canAccessDisruptionReports = useAllowDisruptionReports();

  useEffect(() => {
    dispatch(setPathname(pathname));
  }, [pathname, dispatch]);

  if (!canAccessDisruptionReports) {
    return <Navigate to={workspaces.default.path} replace />;
  }

  return currentClient ? (
    <div className="App">
      <AppTopBar />
      <DisruptionAppDrawer />
      <AppMain />
      <Backdrop className="loadingBackdrop" open={isUpdating}>
        <CircularProgress color="primary" size={60} thickness={4} />
      </Backdrop>
    </div>
  ) : (
    <Navigate to="select-client" replace state={{ from: pathname }} />
  );
}
