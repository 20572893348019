import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sumCountyGaps } from "../../features/scorecard";
import { selectSelectedNetworkId } from "../../features/selectedNetwork";
import { convertToPercent } from "../../utils";
import {
  SCORES,
  THRESHOLDS,
  THRESHOLD_REDUCTIONS,
  ADEQUACIES,
  SCORE_TYPE_LABELS,
} from "@j2nm/score-metric-keys";

export function getScoreComponents(score) {
  if (score === undefined) return {};
  const driveDistance = convertToPercent(score[SCORES.DRIVE_DISTANCE], true);
  const driveTime = convertToPercent(score[SCORES.DRIVE_TIME], true);
  const minCount = score[SCORES.MINIMUM_COUNT];
  const minCountThreshold = score[THRESHOLDS.MINIMUM_COUNT];
  const minCountInCounty = score[SCORES.MINIMUM_COUNT_IN_COUNTY];
  const minCountInCountyThreshold = score[THRESHOLDS.MINIMUM_COUNT_IN_COUNTY];
  const isAdequateTime = Boolean(score[ADEQUACIES.DRIVE_TIME]);
  const isAdequateDistance = Boolean(score[ADEQUACIES.DRIVE_DISTANCE]);
  const isAdequateMinCount = Boolean(score[ADEQUACIES.MINIMUM_COUNT]);
  const isAdequateMinCountInCounty = Boolean(
    score[ADEQUACIES.MINIMUM_COUNT_IN_COUNTY]
  );
  const isAdequateAccess = Boolean(score[ADEQUACIES.ACCESS]);
  const isAdequate = Boolean(score[ADEQUACIES.OVERALL]);

  let metrics = [];
  if (driveDistance !== undefined) {
    metrics.push({
      type: "access",
      label: SCORE_TYPE_LABELS[SCORES.DRIVE_DISTANCE],
      values: [driveDistance, isAdequateDistance],
    });
  }
  if (driveTime !== undefined) {
    metrics.push({
      type: "access",
      label: SCORE_TYPE_LABELS[SCORES.DRIVE_TIME],
      values: [driveTime, isAdequateTime],
    });
  }
  if (minCountThreshold !== undefined) {
    metrics.push({
      type: "count",
      label: SCORE_TYPE_LABELS[SCORES.MINIMUM_COUNT],
      values: [minCount, isAdequateMinCount, minCountThreshold],
    });
  }
  if (minCountInCountyThreshold !== undefined) {
    metrics.push({
      type: "count",
      label: SCORE_TYPE_LABELS[SCORES.MINIMUM_COUNT_IN_COUNTY],
      values: [
        minCountInCounty,
        isAdequateMinCountInCounty,
        minCountInCountyThreshold,
      ],
    });
  }

  return {
    metrics,
    isAdequate,
    isAdequateAccess,
    conThresholdReduction: score[THRESHOLD_REDUCTIONS.CON],
    thresholdReduction: score[THRESHOLD_REDUCTIONS.OVERALL],
    telehealthCredit: score[THRESHOLD_REDUCTIONS.TELEHEALTH],
  };
}

export function getCurrentGapCount(row, columns) {
  const { id, name, telehealth, gapCount: initGapCount, ...countyScores } = row;
  const { columnVisibilityModel } = columns;
  return Object.keys(columnVisibilityModel).length <= 1 // id is always hidden
    ? initGapCount
    : sumCountyGaps(
        Object.entries(countyScores).flatMap(([countyId, scores]) => {
          return columnVisibilityModel[countyId] !== false ? [scores] : [];
        })
      );
}

export const exportedColumns = (metricKeys) =>
  ["Is gap", "Gap count", "Gap change"].concat(
    metricKeys.map((k) => SCORE_TYPE_LABELS[k])
  );

export const csvDelimiter = ";";

export function exportFileName(networkName) {
  let now = new Date();
  let year = now.toLocaleString("us", { year: "2-digit" });
  let month = now.toLocaleString("us", { month: "2-digit" });
  let day = now.toLocaleString("us", { day: "2-digit" });
  return `${networkName || "my_network"}_${year}_${month}_${day}`;
}

export const useSyncWithAction = (actionCreator) => {
  // sync state so it is preserved
  const dispatch = useDispatch();
  const networkId = useSelector(selectSelectedNetworkId);

  React.useEffect(() => {
    return () => {
      dispatch(actionCreator(networkId));
    };
  }, [actionCreator, dispatch, networkId]);
};

export function getAdequateChange(currentIsAdequate, baselineIsAdequate) {
  if (currentIsAdequate === undefined || baselineIsAdequate === undefined)
    return undefined;
  let gapChangeVal = 0;
  if (currentIsAdequate && !baselineIsAdequate) gapChangeVal = -1;
  else if (!currentIsAdequate && baselineIsAdequate) gapChangeVal = 1;
  return gapChangeVal;
}

export function shouldBeMarkedAsGap(type, isValueAdequate, isAdequateAccess) {
  const isAdequate =
    type === "access" ? isAdequateAccess || isValueAdequate : isValueAdequate;
  return !isAdequate;
}
