export function transformAdequacyMetricApiResponse(response) {
  // add code used in UI grid generation
  const all = response.map((metric) => {
    metric.code = metric.name.toLowerCase().trim().replaceAll(" ", "_");
    return metric;
  });

  // divide metrics by type and add all
  const result = { all };
  for (const metric of all) {
    if (!result[metric.type]) result[metric.type] = [];
    result[metric.type].push(metric);
  }
  return result;
}
