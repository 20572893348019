import { useState } from "react";
import { useSelector } from "react-redux";
import { selectSpecialtyFilterVal } from "../../features/providers";
import {
  selectBaselineScorecard,
  selectNetworkScorecard,
} from "../../features/scorecard";
import { GapsChanges } from "./GapsChanges";
import { BaselineChanges } from "../ScoresTable/BaselineChanges";

export function SpecialtyStats({ isLoading }) {
  const scorecard = useSelector(selectNetworkScorecard);
  const specialtyId = useSelector(selectSpecialtyFilterVal);
  const baselineScorecard = useSelector(selectBaselineScorecard);
  const [open, setOpen] = useState(false);

  const { gapCount } = scorecard.find(({ id }) => id === specialtyId) || {};
  const { gapCount: baselineGapCount } =
    baselineScorecard?.find(({ id }) => id === specialtyId) || {};

  return (
    <>
      <GapsChanges
        gaps={gapCount}
        changes={gapCount - baselineGapCount}
        show={!isLoading}
        onChangesClick={() => setOpen(true)}
      />
      {open && (
        <BaselineChanges
          open={open}
          setOpen={setOpen}
          specialtyId={specialtyId}
        />
      )}
    </>
  );
}
