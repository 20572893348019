import { useImperativeHandle, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectFilteredProvidersPoints } from "../features/layersData";

export function useFilteredProviderPoints(ref) {
  const [gridFilteredIds, setGridFilteredIds] = useState(null);

  useImperativeHandle(ref, () => ({
    setGridFilteredIds: (ids) => setGridFilteredIds(new Set(ids)),
  }));

  const allProviderPoints = useSelector(selectFilteredProvidersPoints);
  const providerPoints = useMemo(
    () =>
      gridFilteredIds === null
        ? allProviderPoints
        : allProviderPoints.filter((p) => gridFilteredIds.has(p.properties.id)),
    [allProviderPoints, gridFilteredIds]
  );
  return providerPoints;
}
