import { SettingsBackupRestoreOutlined } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { usePostNetworkResetChangesMutation } from "../../api/networksApi";
import { selectSpecialtyFilterVal } from "../../features/providers";
import { selectSelectedNetworkId } from "../../features/selectedNetwork";

export function ResetToBaselineButton() {
  const networkId = useSelector(selectSelectedNetworkId);
  const specialtyId = useSelector(selectSpecialtyFilterVal);

  const [resetToBaseline, result] = usePostNetworkResetChangesMutation();
  const resultMsg = result.isError
    ? `Error: ${result.error?.data?.message ?? "backend error occured"}`
    : result.data?.message;

  const [open, setOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const closeDialog = () => setOpen(false);

  return (
    <>
      <Button
        variant="text"
        startIcon={<SettingsBackupRestoreOutlined />}
        onClick={() => {
          setOpen(true);
        }}
        color="info"
        size="small"
        className="reset-to-baseline"
        sx={{
          marginLeft: "auto",
          color: "#fff7a1",
          flexShrink: "0",
        }}
      >
        Reset
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Reset network to baseline</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to reset your network to baseline and undo all changes
            made to your network.
          </DialogContentText>
          <DialogContentText mt={2}>Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            disabled={result.status === "pending"}
            onClick={() => {
              closeDialog();
              resetToBaseline({ networkId, specialtyId })
                .unwrap()
                .catch(() => {}) // error is in result.error
                .finally(() => setShowSnackbar(true));
            }} // specialtyId needed only for proper cache invalidation; not part of the request
            autoFocus
          >
            Yes, proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
      >
        <Alert severity={result.isError ? "error" : "success"}>
          {resultMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
