const SCORE_METRICS = {
  DRIVE_DISTANCE: "score_drive_distance",
  DRIVE_TIME: "score_drive_time",
  MINIMUM_COUNT: "score_minimum_count",
  MINIMUM_COUNT_IN_COUNTY: "score_minimum_count_in_county",
};

export const SCORE_TYPES = Object.keys(SCORE_METRICS);

export const ACCESS_SCORE_TYPES = SCORE_TYPES.filter((st) =>
  st.startsWith("DRIVE")
);

export const COUNT_SCORE_TYPES = SCORE_TYPES.filter(
  (st) => !ACCESS_SCORE_TYPES.includes(st)
);

// score keys
export const SCORES = {
  ...SCORE_METRICS,
  ACCESS: "score_access",
};

// score labels
export const SCORE_TYPE_LABELS = {
  [SCORES.DRIVE_DISTANCE]: "Drive Distance",
  [SCORES.DRIVE_TIME]: "Drive Time",
  [SCORES.MINIMUM_COUNT]: "Servicing Count",
  [SCORES.MINIMUM_COUNT_IN_COUNTY]: "Count in County",
};

// adequacy keys
export const ADEQUACIES = {
  DRIVE_DISTANCE: "adequate_drive_distance",
  DRIVE_TIME: "adequate_drive_time",
  MINIMUM_COUNT: "adequate_minimum_count",
  MINIMUM_COUNT_IN_COUNTY: "adequate_minimum_count_in_county",
  ACCESS: "adequate_access",
  OVERALL: "adequate_overall",
};

// threshold keys
export const THRESHOLDS = {
  DRIVE_DISTANCE: "threshold_drive_distance",
  DRIVE_TIME: "threshold_drive_time",
  MINIMUM_COUNT: "threshold_minimum_count",
  MINIMUM_COUNT_IN_COUNTY: "threshold_minimum_count_in_county",
  ACCESS: "threshold_access",
};

// threshold reduction keys
export const THRESHOLD_REDUCTIONS = {
  CON: "reduction_con",
  TELEHEALTH: "reduction_telehealth",
  EXPANSION: "reduction_expansion",
};
