import { useCallback } from "react";
import { useAmplitudeEvent } from "./useAmplitudeEvent";
import { useGetUsStatesQuery } from "../api/networksApi";

export function useNetworkCreationAmplitudeEvent() {
    const { data: usStates } = useGetUsStatesQuery();
    const trackAmplitudeEvent = useAmplitudeEvent();

    const trackNetworkCreationAmplitudeEvent = useCallback((eventName, eventProps = {}) => {
        const { networkId, networkName, counties, lob, regulatoryStandards, file, error } = eventProps;
        const regulatoryStandard = regulatoryStandards?.find(item => item.value === lob)?.label;
        const fips = counties && counties[0]?.fips?.slice(0, 2);

        return trackAmplitudeEvent(eventName, {
            networkId,
            networkName,
            county: counties?.map(county => county.county_name),
            state: usStates.find(state => state.statefp === fips)?.name,
            regulatoryStandard: regulatoryStandard || lob,
            file: file ? true : false,
            error: error ? "Error when processing file" : "",
        });
    }, [usStates, trackAmplitudeEvent]);

    return trackNetworkCreationAmplitudeEvent;
}
