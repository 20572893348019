import React from "react";
import { Source, Layer } from "react-map-gl";
import { dataToGeoJSON } from "../../utils";
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from "./layerStyles";

function ProviderClusters({ providerSourceRef, providerPoints }) {
  return (
    <Source
      id="clusters"
      type="geojson"
      data={dataToGeoJSON(providerPoints)}
      cluster={true}
      // maxzoom={23}
      clusterRadius={25}
      clusterProperties={{
        hasINN: ["+", ["get", "is_inn"]],
        hasOON: ["+", ["get", "is_oon"]],
        isSelected: ["any", ["get", "isSelected"]],
        isMarked: ["any", ["get", "isMarked"]],
        maxIncrementalAdequacy: [
          "max",
          ["number", ["get", "incrementalAdequacy"], 0],
        ],
      }}
      ref={providerSourceRef}
    >
      <Layer
        {...clusterLayer}
        filter={[
          "all",
          ["has", "point_count"],
          [
            "any",
            ...[[">", ["get", "hasINN"], 0]],
            ...[["==", ["get", "hasINN"], 0]],
          ],
        ]}
        minzoom={7.2}
      />
      <Layer
        {...clusterCountLayer}
        filter={[
          "all",
          ["has", "point_count"],
          [
            "any",
            ...[[">", ["get", "hasINN"], 0]],
            ...[["==", ["get", "hasINN"], 0]],
          ],
        ]}
        minzoom={7.2}
      />
      <Layer
        {...unclusteredPointLayer}
        filter={[
          "all",
          ["!", ["has", "point_count"]],
          [
            "any",
            ...[["==", ["get", "is_inn"], 1]],
            ...[["==", ["get", "is_inn"], 0]],
          ],
        ]}
        minzoom={7.2}
      />
    </Source>
  );
}

export default ProviderClusters;
