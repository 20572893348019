import { renderMetricEditCell } from "./metricEdit";
import { renderMetricCell } from "./metricCell";
import {
  integerOnly,
  notEmptyAndIntegerOnly,
  notEmptyAndPercentageIntegerOnly,
} from "./gridValidators";

const metricsColumns = [
  {
    field: "drive_time",
    type: "number",
    headerName: "Drive Time (min)",
    width: 150,
    renderEditCell: renderMetricEditCell,
    renderCell: renderMetricCell,
    editable: true,
    // custom properties
    isAccessMetric: true,
    validator: notEmptyAndIntegerOnly,
  },
  {
    field: "drive_distance",
    type: "number",
    headerName: "Drive Distance (mi)",
    width: 150,
    renderEditCell: renderMetricEditCell,
    renderCell: renderMetricCell,
    editable: true,
    isAccessMetric: true,
    validator: notEmptyAndIntegerOnly,
  },
  {
    field: "access_threshold",

    type: "number",
    headerName: "Access Threshold (%)",
    width: 200,
    renderEditCell: renderMetricEditCell,
    renderCell: renderMetricCell,
    editable: true,
    isAccessMetric: false,
    validator: notEmptyAndPercentageIntegerOnly,
  },
  {
    field: "access_count",
    type: "number",
    headerName: "Access Count",
    width: 150,
    renderEditCell: renderMetricEditCell,
    renderCell: renderMetricCell,
    editable: true,
    isAccessMetric: false,
    validator: notEmptyAndIntegerOnly,
  },
  {
    field: "count_in_county",
    type: "number",
    headerName: "Count in County",
    width: 200,
    renderEditCell: renderMetricEditCell,
    renderCell: renderMetricCell,
    editable: true,
    isAccessMetric: false,
    validator: integerOnly,
  },
  {
    field: "servicing_count_in_county",
    type: "number",
    visible: true,
    headerName: "Servicing Count in County",
    width: 200,
    renderEditCell: renderMetricEditCell,
    renderCell: renderMetricCell,
    editable: true,
    isAccessMetric: false,
    validator: integerOnly,
  },
];

const designationColumns = [
  { field: "designation_name", headerName: "County Designation", width: 200 },
];

export const makeGridColumns = (
  metrics = [],
  designations = [],
  readOnly = false
) => [
  { field: "specialty_name", headerName: "Specialty", width: 200 },
  { field: "specialty_type", headerName: "Type", width: 100 },
  ...(designations.length > 1 ? designationColumns : []),
  ...metricsColumns
    .filter(({ field }) =>
      metrics.find(
        ({ code, type }) =>
          (field.startsWith("access") && type === "access") || field === code
      )
    )
    .map((col) => {
      return { ...col, editable: !readOnly };
    }),
];
