import { Chip, Stack } from "@mui/material";
import {
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-premium";

export function makeMemberServedColumn(rule) {
  const { field, label } = rule;
  return {
    field,
    headerName: label,
    width: 200,
    type: "string",
    renderCell: renderServedCell,
    sortComparator: servedComparator,
    filterOperators: servedOperators(),
    valueFormatter: formatServedValueForExport,
  };
}

// value getter from object of specialty id to count, to array of tuples [specialty name, county name,  % member served]

export function getServedValue(params) {
  const {
    member_served,
    countyLookup,
    specialtyLookup,
    providerSpecialtyLookup,
  } = params;
  if (typeof member_served !== "object") return member_served;
  return Object.entries(member_served ?? {}).flatMap(([countyId, serveds]) =>
    Object.entries(serveds).flatMap(([id, value]) =>
      providerSpecialtyLookup[id]
        ? [
            [
              specialtyLookup[id] ?? String(id),
              countyLookup[countyId] ?? String(countyId),
              Math.round(10000 * value) / 100,
            ],
          ]
        : []
    )
  );
}

// value renderer

function renderServedCell({ value, row }) {
  if (value === undefined || value === null || value?.length === 0)
    return "no members covered";
  if (!Array.isArray(value)) return value;
  const isOneSpecialty = row.specialty_ids.length === 1;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      height="100%"
      sx={{ overflowX: "scroll" }}
    >
      {value.map(([specialty, county, served]) => {
        const labelKey = isOneSpecialty ? county : `${specialty}-${county}`;
        return (
          <Chip
            key={labelKey}
            size="small"
            label={`${labelKey}: ${served}%`}
            sx={{ lineHeight: "1" }}
          />
        );
      })}
    </Stack>
  );
}

// formatter for csv export

function formatServedValueForExport({ value = "" }) {
  if (!Array.isArray(value)) return value;
  return value
    .flatMap(
      ([specialty, county, value]) => `${specialty}/${county}: ${value}%`
    )
    .join(", ");
}

// sorter

const byServedValue = ([, , value]) => value;

function servedComparator(a1, a2) {
  let v1 = a1 ?? [[1, 1, -1]];
  v1 = Array.isArray(v1) ? v1 : [[1, 1, v1]];
  let v2 = a2 ?? [[1, 1, -1]];
  v2 = Array.isArray(v2) ? v2 : [[2, 2, v2]];
  return (
    Math.max(...v1.map(byServedValue)) - Math.max(...v2.map(byServedValue))
  );
}

// filters

const numericFilterPredicates = {
  "=": (fVal) => (arr) => arr.some((v) => v[2] === fVal),
  "!=": (fVal) => (arr) => arr.some((v) => v[2] !== fVal),
  ">": (fVal) => (arr) => arr.some((v) => v[2] > fVal),
  ">=": (fVal) => (arr) => arr.some((v) => v[2] >= fVal),
  "<": (fVal) => (arr) => arr.some((v) => v[2] < fVal),
  "<=": (fVal) => (arr) => arr.some((v) => v[2] <= fVal),
};

function isEmptyValidFilterItem(filterItem) {
  return (
    !filterItem.field ||
    !filterItem.value ||
    !filterItem.operator ||
    !filterItem.value?.length
  );
}

function servedOperators() {
  return [
    ...["=", "!=", ">", ">=", "<", "<="].map((op) => ({
      ...getGridNumericOperators().find((op) => op.value === "="), // template operator
      value: op,
      label: op,
      getApplyFilterFn: (filterItem) => {
        if (isEmptyValidFilterItem(filterItem)) return null;
        const { operator, value } = filterItem;

        const pred = numericFilterPredicates[operator](Number(value));

        return (p) => {
          if (!Array.isArray(p.value))
            return getGridNumericOperators()
              .find((_op) => _op.value === op)
              .getApplyFilterFn(filterItem)?.(p);
          return pred(p.value);
        };
      },
    })),

    {
      ...getGridStringOperators().find((op) => op.value === "isEmpty"), // template operator
      value: "isEmpty",
      label: "is empty",
      getApplyFilterFn: () => (p) => !p.value,
    },
    {
      ...getGridStringOperators().find((op) => op.value === "isAnyOf"), // template operator
      value: "isSpecialty",
      label: "impacts specialty",

      getApplyFilterFn: (filterItem) => {
        if (isEmptyValidFilterItem(filterItem)) return null;

        return (p) => {
          if (Array.isArray(p.value))
            return (
              p.value.filter(([v]) =>
                filterItem.value.some((fv) =>
                  v.toLowerCase().includes(fv.toLowerCase())
                )
              ).length > 0
            );
          if (p.value === undefined) return false; // no served attributes
          // served are not array so pass it
          return true;
        };
      },
    },
    {
      ...getGridStringOperators().find((op) => op.value === "isAnyOf"), // template operator
      value: "isCounty",
      label: "impacts county",

      getApplyFilterFn: (filterItem) => {
        if (isEmptyValidFilterItem(filterItem)) return null;

        return (p) => {
          if (Array.isArray(p.value))
            return (
              p.value.filter(([, v]) =>
                filterItem.value.some((fv) =>
                  v.toLowerCase().includes(fv.toLowerCase())
                )
              ).length > 0
            );
          if (p.value === undefined) return false; // no served attributes
          // served are not array so pass it
          return true;
        };
      },
    },
  ];
}
