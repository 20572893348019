// validators
export const isPresent = (label = "notPresent", emptyCheck) => {
  return (input) => {
    const { field, values } = input;
    const ok = emptyCheck ? emptyCheck(input) : Boolean(values[field]);
    return ok ? { ok: input } : { error: label };
  };
};

export const isPattern = (pattern, label = "notPattern") => {
  return (input) => {
    const ok = pattern.test(input.values[input.field]);
    return ok ? { ok: input } : { error: label };
  };
};

export const isChanged = (label = "notChanged", comparator) => {
  return (input) => {
    const { field, values, initValues } = input;
    const ok = comparator
      ? comparator(input)
      : values[field] !== initValues[field];
    return ok ? { ok: input } : { error: label };
  };
};

export const isInteger = (label = "notAnInteger") => {
  return (input) => {
    const { field, values } = input;
    const value = values[field];
    const ok = value === "" || Number.isInteger(Number(value));
    return ok ? { ok: input } : { error: label };
  };
};

export const isPercentage = (label = "notAPercentage") => {
  return (input) => {
    const { field, values } = input;
    const value = Number(values[field]);
    const ok = value > 0 && value <= 100;
    return ok ? { ok: input } : { error: label };
  };
};
