import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import Map, { NavigationControl, Popup, ScaleControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { get } from "lodash";
import Modal from "@mui/material/Modal";
import ProviderClusters from "./layers/ProviderClusters";
import Polygons from "./layers/Polygons";
import ProviderPopup from "./ProviderPopup";
import { useDispatch, useSelector } from "react-redux";
import { setMapHover } from "../features/map";
import {
  selectCountiesMetricsLayer,
  selectZipsMetricsLayer,
} from "../features/layersData";
import { selectSelectedNetwork } from "../features/networks";
import { GeoStats } from "../components/GeoStats";
import { useLocation } from "react-router-dom";
import { selectIsApiBusy } from "../features/apiActivity";
import { useFilteredProviderPoints } from "../hooks/useFilteredProviderPoints";

// function easeOutExpo(x) {
//   return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
// }

const mapBoxToken =
  "pk.eyJ1IjoianBvcmV0eiIsImEiOiJja2s2NjJzbDgwMHZ0MnZxc2YzOWIyaGZ6In0.Ou4iaNwSgpwMAPuVcNuZYg";

export const J2Map = forwardRef(function J2Map(_props, ref) {
  const dispatch = useDispatch();
  const isBusy = useSelector(selectIsApiBusy);
  const network = useSelector(selectSelectedNetwork);

  const mapRef = useRef();
  const providerPoints = useFilteredProviderPoints(ref);
  useImperativeHandle(ref, () => ({
    ...ref.current,
    ...mapRef.current,
  }));
  // const providerToMark = useSelector(selectPointToMark);
  const countyMetricsLayer = useSelector(selectCountiesMetricsLayer);
  const zipCodeMetricsLayer = useSelector(selectZipsMetricsLayer);

  const location = useLocation();
  const { zoomCounty } = location.state || {};
  const countyShape = zoomCounty
    ? countyMetricsLayer.find(
        ({ properties }) => properties.GEOID === zoomCounty
      )
    : null;

  const countyGeoCenter = countyShape?.properties?.geoCenter;
  const flyToCountyDone = useRef(false);

  const initState = useMemo(
    () => Object.entries(network?.states ?? {})[0]?.[1] ?? {},
    [network?.states]
  );

  const [popupInfo, setPopupInfo] = useState(null);
  // const [mapFilterState] = useState({
  //   showINN: true,
  //   showOON: false,
  // });

  function onHover(event) {
    const countiesFeature = event.features.find(
      (f) => f.layer.id === "county-fill" || f.layer.id === "counties-over-zips"
    );
    const zipFeature = event.features.find(
      (f) => f.layer.id === "zipcode-fill"
    );

    let newCounty = countiesFeature?.properties.GEOID;
    let newZipCode = zipFeature?.properties.GEOID20;

    dispatch(
      setMapHover({
        county: newCounty,
        zipCode: newZipCode,
      })
    );
    // }
  }

  function onClick(event) {
    const feature = get(event, "features[0]");
    if (feature) {
      if (get(feature, "layer.id") === "provider-clusters") {
        const clusterId = get(feature, "properties.cluster_id");

        const mapboxSource = mapRef.current.getMap().getSource("clusters");

        mapboxSource.getClusterLeaves(
          clusterId,
          20,
          0,
          (err, clusterLeaves) => {
            setPopupInfo(clusterLeaves);
            setOpen(true);
          }
        );

        return;
      }

      if (get(feature, "layer.id") === "provider-unclustered-point") {
        const point = providerPoints.find(
          (p) => p.properties.id === feature.properties.id
        );
        setPopupInfo([point]);
        setOpen(true);

        return;
      }

      if (feature?.layer?.id === "zipcode-fill") {
        const zoom = mapRef.current.getMap().getZoom();
        if (zoom >= 8) {
          const zipCodePopup = {
            zipcode: feature?.properties?.GEOID20,
            zipCodeScores: feature?.properties || {},
            coordinates: JSON.parse(feature?.properties.geoCenter)?.geometry
              .coordinates,
          };
          setZipPopup(zipCodePopup);
        }
      }

      if (feature?.layer?.id === "county-fill") {
        const zoom = mapRef.current.getMap().getZoom();
        if (zoom >= 6.5 && zoom <= 8) {
          const countyPopup = {
            county: feature?.properties?.GEOID,
            countyScores: feature?.properties || {},
            coordinates: JSON.parse(feature?.properties.geoCenter)?.geometry
              .coordinates,
          };
          setCountyPopup(countyPopup);
        }
      }

      // calculate the bounding box of the feature
      // const [minLng, minLat, maxLng, maxLat] = bbox(feature);
      // construct a viewport instance from the current state
      // const viewportInstance = new WebMercatorViewport(viewport);
      // const { longitude, latitude, zoom } = viewportInstance.fitBounds(
      //   [
      //     [minLng, minLat],
      //     [maxLng, maxLat],
      //   ],
      //   {
      //     padding: 40,
      //   }
      // );

      // setViewport({
      //   ...viewport,
      //   longitude: minLng,
      //   latitude: minLat,
      //   // zoom,
      //   // transitionInterpolator: new LinearInterpolator({
      //   //   around: [event.offsetCenter.x, event.offsetCenter.y],
      //   // }),
      //   // transitionDuration: 500,
      // });
    }
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [zipPopup, setZipPopup] = useState(null);
  const [countyPopup, setCountyPopup] = useState(null);

  // fly to for network change
  useEffect(() => {
    // somehow we need this asynchrony to have the ref not null
    // setTimeout(() => {
    mapRef.current?.flyTo({
      center: [initState.lon, initState.lat],
      zoom: 6,
      duration: 1000,
    });
    // }, 0);
  }, [initState]);

  // fly to for scorecard -> map
  useEffect(() => {
    if (!isBusy && countyGeoCenter && !flyToCountyDone.current)
      setTimeout(() => {
        mapRef.current?.flyTo({
          center: countyGeoCenter.geometry.coordinates,
          zoom: 7.9,
          duration: 1000,
        });
        flyToCountyDone.current = true;
      }, 0);
  }, [isBusy, countyGeoCenter]);
  return (
    <div className="interactiveMap">
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <div className="modal-popup-main">
          <ProviderPopup popupInfo={popupInfo} />
        </div>
      </Modal>
      <Map
        initialViewState={{
          latitude: initState.lat,
          longitude: initState.lon,
          zoom: 6,
          transitionDuration: 500,
        }}
        onLoad={() => {}}
        mapStyle="mapbox://styles/mapbox/light-v10"
        // onViewportChange={(nextViewport) => setViewport(nextViewport)}
        mapboxAccessToken={mapBoxToken}
        // onClick={allowInteraction ? onClick : noop}
        onClick={onClick}
        onMouseMove={onHover}
        // scrollZoom={allowInteraction}
        maxZoom={22}
        attributionControl={false}
        interactiveLayerIds={[
          "county-fill",
          "zipcode-fill",
          "provider-clusters",
          "provider-unclustered-point",
          "counties-over-zips",
        ]}
        dragRotate={false}
        ref={mapRef}
      >
        <NavigationControl
          position="bottom-right"
          style={{
            position: "absolute",
            margin: 0,
            right: 8,
            bottom: 86,
          }}
        />
        <ScaleControl unit="imperial" />
        {/* <AttributionControl compact position="bottom-right" /> */}
        <Polygons
          shapes={countyMetricsLayer}
          fillLayerId="county-fill"
          lineLayerId="county-line"
          layerProperties={{ maxzoom: 8 }}
        />
        <Polygons
          shapes={zipCodeMetricsLayer}
          layerProperties={{
            minzoom: 8,
          }}
          fillLayerId="zipcode-fill"
          lineLayerId="zipcode-line"
        />
        <ProviderClusters providerPoints={providerPoints} />
        {zipPopup && (
          <Popup
            longitude={zipPopup.coordinates[0]}
            latitude={zipPopup.coordinates[1]}
            anchor="bottom"
            onClose={() => setZipPopup(null)}
          >
            <GeoStats
              label="zipcode"
              entityName={zipPopup.zipcode}
              entityScore={zipPopup.zipCodeScores}
            />
          </Popup>
        )}
        {countyPopup && (
          <Popup
            longitude={countyPopup.coordinates[0]}
            latitude={countyPopup.coordinates[1]}
            anchor="bottom"
            onClose={() => setCountyPopup(null)}
          >
            <GeoStats
              label="county"
              entityName={countyPopup.county}
              entityScore={countyPopup.countyScores}
            />
          </Popup>
        )}
      </Map>
    </div>
  );
});
