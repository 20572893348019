import React, { useState } from "react";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid-premium";
import TableContainer from "@mui/material/TableContainer";
import { isEqual } from "lodash";
import { makeStyles } from "@mui/styles";

// last version where Pro key works is "@mui/x-data-grid-pro": "5.5.0",
LicenseInfo.setLicenseKey(
  // "bbe4e0b3631b7a877c44b6f0ac6864f3T1JERVI6MjgxMzcsRVhQSVJZPTE2NjAyNTMzOTEwMDAsS0VZVkVSU0lPTj0x",
  "34a218e6a091b0e45b303a8e0b9f4df7Tz04NTY3OSxFPTE3NDExNzkyMDUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root.MuiDataGrid-cell:focus,&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,&.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none",
      },
    "&.MuiDataGrid-root .MuiDataGrid-cell": {
      fontSize: ".875em",
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell[data-field='likability']": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "#fff7a1",
    },
    "&.MuiDataGrid-root .MuiDataGrid-row.is-inactive": {
      backgroundColor: "rgb(255 99 71 / 20%)",
    },
  },
});

export function Table({
  rows,
  columns,
  loading,
  tableHeight,
  showProviderToolbar,
  customSortModel,
  ...rest
}) {
  const classes = useStyles();

  const [tableSortModel, setTableSortModel] = useState(customSortModel);

  return (
    <TableContainer style={{ height: tableHeight || "auto" }}>
      <DataGridPremium
        className={classes.root}
        rows={loading ? [] : rows}
        autoHeight={!tableHeight}
        columns={columns}
        density="compact"
        loading={loading}
        sortModel={tableSortModel}
        onSortModelChange={(model) => {
          if (!isEqual(model, tableSortModel)) setTableSortModel(model);
        }}
        pagination
        disableSelectionOnClick
        disableAggregation
        disableRowGrouping
        getRowClassName={(params) =>
          params.row.oig_sanctioned === "true" ? "is-inactive" : ""
        }
        rowsPerPageOptions={[10, 25, 50, 100]}
        components={{
          Toolbar: () =>
            showProviderToolbar ? (
              <GridToolbarContainer style={{ justifyContent: "flex-end" }}>
                {/* <ProviderTypeOptions /> */}
                <GridToolbarExport />
              </GridToolbarContainer>
            ) : null,
        }}
        {...rest}
        sx={{
          [`& .${gridClasses.columnHeaderTitleContainer} .${gridClasses.iconButtonContainer}`]:
            {
              visibility: "visible",
              width: "auto",
            },
          [`& .${gridClasses.columnHeaderTitleContainer} .${gridClasses.iconButtonContainer} .${gridClasses.filterIcon}`]:
            {
              color: "tomato",
            },
        }}
        {...rest}
      />
    </TableContainer>
  );
}
