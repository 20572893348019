import {
  validate,
  isPresent,
  isInteger,
  isPercentage,
} from "../../../utils/formValidate";

const isPresentValidator = isPresent("value cannot be empty");
const isIntegerValidator = isInteger("value must be an integer");
const isPercentageValidator = isPercentage("value must be between 0 and 100");

export const integerOnly = validate([isIntegerValidator]);

export const notEmptyAndIntegerOnly = validate([
  isPresentValidator,
  isIntegerValidator,
]);

export const notEmptyAndPercentageIntegerOnly = validate([
  isPresentValidator,
  isIntegerValidator,
  isPercentageValidator,
]);

export function validateGrid({ rows, columns }) {
  let errors = {};
  for (const row of rows) {
    const rowHasAccessValue = columns.some(
      (col) => col.isAccessMetric && row[col.field]
    );
    let metricErrors = {};
    for (const column of columns.filter((col) => col.validator)) {
      const { field, isAccessMetric, validator } = column;
      const metricValue = Number(row[field]);
      // skip empty access metrics if operator is OR and there is another access metric
      if (
        row.has_access_metrics_alternative &&
        rowHasAccessValue &&
        isAccessMetric &&
        !metricValue
      )
        continue;

      const { error } = validator({ field, values: row });

      if (error) metricErrors[field] = error;
    }
    if (Object.keys(metricErrors).length > 0) {
      if (!errors[row.id]) errors[row.id] = {};
      errors[row.id] = metricErrors;
    }
  }
  return errors;
}
