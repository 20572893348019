import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import EditOutlined from "@mui/icons-material/EditOutlined";
export const reportsGridColumns = [
  { field: "name", headerName: "Report Name", width: 200 },
  { field: "created_by_email", headerName: "Created By", width: 150 },
  {
    field: "updated_at",
    type: "date",
    headerName: "Last Modified",
    width: 150,
    valueGetter: ({ row }) => new Date(row.updated_at),
    valueFormatter: ({ value }) =>
      new Intl.DateTimeFormat("en-US").format(value),
  },
  { field: "target_network_name", headerName: "Replacement Network", width: 200 },
  { field: "source_network_name", headerName: "Incumbent Network", width: 200 },
  { field: "status_display", headerName: "Status", width: 150 },
  {
    field: "action",
    headerName: "Actions",
    type: "actions",
    width: 150,
    getActions: ({ id, row }) => {
      const { status } = row;
      const complete = status === "complete";
      const edit = status === "draft";
      const viewResultsButton = (
        <Button
          size="small"
          component={Link}
          to={`${id}/results`}
        >
          View Results
        </Button>
      );
      const editButton = (
        <Button
          size="small"
          component={Link}
          startIcon={<EditOutlined />}
          to={`${id}/edit`}
        >
          Edit
        </Button>
      );
      return edit ? [editButton] : complete ? [viewResultsButton] : [];
    },
  },
];
