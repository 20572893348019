import { Link, Navigate, useSearchParams } from "react-router-dom";
import {
  CircularProgress,
  Stack,
  Typography,
  TableContainer,
  Button,
} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import {
  useGetJ2SpecialtiesQuery,
  useGetUsStatesQuery,
} from "../../api/networksApi";
import { useCustomStandardsEnabled } from "../../hooks/useCustomStandardsEnabled";
import {
  useGetAdequacyMetricsQuery,
  useGetCustomStandardsQuery,
  useGetMemberUniversesQuery,
} from "../../api/j2/j2Api";
import {
  standardsGridColumns,
  archivedStandardsGridColumns,
} from "../../components/SelfServeCustomStandards/standardsGrid/standardsGridColumns";
import { ServerResponseInfo } from "../../components/SelfServeCustomStandards/ServerResponseInfo";
import { useHandleStandardActions } from "../../hooks/useHandleStandardActions";

export function StandardsIndexRoute() {
  const [searchParams] = useSearchParams();
  const archived = searchParams.get("archived") !== null;

  const {
    data: standards = [],
    isFetching,
    isError,
  } = useGetCustomStandardsQuery({
    archived,
  });
  useGetUsStatesQuery(); // prefetch states
  useGetJ2SpecialtiesQuery(); // prefetch j2Specialties
  useGetAdequacyMetricsQuery(); // prefetch adequacy metrics
  useGetMemberUniversesQuery(); // prefetch member universes

  const { successMsg, errorMsg } = useHandleStandardActions();

  const toggleButtonLabel = archived ? "Active" : "Archived";
  const toggleButtonPath = archived ? "/self-serve/standards" : ".?archived";
  const title = archived ? "Archived Standards" : "Standards";

  if (!useCustomStandardsEnabled()) return <Navigate to="/self-serve" />;

  return (
    <Stack mt={1} height={"100%"}>
      <ServerResponseInfo successMsg={successMsg} errorMsg={errorMsg} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography variant="h4">{title}</Typography>
        <Button component={Link} to={toggleButtonPath}>
          {toggleButtonLabel} standards
        </Button>
      </Stack>
      <Stack mt={2} height={"100%"}>
        {isFetching ? (
          <CircularProgress size={14} />
        ) : (
          <TableContainer sx={{ height: "100%" }}>
            <DataGridPremium
              sx={{
                '& .MuiDataGrid-cell[data-field="actions"]': {
                  justifyContent: "flex-start",
                },
              }}
              rows={isError ? [] : standards}
              columns={
                archived ? archivedStandardsGridColumns : standardsGridColumns
              }
              autoHeight
              hideFooter
              disableRowSelectionOnClick
            />
          </TableContainer>
        )}
      </Stack>
    </Stack>
  );
}
