import { createSelector } from "@reduxjs/toolkit";
import { centroid, point } from "@turf/turf";
import {
  getZipcodeMetricValues,
  getCountyShapes,
  getZipShapes,
  getZipScores,
  getZipcodeMetrics,
  getZipGaps,
} from "../api/networksApi";
import {
  selectHiglightedCounty,
  selectMap,
  selectMapHover,
  selectMapTheme,
} from "./map";
import { selectStateAndNetwork } from "./networks";
import { selectSpecialtyFilterVal, selectFilteredProviders } from "./providers";
import { selectNetworkCountiesScores } from "./scores";
import { selectStagedChangesLookup } from "./stagedChanges";

export const selectNetworkZipcodeScores = createSelector(
  selectStateAndNetwork,
  selectSpecialtyFilterVal,
  ([state, network], specialtyId) =>
    network
      ? getZipScores.select({ networkId: network.id, specialtyId })(state)
          ?.data ?? {}
      : {}
);

export const selectNetworkZipcodeGaps = createSelector(
  selectStateAndNetwork,
  ([state, network]) =>
    network ? getZipGaps.select(network.id)(state)?.data ?? {} : {}
);

const selectNetworkZipcodeShapes = createSelector(
  selectStateAndNetwork,
  ([state, network]) =>
    network ? getZipShapes.select(network.id)(state)?.data ?? [] : []
);

export const selectZipcodeMetrics = createSelector(
  selectStateAndNetwork,
  ([state, network]) =>
    network ? getZipcodeMetrics.select(network.id)(state)?.data ?? [] : []
);

export const selectZipcodeMetricValues = createSelector(
  selectStateAndNetwork,
  selectMapTheme,
  ([state, network], { metric_id: metricId }) => {
    if (!network) return undefined;
    return metricId === "adequacy"
      ? undefined
      : getZipcodeMetricValues.select({ networkId: network.id, metricId })(
          state
        )?.data;
  }
);

export const selectCurrentMetric = createSelector(
  selectMapTheme,
  selectZipcodeMetrics,
  (theme, metrics) => {
    return [
      theme.metric_id,
      metrics.find((m) => m.metric_id === theme.metric_id),
    ];
  }
);

export const selectZipcodeScoresLayer = createSelector(
  selectNetworkZipcodeShapes,
  selectNetworkZipcodeScores,
  selectCurrentMetric,
  selectZipcodeMetricValues,
  (shapes, scores, currentMetric, metricValues) => {
    const buckets = currentMetric[1]?.buckets;
    return shapes.map((shape) => {
      const { properties, geometry, ...restShape } = shape;
      const zip = properties.GEOID20;
      const zipScores = scores[zip];
      const zipMetricValues = metricValues?.[zip];

      const fillColor = buckets
        ? {
            fillColor: zipMetricValues
              ? zipDemographicsBucketColor(zipMetricValues.bucket - 1)
              : "gray",
          }
        : undefined;
      const zipPropScores = !zipScores
        ? {}
        : Object.keys(zipScores).length === 0
        ? { ao: 0, so: 0 }
        : zipScores;
      const polygon = { geometry, type: "Feature" };
      return {
        ...restShape,
        geometry,
        properties: {
          ...properties,
          ...zipPropScores,
          ...zipMetricValues,
          ...fillColor,
          geoCenter: centroid(polygon),
        },
      };
    });
  }
);

export const selectZipsMetricsLayer = createSelector(
  selectNetworkZipcodeShapes,
  selectNetworkZipcodeGaps,
  selectZipcodeScoresLayer,
  selectMapTheme,
  (shapes = [], zipGaps, zipShapesWithScores, { metric_id }) => {
    switch (metric_id) {
      case "gapsTotal":
        return makeZipGapsLayer(shapes, zipGaps);
      case "none":
        return makeEmptyLayer(shapes);
      default:
        return zipShapesWithScores;
    }
  }
);

function makeZipGapsLayer(shapes, gaps) {
  const totalGapCount = Object.values(gaps).reduce((acc, v) => acc + v, 0);

  return shapes.map((shape) => {
    const { properties, geometry, ...restShape } = shape;
    const zip = properties.GEOID20;
    const gapCount = gaps[zip];
    const fillColor = gapsBucketsDefinition.find(
      (b) => gapCount >= b.from && gapCount <= (b.to ?? +Infinity)
    )?.color;
    const polygon = { geometry, type: "Feature" };
    return {
      ...restShape,
      geometry,
      properties: {
        ...properties,
        gapCount,
        totalGapCount,
        fillColor,
        hasMetric: 1,
        geoCenter: centroid(polygon),
        ao: -1, // property must exist to display zip codes
      },
    };
  });
}

// /* possible other take on colors calculation */
// const defaultHSL = "hsl(221, 55%, 24%)";
// export function bucketColorDyn(bucketsCount, bucket, hsl = defaultHSL) {
//   const idx = bucketsCount - bucket; // reverse: eg: bucket 1 is last and 5 is first
//   const [h, s, l] = hsl.split(",");
//   const initL = parseInt(l);
//   const newL = initL + idx * ((100 - initL) / (bucketsCount - 1));
//   return [h, s, `${newL}%)`].join(",");
// }

const zipDemographicsColor = [
  "#ffffff",
  "#CEDAE9",
  "#8AACCB",
  "#4D72A5",
  "#1C3260",
];
export function zipDemographicsBucketColor(idx) {
  return zipDemographicsColor[idx];
}

// counties

const selectNetworkCountiesShapes = createSelector(
  selectStateAndNetwork,
  ([state, network]) =>
    network ? getCountyShapes.select(network.id)(state)?.data ?? [] : []
);

export const selectCountiesScoresLayer = createSelector(
  selectNetworkCountiesShapes,
  selectNetworkCountiesScores,
  selectSpecialtyFilterVal,
  selectHiglightedCounty,
  (shapes = [], scores, specialtyId, highlight) => {
    const specialtyScores = scores?.[specialtyId] ?? {};
    return shapes.map((shape) => {
      const { county_id, name, geometry } = shape;
      const countyScores = specialtyScores[county_id];
      const countyPropScores = !countyScores
        ? {}
        : Object.keys(countyScores).length === 0
        ? { ao: 0, so: 0 }
        : countyScores;

      const polygon = { geometry, type: "Feature" };
      return {
        geometry,
        properties: {
          GEOID: county_id,
          NAME: name,
          ...countyPropScores,
          geoCenter: centroid(polygon),
          highlight,
        },
      };
    });
  }
);

// export const makeSelectCountyScores = () =>
//   createSelector(
//     selectCountiesScoresLayer,
//     (_state, countyId) => countyId,
//     (scores, countyId) => {
//       return scores.find(({ properties }) => properties.GEOID === countyId);
//     }
//   );

// single county metrics layer

export const selectCountiesMetricsLayer = createSelector(
  selectNetworkCountiesShapes,
  selectNetworkCountiesScores,
  selectSpecialtyFilterVal,
  selectMapTheme,
  selectHiglightedCounty,
  (shapes = [], scores, specialtyId, { metric_id }, highlight) => {
    switch (metric_id) {
      case "gapsTotal":
        return makeGapsLayer(shapes, scores, highlight);
      case "none":
        return makeEmptyLayer(shapes, highlight);
      default:
        return makeAdequacyLayer(shapes, scores, specialtyId, highlight);
    }
  }
);

// providers points

export const selectFilteredProvidersPoints = createSelector(
  selectFilteredProviders,
  selectStagedChangesLookup,
  selectMap,
  (providers, selectedLookup, { pointToMark }) => {
    return providers.map((data) =>
      point([data.lon, data.lat], {
        id: data.id,
        lat: data.lat,
        lon: data.lon,
        is_inn: data.is_inn,
        is_oon: data.is_oon,
        specialty_ids: data.specialty_ids,
        isSelected: !!selectedLookup[data.id],
        isMarked: pointToMark === data.id,
      })
    );
  }
);

export const selectPointToMark = createSelector(
  selectMap,
  selectFilteredProvidersPoints,
  ({ pointToMark }, points = []) => {
    const point = points.find(
      ({ properties }) => properties.id === pointToMark
    );
    return point
      ? { id: point.properties.id, coordinates: point.geometry.coordinates }
      : null;
  }
);

// hovered layers

export const selectHoveredLayers = createSelector(
  selectMapHover,
  selectCountiesScoresLayer,
  selectZipcodeScoresLayer,
  ({ county, zipCode }, countyScores, zipcodeScores) => {
    return {
      county: countyScores.find(({ properties }) => properties.GEOID === county)
        ?.properties,
      zipCode: zipcodeScores.find(
        ({ properties }) => properties.GEOID20 === zipCode
      )?.properties,
    };
  }
);

// adequacy layer

function makeAdequacyLayer(shapes, scores, specialtyId, highlight) {
  const specialtyScores = scores?.[specialtyId] || {};
  return shapes.map((shape) => {
    const { county_id, name, geometry } = shape;
    const countyScores = specialtyScores[county_id];
    const countyPropScores = !countyScores
      ? undefined
      : Object.keys(countyScores).length === 0
      ? { ao: 0, so: 0 }
      : countyScores;

    const polygon = { geometry, type: "Feature" };
    return {
      geometry,
      properties: {
        GEOID: county_id,
        NAME: name,
        ...countyPropScores,
        ...(countyPropScores ? { hasMetric: 1 } : undefined),
        geoCenter: centroid(polygon),
        highlight,
      },
    };
  });
}

// no metrics layer

function makeEmptyLayer(shapes, highlight) {
  return shapes.map((shape) => {
    const { county_id, name, geometry } = shape;

    const polygon = { geometry, type: "Feature" };
    return {
      geometry,
      properties: {
        GEOID: county_id,
        NAME: name,
        geoCenter: centroid(polygon),
        highlight,
      },
    };
  });
}

// total gaps layer

function getScoreGaps(scores) {
  const areaGapCount = {};
  let totalGapCount = 0;
  for (const specialtyScores of Object.values(scores ?? {})) {
    for (const [areaId, { ao }] of Object.entries(specialtyScores)) {
      const gap = ao < 1 ? 1 : 0;
      areaGapCount[areaId] = (areaGapCount[areaId] || 0) + gap;
      totalGapCount += gap;
    }
  }
  const maxGapCount = Math.max(...Object.values(areaGapCount));
  return [areaGapCount, totalGapCount, maxGapCount];
}

export const gapsBucketsDefinition = [
  { color: "#0d8f2e", from: 0, to: 0 },
  { color: "#80FF00", from: 1, to: 1 },
  { color: "#FFFF00", from: 2, to: 4 },
  { color: "#FF8000", from: 5, to: 7 },
  { color: "#f66565", from: 8, to: 10 },
  { color: "#b70b0b", from: 11 },
];

export const gapsLayerBuckets = gapsBucketsDefinition
  .map((definition, i) => {
    const j = gapsBucketsDefinition.length - (i + 1);
    return {
      bucket: j + 1,
      ...definition,
    };
  })
  .reverse();

function makeGapsLayer(shapes, scores, highlight) {
  const [countyGapCount, totalGapCount] = getScoreGaps(scores);
  return shapes.map((shape) => {
    const { county_id, name, geometry } = shape;
    const polygon = { geometry, type: "Feature" };
    const gapCount = countyGapCount[county_id];
    const fillColor = gapsBucketsDefinition.find(
      (b) => gapCount >= b.from && gapCount <= (b.to ?? +Infinity)
    )?.color;
    return {
      geometry,
      properties: {
        GEOID: county_id,
        NAME: name,
        gapCount,
        totalGapCount,
        fillColor,
        hasMetric: 1,
        geoCenter: centroid(polygon),
        highlight,
      },
    };
  });
}
