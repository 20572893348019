import { useSelector } from "react-redux";
import {
  selectHoveredLayers,
  selectNetworkZipcodeScores,
} from "../../features/layersData";
import { selectNetworkCountiesScores } from "../../features/scores";
import { selectSpecialtyFilterVal } from "../../features/providers";
import { convertToPercent } from "../../utils";
import { Badge, styled } from "@mui/material";
import {
  SCORES,
  ADEQUACIES,
  THRESHOLD_REDUCTIONS,
} from "@j2nm/score-metric-keys";

const ThresholdReduction = styled(Badge)(() => ({
  marginLeft: "18px",
  marginRight: "12px",
  "> span": { fontSize: "8px", height: "14px" },
}));

export function HoveredRegion() {
  const specialtyId = useSelector(selectSpecialtyFilterVal);
  const countiesScores =
    useSelector(selectNetworkCountiesScores)?.[specialtyId] ?? {};
  const zipScores = useSelector(selectNetworkZipcodeScores);

  const { county = {}, zipCode = {} } = useSelector(selectHoveredLayers);

  const { NAME: regionName, GEOID: countyId } = county;
  const zip = zipCode["GEOID20"];
  const countyScore = countiesScores[countyId] || {};
  const zipScore = zipScores[zip] || {};

  const {
    [SCORES.ACCESS]: totalScoreCounty,
    [ADEQUACIES.OVERALL]: adequateCounty,
    [THRESHOLD_REDUCTIONS.CON]: rtr,
    [THRESHOLD_REDUCTIONS.EXPANSION]: rte,
  } = countyScore;
  const { [SCORES.ACCESS]: totalScoreZip, [ADEQUACIES.OVERALL]: adequateZip } =
    zipScore;
  const hasGapCounty = !adequateCounty;
  const hasGapZip = !adequateZip;

  return (
    <div className="county-stats">
      <div className="label">mouse over</div>
      <HoverDetails
        name={regionName}
        score={totalScoreCounty}
        hasGap={hasGapCounty}
      >
        {rtr > 0 && <ThresholdReduction color="success" badgeContent="CON" />}
        {rte > 0 && <ThresholdReduction color="success" badgeContent="EXP" />}
      </HoverDetails>
      <HoverDetails name={zip} score={totalScoreZip} hasGap={hasGapZip} />
    </div>
  );
}

function HoverDetails({ name, score, hasGap, children }) {
  return (
    <div className="county-stats-details">
      <span className="region">
        {name ? `${name}${!isNaN(score) ? ":" : ""}` : "-"}
      </span>
      <strong className={hasGap ? "gap" : null}>
        {name && !isNaN(score) ? convertToPercent(score, true) : null}
      </strong>
      {children}
    </div>
  );
}
