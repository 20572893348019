import { ClassOutlined, Delete } from "@mui/icons-material";
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";

import React, { useState } from "react";
import { PersistedItemsList } from "./PersistedItemsList";

export function PersistedItems({
  state,
  api,
  items = state.persistedItems,
  icon,
  context,
}) {
  const [open, setOpen] = useState(false);

  const { setPersistedItems, applyPersistedItem, storage, renderItem, labels } =
    api;
  return (
    <>
      <Button
        className="action-show"
        variant="contained"
        size="small"
        color="inherit"
        onClick={() => setOpen(true)}
        startIcon={
          <Badge
            badgeContent={items.length}
            color="primary"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            overlap="circular"
          >
            {icon ?? <ClassOutlined />}
          </Badge>
        }
      >
        My {labels[1]}
      </Button>
      <Dialog fullWidth onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Saved {labels[1]}</DialogTitle>
        <DialogContent>
          <PersistedItemsList
            items={items}
            context={context}
            renderItem={renderItem}
            renderItemActions={(item) => {
              return (
                <Stack direction="row" spacing={1}>
                  <Button
                    size="small"
                    onClick={() => {
                      const [, value] = item;
                      setOpen(false);
                      applyPersistedItem(value);
                    }}
                  >
                    apply {labels[1]}
                  </Button>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      const name = item[0];
                      const persistedItems = storage.deleteItem(name);
                      setPersistedItems(persistedItems);
                      if (!persistedItems) setOpen(false);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
