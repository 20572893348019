import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { EditOutlined, VisibilityOutlined } from "@mui/icons-material";
import { CustomStandardCopyButton } from "./CustomStandardCopyButton";
import { CustomStandardDeleteButton } from "./CustomStandardDeleteButton";
import { CustomStandardArchiveButton } from "./CustomStandardArchiveButton";
import { CustomStandardUnarchiveButton } from "./CustomStandardUnarchiveButton";

const commonColumns = [
  { field: "name", headerName: "Standard Name", width: 250 },
  { field: "created_by", headerName: "Created By", width: 200 },
  {
    field: "updated_at",
    headerName: "Last Modified",
    width: 120,
    valueGetter: ({ row }) => new Date(row.updated_at),
    valueFormatter: ({ value }) =>
      new Intl.DateTimeFormat("en-US").format(value),
  },
];

const activeStandardsColumns = [
  {
    field: "draft",
    headerName: "Status",
    width: 120,
    valueGetter: ({ row }) => (row.draft ? "Draft" : "Released"),
  },
  {
    field: "actions",
    type: "actions",
    headerName: "",
    className: "actions",
    width: 250,
    getActions: (params) => {
      const { id, row } = params;
      const isReleased = !row.draft;
      const editButton = (
        <Button
          size="small"
          startIcon={<EditOutlined />}
          component={Link}
          to={`${id}/edit`}
        >
          Edit
        </Button>
      );
      const showButton = (
        <Button
          size="small"
          startIcon={<VisibilityOutlined />}
          component={Link}
          to={String(id)}
        >
          View
        </Button>
      );

      const copyButton = <CustomStandardCopyButton standard={row} />;
      const deleteButton = <CustomStandardDeleteButton standard={row} />;
      const archiveButton = <CustomStandardArchiveButton standard={row} />;

      return isReleased
        ? [showButton, copyButton, archiveButton]
        : [editButton, deleteButton];
    },
  },
];

const archivedStandardsColumns = [
  {
    field: "is_removed",
    headerName: "Status",
    width: 120,
    valueGetter: ({ row }) => (row.is_removed ?? true ? "Archived" : "Active"),
  },
  {
    field: "actions",
    type: "actions",
    headerName: "",
    className: "actions",
    width: 250,
    getActions: (params) => {
      const { row } = params;

      const unarchiveButton = <CustomStandardUnarchiveButton standard={row} />;

      return [unarchiveButton];
    },
  },
];

export function makeStandardsGridcolumns(columns = activeStandardsColumns) {
  return [...commonColumns, ...columns];
}

export const standardsGridColumns = makeStandardsGridcolumns();
export const archivedStandardsGridColumns = makeStandardsGridcolumns(
  archivedStandardsColumns
);
