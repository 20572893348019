import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import {
  getGapSummaryAggregationModel,
  getGapSummaryColumns,
  getGapSummaryRows,
} from "./utils";
import { CircularProgress } from "@mui/material";

export function GapSummaryGrid({
  rows: data,
  initialState,
  loading,
  ...commonProps
}) {
  const rows = useMemo(() => getGapSummaryRows(data), [data]);
  const columns = useMemo(() => getGapSummaryColumns(data), [data]);
  const initState = {
    ...initialState,
    aggregation: getGapSummaryAggregationModel(columns),
  };

  if (loading) return <CircularProgress size={14} />;

  return (
    <DataGridPremium
      sx={{
        "& .MuiDataGrid-aggregationColumnHeaderLabel": { display: "none" },
      }}
      {...commonProps}
      initialState={initState}
      rows={rows}
      columns={columns}
    />
  );
}
