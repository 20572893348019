import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import {
  selectAllApiActivity,
  selectApiActivity,
  selectIsApiBusy,
} from "../features/apiActivity";

export function ApiActivityProgress() {
  const isBusy = useSelector(selectIsApiBusy);
  const apiRequests = useSelector(selectAllApiActivity);
  const { toProcess, updating } = useSelector(selectApiActivity);

  return isBusy ? (
    <div className="mapLoading">
      <span>
        {updating.length > 0 ? "Updating" : "Loading"} data{" "}
        {toProcess - apiRequests.length + 1} / {toProcess}
      </span>
      <CircularProgress color="primary" size={14} thickness={3} />
    </div>
  ) : null;
}
