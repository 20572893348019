import { ArrowForwardIosSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";

export function PersistedItemsList({
  items,
  renderItem,
  renderItemActions,
  context,
}) {
  return (
    <List>
      {items.map((item) => {
        return (
          <PersistedItem
            key={item[0]}
            item={[item[0], renderItem(item[1], context)]}
          >
            {renderItemActions && renderItemActions(item)}
          </PersistedItem>
        );
      })}
    </List>
  );
}

function PersistedItem({ item, children }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [name, value] = item;

  return (
    <ListItem
      disableGutters
      sx={{
        padding: "0",
        border: "1px solid rgba(0, 0, 0, .125)",
        "&:not(:last-child)": {
          borderBottom: 0,
        },
      }}
    >
      <Accordion
        disableGutters
        elevation={0}
        square
        expanded={expanded === name}
        onChange={handleChange(name)}
        sx={{
          "&:before": {
            display: "none",
          },
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
          sx={{
            flexDirection: "row-reverse",
            alignItems: "center",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            backgroundColor: "rgba(0, 0, 0, .03)",
            "& .MuiAccordionSummary-content": {
              marginLeft: "8px",
            },
          }}
        >
          <ListItemText
            primary={name}
            sx={{ display: "flex", alignItems: "center" }}
          />
          {children}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "16px",
            borderTop: "1px solid rgba(0, 0, 0, .125)",
          }}
        >
          {value}
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
}
