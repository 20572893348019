/* eslint-disable no-sequences */
import { createSelector } from "@reduxjs/toolkit";
import { arrToMap } from "../utils";
import { selectSelectedNetwork } from "./networks";

export const selectSpecialties = createSelector(
  selectSelectedNetwork,
  (network) => network?.specialties
);

export const selectSpecialtiesMap = createSelector(selectSpecialties, (items) =>
  arrToMap(items, "id", ({ name }) => name)
);
