import { createSlice } from "@reduxjs/toolkit";

export const currentClientSlice = createSlice({
  name: "currentClient",
  initialState: null,
  reducers: {
    setCurrentClient(state, { payload }) {
      return payload;
    },
  },
});

export const { setCurrentClient } = currentClientSlice.actions;

export const selectCurrentClient = (state) => state.currentClient;
