import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useBlocker } from "react-router-dom";

export function UnsavedChangesWarning({ when }) {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      when && currentLocation.pathname !== nextLocation.pathname
  );
  return blocker.state === "blocked" ? (
    <Dialog open={blocker.state === "blocked"}>
      <DialogTitle>You have unsaved changes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to leave the form with unsaved changes
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => blocker.reset()}>Cancel</Button>
        <Button
          onClick={() => {
            blocker.proceed();
          }}
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
