import { createSelector } from "@reduxjs/toolkit";
import { selectSelectedNetwork, selectServiceArea } from "./networks";
import { selectSpecialtiesMap } from "./specialties";
import { selectIsAllInNetworkFilterVal } from "./providers";
import { customStringColumnType } from "./tableColumns/customStringColumn";
import {
  virtualFieldHandlers,
  networkStatusColumn,
  oigSanctionedColumn,
} from "./tableColumns/virtualFields";

const MODELS_KEY = "view_models";
const VIRTUAL_FIELD_KEY = "virtual";
const DEFAULT_MODEL_KEY = "P_DEFAULT";
const FALLBACK_MODEL = [
  { field: "id", label: "ID" },
  { field: "npi", label: "NPI" },
];

const isVirtual = (field) => field[VIRTUAL_FIELD_KEY];

const getModelByKey = (models, key) =>
  models[key] || models[DEFAULT_MODEL_KEY] || FALLBACK_MODEL;

const makeSelectTableConfig = (modelKey, checkProvidersFilter = false) =>
  createSelector(
    selectSelectedNetwork,
    selectServiceArea,
    selectSpecialtiesMap,
    selectIsAllInNetworkFilterVal,
    (network, serviceArea, specialties, isAllFilter) => {
      if (!network) return {};
      const ctx = { network, serviceArea, specialties };
      const modelFields = getModelByKey(network[MODELS_KEY], modelKey);
      const columns =
        checkProvidersFilter && isAllFilter ? [networkStatusColumn] : [];
      const sortModel = [];
      for (const modelField of modelFields) {
        const { field, label = field, type } = modelField;
        const customStringFilterOperators =
          type === "string"
            ? { filterOperators: customStringColumnType.filterOperators }
            : undefined;
        if (isVirtual(modelField)) {
          const handler = virtualFieldHandlers[field];
          if (handler) columns.push(handler(modelField, ctx));
        } else {
          columns.push({
            field: field,
            headerName: label,
            type,
            filterable: true,
            sortable: true,
            ...customStringFilterOperators,
          });
        }
        if (modelField.sort_order) {
          sortModel.push({ field, sort: modelField.sort_order });
        }
      }
      columns.push(oigSanctionedColumn); // add this as a last column, then filter out if feature flag not enabled
      return { columns, sortModel };
    }
  );

export const selectTableConfig_P_MAP_TABLE_ROW =
  makeSelectTableConfig("P_MAP_TABLE_ROW");

export const selectTableConfig_P_ALL_INN_TABLE_ROW = makeSelectTableConfig(
  "P_ALL_INN_TABLE_ROW"
);
export const selectTableConfig_P_ALL_OON_TABLE_ROW = makeSelectTableConfig(
  "P_ALL_OON_TABLE_ROW"
);
export const selectTableConfig_P_EDITS_TABLE_ROW =
  makeSelectTableConfig("P_EDITS_TABLE_ROW");

export const selectTableConfig_P_MAP_TABLE_ROW_extended = makeSelectTableConfig(
  "P_MAP_TABLE_ROW",
  true
);
