import { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { track } from "@amplitude/analytics-browser";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "../features/currentClient";

export function useAmplitudeEvent() {
    const userEmail = useAuth0().user?.email;
    const clientName = useSelector(selectCurrentClient);

    const trackAmplitudeEvent = useCallback((eventName, eventProps = {}) => {
        return track(eventName, {
            userEmail,
            clientName,
            impersonation: clientName !== "Develop" && userEmail?.includes("@j2health.com"),
            ...eventProps,
        });
    }, [userEmail, clientName]);

    return trackAmplitudeEvent;
}