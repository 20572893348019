import {
  GridMenu,
  gridClasses,
  useGridRootProps,
} from "@mui/x-data-grid-premium";
import { MenuList, useForkRef } from "@mui/material";
import useId from "@mui/material/utils/useId";
import React from "react";

// adapted https://github.com/mui/mui-x/blob/809b5c7e0ed265e6548401831c0c24ac0d71d25a/packages/grid/x-data-grid/src/components/toolbar/GridToolbarExportContainer.tsx#L11

export const CustomGridToolbarExportContainer = React.forwardRef(
  function CustomGridToolbarExportContainer(props, ref) {
    const { children, onClick, name, ...other } = props;
    // const network = useSelector(selectSelectedNetwork);

    // const [download] = useGetDownloadFilingMutation();

    const rootProps = useGridRootProps();
    const exportButtonId = useId();
    const exportMenuId = useId();

    const [open, setOpen] = React.useState(false);
    const buttonRef = React.useRef(null);
    const handleRef = useForkRef(ref, buttonRef);

    const handleMenuOpen = (event) => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleMenuClose = () => setOpen(false);

    const handleListKeyDown = (event) => {
      if (event.key === "Tab") {
        event.preventDefault();
      }
      if (event.key === "Tab" || event.key === "Escape") {
        handleMenuClose();
      }
    };

    const handleMenuClickAway = (event) => {
      if (
        buttonRef.current === event.target ||
        // if user clicked on the icon
        buttonRef.current?.contains(event.target)
      ) {
        return;
      }
      setOpen(false);
    };

    // if (network.taxonomy_id !== "HSD") return null;
    return (
      <React.Fragment>
        <rootProps.slots.baseButton
          ref={handleRef}
          size="small"
          startIcon={<rootProps.slots.exportIcon />}
          aria-expanded={open}
          aria-label={name}
          aria-haspopup="menu"
          aria-controls={open ? exportMenuId : undefined}
          id={exportButtonId}
          {...other}
          onClick={handleMenuOpen}
          {...rootProps.slotProps?.baseButton}
        >
          {name}
        </rootProps.slots.baseButton>
        <GridMenu
          open={open}
          target={buttonRef.current}
          onClickAway={handleMenuClickAway}
          position="bottom-start"
        >
          <MenuList
            id={exportMenuId}
            className={gridClasses.menuList}
            aria-labelledby={exportButtonId}
            onKeyDown={handleListKeyDown}
            autoFocusItem={open}
          >
            {React.Children.map(children, (child) => {
              if (!React.isValidElement(child)) {
                return child;
              }
              return React.cloneElement(child, { hideMenu: handleMenuClose });
            })}
          </MenuList>
        </GridMenu>
      </React.Fragment>
    );
  }
);
