import { Stack, Drawer } from "@mui/material";
import "./AppDrawer.css";
import logo from "../../assets/j2logo.png";
import Account from "../Account";
import { WorkspaceSelector } from "./WorkspaceSelector";

export function AppDrawerContainer({ children }) {
  return (
    <Drawer variant="permanent" className="app-drawer">
      <img className="logo" src={logo} alt="Logo" width={80} height={80} />
      <WorkspaceSelector />
      <Stack className="app-drawer-main" height={"100%"}>
        {children}
      </Stack>
      <Account />
    </Drawer>
  );
}
