import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { selectNetworks } from "../../features/networks";
import {
  selectSelectedNetworkId,
  setSelectedNetworkId,
} from "../../features/selectedNetwork";

export const NetworkSelector = () => {
  const dispatch = useDispatch();
  const networks = useSelector(selectNetworks);
  const selectedNetworkId = useSelector(selectSelectedNetworkId);

  return (
    <div className="network-selector">
      <Select
        value={selectedNetworkId ?? ""}
        onChange={(e) => {
          dispatch(setSelectedNetworkId(e.target.value));
        }}
      >
        {networks?.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
