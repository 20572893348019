export function clearCellErrors(updatedRows, updatedColumn) {
  function clearReducer(errors) {
    const clearedErrors = updatedRows.reduce((acc, row) => {
      const rowErrors = { ...errors[row.id] };
      if (rowErrors[updatedColumn]) delete rowErrors[updatedColumn];
      if (Object.keys(rowErrors).length > 0)
        Object.assign(acc, { [row.id]: rowErrors });
      else delete acc[row.id];
      return acc;
    }, {...errors});

    return {...clearedErrors};
  }
  return clearReducer;
}
