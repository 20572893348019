import { Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";

export function PersistItemForm({ onSave }) {
  const [name, setName] = useState("");
  return (
    <Stack direction="row" spacing={2} paddingTop="8px">
      <TextField
        autoFocus
        variant="outlined"
        size="small"
        margin="none"
        label="enter name for saved values"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (name) onSave(name);
          }
        }}
        sx={{ width: "100%" }}
      />
      <Button
        variant="contained"
        size="small"
        disabled={!name}
        onClick={() => onSave(name)}
      >
        Save
      </Button>
    </Stack>
  );
}
