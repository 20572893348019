import {
  gridFilterModelSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import React from "react";
import { Badge, Button } from "@mui/material";
import { GridFilterAltIcon } from "@mui/x-data-grid-premium";
import { selectSelectedNetworkId } from "../../features/selectedNetwork";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../features/scorecard";

const gapCountFilter = {
  id: "gapCount",
  field: "gapCount",
  operator: ">",
  value: 0,
};

export function GapCountFilterButton() {
  const networkId = useSelector(selectSelectedNetworkId);
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

  const dispatch = useDispatch();

  const gapCountFilterIndex =
    filterModel?.items?.findIndex((item) => item.field === "gapCount") ?? -1;

  const toggleGapCountFilter = () => {
    if (apiRef.current?.state?.rows?.loading) return;
    const model = {
      ...filterModel,
      items: [...(filterModel?.items ?? [])],
    };
    if (gapCountFilterIndex > -1) {
      // filter found, remove
      model.items.splice(gapCountFilterIndex, 1);
    } else {
      // not found, add
      model.items.push(gapCountFilter);
    }

    apiRef.current.setFilterModel(model);
    dispatch(
      setFilter({
        filters: model,
        networkId,
      })
    );
  };

  return (
    <Button
      color="primary"
      size="small"
      startIcon={
        <Badge
          badgeContent={gapCountFilterIndex > -1 ? 1 : 0}
          color="primary"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          overlap="circular"
        >
          <GridFilterAltIcon />
        </Badge>
      }
      onClick={toggleGapCountFilter}
    >
      Gaps
    </Button>
  );
}
