// change `fieldName` to `field` in `providerNetowork.filters`
export function changeFieldNameToField(state) {
  const { providersNetwork } = state;
  providersNetwork.filters = providersNetwork.filters.map(
    ({ fieldName, value }) => ({
      field: fieldName,
      value,
    })
  );
  return {
    ...state,
    providersNetwork,
  };
}
