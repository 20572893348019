import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { fieldValidator, validate } from "../../utils/formValidate";
import { useGetTaxonomyGeographiesQuery } from "../../api/networksApi";
import { Close } from "@mui/icons-material";
import { SaveCountiesToolbar } from "./SaveCountiesToolbar";
import { NotEditableFieldTooltip } from "./NonEditableFieldTooltip";

const schema = {
  counties: validate([
    (input) => {
      const { field, values } = input;
      return values[field].length
        ? { ok: input }
        : { error: "cannot be empty" };
    },
  ]),
};

const pasteSplitRx = /\r?\n|,/;

export function CountiesSelect({
  onChange,
  disabled,
  lob,
  value,
  initState,
  initCounties,
  error,
  setError,
  validateAndSetError,
  showStateSelect = true,
  disableStateSelect = false,
  disableQuickSave = false,
}) {
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [notMatchedPaste, setNotMatchedPaste] = useState([]);

  // controls counties select box open/close
  // not mandatory but used here because of select all button UX
  const [open, setOpen] = useState(false);

  const { data: statesOptions, isFetching } = useGetTaxonomyGeographiesQuery(
    lob,
    { skip: !lob }
  );

  const StateInputWrapper = disableStateSelect
    ? NotEditableFieldTooltip
    : React.Fragment;

  const stateCounties = selectedStateOption?.counties?.filter(Boolean) || [];
  const disabledStateSelect =
    disableStateSelect || isFetching || !statesOptions || disabled;
  const disabledCountiesSelect =
    showStateSelect && (!selectedStateOption || isFetching || disabled);

  const validateField = fieldValidator(schema, { counties: value });

  const setCounties = useCallback(
    (newCounties) => {
      if (setError)
        setError(
          open
            ? undefined
            : schema.counties({
                field: "counties",
                values: { counties: newCounties },
              }).error
        );
      onChange(newCounties);
      if (validateAndSetError)
        validateAndSetError({ value: newCounties, clearError: open });
    },
    [onChange, open, setError, validateAndSetError]
  );

  // when one state option, set it
  useEffect(() => {
    setSelectedStateOption(
      initState
        ? statesOptions?.find(({ code }) => code === initState)
        : statesOptions?.length === 1
        ? statesOptions[0]
        : null
    );
  }, [statesOptions, initState]);

  useEffect(() => {
    if (showStateSelect) {
      setError();
      if (initCounties) onChange(initCounties);
    }
  }, [initCounties, onChange, setError, showStateSelect, statesOptions]);
  return (
    <>
      {showStateSelect && (
        <StateInputWrapper>
          <Autocomplete
            id="state-select"
            size="small"
            options={statesOptions || []}
            isOptionEqualToValue={(option, value) =>
              option.state === value.state
            }
            value={
              // this prevents: MUI: The value provided to Autocomplete is invalid.
              // this prevents `statesOptions` not including `selectedStateOption`
              // when `statesOptions` changes and `useEffect` has not yet set new `selectedStateOption` value
              statesOptions?.find(
                ({ state }) => state === selectedStateOption?.state
              )
                ? selectedStateOption
                : null
            }
            disabled={disabledStateSelect}
            getOptionLabel={(option) => option.state_name}
            onChange={(_, value) => {
              setSelectedStateOption(value);
              setCounties([]); // reset counties on each state selection change
              setError && setError();
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="State"
                required
                helperText={
                  !disableStateSelect && !disabled
                    ? "HINT: select state and then pick your network counties"
                    : ""
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isFetching && (
                        // show spinner while loading counties
                        <CircularProgress
                          color="primary"
                          size={14}
                          thickness={3}
                        />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable browser autocomplete and autofill
                  // autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </StateInputWrapper>
      )}

      <Autocomplete
        multiple
        disableCloseOnSelect
        fullWidth
        id="counties-select"
        size="small"
        disabled={disabledCountiesSelect}
        options={stateCounties}
        value={value}
        getOptionLabel={(option) => option.county_name}
        isOptionEqualToValue={(option, value) => option.fips === value.fips}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} />
            {option.county_name} ({option.fips})
          </li>
        )}
        onChange={(_event, selections) => setCounties(selections)}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onPaste={(e) => {
          e.preventDefault();

          const paste = e.clipboardData?.getData("text") ?? "";
          if (!paste) return;

          const inputValuesSet = new Set(
            paste.split(pasteSplitRx).filter((i) => i)
          );
          const inputValues = [...inputValuesSet];
          let notMatched = [];
          let selections = [];
          for (const input of inputValues) {
            const matched = stateCounties.filter(
              (o) =>
                o.fips === input ||
                o.county_name.toLowerCase() === input.toLowerCase()
            );
            if (matched.length > 0) selections.push(matched);
            else notMatched.push(input);
          }
          setNotMatchedPaste(notMatched);
          setCounties(selections.flat());
          setOpen(false);
        }}
        renderInput={(params) => (
          <>
            <Stack direction="row" spacing={1}>
              <TextField
                {...params}
                margin="dense"
                label="Counties"
                required
                onFocus={() =>
                  setError
                    ? setError()
                    : validateAndSetError({ clearError: true })
                }
                onBlur={() =>
                  setError
                    ? setError(validateField("counties").error)
                    : validateAndSetError()
                }
                error={!!error}
                helperText={error}
              />
              {!disabled && (
                <Button
                  size="small"
                  disabled={disabledCountiesSelect}
                  sx={{
                    height: "fit-content",
                    width: "92px",
                    marginTop: "14px !important",
                    flexShrink: 0,
                  }}
                  onClick={() => {
                    setCounties(value.length ? [] : stateCounties);
                    setOpen(false);
                  }}
                >
                  {value.length ? "Clear all" : "Select all"}
                </Button>
              )}
            </Stack>
            {!disableQuickSave && selectedStateOption && (
              <SaveCountiesToolbar
                onApply={(v) => setCounties(Object.values(v)[0])}
                initState={selectedStateOption.state_name}
                selectedCounties={value}
              />
            )}
            {notMatchedPaste.length > 0 && (
              <Snackbar
                open={notMatchedPaste.length > 0}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setNotMatchedPaste([])}
              >
                <Alert severity="warning">
                  <Stack
                    direction={"row"}
                    gap={"16px"}
                    alignItems={"flex-start"}
                  >
                    <div>
                      Following pasted values has not been matched:
                      <br />
                      <strong>{notMatchedPaste.join(", ")}</strong>
                    </div>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="tomato"
                      onClick={() => setNotMatchedPaste([])}
                      sx={{ marginTop: "-8px", flexShrink: 0 }}
                    >
                      <Close />
                    </IconButton>
                  </Stack>
                </Alert>
              </Snackbar>
            )}
          </>
        )}
      />
    </>
  );
}
