import React from "react";
import { Badge } from "@mui/material";

import { onlyCountyColumn } from "../../features/scorecard";
import "./ColumnsButton.css";
import {
  gridPreferencePanelStateSelector,
  GridPreferencePanelsValue,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid-premium";

const GridToolbarColumnsButton = React.forwardRef(
  function GridToolbarColumnsButton(props, ref) {
    const { onClick, ...other } = props;
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const { open, openedPanelValue } = useGridSelector(
      apiRef,
      gridPreferencePanelStateSelector
    );

    const showColumns = (event) => {
      if (open && openedPanelValue === GridPreferencePanelsValue.columns) {
        apiRef.current.hidePreferences();
      } else {
        apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
      }

      onClick?.(event);
    };

    // Disable the button if the corresponding is disabled
    if (rootProps.disableColumnSelector) {
      return null;
    }

    return (
      <rootProps.slots.baseButton
        ref={ref}
        size="small"
        aria-label="Counties"
        startIcon={<rootProps.slots.columnSelectorIcon />}
        {...other}
        onClick={showColumns}
        {...rootProps.slotProps?.baseButton}
      >
        Counties
      </rootProps.slots.baseButton>
    );
  }
);

export function ColumnsButton() {
  const apiRef = useGridApiContext();
  const hiddenCount = Object.entries(
    apiRef.current.state.columns.columnVisibilityModel
  ).filter(([key, val]) => onlyCountyColumn(key) && val === false).length;
  return (
    <Badge
      badgeContent={hiddenCount}
      color="primary"
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      overlap="circular"
      className="hiddenColCount"
    >
      <GridToolbarColumnsButton />
    </Badge>
  );
}
