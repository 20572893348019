import {
  arrToQueryParam,
  downloadAsFile,
  invalidateCacheOnNewNetworkState,
  makeFetchWithPages,
} from "./helpers";

//
// GET  /tenants?r=&r=
//

export const getTenantsApi = (build) => {
  return build.query({
    query: (roles) => ({
      method: "GET",
      url: `/tenants?${arrToQueryParam(roles, "r")}`,
    }),
  });
};

//
// GET /networks
//

export const getNetworksApi = (build) =>
  build.query({
    query: () => ({ method: "GET", url: "/networks" }),
  });

//
// GET  /county-shapes?networkId=
//

export const getCountyShapesApi = (build) =>
  build.query({
    queryFn: makeFetchWithPages(
      (urlParams) => ({
        method: "GET",
        url: `/county-shapes?${urlParams.toString()}`,
      }),
      (chunks) => {
        console.time("Merging /county-shapes");
        let result = [];
        for (let chunk of chunks) {
          for (const row of chunk.data) {
            result.push(row);
          }
        }
        console.timeEnd("Merging /county-shapes");
        return result;
      },
      process.env.NODE_ENV === "production" ? 6 : 3
    ),
  });

// EXAMPLE: hot to build paging the other way
// the issue is it cannot be started in parallel
// becouse serializeQueryArgs will always return same value
//
// build.query({
//   queryFn: async (args, _state, _extraOptions, baseQuery) => {
//     return baseQuery({
//       method: "GET",
//       url: `/county-shapes`,
//       params: args,
//     });
//   },
//   serializeQueryArgs: ({ queryArgs }) => {
//     return queryArgs.networkId;
//   },
//   merge: (currentCache, newItems) => {
//     for (const row of newItems) {
//       currentCache.push(row);
//     }
//   },
//   forceRefetch({ currentArg, previousArg }) {
//     return currentArg !== previousArg;
//   },
// });

//
// GET  /zip-shapes?networkId=
//

export const getZipShapesApi = (build) =>
  build.query({
    queryFn: makeFetchWithPages(
      (urlParams) => ({
        method: "GET",
        url: `/zip-shapes?${urlParams.toString()}`,
      }),
      (chunks) => {
        console.time("Merging /zip-shapes");
        let result = [];
        for (let chunk of chunks) {
          for (const row of chunk.data) {
            result.push(row);
          }
        }
        console.timeEnd("Merging /zip-shapes");
        return result;
      },
      process.env.NODE_ENV === "production" ? 8 : 4
    ),
  });

//
// GET  /provider-universe?networkId=&specialtyId=&edits
//

export const getProviderUniverseApi = (build) =>
  build.query({
    queryFn: makeFetchWithPages(
      (urlParams) => ({
        method: "GET",
        url: `/provider-universe?${urlParams.toString()}`,
      }),
      (chunks) => {
        console.time("Merging /provider-universe");
        let result = [];
        for (let chunk of chunks) {
          for (const row of chunk.data) {
            result.push(row);
          }
        }
        console.timeEnd("Merging /provider-universe");
        return result;
      },
      process.env.NODE_ENV === "production" ? 20 : 10
    ),
  });

//
// GET  /provider-scores?networkId=&specialtyId=&edits
//

export const getProviderScoresApi = (build) =>
  build.query({
    queryFn: makeFetchWithPages(
      (urlParams) => ({
        method: "GET",
        url: `/provider-scores?${urlParams.toString()}`,
      }),
      (chunks) => {
        console.time("Merging /provider-scores");
        let result = {};
        for (let chunk of chunks) Object.assign(result, chunk.data);
        console.timeEnd("Merging /provider-scores");
        return result;
      },
      process.env.NODE_ENV === "production" ? 10 : 5
    ),
    providesTags: (_result, _err, { networkId, specialtyId }) => [
      { type: "Scores", id: `${networkId}-${specialtyId}` },
      { type: "Scores", id: "ALL" },
    ],
  });

//
// GET  /provider-statuses?networkId=
//

export const getProviderStatusesApi = (build) =>
  build.query({
    query: ({ networkId, baseline }) => {
      let urlParams = new URLSearchParams();
      urlParams.set("networkId", networkId);
      if (baseline) urlParams.set("baseline", baseline);
      return {
        method: "GET",
        url: `/provider-statuses?${urlParams.toString()}`,
      };
    },
    providesTags: (_result, _err, { baseline }) =>
      baseline ? [] : [{ type: "Scores", id: "COUNTY-ALL" }],
  });

//
// GET  /provider-location-tags?networkId=
//

export const getProviderLocationTagsApi = (build) =>
  build.query({
    query: (networkId) => ({
      method: "GET",
      url: `/provider-location-tags?networkId=${networkId}`,
    }),
  });

//
// GET  /county-scores?networkId=&baseline=
//

export const getCountyScoresApi = (build) =>
  build.query({
    query: ({ networkId, baseline }) => ({
      method: "GET",
      url: `/county-scores?networkId=${networkId}${
        baseline ? "&baseline=1" : ""
      }`,
    }),

    providesTags: (_result, _err, { baseline }) =>
      baseline
        ? [{ type: "Scores", id: "BASELINE-ALL" }]
        : [{ type: "Scores", id: "COUNTY-ALL" }],
  });

//
// GET  /zip-scores?networkId=&specialtyId=
//

export const getZipScoresApi = (build) =>
  build.query({
    query: ({ networkId, specialtyId }) => {
      let urlParams = new URLSearchParams();
      urlParams.set("networkId", networkId);
      urlParams.set("specialtyId", specialtyId);
      return {
        method: "GET",
        url: `/zip-scores?${urlParams.toString()}`,
      };
    },

    providesTags: (_result, _err, { networkId, specialtyId }) => [
      { type: "Scores", id: `${networkId}-${specialtyId}` },
      { type: "Scores", id: "ALL" },
    ],
  });

//
// GET  /zip-gaps?networkId
//

export const getZipGapsApi = (build) =>
  build.query({
    query: (networkId) => {
      let urlParams = new URLSearchParams();
      urlParams.set("networkId", networkId);
      return {
        method: "GET",
        url: `/zip-gaps?${urlParams.toString()}`,
      };
    },

    providesTags: (_result, _err) => [{ type: "Scores", id: "ALL" }],
  });

//
// GET  /standards?networkId=&specialtyId=
//

export const getStandardsApi = (build) =>
  build.query({
    query: ({ networkId, specialtyId }) => {
      let urlParams = new URLSearchParams();
      urlParams.set("networkId", networkId);
      if (specialtyId) urlParams.set("specialtyId", specialtyId);
      return {
        method: "GET",
        url: `/standards?${urlParams.toString()}`,
      };
    },
  });

//
// GET  /zipcode-metrics?networkId=
//

export const getZipcodeMetricsApi = (build) =>
  build.query({
    query: (networkId) => {
      let urlParams = new URLSearchParams();
      urlParams.set("networkId", networkId);
      return {
        method: "GET",
        url: `/zipcode-metrics?${urlParams.toString()}`,
      };
    },
  });

//
// GET  /zipcode-metric-values?networkId=,metricId=
//

export const getZipcodeMetricValuesApi = (build) =>
  build.query({
    query: ({ metricId, networkId }) => {
      let urlParams = new URLSearchParams();
      urlParams.set("metricId", metricId);
      urlParams.set("networkId", networkId);
      return {
        method: "GET",
        url: `/zipcode-metric-values?${urlParams.toString()}`,
      };
    },
  });

//
// POST  /network-change-events
//
// body: { networkId, toAdd, toRemove },
//

export const postNetworkChangesApi = (build) =>
  build.mutation({
    queryFn(
      { networkId, toAdd, toRemove },
      _context,
      _extraOptions,
      baseQuery
    ) {
      return baseQuery({
        method: "POST",
        url: "/network-change-events",
        body: { networkId, toAdd, toRemove },
      });
    },
    onQueryStarted: invalidateCacheOnNewNetworkState,
    invalidatesTags: (_result, error, arg) => {
      return error
        ? []
        : [
            { type: "Scores", id: `${arg.networkId}-${arg.specialtyId}` },
            { type: "Scores", id: "COUNTY-ALL" },
          ];
      // : result.impacted_specialties.map((id) => ({
      //     type: "Scores",
      //     id: `${arg.networkId}-${id}`,
      //   }));
    },
  });

//
// POST  /network-reset-changes
//
// body: { networkId },

export const postNetworkResetChangesApi = (build) =>
  build.mutation({
    queryFn({ networkId }, _context, _extraOptions, baseQuery) {
      return baseQuery({
        method: "POST",
        url: "/network-reset-events",
        body: { networkId },
      });
    },
    onQueryStarted: invalidateCacheOnNewNetworkState,
    invalidatesTags: (_result, error, arg) => {
      return error
        ? []
        : [
            { type: "Scores", id: `${arg.networkId}-${arg.specialtyId}` },
            { type: "Scores", id: "COUNTY-ALL" },
            { type: "Scores", id: "BASELINE-ALL" },
          ];
    },
  });

//
// GET  /download-hsd?networkId=
//

export const getDownloadFilingApi = (build) =>
  build.mutation({
    queryFn: async (
      { networkId, fileType = "csv", fileId },
      api,
      extraOptions,
      baseQuery
    ) => {
      let urlParams = new URLSearchParams();
      urlParams.set("networkId", networkId);
      urlParams.set("fileType", fileType);

      const result = await baseQuery({
        method: "GET",
        url: `/download-filing?${urlParams.toString()}`,
        responseHandler: (res) => res.blob(),
      });

      downloadAsFile(result.data, "zip", `filing_${fileType}_${fileId}`);

      return { data: null };
    },
  });

//
// GET  /downloads/user-guide
//

export const getDownloadUserGuideApi = (build) =>
  build.mutation({
    queryFn: async (args, api, extraOptions, baseQuery) => {
      const result = await baseQuery({
        method: "GET",
        url: "/downloads/user-guide",
        responseHandler: (res) => res.blob(),
      });

      if (result.error) return { error: result.error };
      return { data: result.data };
    },
  });

//
// GET  /reports/:reportId?networkId=
//

export const getReportDataApi = (build) =>
  build.query({
    query: ({ reportId, networkId }) => {
      let urlParams = new URLSearchParams();
      urlParams.set("networkId", networkId);
      return {
        method: "GET",
        url: `/reports/${reportId}?${urlParams.toString()}`,
      };
    },
    providesTags: (_result, _err, _args) => [
      { type: "Scores", id: "COUNTY-ALL" },
    ],
  });

//
// GET  /taxonomies
//

export const getTaxonomiesApi = (build) => {
  return build.query({
    query: () => ({
      method: "GET",
      url: `/taxonomies`,
    }),
  });
};

//
// GET  /taxonomies/:taxonmyId/geographies
//

export const getTaxonomyGeographiesApi = (build) => {
  return build.query({
    query: (taxonomyId) => ({
      method: "GET",
      url: `/taxonomies/${taxonomyId}/geographies`,
    }),
  });
};

//
// GET  /member-served?networkId=
//

export const getMemberServedApi = (build) => {
  return build.query({
    queryFn: makeFetchWithPages(
      (urlParams) => ({
        method: "GET",
        url: `/member-served?${urlParams.toString()}`,
      }),
      (chunks) => {
        console.time("Merging /member-served");
        let result = {};
        for (let chunk of chunks) Object.assign(result, chunk.data);
        console.timeEnd("Merging /member-served");
        return result;
      },
      process.env.NODE_ENV === "production" ? 10 : 5
    ),
    providesTags: (_result, _err, { networkId, specialtyId }) => [
      { type: "Scores", id: `${networkId}-${specialtyId}` },
      { type: "Scores", id: "ALL" },
    ],
  });
};

//
// GET  /us-states
//

export const getUsStatesApi = (build) => {
  return build.query({
    query: () => ({
      method: "GET",
      url: `/us-states`,
    }),
  });
};

//
// GET  /j2-specialties
//

export const getJ2SpecialtiesApi = (build) => {
  return build.query({
    query: () => ({
      method: "GET",
      url: `/j2-specialties`,
    }),
  });
};
